import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import read from '../../../assets/icons/read_tick.svg'
import notification1 from '../../../assets/images/notification_1.png'
// import notification2 from "../../../assets/images/notification_2.png";
// import notification3 from "../../../assets/images/notification_3.png";
import { THEME_COLORS } from '../../../ThemeConfig'
import { baseConfig } from '../../../axiosConfig'
import { Context } from '../../../contexts/Store'
import EmptyPage from '../../includes/empty-page/EmptyPage'
import nodata from '../../../assets/icons/notification-no-data.svg'
import { useNavigate } from 'react-router-dom'

export default function Notification() {
  const { state } = useContext(Context)
  const [notificationDetails, setNotificationDetails] = useState([])
  const [dateString, setDateString] = useState([])

  const navigate = useNavigate()
  let getNotificationDetails = (token) => {
    let accessToken = state.user_details.access
    baseConfig
      .get('general/notifications/', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        setNotificationDetails(response.data.data)
      })
      .catch((error) => {
        const { status, data } = error?.response
      })
  }
  useEffect(() => {
    getNotificationDetails()
    window.scrollTo(0, 0)
  }, [])

  const getDate = (token) => {
    // const dateString = token
    const date = new window.Date(token)
    // Get day, month, and year components from the Date object
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0') // Note: Month is zero-based, so add 1
    const year = date.getFullYear()
    // Format the date as "dd-mm-yyyy"
    const formattedDate = `${day}-${month}-${year}`
    return formattedDate
  }

  return (
    <>
      <MainContainer>
        <MainBox>
          {notificationDetails.length > 0 ? (
            <SubContainer>
              <TitleBox>
                <Title>Notifications</Title>
                <MarkAs>
                  <Icon src={read} alt="Image" />
                  Mark as Read
                </MarkAs>
              </TitleBox>
              {notificationDetails?.map((data, i) => (
                <ReadBox key={i} onClick={() => navigate('/order-status')}>
                  <LeftBox>
                    <Img src={data.image} alt="Image" />
                  </LeftBox>
                  <RightBox>
                    <TabletName>{data.notification_title}</TabletName>
                    <Details>{data.message}</Details>
                    <Date>{getDate(data.time)}</Date>
                  </RightBox>
                  {/* {data.is_opened == false ? <Dot></Dot> : null} */}
                </ReadBox>
              ))}
            </SubContainer>
          ) : (
            <EmptyPage image={nodata} status={'No Notifications'} />
          )}
        </MainBox>
      </MainContainer>
    </>
  )
}

const MainContainer = styled.div`
  width: 90%;
  max-width: 1350px;
  margin: 0 auto;
  padding-top: 80px;
  @media screen and (max-width: 980px) {
    padding-top: 60px;
  }
  @media screen and (max-width: 768px) {
    width: 95%;
  }
`
const MainBox = styled.div`
  display: flex;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
  align-items: center;
  padding: 64px;
  @media screen and (max-width: 1280px) {
    width: 90%;
  }
  @media screen and (max-width: 980px) {
    width: 100%;
    padding: 32px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 32px;
  }
  @media screen and (max-width: 460px) {
    padding: 22px;
  }
`
const SubContainer = styled.div``
const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 42px;
`
const Title = styled.h4`
  font-size: 32px;
  font-family: 'poppins-semibold';
  @media screen and (max-width: 460px) {
    font-size: 26px;
  }
`
const MarkAs = styled.span`
  display: flex;
  align-items: center;
  color: #2863fb;
  font-family: 'poppins-medium';
  font-size: 16px;
  cursor: pointer;
  @media screen and (max-width: 460px) {
    font-size: 12px;
  }
`
const Icon = styled.img`
  display: block;
  width: 24px;
  height: 24px;
  margin-right: 6px;
`
const ReadBox = styled.div`
  position: relative;
  display: flex;
  background: ${THEME_COLORS.border_5};
  border-radius: 8px;
  gap: 23px;
  padding: 32px;
  margin-bottom: 32px;
  cursor: pointer;
  &.active {
    filter: drop-shadow(0px 14px 14px rgba(0, 0, 0, 0.08));
  }
  @media screen and (max-width: 640px) {
    flex-direction: column;
  }
`
const LeftBox = styled.div``
const Img = styled.img`
  display: block;
  width: 145px;
  height: 133px;
  object-fit: contain;
  border-radius: 9px;
`
const RightBox = styled.div``
const TabletName = styled.h4`
  color: ${THEME_COLORS.background_1};
  font-family: 'poppins-medium';
  font-size: 20px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`
const Details = styled.p`
  font-size: 19px;
  font-family: 'poppins-regular';
  color: ${THEME_COLORS.background_3};
  margin-bottom: 15px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`
const Date = styled.span`
  display: inline-block;
  font-size: 16px;
  color: ${THEME_COLORS.border_1};
  font-family: 'poppins-semibold';
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`
const Dot = styled.div`
  width: 10px;
  height: 10px;
  background: #26a541;
  border-radius: 25px;
  position: absolute;
  right: 20px;
  top: 20px;
`
