import React from 'react'
import styled from 'styled-components'
import bottlem1 from '../../../assets/icons/bottle1.svg'
import { THEME_COLORS } from '../../../ThemeConfig'
import PrimaryButton from '../../includes/buttons/PrimaryButton'
import Dropdown from '../../includes/form/Dropdown'

export default function OrderCancelation() {
  return (
    <MainContainer>
      <Container>
        <Title>Request Cancellation</Title>
        <Top>
          <Product>
            <Image>
              <Img src={bottlem1} alt="Image" />
            </Image>
            <ProductName>
              <Name>Curcumin Tablet</Name>
              <Pack>Pack of 1</Pack>
              <Pack>30 Capsules</Pack>
            </ProductName>
          </Product>
          <Price>₹ 160.00</Price>
        </Top>
        <Comments>
          <SingleForm>
            <Label>Reason for cancellations *</Label>
            <SelectInput>
              <Dropdown
                // data={"item.more_variants"}
                value={'filterValue'}
                // setValue={"setFilterValue"}
              />
            </SelectInput>
          </SingleForm>
          <SingleForm>
            <Label>Comments</Label>
            <Input type={'text'} />
          </SingleForm>
        </Comments>
        <Bottom>
          <Paragraph>
            Refund to original payment source
            <br />
            ₹160.00 will be transferred within 7 business days (Bank holidays
            not included)
          </Paragraph>
          <Button>
            <PrimaryButton text={'Confirm Cancellation'} size="small" />
          </Button>
        </Bottom>
      </Container>
    </MainContainer>
  )
}

const MainContainer = styled.div`
  padding: 100px 0;
  background: ${THEME_COLORS.border_5};
`
const Container = styled.div`
  background: ${THEME_COLORS.white};
  box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.04);
  width: 90%;
  margin: 0 auto;
  padding: 32px;
  gap: 8px;
  width: 748px;
  @media screen and (max-width: 1280px) {
    padding: 30px;
  }
  @media screen and (max-width: 980px) {
    flex-direction: column;
  }
  @media screen and (max-width: 768px) {
    padding: 30px 38px;
    width: 100%;
  }
`
const Title = styled.p`
  font-family: 'poppins-regular';
  font-size: 28px;
  color: ${THEME_COLORS.background_1};
  margin-bottom: 22px;
  @media screen and (max-width: 1280px) {
    font-size: 24px;
  }
`
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 46px;

  @media screen and (max-width: 580px) {
    flex-wrap: wrap;
  }
`
const Product = styled.div`
  display: flex;
  @media screen and (max-width: 580px) {
    flex-wrap: wrap;
    width: 100%;
  }
`

const Image = styled.div`
  margin-right: 20px;
  width: 90px;
  @media screen and (max-width: 580px) {
    width: 80%;
    margin: 0 auto;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`
const Img = styled.img`
  display: block;
  width: 100%;
`
const ProductName = styled.div`
  margin-top: 10px;
`

const Name = styled.p`
  font-family: 'poppins-regular';
  color: ${THEME_COLORS.background_1};
  font-size: 20px;
  @media screen and (max-width: 1280px) {
    font-size: 18px;
  }
`
const Pack = styled.p`
  font-family: 'poppins-regular';
  color: ${THEME_COLORS.background_4};
  font-size: 17px;
  @media screen and (max-width: 980px) {
    font-size: 14px;
  }
`
const Price = styled.p`
  color: ${THEME_COLORS.background_1};
  font-size: 20px;
  font-family: 'poppins-regular';
`
const Comments = styled.div`
  margin-bottom: 30px;
`
const SingleForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 580px) {
    flex-wrap: wrap;
  }
`
const Label = styled.label`
  font-family: 'poppins-regular';
  color: ${THEME_COLORS.background_4};
  font-size: 17px;
  width: 38%;
  @media screen and (max-width: 768px) {
    width: 30%;
  }
  @media screen and (max-width: 580px) {
    width: 100%;
  }
`
const SelectInput = styled.div`
  width: 60%;
  margin-bottom: 15px;
  @media screen and (max-width: 580px) {
    width: 100%;
  }
`
const Input = styled.input`
  width: 60%;
  padding: 14px 16px;
  background: #f9f9f9;
  border: 1px solid ${THEME_COLORS.secondary};
  border-radius: 8px;
  color: ${THEME_COLORS.background_1};
  font-size: 15px;
  font-family: 'poppins-regular';
  @media screen and (max-width: 580px) {
    width: 100%;
  }
`
const Bottom = styled.div``
const Paragraph = styled.p`
  font-family: 'poppins-regular';
  color: ${THEME_COLORS.background_4};
  font-size: 16px;
  margin-bottom: 40px;
`
const Button = styled.div`
  display: flex;
  justify-content: end;
`
