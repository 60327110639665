import React, { useContext } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { THEME_COLORS } from '../../../ThemeConfig'
import truck from '../../../assets/icons/truck.svg'
import pack from '../../../assets/icons/package.svg'
import check from '../../../assets/icons/check.svg'
import alert from '../../../assets/icons/alert-triangle.svg'
import { Context } from '../../../contexts/Store'

const OrderCard = ({ order, active, setActive, responsive }) => {
  const navigate = useNavigate()
  const { state, dispatch, selectedAdress } = useContext(Context)

  // console.log(order, 'orderorderorder')
  const ResponsiveNav = () => {
    const order_details = {
      pk: order.pk,
    }
    dispatch({
      type: 'UPDATE_ORDER',
      order_details,
    })
    navigate(`/orders-order`)
  }

  return (
    <OrderItem
      className={
        responsive
          ? 'mobile-view'
          : active.order_id === order.order_id
          ? 'active'
          : ''
      }
      onClick={() => {
        responsive ? ResponsiveNav() : setActive(order)
        window.scrollTo(0, 0)
        // const order_details = {
        //   pk: order.pk,
        // }
        // dispatch({
        //   type: 'UPDATE_ORDER',
        //   order_details,
        // })
      }}
    >
      <Left>
        <Img src={order?.order_items[0].image} alt="product" />
      </Left>
      <Middle>
        <Id>{order?.order_id}</Id>
        <NameWrapper>
          <Name>
            {order?.order_items[0].variant_name}{' '}
            {order?.items_count > 1 && (
              <>
                {' '}
                + <span> {order?.items_count - 1} others</span>
              </>
            )}
          </Name>
        </NameWrapper>
        <Price>{order?.total_amount}</Price>
      </Middle>
      <Right>
        <Status>
          {order?.order_status !== 'pending' && (
            <Icon>
              <Img
                className={order?.order_status == 'pending' ? 'disabled' : ''}
                src={
                  order?.order_status === 'shipped'
                    ? truck
                    : order?.order_status === 'ordered'
                    ? pack
                    : order?.order_status === 'delivered'
                    ? check
                    : order?.order_status === 'cancelled'
                    ? alert
                    : null
                }
                alt="icon"
              />
            </Icon>
          )}
          <Text status={order?.order_status}>{order?.order_status}</Text>
        </Status>
        <Date>{order.time.split('T')[0].split('-').reverse().join('-')}</Date>
      </Right>
    </OrderItem>
  )
}

export default OrderCard

const OrderItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background: ${THEME_COLORS.white};
  border-bottom: 1px solid ${THEME_COLORS.border_4};
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
  &.active {
    background: ${THEME_COLORS.light_orange};
    border-left: 3px solid ${THEME_COLORS.secondary};
    border-radius: 6px 0px 0px 4px;
    border-bottom: none;
  }
  &.mobile-view {
    border: 2px solid #f1f1f1;
    border-radius: 12px;
    margin-bottom: 16px;
  }
`
const Left = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 8px;
`
const Middle = styled.div`
  flex-grow: 1;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
`
const Id = styled.h6`
  font-family: 'poppins-regular';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${THEME_COLORS.background_3};
`
const NameWrapper = styled.div`
  display: flex;
`
const Name = styled.h6`
  font-family: 'poppins-regular';
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: ${THEME_COLORS.background_3};
  span {
    color: ${THEME_COLORS.background_5};
  }
`
const Price = styled.h6`
  font-family: 'poppins-regular';
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: ${THEME_COLORS.background_3};
`
const Right = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
const Status = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`
const Icon = styled.div`
  margin-right: 8px;
  width: 16px;
  height: 16px;
`
const Text = styled.span`
  color: ${(props) =>
    props.status === 'Shipping'
      ? '#356DF2'
      : props.status === 'Packed'
      ? '#D87400'
      : props.status === 'Delivered'
      ? '#0FC642'
      : props.status === 'Cancelled'
      ? '#E71C22'
      : ''};
  font-family: 'poppins-regular';
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
`
const Date = styled.div`
  font-family: 'poppins-regular';
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: ${THEME_COLORS.border_1};
  @media all and (max-width: 1080px) {
    font-size: 9px;
  }
  @media all and (max-width: 460px) {
    font-size: 11px;
  }
`
const Img = styled.img`
  display: block;
  width: 100%;
  max-height: 100%;
  &.disabled {
    display: none;
  }
`
