import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'
import DefaultLottie from '../../includes/common/loader/DefaultLottie'
import Intro from './Intro'
import MissionAndVison from './MissionAndVison'
import About from './About'
import Team from './Team'
import Promise from './Promise'
import Spotlight from './Spotlight'

export default function AboutPage() {
  const [isLoading, setLoading] = useState(true)
  // isLoading(true)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <MainContainer>
        <>
          <Spotlight />
          <Intro />
          <About />
          <MissionAndVison />
          <Team />
          <Promise />
        </>
      </MainContainer>
    </>
  )
}

const MainContainer = styled.div`
  /* width: 90%;
    max-width: 1350px;
    margin: 0 auto; */
  padding-top: 50px;
  @media screen and (max-width: 980px) {
    padding-top: 60px;
  }
`
