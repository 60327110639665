import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import loginimage from "../../../assets/icons/forgot-icon.svg";
import PrimaryButton from "../../includes/buttons/PrimaryButton";
import InputForm from "../../includes/form/InputForm";
import close from "../../../assets/icons/close-square.svg";
import { AuthContext } from "../../../contexts/AuthStore";
import Button from "../../includes/buttons/Button";
import { baseConfig } from "../../../axiosConfig";
import { THEME_COLORS } from "../../../ThemeConfig";
export default function ForgotPage({ setModal }) {
	// const [username, setUsername] = useState("");
	const [email, setEmail] = useState("");
	const [emailValidation, setEmailValidation] = useState(false);
	const { authstate, authdispatch } = useContext(AuthContext);
	const [seconds, setSeconds] = useState(10);
	const [message, setMessage] = useState("");
	const [errorEmailMsg, setErrorEmailMsg] = useState("");

	useEffect(() => {
		const interval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1);
			}
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, [seconds]);

	// Mail Validation
	const validate = (text) => {
		let reg = /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/;
		if (reg.test(text) === false) {
			setEmailValidation(false);
			setEmail(text);
			return false;
		} else {
			setEmail(text);
			setEmailValidation(true);
		}
	};

	const handleEnter = (event) => {
		if (event.key.toLowerCase() === "enter") {
			const form = event.target.form;
			const index = [...form].indexOf(event.target);
			form.elements[index + 1].focus();
			event.preventDefault();
		}
	};
	const closeModal = () => {
		const auth_modal_details = {
			auth_modal_name: "",
			is_auth_modal: false,
		};
		authdispatch({
			type: "AUTH_MODAL",
			auth_modal_details,
		});
	};

	const handleForget = () => {
		const formData = new FormData();
		formData.append("email", email);

		baseConfig
			.post("users/send-otp/", formData, {})
			.then((response) => {
				const { StatusCode, data, token, message } = response.data;
				if (StatusCode === 6000) {
					const auth_modal_details = {
						auth_modal_name: "otp",
						is_auth_modal: true,
						email: email,
						forget_otp: true,
					};
					authdispatch({
						type: "AUTH_MODAL",
						auth_modal_details,
					});
				} else if (StatusCode === 6001) {
					setErrorEmailMsg(message);
				}
			})
			.catch((error) => {});
	};

	return (
		<MainContiner>
			<LeftBox>
				<ImageBox>
					<Img src={loginimage} alt="Image" />
				</ImageBox>
				<div>
					<Heading>Forgot Password</Heading>
					<Title>
						Enter the email id associated with your account.
					</Title>
				</div>
			</LeftBox>
			<RightBox>
				<div>
					<Form>
						<InputForm
							setValue={validate}
							focus={"autoFocus"}
							placeholder={""}
							name={"Email"}
							onKeyDown={handleEnter}
							value={email}
							type="mail"
							size={"small"}
						/>
						<ErrorMsg>{errorEmailMsg && errorEmailMsg}</ErrorMsg>
					</Form>
					<Buttons className="context">
						<Button
							text={"Cancel"}
							size={"small"}
							onClick={closeModal}
						/>
						<PrimaryButton
							// className="category_button"
							text={"Reset Password"}
							size={"small"}
							onClick={() => {
								if (!emailValidation) {
									setErrorEmailMsg(message);
								} else {
									handleForget();
								}
							}}
						/>
					</Buttons>
				</div>
				<BottomContainer>
					{/* <BottomTop>
                        <TextSub>Haven't receive mail?&nbsp;</TextSub>
                        <HomeLink
                        // onClick={() => {
                        //     const auth_modal_details = {
                        //         auth_modal_name: "register",
                        //         is_auth_modal: true,
                        //     };
                        //     authdispatch({
                        //         type: "AUTH_MODAL",
                        //         auth_modal_details,
                        //     });
                        // }}
                        >
                            {seconds > 0 ? (
                                <HomeLink>
                                    Resend in&nbsp;
                                    {seconds < 10 ? `0${seconds}` : seconds}
                                </HomeLink>
                            ) : (
                                <HomeLink>Resend</HomeLink>
                            )}
                        </HomeLink>
                    </BottomTop> */}
					{/* <TextSubTitle>
                        Back to{" "}
                        <HomeLink
                            onClick={() => {
                                const auth_modal_details = {
                                    auth_modal_name: "login",
                                    is_auth_modal: true,
                                };
                                authdispatch({
                                    type: "AUTH_MODAL",
                                    auth_modal_details,
                                });
                            }}
                        >
                            Login
                        </HomeLink>
                    </TextSubTitle> */}
				</BottomContainer>
				<RightIcon
					onClick={() => {
						closeModal();
					}}
				>
					<Img src={close} alt="Image" />
				</RightIcon>
			</RightBox>
		</MainContiner>
	);
}
const MainContiner = styled.div`
	display: flex;
	flex-direction: column;
	padding: 32px;
	max-width: 794px;
	width: 600px;
	/* height: 510px; */
	@media screen and (max-width: 640px) {
		width: 460px;
	}
	@media screen and (max-width: 480px) {
		padding: 22px;
		width: 360px;
	}
	@media screen and (max-width: 360px) {
		width: 330px;
	}
`;
const LeftBox = styled.div``;
const Heading = styled.h3`
	font-size: 28px;
	line-height: 36px;
	color: ${THEME_COLORS.background_1};
	margin-bottom: 5px;
	font-family: "poppins-medium";
`;
const Title = styled.p`
	font-size: 16px;
	line-height: 14px;
	color: ${THEME_COLORS.background_4};
	font-family: "poppins-medium";
	margin-bottom: 30px;
	width: max-content;
	@media screen and (max-width: 480px) {
		width: 300px;
	}
	@media screen and (max-width: 360px) {
		width: 100%;
	}
`;
const ImageBox = styled.div`
	width: 84px;
`;
const Img = styled.img`
	display: block;
	width: 100%;
	height: 100%;
`;
const RightBox = styled.div`
	background: ${THEME_COLORS.white};
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	&.context {
		box-shadow: 7px 11px 30px rgba(0, 0, 0, 0.06);
	}
`;
const Form = styled.div`
	width: 100%;
`;
// const HomeLink = styled.a`
// 	color: #106f39;
// 	text-decoration: underline;
// 	font-size: 14px;
// 	line-height: 30px;
// 	font-family: "poppins-medium";
// 	cursor: pointer;
// 	/* margin-left: 6px; */
// `;
// const TextSub = styled.h4`
// 	color: #aaaaaa;
// 	font-weight: 400;
// 	font-size: 14px;
// 	font-family: "poppins-medium";
// 	line-height: 28px;
// 	align-items: flex-start;
// `;
const ErrorMsg = styled.p`
    font-size: 12px;
    font-family: "poppins-regular";
    color: red;
	margin-bottom: 12px;
`;
const BottomContainer = styled.div``;
const Buttons = styled.div`
	display: flex;
	gap: 12px;
	justify-content: end;
	margin-bottom: 52px;
	@media screen and (max-width: 640px) {
		margin-bottom: 32px;
	}
	@media screen and (max-width: 480px) {
		margin-bottom: 22px;
	}
`;
// const BottomTop = styled.div`
// 	display: flex;
// 	flex-wrap: wrap;
// 	justify-content: center;
// 	align-items: center;
// `;
// const TextSubTitle = styled.h4`
// 	color: #aaaaaa;
// 	font-weight: 400;
// 	font-size: 14px;
// 	font-family: "poppins-medium";
// 	line-height: 28px;
// 	align-items: center;
// 	display: flex;
// 	justify-content: center;
// `;
const RightIcon = styled.span`
	position: absolute;
	display: inline-block;
	width: 32px;
	height: 32px;
	top: 30px;
	right: 30px;
	cursor: pointer;
	@media screen and (max-width: 480px) {
		top: 20px;
		right: 20px;
	}
`;
