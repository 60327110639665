import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'
import filter from '../../../assets/icons/filter.svg'
import arrow from '../../../assets/icons/arrow-left.svg'
import OrderCard from './OrderCard'
import FilterOrder from '../../includes/filter/FilterOrder'

export default function OrderHistoryResponsive({ orderList }) {
  const navigate = useNavigate()
  const [showFilter, setShowFilter] = useState(false)

  return (
    <Container>
      <HeadWrapper>
        <HeadLeft>
          <Arrow onClick={() => navigate(-1)}>
            <Img src={arrow} alt="left arrow" />
          </Arrow>
          <Head>Order History</Head>
        </HeadLeft>
        {/* <FilterIcon onClick={() => setShowFilter(!showFilter)}>
          <Img src={filter} alt="filter" />
        </FilterIcon> */}
        {showFilter && (
          <FilterModal>
            <FilterOrder setShowFilter={setShowFilter} />
          </FilterModal>
        )}
      </HeadWrapper>
      <OrderList>
        {orderList.map((order) => {
          return <OrderCard key={order.order_id} order={order} responsive />
        })}
      </OrderList>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  /* padding-top: 80px; */
`
const HeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
  margin-bottom: 34px;
  position: relative;
`
const HeadLeft = styled.div`
  display: flex;
  align-items: center;
`
const Arrow = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`
const Head = styled.h4`
  font-family: 'poppins-regular';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${THEME_COLORS.background_2};
`
const FilterIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Img = styled.img`
  display: block;
  width: 100%;
`
const OrderList = styled.div`
  display: flex;
  flex-direction: column;
  background: ${THEME_COLORS.white};
  border-radius: 8px;
`
const FilterModal = styled.div`
  position: absolute;
  right: 0;
  top: 48px;
`
