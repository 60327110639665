import React, { createContext, useReducer } from "react";
import AuthReducer from "./AuthReducer";
// import Reducer from "./Reducer";

const initialState = {
	// user_details: {
	// 	is_verified: false,
	// 	role: "",
	// },
	auth_modal_details: {
		auth_modal_name: "",
		is_auth_modal: false,
	},
};

const AuthStore = ({ children }) => {
	const [authstate, authdispatch] = useReducer(AuthReducer, initialState);

	return (
		<AuthContext.Provider value={{ authstate, authdispatch }}>
			{children}
		</AuthContext.Provider>
	);
};

export const AuthContext = createContext(initialState);

export default AuthStore;
