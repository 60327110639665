import React, { useState } from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'

function AddButton({ text, onClick, size, className, image }) {
  return (
    <MainContainer className={className} onClick={onClick} size={size}>
      <ChangeIcon>
        <Img src={image} alt="Image" />
      </ChangeIcon>{' '}
      {text}
    </MainContainer>
  )
}

const MainContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${(props) => (props.size === 'small' ? '12px 24px' : '16px 32px')};
    cursor: pointer;
    color: ${THEME_COLORS.white};
    font-family: "poppins-regular";
    border-radius: 6px;
    width: max-content;
    background-color: ${THEME_COLORS.secondary};
    border: 2px solid ${THEME_COLORS.secondary};
    /* &:hover {
        background-color: transparent;
        color: ${THEME_COLORS.secondary};
    } */
    font-size: ${(props) => (props.size === 'small' ? '14px' : '16px')};
    font-weight: ${(props) => (props.size === 'small' ? '500' : '600')};
    &.category_button {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
    }
    &.not-available{
      filter: opacity(0.5);
      cursor: not-allowed;
       width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
    }
`
const ChangeIcon = styled.span`
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  filter: brightness(0) invert(1);
`
const Img = styled.img`
  width: 100%;
  height: 100%;
  display: block;
`
export default AddButton
