import React from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'
import mission from '../../../assets/images/mission.svg'
import vision from '../../../assets/images/vision.svg'
import line from '../../../assets/images/bg-line.svg'

function MissionAndVison() {
  const services = [
    {
      id: 1,
      image: mission,
      title: 'MISSION',
      description:
        'The growing world population and increase in life expectancy, coupled with scarcity of resources are dramatically changing and challenging our life styles. In these dynamic settings the healthcare industry must play a critical role. As a business we aware of our responsibility towards the environment, society and our partners.',
    },
    {
      id: 2,
      image: vision,
      title: 'VISION',
      description:
        'To be a global nutraceutical company with and enduring commitment and quality. We are committed to provide our best services and products to our customers with utmost attention to maintain the highest quality of global standards and providing cost effective solutions.',
    },
  ]
  return (
    <>
      <OurServices>
        <Services>
          <SectionTop>
            <Heading>
              <span>Our</span> Mission & Vision
            </Heading>
          </SectionTop>
          <ServicesContainer ontainer>
            {services.map((data, i) => (
              <ServiceCard key={i}>
                <ServiceImage>
                  <Img src={data.image} alt="Image" />
                </ServiceImage>
                <CardTitle>{data.title}</CardTitle>
                <CardData>{data.description}</CardData>
              </ServiceCard>
            ))}
          </ServicesContainer>
        </Services>
      </OurServices>
    </>
  )
}

export default MissionAndVison

const Services = styled.div`
  width: 80%;
  max-width: 1350px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 100px 0px;
  @media screen and (max-width: 1080px) {
    padding: 70px 0;
  }
  @media screen and (max-width: 980px) {
    padding: 50px 0;
  }
`
const OurServices = styled.div`
  /* margin: 100px 0; */
  /* background: #fcfcfc; */
  background-image: url(${line});
  background-position: bottom;
  background-repeat: no-repeat;
  @media screen and (max-width: 980px) {
    background-position: center;
  }
  @media screen and (max-width: 640px) {
    background-position: top;
  }
`
const SectionTop = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const ServicesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  @media screen and (max-width: 980px) {
    grid-template-columns: 1fr;
    /* grid-gap: 25px; */
  }
`
const ServiceCard = styled.div`
  /* width: 30%; */
  padding: 20px;
  background: #f5fff9;
  box-shadow: 7px 11px 30px rgba(0, 0, 0, 0.04);
  border-radius: 24px;
  transition: 1s ease;
  border: 1px solid #106f39;
  /* :hover {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
    transition: 1s ease;
  } */
  @media screen and (max-width: 980px) {
    width: 80%;
    margin: 0 auto;
  }
  @media screen and (max-width: 640px) {
    width: 90%;
    margin: 0 auto;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    margin: 0 auto;
  }
`
const ServiceImage = styled.div`
  margin: 0 auto 10px;
  width: 80px;
  @media screen and (max-width: 640px) {
    width: 70px;
  }
  @media screen and (max-width: 480px) {
    width: 60px;
  }
`
const CardTitle = styled.h6`
  color: #232323;
  font-size: 20px;
  margin-bottom: 14px;
  line-height: 26px;
  font-family: 'poppins-medium';
  text-align: center;
  @media screen and (max-width: 980px) {
    font-size: 18px;
    margin-bottom: 8px;
  }
  @media screen and (max-width: 640px) {
    font-size: 16px;
    line-height: 23px;
  }
`
const CardData = styled.p`
  color: #464646;
  font-size: 18px;
  font-family: 'poppins-regular';
  text-align: justify;

  @media screen and (max-width: 1280px) {
    font-size: 18px;
  }
  @media screen and (max-width: 980px) {
    font-size: 16px;
  }
  @media screen and (max-width: 640px) {
    font-size: 14px;
    line-height: 24px;
  }
`
const Img = styled.img`
  width: 100%;
  display: block;
`
const Heading = styled.h6`
  font-family: 'poppins-medium';
  color: #1d1d46;
  font-size: 40px;
  margin-bottom: 35px;
  span {
    color: #106f39;
  }
  @media screen and (max-width: 1280px) {
    font-size: 36px;
  }
  @media screen and (max-width: 1080px) {
    font-size: 34px;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 25px;
    font-size: 30px;
  }
  @media screen and (max-width: 480px) {
    font-size: 26px;
  }
`
