import React from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'

function ModalCenter({
  setModal,
  isModal,
  children,
  closeModal,
  search,
  height,
  address,
}) {
  // useEffect(() => {
  // 	if (isModal) document.body.style.overflow = "hidden";
  // 	else document.body.removeAttribute("style");
  // 	return () => {
  // 		document.body.removeAttribute("style");
  // 	};
  // }, [isModal]);
  // console.log(height, 'heightheightheightheight')

  return (
    <BackContainer>
      <Overlay
        onClick={() => {
          setModal(false)
          closeModal()
        }}
      />
      <Wrapper
        className={
          search ? 'active' : height ? 'height' : address ? 'address' : ''
        }
      >
        {/* <Modal> */}
        {/* <div>{childModal({setModal, edit, data, value, setValue, input, setInput})}</div> */}
        {children}
        {/* </Modal> */}
      </Wrapper>
    </BackContainer>
  )
}
export default ModalCenter

const BackContainer = styled.div`
  position: fixed;
  transition: 0.3s;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
`
const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0px;
  width: 100%;
  cursor: pointer;
  height: 100vh;
  opacity: 0.5;
  background: ${THEME_COLORS.background_1};
  /* background: red; */
`
const Wrapper = styled.div`
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 6px;
  background: ${THEME_COLORS.white};
  transition: 0.5s;
  z-index: 10002;
  animation: slideIn 0.2s;
  /* height: 90vh; */
  /* width: 800px; */
  overflow: scroll;
  &.search {
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 6px;
    background: ${THEME_COLORS.white};
    transition: 0.5s;
    z-index: 10002;
    animation: slideIn 0.2s;
    /* height: 90vh; */
    /* width: 800px; */
    /* width: 70%; */
    overflow: scroll;
  }
  &.active {
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 6px;
    background: ${THEME_COLORS.white};
    transition: 0.5s;
    z-index: 10002;
    animation: slideIn 0.2s;
    height: 90vh;
    /* width: 800px; */
    overflow: scroll;
    /* width: 70%; */
    @media screen and (max-width: 480px) {
      width: 90%;
    }
  }
  &.address {
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 6px;
    /* background: red; */
    transition: 0.5s;
    z-index: 10002;
    animation: slideIn 0.2s;
    height: 80vh;

    /* width: 800px; */
    /* overflow: hidden; */
  }
  @keyframes slideIn {
    0% {
      left: 50%;
    }
  }
  @media screen and (max-width: 360px) {
    /* left: 40%; */
  }
`

// const Container = styled.div`
// 	/* width: 50%; */
// 	padding: 30px;
// 	display: flex;
// 	flex-direction: column;
// 	justify-content: space-between;
// 	background: red;
// `;
