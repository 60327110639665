import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { homeSvg, wishlistSvg, ordersSvg, settingsSvg } from './ProfileSvgFiles'
import { NavLink } from 'react-router-dom'
import { baseConfig } from '../../../axiosConfig'
import { Context } from '../../../contexts/Store'
import ModalCenter from '../../includes/modal/ModalCenter'
import EditProfile from './EditProfile'
import Button from '../../includes/buttons/Button'
import { THEME_COLORS } from '../../../ThemeConfig'
import profile from '../../../../src/assets/icons/no-profile.svg'
import contentcopy from '../../../assets/icons/content-copy.svg'
import DefaultLottie from '../../includes/common/loader/DefaultLottie'
import EditProfilePicModal from './EditProfilePicModal'
import person from '../../../assets/icons/person.svg'

export default function Profile() {
  const { state } = useContext(Context)
  const [profileDetails, setProfileDetails] = useState({})
  const [editProfile, setEditProfile] = useState(false)
  const [couponCode, setCouponCode] = useState('')
  const [testCopy, setTestCopy] = useState(false)
  const [files, setFile] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [image, setImage] = useState('')

  // let photo = []
  // const handleFileChange = async (e) => {
  //   console.log('handlefilechnage')
    let photo = []
    const handleFileChange = async (e) => {
    photo = await e.target.files[0]
    setImage(photo)
    console.log(photo,'handlefilechnage')

    if (photo) {
      handleProfilePicChange()
    }
  }

  console.log(image,"profileDetailsssssss")

  const getProfile = () => {
    let accessToken = state.user_details.access
    baseConfig
      .get('customers/profile/', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const { data, StatusCode, coupon } = response.data
        console.log(data,"picccccccccccc")
        if (StatusCode === 6000) {
          setProfileDetails(data)
          setFile('')
          setCouponCode(coupon)
          setImage(response.data.data.image)
        }
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
      .catch((error) => {
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
  }

  useEffect(() => {
    getProfile()
  }, [refresh])

  useEffect(() => {
    setTimeout(() => {
      setTestCopy(false)
    }, 2000)
  }, [testCopy])

  const handleProfilePicChange = () => {
    let accessToken = state.user_details.access
    const formData = new FormData()
    formData.append('image', photo)

    baseConfig
      .post('customers/update-profile-image/', formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const { StatusCode, data, token } = response.data
        console.log(data,"get profile1")
        if (StatusCode === 6000) {
          setRefresh(true)
        } else if (StatusCode === 6001) {
        }
      })
      .catch((error) => {})
  }

  return (
    <>
      <MainContainer>
        {!isLoading ? (
          <ProfileContainer>
            <ProfileBox>
              <Title>Profile</Title>
              <MainBox>
                <LeftBox>
                  {profileDetails.image == null ? (
                    <Img src={profile} alt="Image" />
                  ) :(
                    <Img src={image} alt="Image" />
                  )}
                </LeftBox>
                <RightBox>
                  <div>
                    {' '}
                    <Content>
                      Name :<Name> {profileDetails.name}</Name>
                    </Content>
                    <Content>
                      Phone :<Name> {profileDetails.phone}</Name>
                    </Content>
                    <Content>
                      E-mail :<Name> {profileDetails.email}</Name>
                    </Content>
                  </div>
                  <BoxContainer>
                    <Button
                      text={'EDIT PROFILE'}
                      size={'small'}
                      onClick={() => {
                        setEditProfile(true)
                      }}
                      className={'category_button'}
                    />
                  </BoxContainer>
                </RightBox>
              </MainBox>
            </ProfileBox>
            <BottomBox>
              <AddBox to={'/address'}>
                <Icon>{homeSvg()}</Icon>
                My Address
              </AddBox>
              <AddBox to={'/wishlist'}>
                <Icon>{wishlistSvg()}</Icon>
                My Wishlist
              </AddBox>
              <AddBox to={'/order-status'}>
                <Icon>{ordersSvg()}</Icon>
                My Orders
              </AddBox>
              <AddBox to={'/settings'}>
                <Icon>{settingsSvg()}</Icon>
                Settings
              </AddBox>
            </BottomBox>
          </ProfileContainer>
        ) : (
          <DefaultLottie />
        )}
      </MainContainer>
      {editProfile && (
        <ModalCenter
          // closeModal={<EditProfile  /> }
          children={
            <EditProfile
              profileDetails={profileDetails}
              setEditProfile={setEditProfile}
              handleFileChange={handleFileChange()}
              handleProfilePicChange={handleProfilePicChange}
              getProfile={getProfile}
              editProfile={editProfile}
            />
          }
        />
      )}
    </>
  )
}
const MainContainer = styled.div`
  width: 848px;
  max-width: 1350px;
  margin: 0 auto 100px;
  padding-top: 150px;
  @media screen and (max-width: 980px) {
    width: 90%;
  }
  @media screen and (max-width: 360px) {
    width: 100%;
  }
`
const ProfileContainer = styled.div`
  display: wrap;
`
const ProfileBox = styled.div`
  background: ${THEME_COLORS.border_5};
  border-radius: 16px;
  padding: 32px;
  border-radius: 16px;
  margin-bottom: 24px;
  @media screen and (max-width: 480px) {
    padding: 22px;
  }
`
const ReferralBox = styled.div`
  background: #eafff3;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 8px;
  box-sizing: border-box;
  margin-bottom: 24px;
`
const Code = styled.h4`
  color: ${THEME_COLORS.secondary};
  font-family: 'poppins-Regular';
  font-size: 18px;
`
const CouponBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`
const CopyButton = styled.div`
  cursor: pointer;
  background: ${THEME_COLORS.secondary};
  border-radius: 0px 6px 6px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 12px;
  gap: 8px;
  color: ${THEME_COLORS.white};
  font-family: 'poppins-Regular';
  width: 140px;
`
const CodeBox = styled.div`
  border-width: 1px 0px 1px 1px;
  border-style: dashed;
  border-color: ${THEME_COLORS.secondary};
  border-radius: 6px 0px 0px 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px 12px;
  gap: 10px;
  color: ${THEME_COLORS.secondary};
  width: 100%;
  font-family: 'poppins-Regular';
`
const Title = styled.h4`
  font-size: 32px;
  font-family: 'poppins-semibold';
  margin-bottom: 16px;
`
const MainBox = styled.div`
  display: flex;
  gap: 40px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`
const LeftBox = styled.div`
  position: relative;
  width: 35%;
  border-radius: 8px;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    width: 185px;
    height: 185px;
    border-radius: 50%;
  }
`
const Img = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const EditPhoto = styled.label`
  position: absolute;
  bottom: 16px;
  font-family: 'poppins-regular';
  left: 16px;
  color: ${THEME_COLORS.white};
  font-size: 14px;
  background: rgba(37, 37, 37, 0.5);
  border-radius: 6px;
  padding: 4px 18px;
  cursor: pointer;
  z-index: 10;
  @media screen and (max-width: 768px) {
    display: none;
  }
`
const Input = styled.input``
const RightBox = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  width: 50%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
const BoxContainer = styled.div`
  width: max-content;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
const Content = styled.span`
  display: flex;
  font-size: 16px;
  margin-bottom: 12px;
  font-family: 'poppins-semibold';
  color: #8e8e8e;
  @media screen and (max-width: 480px) {
    font-size: 13px;
  }
`
const Name = styled.span`
  margin-left: 16px;
  font-size: 16px;
  color: ${THEME_COLORS.background_1};
  font-family: 'poppins-semibold';
  @media screen and (max-width: 480px) {
    font-size: 13px;
    margin-left: 10px;
  }
`
const BottomBox = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 16px;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`
const AddBox = styled(NavLink)`
  display: flex;
  border: 1px solid #efc1b4;
  align-items: center;
  border-radius: 8px;
  gap: 10px;
  padding: 12px 24px;
  font-size: 19px;
  font-family: 'poppins-regular';
  cursor: pointer;
  &:hover {
    border: 2px solid ${THEME_COLORS.secondary};
    padding: 11px 23px;
  }
  &:hover {
    background: rgba(16, 111, 57, 0.1);
    path {
      stroke: ${THEME_COLORS.secondary};
    }
    color: ${THEME_COLORS.secondary};
  }
  @media screen and (max-width: 980px) {
    font-size: 17px;
    padding: 12px 12px;
    &:hover {
      padding: 11px 11px;
    }
  }
  @media screen and (max-width: 480px) {
    font-size: 13px;
  }
`
const Icon = styled.div`
  display: block;
  width: 24px;
  height: 24px;
  :hover {
    /* border-radius: 4px; */
    color: ${THEME_COLORS.secondary};
    stroke: ${THEME_COLORS.secondary};

    /* path {
			stroke: green;
		} */
  }
`
