import React from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'

const ProductCard = ({ product, responsive }) => {
  return (
    <Product className={responsive && 'mobile-view'}>
      <Image className={responsive && 'mobile-view'}>
        <Img src={product.image} alt="Image" />
      </Image>
      <ProductName>
        <Name className={responsive && 'mobile-view'}>
          {product.variant_name}
        </Name>
        <Pack className={responsive && 'mobile-view'}>
          Pack of {product.pack}
        </Pack>
        <Pack className={responsive ? 'mobile-view capsule' : 'capsule'}>
          {product.quantity_in_pack} Capsules
        </Pack>
      </ProductName>
      <Price className={responsive && 'mobile-view'}>
        ₹ {product.subtotal}
      </Price>
    </Product>
  )
}

export default ProductCard

const Product = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0px 24px;
  background: ${THEME_COLORS.white};
  border-bottom: 1px solid ${THEME_COLORS.border_4};
  &.mobile-view {
    padding: 12px;
    border: 2px solid #f1f1f1;
    border-radius: 12px;
    margin-bottom: 12px;
  }
`
const Image = styled.div`
  margin-right: 24px;
  width: 100px;
  &.mobile-view {
    margin-right: 12px;
    width: 80px;
    border-radius: 8px;
  }
`
const Img = styled.img`
  display: block;
  width: 100%;
`
const ProductName = styled.div`
  flex-grow: 1;
`
const Name = styled.p`
  font-family: 'poppins-regular';
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: ${THEME_COLORS.background_1};
  margin-bottom: 4px;
  &.mobile-view {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }
`
const Pack = styled.p`
  font-family: 'poppins-regular';
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: ${THEME_COLORS.background_4};
  margin-bottom: 4px;
  &.capsule {
    margin-bottom: 0;
  }
  &.mobile-view {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: ${THEME_COLORS.background_5};
  }
`
const Price = styled.p`
  font-family: 'poppins-regular';
  font-weight: 500;
  font-size: 20px;
  color: ${THEME_COLORS.background_1};
  &.mobile-view {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: ${THEME_COLORS.secondary};
  }
`
