import React, { useContext, useEffect, useState } from 'react'
import $ from 'jquery'
import styled from 'styled-components'
import close from '../../../assets/icons/close-circle.svg'
import bottlem1 from '../../../assets/icons/bottle1.svg'
import PrimaryButton from '../../includes/buttons/PrimaryButton'
import ModalCenter from '../../includes/modal/ModalCenter'
import PaymentInfo from '../../includes/modal/payment/PaymentInfo'
import { THEME_COLORS } from '../../../ThemeConfig'
import { baseConfig } from '../../../axiosConfig'
import { Context } from '../../../contexts/Store'
import ChangeAddressModal from '../../includes/modal/address/ChangeAddressModal'
import ConfirmAddress from '../../includes/modal/address/ConfirmAddress'
import ConfirmOrder from '../../includes/modal/payment/ConfirmOrder'
import { useNavigate } from 'react-router-dom'
import OverlayModal from '../../includes/modal/OverlayModal'
import ConfirmAddressModal from '../../includes/modal/address/ConfirmAddressModal'
import useScrollBlock from '../../includes/functions/useScrollBlock'

export default function CheckOutPage({
  setModal,
  modal,
  data,
  address,
  datasingle,
  SetHeight,
  height,
  setAddress,
  // close,
  closeModal,
}) {
  const [paymentInfo, setpaymentInfo] = useState(false)
  const { state, dispatch, selectedAdress } = useContext(Context)
  const [confirmAddress, setConfirmAddress] = useState(false)
  const [selectedAddress, setSelectedAddress] = useState({})
  const [isLoading, setLoading] = useState(true)
  const [orderId, setOrderId] = useState('')
  const [confirmOrder, setConfirmOrder] = useState(false)
  const [confirmOrderData, setConfirmOrderData] = useState()
  const [confirmProducts, setConfirmProducts] = useState('')
  const navigate = useNavigate()

  // useEffect(() => {
  //   if (modal) {
  //     document.body.classList.add('modal-open')
  //   } else {
  //     document.body.classList.remove('modal-open')
  //   }
  //   // document.body.style.overflow = 'hidden'
  // }, [modal])

  const [blockScroll, allowScroll] = useScrollBlock()

  useEffect(() => {
    blockScroll()
  }, [modal])

  const total = data ? parseFloat(data.total) : parseFloat(datasingle.price)
  const total_amount = total + 50
  const new_total = total_amount.toString()
  const getCustomerAddress = () => {
    let accessToken = state.user_details.access
    setLoading(true)

    // setTimeout(() => {
    // accessToken &&
    baseConfig
      .get('customers/address-list/', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const { data, StatusCode } = response.data
        if (StatusCode === 6000) {
          if (data.length > 0) {
            data.map((item) => {
              if (item.is_default) {
                setSelectedAddress(item)
              } else {
                setSelectedAddress(data[0])
              }
            })
          }
          setTimeout(() => {
            setLoading(false)
          }, 100)
        }
      })
      .catch((error) => {
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
    // }, 100);
  }
  useEffect(() => {
    getCustomerAddress()
  }, [])

  //palce order
  const placeOrder = () => {
    let access_token = state.user_details.access
    let formdata = new FormData()

    formdata.append('address_pk', selectedAddress.id)

    baseConfig
      .post(`orders/place-order/`, formdata, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        const { order_id } = response.data
        const order_details = {
          orderId: order_id,
        }
        dispatch({
          type: 'UPDATE_ORDER',
          order_details,
        })
        makePayment(order_id)
      })
      .catch((error) => {})
  }
  //buy-now
  const SingleplaceOrder = () => {
    let access_token = state.user_details.access
    let formdata = new FormData()
    formdata.append('address_pk', selectedAddress.id)
    baseConfig
      .post(`orders/place-single-order/${datasingle.id}/`, formdata, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        const { order_id } = response.data
        makePayment(order_id)
      })
      .catch((error) => {})
  }

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }

  useEffect(() => {
    loadScript('https://checkout.razorpay.com/v1/checkout.js')
  })

  function makePayment(orderId) {
    let formData = new FormData()
    let access_token = state.user_details.access
    // formData.append("price", price);
    // formData.append("product_name", book_name);
    // formData.append("id",orderid)

    async function paymentGateway() {
      const url = `${baseConfig.defaults.baseURL}payments/order-payment/${orderId}/`
      const res = await fetch(url, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      const jsonRes = await res.json()
      // const pay_url = jsonRes.pay_url
      // navigate({ pay_url })
      const order_details = {
        orderId: orderId,
      }
      dispatch({
        type: 'UPDATE_ORDER',
        order_details,
      })
      // window.location.replace(pay_url)
      console.log(res, 'inside function')

      return jsonRes
    }

    paymentGateway().then((jsonRes) => {
      console.log(jsonRes, 'resssssponsex')
      //_________ call razorpay gateway ________
      const options = {
        key: jsonRes.razorpay_key,
        amount: jsonRes.current_amount,
        currency: 'INR',
        handler: async function (response) {
          const razorpay_paymentId = response.razorpay_payment_id
          const razorpay_orderId = response.razorpay_order_id
          const razorpay_signature = response.razorpay_signature

          let formdata = new FormData()

          formdata.append('razorpay_payment_id', razorpay_paymentId)
          formdata.append('razorpay_order_id', razorpay_orderId)
          formdata.append('razorpay_signature', razorpay_signature)

          await baseConfig
            .post(
              `payments/payment-response/${jsonRes.data.order_id}/`,
              formdata,
              {
                headers: {
                  Authorization: `Bearer ${state.user_details.access}`,
                },
              },
            )
            .then((response) => {
              const { data, StatusCode } = response.data
              if (StatusCode === 6000) {
                setConfirmOrder(true)
                setConfirmOrderData(data)
                setConfirmProducts(data[0].order_items)
              }
            })
            .catch((error) => {
              console.log('elsee', error)
            })
        },
        prefill: {
          email: jsonRes.data.email,
          contact: jsonRes.data.phone,
          name: jsonRes.data.name,
        },
        // name: res.data.order_id,
        name: 'PACESS WELLNESS INDIA LLP',
        order_id: jsonRes.response.id,

        theme: { color: '#106F39' },
      }

      if (typeof window.Razorpay === 'undefined') {
        console.error('Razorpay library is not loaded.');
        // Handle the error (e.g., show a message to the user)
      } else {
        // Initialize Razorpay
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
      }
    })
  }

  return (
    <>
      <MainContainer>
        <Cover>
          <TitleContainer>
            <Heading>Checkout</Heading>
            <CloseIcon
              onClick={() => {
                setModal(false)
                allowScroll()
                // console.log(modal, 'modal')
              }}
            >
              <Img src={close} alt="Image" />
            </CloseIcon>
          </TitleContainer>
          {data ? (
            <>
              <Container>
                {data?.products_data?.map((item, i) => (
                  <>
                    {
                      <SubContainer key={item.id}>
                        <ProductContainer>
                          <Left>
                            <Image src={item.variant_image} alt="Image" />
                          </Left>
                          <Right>
                            <ProductName>{item.variant_name}</ProductName>
                            {/* <Pack>Qty : {item.qty}</Pack> */}

                            <Pack>Pack of {item.pack}</Pack>
                            <Pack>{item.quantity_in_pack} capsules</Pack>
                          </Right>
                        </ProductContainer>
                        <AmountContainer>
                          <Amount>
                            {/* ₹ `{item.offer_price}?{item.offer_price} :{" "}
									{item.unit_price}` */}
                            {`₹ ${
                              item.offer_price
                                ? item.offer_price
                                : item.unit_price
                            }`}
                          </Amount>
                        </AmountContainer>
                      </SubContainer>
                    }
                  </>
                ))}
              </Container>
              <TotalPriceContainer>
                <PriceContainer>
                  <SubTotal>Subtotal</SubTotal>
                  <Amount>₹{data?.total}</Amount>
                </PriceContainer>
                <PriceContainer>
                  <SubTotal>Shipping</SubTotal>
                  <Amount>₹ 0.00</Amount>
                </PriceContainer>
              </TotalPriceContainer>
              <AllPrice>
                <SubTotal>Total</SubTotal>
                <TotalPrice>₹ {data?.total}</TotalPrice>
              </AllPrice>
            </>
          ) : (
            <>
              <Container>
                <SubContainer>
                  <ProductContainer>
                    <Left>
                      <Image src={datasingle.image} alt="Image" />
                    </Left>
                    <Right>
                      <ProductName>{datasingle.product.name}</ProductName>

                      <Pack>Pack of {datasingle.pack}</Pack>
                      <Pack>{datasingle.quantity} capsules</Pack>
                    </Right>
                  </ProductContainer>
                  <AmountContainer>
                    <Amount>
                      {`₹ ${
                        datasingle.offer_price
                          ? datasingle.offer_price
                          : datasingle.price
                      }`}
                    </Amount>
                  </AmountContainer>
                </SubContainer>
              </Container>
              <TotalPriceContainer>
                <PriceContainer>
                  <SubTotal>Subtotal</SubTotal>
                  <Amount>₹{datasingle.price}</Amount>
                </PriceContainer>
                <PriceContainer>
                  <SubTotal>Shipping</SubTotal>
                  <Amount>₹ 0.00</Amount>
                </PriceContainer>
              </TotalPriceContainer>
              <AllPrice>
                <SubTotal>Total</SubTotal>
                <TotalPrice>₹ {datasingle.price}</TotalPrice>
              </AllPrice>
            </>
          )}

          <IncludingPrice>
            <PrimaryButton
              className="category_button"
              text={'PLACE ORDER'}
              size={'small'}
              onClick={() => {
                // placeOrder();
                // window.scrollTo(0, 0)
                setConfirmAddress(true)
                SetHeight(true)
                // setAddress(true)
              }}
            />
          </IncludingPrice>
          <div className="address">
            {confirmAddress && (
              <ConfirmAddressModal
                selectedAddress={selectedAddress}
                closeModal={closeModal}
                setSelectedAddress={setSelectedAddress}
                orderId={orderId}
                setOrderId={setOrderId}
                refreshApi={makePayment}
                confirmAddress={confirmAddress}
                setConfirmAddress={setConfirmAddress}
                setModal={setModal}
                placeOrder={placeOrder}
                datasingle={datasingle}
                SingleplaceOrder={SingleplaceOrder}
              />
            )}
          </div>
        </Cover>
      </MainContainer>
      {/* {paymentInfo && <ModalCenter children={<PaymentInfo />} />} */}

      {confirmOrder && (
        <ModalCenter
          children={
            <ConfirmOrder
              confirmOrder={confirmOrder}
              setConfirmOrder={setConfirmOrder}
              confirmAddress={confirmAddress}
              setConfirmAddress={setConfirmAddress}
              setModal={setModal}
              confirmOrderData={confirmOrderData}
              setConfirmOrderData={setConfirmOrderData}
              setConfirmProducts={setConfirmProducts}
              confirmProducts={confirmProducts}
            />
          }
          // closeModal={closeModal}
        />
      )}
    </>
  )
}
const MainContainer = styled.div`
  width: 800px;
  background: #fff;
  div.address {
    /* height: 100px;
    width: 100%; */
  }
  /* height: 95vh; */
  @media screen and (max-width: 980px) {
    padding: 30px;
    width: 600px;
  }
  @media screen and (max-width: 768px) {
    padding: 30px;
    width: 600px;
  }
  @media screen and (max-width: 640px) {
    width: 480px;
  }
  @media screen and (max-width: 500px) {
    width: 400px;
  }
  @media screen and (max-width: 420px) {
    width: 350px;
  }
  @media screen and (max-width: 360px) {
    width: 305px;
    padding: 17px;
  }
`
const Cover = styled.div`
  padding: 40px;
  height: 700px;
  overflow: scroll;
`
const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`
const Container = styled.div`
  border-bottom: 1px solid ${THEME_COLORS.border_1};
`
const Heading = styled.h3`
  font-size: 32px;
  /* line-height: 48px; */
  color: ${THEME_COLORS.background_1};
  font-family: 'poppins-medium';
  @media screen and (max-width: 480px) {
    font-size: 20px;
  }
`
const CloseIcon = styled.span`
  display: inline-block;
  width: 53px;
  height: 53px;
  cursor: pointer;
`
const Img = styled.img`
  display: block;
  height: 100%;
  width: 100%;
`
const SubContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  align-items: center;
  :last-child {
    margin-bottom: 22px;
  }
`
const ProductContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Left = styled.div`
  /* width: 35%; */
  width: 93px;
  height: 93px;
  border-radius: 6px;
  margin-right: 20px;
`
const Image = styled.img`
  width: 100%;
  display: block;
`
const Right = styled.div`
  width: 65%;
`
const ProductName = styled.h4`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 6px;
  font-family: 'poppins-medium';
  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`
const Pack = styled.div`
  font-weight: 400;
  font-size: 17px;
  color: ${THEME_COLORS.background_4};
  margin-bottom: 5px;
  font-family: 'poppins-medium';
  line-height: 26px;
  @media screen and (max-width: 480px) {
    font-size: 15px;
  }
`
const AmountContainer = styled.div``
const Amount = styled.h4`
  font-size: 20px;
  line-height: 30px;
  font-family: 'poppins-medium';
  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
  @media screen and (max-width: 360px) {
    font-size: 12px;
  }
`
const TotalPriceContainer = styled.div`
  border-bottom: 1px solid ${THEME_COLORS.border_1};
`
const PriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 49px 0px 16px;
  :last-child {
    margin-bottom: 32px;
    margin-top: 0px;
  }
`
const SubTotal = styled.h4`
  font-size: 20px;
  line-height: 30px;
  font-family: 'poppins-medium';
  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`
const IncludingPrice = styled.div`
  @media screen and (max-width: 480px) {
    padding-bottom: 20px;
  }
`
const AllPrice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin: 32px 0px 42px;
`
const TotalPrice = styled.h3`
  font-size: 28px;
  line-height: 42px;
  font-family: 'poppins-medium';
  @media screen and (max-width: 480px) {
    font-size: 22px;
  }
`
