import React from 'react'
import TextField from '@mui/material/TextField'
import styled from 'styled-components'
import close from './../../../../assets/icons/close-square.svg'
import paytm from './../../../../assets/icons/paytm.svg'
import netBankig from './../../../../assets/icons/net-banking.svg'
import upi from './../../../../assets/icons/upi-icon.svg'
import card from './../../../../assets/icons/card.svg'
import select from './../../../../assets/icons/selected-round.svg'
import PrimaryButton from '../../buttons/PrimaryButton'
import { useState } from 'react'

export default function PaymentInfo({ setModal }) {
  const [paymentMethod, setPaymentMethode] = useState('')
  const paymentArray = [
    {
      id: 1,
      icon: card,
      name: 'Credit Or Debit Card',
    },
    {
      id: 2,
      icon: paytm,
      name: 'Wallet & Postpaid',
    },
    {
      id: 3,
      icon: upi,
    },
    {
      id: 4,
      icon: netBankig,
      name: 'Net Banking',
    },
  ]
  return (
    <MainContainer>
      <TopContainer>
        <Title>How would you like to pay</Title>
        <Image
          onClick={() => {
            setModal(false)
          }}
        >
          <Img src={close} alt="Image" />
        </Image>
      </TopContainer>
      <PaymentMethode>
        {paymentArray.map((data) => (
          <Item
            key={data.id}
            onClick={() => {
              setPaymentMethode(data.id)
            }}
          >
            {' '}
            {data.id === paymentMethod && (
              <SelectedIcon>
                <Img src={select} alt="Image" />
              </SelectedIcon>
            )}
            <Icon>
              <Img src={data.icon} alt="Image" />
            </Icon>
            <Name>{data.name}</Name>
          </Item>
        ))}
      </PaymentMethode>
      <Details>
        <Paragraph>
          By Clickin place order,you agreet to the eShopeworld
          <br />
          <Terms href="#">Terms-andConditions.</Terms>
        </Paragraph>
        <Title>Enter your payment details:</Title>
        <DetailsForm>
          <SingleForm>
            <TextField
              fullWidth={true}
              placeholder="Name on Card"
              label="Name on Card"
            />
          </SingleForm>
          <SingleForm>
            <TextField
              fullWidth={true}
              placeholder="Card Number"
              label="Card Number"
            />
          </SingleForm>
          <SingleForm className="small">
            <TextField
              fullWidth={true}
              placeholder="MM/YY"
              label="Expiry Date"
            />
          </SingleForm>
          <SingleForm className="small">
            <TextField fullWidth={true} placeholder="CVV" label="CVV" />
          </SingleForm>
        </DetailsForm>
        <PrimaryButton
          className="category_button"
          text={'PLACE ORDER'}
          size={'small'}
          onClick={() => {
            setModal('confirmOrder')
            // setModalCenter("Chekout");
          }}
        />
      </Details>
      {}
    </MainContainer>
  )
}
const MainContainer = styled.div`
  max-width: 729px;
  padding: 32px;
  max-height: 90vh;
  overflow-y: scroll;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: 980px) {
    width: 630px;
  }
  @media screen and (max-width: 640px) {
    width: 520px;
  }
  @media screen and (max-width: 540px) {
    width: 430px;
  }
  @media screen and (max-width: 480px) {
    width: 360px;
  }
  @media screen and (max-width: 360px) {
    width: 320px;
  }
`
const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`
const Title = styled.h3`
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #161616;
`
const Image = styled.div`
  width: 24px;
  cursor: pointer;
`
const Img = styled.img`
  width: 100%;
  display: block;
`
const PaymentMethode = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 32px;
`
const Item = styled.div`
  cursor: pointer;
  padding: 14px 16px;
  background: #f9f9f9;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
`
const SelectedIcon = styled.div`
  width: 24px;
`
const Icon = styled.div`
  /* width: max-content; */
`
const Name = styled.p`
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #464646;
`
const Details = styled.div``
const Paragraph = styled.p`
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #8e8e8e;
  margin-bottom: 32px;
`
const Terms = styled.a`
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #8e8e8e;
  text-decoration: underline;
`
const DetailsForm = styled.div`
  margin-top: 22px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  /* &.small {
        width: 48%;
    } */
`
const SingleForm = styled.div`
  width: 100%;
  margin-bottom: 24px;
  &.small {
    width: 48%;
  }
`
// const Terms = styled.a``;
