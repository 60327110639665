import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import tick from './../../../../assets/icons/tick-square.svg'
import close from './../../../../assets/icons/close-square.svg'

import { THEME_COLORS } from '../../../../ThemeConfig'
import { baseConfig } from '../../../../axiosConfig'
import { Context } from '../../../../contexts/Store'
import ModalCenter from '../ModalCenter'
import NewAddressModal from './NewAddressModal'
import PrimaryButton from '../../buttons/PrimaryButton'
import $ from 'jquery'
import OverlayModal from '../OverlayModal'
import { useNavigate } from 'react-router-dom'
import useScrollBlock from '../../functions/useScrollBlock'

export default function ConfirmAddress({
  selectedAddress,
  setSelectedAddress,
  orderId,
  setOrderId,
  refreshApi,
  setConfirmAddress,
  setModal,
  placeOrder,
  datasingle,
  SingleplaceOrder,
  confirmAddress,
  setAddress,
}) {
  const { state, selectedAdress, confirmAddressId, dispatch } = useContext(
    Context,
  )
  const navigate = useNavigate()

  const [isLoading, setLoading] = useState(true)
  // const [check, setCheck] = useState(false);
  const [addressList, setAddressList] = useState([])
  const [newAddress, setNewAddress] = useState(false)
  //   console.log(datasingle, 'in confirmadress datasingle')
  const [blockScroll, allowScroll] = useScrollBlock()

  const closeModal = () => {
    setNewAddress(false)
    // checkoutModal(false)
    allowScroll()
  }

  useEffect(() => {
    blockScroll()
  }, [])

  //get address
  const getCustomerAddress = () => {
    setLoading(true)
    let accessToken = state.user_details.access
    // setTimeout(() => {
    baseConfig
      .get('customers/address-list/', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const { data, StatusCode } = response.data
        if (StatusCode === 6000) {
          setLoading(false)
          setAddressList(data)
        }
      })
      .catch((error) => {
        setLoading(false)
      })
    // }, 100);
  }

  function disableScroll() {
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    getCustomerAddress()
    disableScroll()
  }, [])
  console.log(addressList,"oooooo")
  return (
    <>
      <BackContainer>
        <Overlay
          onClick={() => {
            // setConfirmAddress(false)
            // closeModal()
          }}
        />
        <Wrapper>
          <MainContainer>
            <Cover>
              <Image
                onClick={() => {
                  allowScroll()

                  setConfirmAddress(false)
                  // closeModal()
                }}
              >
                <Img src={close} alt="Image" />
              </Image>
              <Body>
                <TopContainer>
                  <Header>
                    <Title>Selected Address</Title>
                    <AddButton
                      onClick={() => {
                        //   navigate('/address')
                        setNewAddress(true)
                      }}
                    >
                      + ADD NEW ADDRESS
                    </AddButton>
                  </Header>
                  <SavedAddress>
                    {addressList.length} Saved Address
                  </SavedAddress>
                </TopContainer>
                {addressList.map((item, i) => (
                  <AddressCard key={i}>
                    <AddressTop>
                      <NameCard>
                        <Name>
                          {item.first_name} {item.last_name}
                        </Name>
                      </NameCard>
                      <EditAddress>
                        {item.is_default && (
                          <DefaultButton>Default Address</DefaultButton>
                        )}

                        <Checkbox
                          onClick={() => {
                            // setCheck(!check);
                            // handleDefaultAddress(item.id);
                            setSelectedAddress(item)
                          }}
                          className={
                            item.id === selectedAddress.id && 'checked'
                          }
                        >
                          {item.id === selectedAddress.id && (
                            <Tick
                              // className={
                              // 	item.is_default && "checked"
                              // }
                              src={tick}
                              alt="Image"
                            />
                          )}
                        </Checkbox>
                      </EditAddress>
                    </AddressTop>
                    <Address>
                      {item.address}
                      <br />
                      {item.location} , {item.state}
                      <br />
                      {item.pincode}
                      <br />
                      {item.phone}
                      <br />
                      {item.email}
                    </Address>
                  </AddressCard>
                ))}
                <BottomContainer className={selectedAddress ? '' : 'none'}>
                  <PrimaryButton
                    className={'category_button'}
                    size={'small'}
                    text={'CONTINUE'}
                    onClick={() => {
                      allowScroll()
                      datasingle ? SingleplaceOrder() : placeOrder()
                      setConfirmAddress(false)
                      // setAddress(false)
                      if(addressList === ""){
                        alert("please add address")
                      }
                    }}
                  />
                </BottomContainer>
              </Body>
              {newAddress && (
                <ModalCenter
                  address={'address'}
                  children={
                    <NewAddressModal
                      newAddress={newAddress}
                      closeModal={closeModal}
                      refreshApi={getCustomerAddress}
                      setNewAddress={setNewAddress}
                    />
                  }
                  // closeModal={closeModal}
                />
              )}{' '}
            </Cover>
          </MainContainer>
          {/* </Modal> */}
        </Wrapper>
      </BackContainer>
    </>
  )
}
const BackContainer = styled.div`
  position: fixed;
  transition: 0.3s;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  left: 0;
  /* top: 0; */
  bottom: 0;
  overflow: hidden;
`
const Overlay = styled.div`
  position: fixed;
  left: 0;
  /* top: 0; */
  bottom: 0;
  width: 100%;
  cursor: pointer;
  height: 100vh;
  opacity: 0.5;
  background: ${THEME_COLORS.background_1};
  /* background: red; */
`
const Wrapper = styled.div`
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 6px;
  background: ${THEME_COLORS.white};
  transition: 0.5s;
  z-index: 10002;
  animation: slideIn 0.2s;
  /* height: 100vh; */
  width: 800px;
  /* overflow: scroll; */
  @keyframes slideIn {
    0% {
      left: 50%;
    }
  }
  @media screen and (max-width: 360px) {
    /* left: 40%; */
  }
`
const MainContainer = styled.div`
  /* padding: 32px; */
  position: relative;
  /* top: 300px; */
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 800px;

  @media screen and (max-width: 980px) {
    width: 600px;
    margin: 0 auto;
  }
  @media screen and (max-width: 640px) {
    width: 500px;
  }
  @media screen and (max-width: 560px) {
    width: 430px;
  }
  @media screen and (max-width: 480px) {
    width: 350px;
  }
`
const Cover = styled.div`
  height: 700px;
  overflow: scroll;
  padding: 90px 30px 30px 30px;
`
const TopContainer = styled.div`
  margin-bottom: 30px;
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Title = styled.h3`
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  color: ${THEME_COLORS.background_1};
  @media screen and (max-width: 640px) {
    font-size: 16px;
    width: 50%;
  }
`
const AddButton = styled.div`
  background: #d87400;
  opacity: 0.7;
  border-radius: 6px;
  padding: 8px 16px;
  color: ${THEME_COLORS.white};
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  @media screen and (max-width: 640px) {
    font-size: 12px;
    width: 35%;
  }
  @media screen and (max-width: 480px) {
    font-size: 12px;
    width: 35%;
  }
`
const SavedAddress = styled.p`
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 28px;
  color: ${THEME_COLORS.background_3};
`
const Body = styled.div`
  /* width: 800px; */
  /* height: 300px; */
  /* overflow-y: scroll; */
  /* height: 705px; */
  /* overflow: scroll; */
  /* ::-webkit-scrollbar {
        display: none;
    } */
`
const AddressCard = styled.div`
  padding: 24px;
  border: 2px solid ${THEME_COLORS.border_3};
  border-radius: 8px;
  margin-bottom: 24px;
  &:hover {
    border: 2px solid ${THEME_COLORS.secondary};
  }
  @media screen and (max-width: 560px) {
    padding: 18px;
  }
`

const AddressTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 16px; */
`
const NameCard = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
const Name = styled.p`
  font-family: 'poppins-medium';
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${THEME_COLORS.background_1};
`
const DefaultButton = styled.div`
  font-family: 'poppins-regular';
  background: ${THEME_COLORS.light_orange};
  border-radius: 48px;
  padding: 4px 10px;
  font-size: 12px;
  color: #e19200;
  cursor: pointer;
  /* max-width: max-content; */
  /* margin: 5px 0; */
  margin-right: 5px;
  @media screen and (max-width: 560px) {
  }
`
const EditAddress = styled.div`
  /* width: 24px; */
  /* cursor: pointer; */
  /* height: 24px; */
  display: flex;
  align-items: center;
`

const Checkbox = styled.div`
  margin-right: 10px;
  width: 18px;
  height: 18px;
  border: 1px solid ${THEME_COLORS.border_3};
  border-radius: 3px;
  cursor: pointer;
  &.checked {
    border-color: ${THEME_COLORS.secondary};
  }
`
const Address = styled.div`
  font-family: 'poppins-regular';
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: ${THEME_COLORS.background_4};
`

const Tick = styled.img`
  width: 18px;
  height: 18px;
`

const BottomContainer = styled.div`
  &.none {
    cursor: not-allowed;
  }
`
const Image = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  top: 55px;
  right: 15px;
`
const Img = styled.img`
  width: 100%;
  height: 100%;
  display: block;
`
