import React from 'react'
import styled from 'styled-components'
import noData from '../../../assets/images/404_Error.svg'
import { Link } from 'react-router-dom'

export default function PageNotFound() {
  return (
    <>
      <MainContainer>
        <NoData>
          <Img src={noData} alt="Image" />
        </NoData>
        <Text to="/">
          Page not found <span>Back to home</span>{' '}
        </Text>
      </MainContainer>
    </>
  )
}
const MainContainer = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  padding-top: 150px;
`
const NoData = styled.div`
  width: 20%;
  margin-bottom: 20px;
  @media all and (max-width: 1080px) {
    width: 30%;
  }
  @media all and (max-width: 768px) {
    width: 40%;
  }
  @media all and (max-width: 480px) {
    width: 45%;
  }
`
const Img = styled.img`
  width: 100%;
  display: block;
`
const Text = styled(Link)`
  font-family: 'poppins-medium';
  color: #bbbbbb;
  @media all and (max-width: 1080px) {
    font-size: 16px;
  }
  @media all and (max-width: 640px) {
    font-size: 14px;
  }
  @media all and (max-width: 480px) {
    font-size: 13px;
  }
  span {
    color: #106f39;
    cursor: pointer;
    border-bottom: 1.5px solid #106f39;
  }
  margin-bottom: 50px;
`
