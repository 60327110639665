import React from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../../ThemeConfig'
import { Navigate } from 'react-router-dom'

export default function DeleteAccount({ setFinalModal }) {
  const logOut = () => {
    localStorage.clear()
    window.location = '/'
  }
  return (
    <Cover>
      <MainContainer>
        <Title>Your account has been deleted!</Title>
        {/* <PrimaryButton
          onClick={() => {
            setFinalModal(false)
            Navigate('/')
            logOut()
          }}
          size={'small'}
          text={'Back to Home'}
        /> */}
        <PrimaryButton
          onClick={() => {
            setFinalModal(false)
            Navigate('/')
            logOut()
          }}
        >
          Back to Home
        </PrimaryButton>
      </MainContainer>
    </Cover>
  )
}
const Cover = styled.div`
  width: 100%;
  height: 100vh;
  background: #14111194;
  position: fixed;
  top: 0;
  left: 0;
`
const MainContainer = styled.div`
  padding: 32px;
  background: ${THEME_COLORS.white};
  box-shadow: 12px 25px 25px 25px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  position: fixed;
  left: 25%;
  top: 200px;
  width: auto;
  @media screen and (max-width: 980px) {
    /* left: 150px; */
    width: 60%;
    left: 20%;
  }
  @media screen and (max-width: 768px) {
    width: 65%;
  }
  @media screen and (max-width: 640px) {
    width: 85%;
    left: 8%;
  }

  @media screen and (max-width: 480px) {
    width: 95%;
    left: 3%;
  }
`
const Title = styled.h3`
  width: 100%;
  color: ${THEME_COLORS.background_1};
  font-size: 22px;
  margin-bottom: 40px;
  font-family: 'poppins-medium';
  @media screen and (max-width: 480px) {
    font-size: 20px;
  }
  @media screen and (max-width: 360px) {
    /* padding: 10px 30px; */
    font-size: 18px;
  }
`

const PrimaryButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  margin-left: 20px;
  cursor: pointer;
  color: ${THEME_COLORS.white};
  font-family: 'poppins-semibold';
  border-radius: 6px;
  width: max-content;
  background-color: ${THEME_COLORS.secondary};
  border: 2px solid ${THEME_COLORS.secondary};
  font-size: 14px;

  @media screen and (max-width: 480px) {
    padding: 8px 15px;
  }
  @media screen and (max-width: 360px) {
    /* padding: 10px 30px; */
    font-size: 13px;
  }
`
