import React from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'

export default function InputForm({
  name,
  status,
  msg,
  image,
  placeholder,
  focus,
  onKeyDown,
  fieldName,
  setValue,
  value,
  type,
  isStatus = false,
  setIsStatus,
  size,
}) {
  // const [clear, setClear] = useState(false);

  return (
    <FormDiv>
      <Name htmlFor={name}>{name}</Name>
      <div style={{ position: 'relative' }}>
        <FormInput
          autoFocus={focus}
          type={type}
          placeholder={placeholder}
          id={name}
          className={fieldName === name && isStatus ? status : ''}
          maxlength="10"
          onChange={(e) => {
            setValue(e.target.value)
            if (setIsStatus) {
              setIsStatus(false)
            }
          }}
          value={value}
          onKeyDown={onKeyDown}
          onWheel={(e) => {
            if (type == 'number') {
              e.target.blur()
            }
          }}
          inputProps={{
            maxLength: 10,
          }}
        />
        {image && <DeleteButton src={image} alt="Image" />}
      </div>
      <ErrorDiv size={size}>
        {fieldName === name && isStatus && (
          <Error className={status}>{msg}</Error>
        )}
      </ErrorDiv>
    </FormDiv>
  )
}

const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`
const Name = styled.label`
  font-family: 'poppins-regular';
  color: ${THEME_COLORS.border_1};
  margin-bottom: 4px;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
`
const FormInput = styled.input`
    font-family: "poppins-medium";
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    /* color: ${THEME_COLORS.text_2}; */
    width: 100%;
    height: 48px;
    border: 1px solid ${THEME_COLORS.border_3};
    border-radius: 4px;
    padding: 0 10px;
    background: ${THEME_COLORS.white};
    &:focus-within {
        background: ${THEME_COLORS.white};
        border: 1.5px solid #126f39;
    }
    &:hover {
        background: ${THEME_COLORS.white};
        /* border: 1px solid ${THEME_COLORS.primary}; */
    }
    /* &.error {
        border: 1px solid ${THEME_COLORS.error_text};
    }
    &.success {
        border: 1px solid ${THEME_COLORS.success_text};
    }
    &.warning {
        border: 1px solid ${THEME_COLORS.warning_text};
    } */
`
const DeleteButton = styled.img`
  position: absolute;
  height: 20px;
  width: 20px;
  top: 12px;
  right: 7px;
`
const ErrorDiv = styled.div`
  height: ${(props) => (props.size === 'small' ? '3px' : '16px')};
`
const Error = styled.p`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: fadeIn 0.5s;
  font-family: 'poppins-regular';
  font-size: 12px;
  &.error {
    color: ${THEME_COLORS.error};
  }
  &.success {
    color: ${THEME_COLORS.success};
  }
  &.warning {
    color: ${THEME_COLORS.warning};
  }
`
