import React from "react";
import Lottie from "react-lottie";
import * as animationData from "./../../../../assets/lottie-files/healthcare-loader.json";
import styled from "styled-components";

function DefaultLottie() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    return (
        <Container>
            <InnerContainer>
                <Lottie
                    options={defaultOptions}
                    // height={"100%"}
                    // width={"100%"}
                />
            </InnerContainer>
        </Container>
    );
}

export default DefaultLottie;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
`;

const InnerContainer = styled.div`
    width: 150px;
    height: 150px;
    @media (max-width: 768px) {
        width: 100px;
        height: 100px;
    }
`;