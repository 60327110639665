import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'
import deleteIcn from '../../../assets/icons/delete.svg'
import editicon from '../../../assets/icons/edit-icon.svg'
import Dropdown from '../../includes/form/Dropdown'
import remove from '../../../assets/icons/remove.svg'
import add from '../../../assets/icons/add.svg'
import PrimaryButton from '../../includes/buttons/PrimaryButton'
import ModalCenter from '../../includes/modal/ModalCenter'
import CheckOutPage from './CheckOutPage'
import ChangeAddressModal from '../../includes/modal/address/ChangeAddressModal'
import NewAddressModal from '../../includes/modal/address/NewAddressModal'
import PaymentInfo from '../../includes/modal/payment/PaymentInfo'
import ConfirmOrder from '../../includes/modal/payment/ConfirmOrder'
import emptyCart from './../../../assets/icons/bag-cross.svg'
import { Context } from '../../../contexts/Store'
import { baseConfig } from '../../../axiosConfig'
import EmptyPage from '../../includes/empty-page/EmptyPage'
import DefaultLottie from '../../includes/common/loader/DefaultLottie'
import CartRemoveConfirmModal from '../../includes/CartRemoveVerification/CartRemoveConfirmModal'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import OverlayModal from '../../includes/modal/OverlayModal'
import CheckOutModal from './CheckOutModal'
import useScrollBlock from '../../includes/functions/useScrollBlock'
// import $ from "jquery"

export default function CartPage() {
  const { state, dispatch } = useContext(Context)
  const [cartDetails, setCartDetails] = useState({})
  const [isLoading, setLoading] = useState(true)
  const [isStock, setIsStock] = useState(false)
  const [defaultAddress, setDefaultAddress] = useState({})
  const [coupon, setCoupon] = useState('')
  const [filterValue, setFilterValue] = useState('')
  const [selectedAddress, setSelectedAddress] = useState({})
  const [referralCode, SetReferralCode] = useState('')
  const [referralSuccess, SetReferralSuccess] = useState(false)
  const [height, SetHeight] = useState(false)
  const [varientId,setVarientId] = useState()

  const [counter, setCounter] = useState(1)
  const incrementCounter = () => setCounter(counter + 1)
  const decrementCounter = () => setCounter(counter - 1)
  if (counter <= 0) {
    decrementCounter = () => setCounter(1)
  }
  const [referralError, setReferralError] = useState('')
  const [varientError, setVarientError] = useState('')

  const [checkoutModal, setCheckoutModal] = useState(false)
  const [editAddress, setEditAddress] = useState(false)
  const [changeAddress, setChangeAddress] = useState(false)
  const [newAddress, setNewAddress] = useState(false)
  const [isconfirmRemove, setConfirmRemove] = useState(false)

  const [search, setSearch] = useState(true)
  // console.log(checkoutModal, 'checkoutModal')

  const [blockScroll, allowScroll] = useScrollBlock()
  console.log(filterValue,"filtervaluueeeeee55555555555555555555")


  const [id, setId] = useState('')
  const closeModal = () => {
    setEditAddress(false)
    setChangeAddress(false)
    setNewAddress(false)
    // checkoutModal(false)
    allowScroll()
  }

  const showToast = () => {
    toast.success('Item removed successfully', {
      autoClose: 200,
      hideProgressBar: true,
    })
  }
  const stockToast = () => {
    toast.error('Item Out of stock please remove from cart', {
      autoClose: 1000,
      hideProgressBar: true,
    })
  }

  //get cart
  const getCartItems = () => {
    setLoading(true)
    let accessToken = state.user_details.access
    console.log(accessToken)
    baseConfig
      .get('cart/cart-list/', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const { data } = response.data
        setCartDetails(data)
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
      .catch((error) => {
        const { status, data } = error?.response
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
    // }, 100);
  }


  const setStock = () => {
    for (let i = 0; i < cartDetails?.products_data?.length; i++) {
    }
  }

  //get address
  const getCustomerAddress = () => {
    let accessToken = state.user_details.access
    setLoading(true)
    baseConfig
      .get('customers/address-list/', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const { data, StatusCode } = response.data
        if (StatusCode === 6000) {
          // setAddressList(data);
          if (data.length > 0) {
            data.map((item) => {
              if (item.is_default) {
                setSelectedAddress(item)
              } else {
                setSelectedAddress(data[0])
              }
            })
          }
          setTimeout(() => {
            setLoading(false)
          }, 100)
        }
      })
      .catch((error) => {
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
    // }, 100);
  }

  //get default address
  const getCustomerDefaultAddress = () => {
    let accessToken = state.user_details.access
    setLoading(true)
    baseConfig
      .get('customers/get-default-address/', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const { data, StatusCode } = response.data
        if (StatusCode === 6000) {
          setDefaultAddress(data)
        }
      })
      .catch((error) => {
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
    // }, 100);
  }

  useEffect(() => {
    getCartItems()
    // getCustomerDefaultAddress();
    getCustomerAddress()
    // setStock()
  }, [])

  const removeFromCart = (id) => {
    let access_token = state.user_details.access
    setLoading(true)
    setIsStock(false)
    let formdata = {
      // quantity: 1,
    }
    baseConfig
      .post(`cart/cart-delete/${id}/`, formdata, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        getCartItems()
        setTimeout(() => {
          setLoading(false)
        }, 100)
        showToast()
        setTimeout(() => {
          window.location.reload()
        }, 800)
      })
      .catch((error) => {
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
  }

  //update qty
  const updateQty = (id, qty) => {
    let access_token = state.user_details.access
    var formdata = new FormData()

    formdata.append('qty', qty)

    baseConfig
      .post(`cart/update-qty/${id}/`, formdata, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        getCartItems()
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
      .catch((error) => {
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
  }

  const changeVariant = (id, variantId) => {
    console.log(id,variantId,"Hellooooooo")
    let access_token = state.user_details.access
    // var formdata = new FormData();
    baseConfig
      .post(
        `cart/update-cart-variant/${id}/${variantId}/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
          params:{

          }
        },
        
      )
      .then((response) => {
        setVarientError(response.data.message)
        getCartItems()
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
      .catch((error) => {
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
  }

  //apply cuppon
  const applyCoupon = () => {
    let access_token = state.user_details.access
    let formdata = new FormData()

    formdata.append('coupon', referralCode)

    baseConfig
      .post(`cart/apply-coupon/`, formdata, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        const { data } = response.data
        setTimeout(() => {
          getCartItems()
          setLoading(false)
        }, 100)
        setReferralError(response.data.message)
        console.log(response.data.message, 'message')
      })
      .catch((error) => {
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
  }
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  // console.log(cartDetails?.products_data?.more_variants, 'data')
  console.log(filterValue, 'filtervluuuuueeeee')

  return (
    <>
      <MainContainer>
        <ToastContainer />
        <ContainerBox>
          <TopContainer>
            <TitleName>Cart</TitleName>
            {/* <PrimaryButton
              // className={"category_button"}
              size={"small"}
              text={"+ADD ADDRESS"}
              onClick={() => {
                setNewAddress(true);
              }}
            /> */}
          </TopContainer>
          {!isLoading ? (
            <>
              {cartDetails?.products_data?.length > 0 ? (
                <BodyContainer>
                  <LeftBox>
                    {cartDetails?.products_data?.map((item, index) => (
                      <>
                        <CartCard
                          key={index}
                          onLoad={() => {
                            if (item.stock == 0) {
                              setIsStock(true)
                              console.log(isStock, 'sucessfully loaded')
                            }
                          }}
                          // className={item?.stock == 0 ? 'unavailable' : ''}
                        >
                          <LeftSide>
                            <ImageBox
                              className={item?.stock == 0 ? 'unavailable' : ''}
                            >
                              <ItemImg src={item.variant_image} alt="Image" />
                            </ImageBox>
                            <ProductDetail>
                              <CartTitle>
                                <ProductName>{item.variant_name}</ProductName>
                                <ProductDetails>
                                  {item.product_short_description}
                                </ProductDetails>
                                {item.qty == item.stock && (
                                  <Availability>
                                    Only {item.stock} items available{' '}
                                  </Availability>
                                )}
                                {item.stock == 0 && (
                                  <Availability>Out of stock </Availability>
                                )}
                              </CartTitle>
                              {/* {varientError ? (
                                <Availability>{varientError}</Availability>
                              ) : (
                                ''
                              )} */}

                              <Forms>
                                {/* {varientError ? (
                                  <Availability>{varientError}</Availability>
                                ) : (
                                  ''
                                )} */}
                                <Dropdown
                                  varientError={varientError}
                                  data={item.more_variants}
                                  id={item.id}
                                  value={filterValue}
                                  setValue={setFilterValue}
                                  submitApi={changeVariant}
                                  cartDetails={cartDetails}
                                  varientId={varientId}
                                  setVarientId={setVarientId}
                                />
                              </Forms>
                            </ProductDetail>
                          </LeftSide>
                          <RightSide>
                            <CardLeft>
                              <QuantityBox>
                                <DecreaseItem
                                  onClick={() => {
                                    if (item.qty > 1) {
                                      updateQty(item.id, item.qty - 1)
                                    } else if (item.qty == 1) {
                                      setId(item.id)
                                      setConfirmRemove(true)
                                    }
                                  }}
                                >
                                  <Img src={remove} alt="Image" />
                                </DecreaseItem>
                                <CountItem>{item.qty}</CountItem>
                                <IncreaseItem
                                  onClick={() => {
                                    updateQty(item.id, item.qty + 1)
                                  }}
                                >
                                  <Img src={add} alt="Image" />
                                </IncreaseItem>
                              </QuantityBox>
                            </CardLeft>
                            <CardRight>
                              {item.offer_price ? (
                                <Price>MRP : ₹ {item.offer_price}</Price>
                              ) : (
                                <Price>MRP : ₹ {item.unit_price}</Price>
                              )}
                              {/* </PriceDetails> */}

                              <OptionContainer>
                                {/* <WishListButton
                                  onClick={() =>
                                    setFav(
                                      !fav
                                    )
                                  }
                                >
                                  <HeartBox
                                    src={
                                      item.in_wishlist
                                        ? heartfill
                                        : heart
                                    }
                                    alt="Icon"
                                  />
                                </WishListButton> */}
                                <CartRemoveConfirmModal
                                  isconfirmRemove={isconfirmRemove}
                                  setConfirmRemove={setConfirmRemove}
                                  id={id}
                                  removeapi={removeFromCart}
                                  setIsStock={setIsStock}
                                />
                                <WishListButton
                                  onClick={() => {
                                    // removeFromCart(
                                    //     item.id
                                    // );
                                    setId(item.id)
                                    setConfirmRemove(true)
                                  }}
                                >
                                  <DeleteIcon src={deleteIcn} alt="Icon" />
                                </WishListButton>
                              </OptionContainer>
                            </CardRight>
                          </RightSide>
                        </CartCard>
                      </>
                    ))}
                  </LeftBox>
                  <RightBox>
                    {Object.keys(selectedAddress).length > 0 && (
                      // <div>
                      <AddressCard>
                        <AddressTop>
                          <NameCard>
                            <Name>
                              {selectedAddress.first_name}
                              &nbsp;
                              {selectedAddress.last_name}
                            </Name>
                          </NameCard>
                          <EditAddress
                            onClick={() => {
                              setEditAddress(true)
                              // setSelectedAddress(
                              //  selectedAddress
                              // );
                            }}
                          >
                            <Img src={editicon} alt="Image" />
                          </EditAddress>
                        </AddressTop>
                        {selectedAddress.is_default && (
                          <DefaultButton>Default Address</DefaultButton>
                        )}
                        <Address>
                          {/* Mellano Rode,East
                      station322,Banaglore, +91 8998788798
                      princeprince@gmail.com 605040 */}
                          {selectedAddress.address}
                          <br />
                          {selectedAddress.location} , {selectedAddress.state}
                          <br />
                          {selectedAddress.pincode}
                          <br />
                          {selectedAddress.phone}
                          <br />
                          {selectedAddress.email}
                        </Address>
                        <ChangeAddress
                          onClick={() => {
                            setChangeAddress(true)
                          }}
                        >
                          Change Address
                        </ChangeAddress>
                      </AddressCard>
                      // </div>
                    )}
                    {cartDetails?.cart_amount && (
                      <div>
                        <TitleHead>Summary</TitleHead>
                        <SubContainer>
                          <TotalPrice>
                            Subtotal{' '}
                            <PriceL>₹ {cartDetails?.cart_amount}</PriceL>
                          </TotalPrice>
                          {/* <Delivery>
                            Delivery & Handing{" "}
                            <PriceL>₹ 60</PriceL>
                          </Delivery> */}
                          {/* <ReferralCard>
                            {referralSuccess ? (
                              <>
                                <ReferralTitle>
                                  Referral Code Applied
                                </ReferralTitle>
                                <SuccessCard>
                                  <SuccessTitle>
                                    You Save: ₹100
                                    <Span
                                      onClick={() =>
                                        SetReferralSuccess(false) &
                                        SetReferralCode('')
                                      }
                                    >
                                      <Img src={deleteIcn} alt="Image" />
                                    </Span>
                                  </SuccessTitle>
                                  <SuccessDetails>
                                    Referral Code Applied: #GERY15
                                  </SuccessDetails>
                                </SuccessCard>
                              </>
                            ) : (
                              <>
                                <ReferralTitle>
                                  Apply Referral Code, get up to 10% OFF
                                </ReferralTitle>
                                <ReferralCode>
                                  <ReferralInput
                                    // type="text"
                                    placeholder="Enter Referral Code"
                                    onChange={(e) => {
                                      SetReferralCode(e.target.value)
                                    }}
                                  />
                                  <ApplyButton
                                    onClick={() => {
                                      if (referralCode) {
                                        applyCoupon()
                                      }
                                    }}
                                  >
                                    Apply
                                  </ApplyButton>
                                </ReferralCode>
                                <RefError>{referralError}</RefError>
                              </>
                            )}
                          </ReferralCard> */}
                          <DeliveryPrice>
                            Total <PriceL>₹ {cartDetails.total}</PriceL>
                          </DeliveryPrice>
                          <PrimaryButton
                            className="category_button"
                            text={'CHECKOUT'}
                            size={'small'}
                            onClick={() => {
                              if (isStock == false) {
                                // console.log(isStock, 'stock')
                                setCheckoutModal(true)
                              } else {
                                stockToast()
                                getCartItems()
                              }
                            }}
                          />
                        </SubContainer>
                      </div>
                    )}
                  </RightBox>
                  {/* </LeftBox> */}
                </BodyContainer>
              ) : (
                <EmptyPage image={emptyCart} status={'Your Cart is Empty !'} />
              )}
            </>
          ) : (
            <DefaultLottie />
          )}
        </ContainerBox>
        {checkoutModal && (
          // <CheckOutModal
          //   setModal={setCheckoutModal}
          //   modal={checkoutModal}
          //   data={cartDetails}
          //   address={selectedAddress}
          //   changeAddress={changeAddress}
          //   setChangeAddress={setChangeAddress}
          //   SetHeight={SetHeight}
          //   height={height}
          // />

          <OverlayModal
            setModal={setCheckoutModal}
            height={height}
            allowScroll={allowScroll}
          >
            {checkoutModal == true ? (
              <CheckOutPage
                setModal={setCheckoutModal}
                modal={checkoutModal}
                data={cartDetails}
                address={selectedAddress}
                changeAddress={changeAddress}
                setChangeAddress={setChangeAddress}
                SetHeight={SetHeight}
                height={height}
                closeModal={closeModal}
              />
            ) : checkoutModal === 'payment' ? (
              <PaymentInfo setModal={setCheckoutModal} />
            ) : checkoutModal === 'confirmOrder' ? (
              <ConfirmOrder setModal={setCheckoutModal} />
            ) : null}
          </OverlayModal>
        )}
        {editAddress && (
          <ModalCenter
            children={
              <NewAddressModal
                closeModal={closeModal}
                edit={true}
                selectedAddress={selectedAddress}
                refreshApi={getCustomerAddress}
              />
            }
            closeModal={closeModal}
          />
        )}
        {changeAddress && (
          <ModalCenter
            children={
              <ChangeAddressModal
                selectedAddress={selectedAddress}
                closeModal={closeModal}
                setSelectedAddress={setSelectedAddress}
                // refreshApi={getCustomerDefaultAddress}
              />
            }
            closeModal={closeModal}
            search={search}
          />
        )}
        {newAddress && (
          <ModalCenter
            children={
              <NewAddressModal
                closeModal={closeModal}
                refreshApi={getCustomerAddress}
              />
            }
            closeModal={closeModal}
          />
        )}
      </MainContainer>
    </>
  )
}

const MainContainer = styled.div`
  width: 90%;
  max-width: 1350px;
  margin: 0 auto 40px;
  padding-top: 120px;
  @media screen and (max-width: 1080px) {
    margin: 0px auto 40px;
  }
  @media screen and (max-width: 480px) {
    /* padding: 50px 0; */
  }
  div.overlay {
    height: 100vh;
    width: 100%;
    background: #201f1fab;
    position: absolute;
    top: 0;
    right: 0;
  }
`
const ContainerBox = styled.div`
  /* display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 110px; */
`

const BodyContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 20px;
  @media screen and (max-width: 1180px) {
    display: block;
  }
`
const LeftBox = styled.div`
  /* display: flex;
  height: max-content;
  justify-content: space-between;
  gap: 32px;
  /* width: 70%; */
  /* @media screen and (max-width: 980px) {
    flex-wrap: wrap;
  } */
  /* @media screen and (max-width: 480px) {
        width: 100%;
    } */
`
const RightBox = styled.div`
  /* width: 27%; */
  @media screen and (max-width: 1080px) {
    /* width: 31%; */
  }
  @media screen and (max-width: 980px) {
    /* width: 70%; */
    /* margin: 0 auto; */
  }
  @media screen and (max-width: 480px) {
    /* width: 100%; */
  }
`
const TopContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between;
`
const TitleName = styled.h3`
  /* width: 100%; */
  font-size: 32px;
  /* line-height: 48px; */
  font-family: 'poppins-medium';
  color: ${THEME_COLORS.background_1};
  @media screen and (max-width: 980px) {
    font-size: 28px;
  }
  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
`
const AddressCard = styled.div`
  border: 2px solid ${THEME_COLORS.border_4};
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 15px;
`
const AddressTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const NameCard = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
const Name = styled.div`
  font-family: 'poppins-medium';
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${THEME_COLORS.background_1};
`
const DefaultButton = styled.div`
  font-family: 'poppins-regular';
  background: ${THEME_COLORS.light_orange};
  border-radius: 48px;
  width: max-content;
  padding: 4px 10px;
  color: #e19200;
  font-size: 12px;
  margin: 5px 0;
  @media screen and (max-width: 1280px) {
    /* font-size: 14px;
    padding: 4px 12px; */
  }
  @media screen and (max-width: 980px) {
    /* font-size: 15px;
    padding: 4px 16px; */
  }
`
const EditAddress = styled.div`
  width: 24px;
  cursor: pointer;
  height: 24px;
`
const Address = styled.div`
  font-family: 'poppins-regular';
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  /* width: 60%; */
  /* text-align: center; */
  margin-bottom: 16px;
  color: ${THEME_COLORS.background_4};
`
const ChangeAddress = styled.div`
  cursor: pointer;
  font-family: 'Poppins-medium';
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${THEME_COLORS.primary};
`
const CartCard = styled.div`
  /* display: flex;
  gap: 35px; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  height: max-content;
  background: ${THEME_COLORS.border_5};
  border-radius: 16px;
  margin-bottom: 20px;
  padding: 12px;
  :last-child {
    margin-bottom: 0px;
  }
  &.unavailable {
    filter: opacity(0.5);
  }
  @media screen and (max-width: 1180px) {
    :last-child {
      margin-bottom: 20px;
    }
  }
  @media screen and (max-width: 980px) {
    /* width: 100%; */
    display: block;
  }
  @media screen and (max-width: 640px) {
    /* flex-wrap: wrap; */
  }
`
const TitleHead = styled.h4`
  font-size: 20px;
  line-height: 30px;
  font-family: 'poppins-medium';
  /* margin: 15px 0 5px; */
`
const LeftSide = styled.div`
  display: flex;
  /* width: 27%; */
  @media screen and (max-width: 980px) {
    margin-bottom: 20px;
  }
  @media screen and (max-width: 480px) {
    display: block;
  }
`
const ImageBox = styled.div`
  width: 180px;
  height: 180px;
  margin-right: 15px;
  &.unavailable {
    filter: opacity(0.5);
  }
  @media screen and (max-width: 480px) {
    margin: 0 auto;
  }
`
const ItemImg = styled.img`
  width: 180px;
  height: 180px;
  display: block;
  border-radius: 16px;
  object-fit: contain;
`
const ProductDetail = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const Img = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 16px;
  object-fit: contain;
`
const RightSide = styled.div`
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: flex-start;
  /* width: 70%; */
  @media screen and (max-width: 1280px) {
    /* width: 100%; */
  }
  @media screen and (max-width: 480px) {
    display: block;
    margin-top: 20px;
  }
`
const ProductName = styled.h4`
  font-size: 22px;
  line-height: 36px;
  color: ${THEME_COLORS.background_1};
  font-family: 'poppins-medium';
  margin-bottom: 7px;
  @media screen and (max-width: 1200px) {
    font-size: 20px;
  }
  @media screen and (max-width: 1080px) {
    font-size: 20px;
  }
  @media screen and (max-width: 480px) {
    text-align: center;
  }
`
const ProductDetails = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-family: 'poppins-medium';
  color: ${THEME_COLORS.background_4};
  font-weight: 400;
  margin-bottom: 7px;

  @media screen and (max-width: 480px) {
    /* font-size: 14px; */
  }
`
const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  justify-content: flex-end;
`
const WishListButton = styled.div`
  width: max-content;
  height: max-content;
  background: ${THEME_COLORS.white};
  border-radius: 50%;
  border: 1px solid ${THEME_COLORS.border_3};
  padding: 10px;
  gap: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: ${THEME_COLORS.border_4};
    border: 1px solid ${THEME_COLORS.border_4};
    cursor: pointer;
  }
  &:active {
    background: ${THEME_COLORS.border_4};
    border: 1px solid ${THEME_COLORS.border_4};
  }
  @media screen and (max-width: 480px) {
    /* font-size: 14px; */
  }
  @media screen and (max-width: 360px) {
    /* margin-bottom: 20px; */
  }
`
// const RemoveItemButton = styled.div`
//  width: max-content;
//  height: max-content;
//  background: ${THEME_COLORS.white};
//  border-radius: 50%;
//  border: 1px solid #dbdbdb;
//  padding: 10px;
//  gap: 16px;
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  &:hover {
//    background: #eeeeee;
//    border: 1px solid #eeeeee;
//  }
//  &:active {
//    background: #eeeeee;
//    border: 1px solid #eeeeee;
//  }
//  @media screen and (max-width: 510px) {
//    font-size: 14px;
//    margin-top: 20px;
//  }
// `;
const DeleteIcon = styled.img`
  display: block;
  width: 24px;
  height: 24px;
  @media screen and (max-width: 1080px) {
    width: 20px;
    height: 20px;
  }
`
// const HeartBox = styled.img`
//     display: block;
//     width: 24px;
//     height: 24px;
//     @media screen and (max-width: 1080px) {
//         width: 20px;
//         height: 20px;
//     }
// `;
const Price = styled.span`
  min-width: max-content;
  font-size: 22px;
  line-height: 36px;
  font-family: 'poppins-medium';
  @media screen and (max-width: 1200px) {
    font-size: 20px;
  }
  @media screen and (max-width: 480px) {
    margin-top: 20px;
  }
`
const Forms = styled.div`
  width: 200px;
  @media screen and (max-width: 480px) {
    /* width: 150px; */
  }
  @media screen and (max-width: 360px) {
    /* margin-bottom: 30px; */
  }
`
const QuantityBox = styled.div`
  gap: 15px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  &.quantity-box1 {
    display: none;
  }
  @media screen and (max-width: 1400px) {
    /* &.quantity-box {
      display: none;
    }
    &.quantity-box1 {
      display: flex;
    } */
  }
`
const CardLeft = styled.div`
  display: flex;
  gap: 20px;
  @media screen and (max-width: 1400px) {
    /* justify-content: space-between;
    width: 100%; */
  }
  @media screen and (max-width: 1280px) {
    /* flex-direction: column;
    gap: 0px;
    margin-bottom: 8px; */
  }
`
const CartTitle = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 40%; */
  margin-bottom: 8px;
  @media screen and (max-width: 1400px) {
    /* width: 90%; */
  }
  @media screen and (max-width: 640px) {
    /* width: 100%; */
  }
`
const CardRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  @media screen and (max-width: 1280px) {
    /* justify-content: space-between; */
  }
`
// const CartCounter = styled.div`
//  display: flex;
//  gap: 8px;
//  flex-direction: column-reverse;
// `;
// const PriceDetails = styled.div`
//  display: flex;
//  justify-content: space-between;
//  width: 57%;
//  align-items: self-start;
//  @media screen and (max-width: 1400px) {
//    width: max-content;
//  }
// `;
const DecreaseItem = styled.span`
  background: ${THEME_COLORS.border_4};
  box-shadow: 0px 2px 2px rgba(114, 114, 114, 0.25),
    inset 0px -2px 2px rgba(255, 255, 255, 0.37),
    inset 0px 2px 2px rgba(227, 227, 227, 0.68);
  border-radius: 8px;
  width: 36px;
  height: 36px;
  padding: 6px;
  gap: 10px;
  display: flex;
  cursor: pointer;
  :hover {
    background: ${THEME_COLORS.white};
  }
  @media screen and (max-width: 1200px) {
    width: 30px;
    height: 30px;
  }
`
const CountItem = styled.div`
  background: ${THEME_COLORS.white};
  border: 1px solid #efc1b4;
  border-radius: 8px;
  width: 68px;
  /* height: 37px; */
  align-items: center;
  padding: 4px 10px;
  gap: 10px;
  text-align: center;
  font-size: 19px;
  /* line-height: 28px; */
  @media screen and (max-width: 1200px) {
    width: 60px;
  }
`
const IncreaseItem = styled.span`
  background: ${THEME_COLORS.border_4};
  box-shadow: 0px 2px 2px rgba(114, 114, 114, 0.25),
    inset 0px -2px 2px rgba(255, 255, 255, 0.37),
    inset 0px 2px 2px rgba(227, 227, 227, 0.68);
  border-radius: 8px;
  width: 36px;
  height: 36px;
  padding: 6px;
  gap: 10px;
  cursor: pointer;
  display: flex;
  :hover {
    background: ${THEME_COLORS.white};
  }
  @media screen and (max-width: 1200px) {
    width: 30px;
    height: 30px;
  }
`
const SubContainer = styled.div`
  background: ${THEME_COLORS.border_5};
  border-radius: 13px;
  padding: 24px;
  /* gap: 16px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 5px;
`
const TotalPrice = styled.h4`
  display: flex;
  font-family: 'poppins-medium';
  font-size: 19px;
  line-height: 28px;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 16px;
  @media screen and (max-width: 480px) {
    /* font-size: 14px; */
  }
`
const PriceL = styled.span`
  font-family: 'poppins-medium';
  font-size: 19px;
  line-height: 28px;
  @media screen and (max-width: 480px) {
    /* font-size: 18px; */
  }
`
const Delivery = styled.h4`
  font-family: 'poppins-medium';
  font-size: 19px;
  line-height: 28px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 16px;
  @media screen and (max-width: 480px) {
    /* font-size: 14px; */
  }
`
const ReferralCard = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  border: 0.5px dashed ${THEME_COLORS.secondary};
  border-radius: 13px;
  margin-bottom: 25px;
  @media screen and (max-width: 1280px) {
    /* min-width: min-content; */
  }
`
const ReferralTitle = styled.p`
  font-family: 'poppins-medium';
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: ${THEME_COLORS.background_1};
  margin-bottom: 16px;
`
const RefError = styled.p`
  font-family: 'poppins-medium';
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: red;
  margin: 20px 0;
`
const ReferralCode = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1280px) {
    /* flex-direction: column; */
  }
  input {
    @media screen and (max-width: 480px) {
      width: 165px;
    }
  }
`
const SuccessCard = styled.div`
  background: #eafff3;
  border: 0.5px solid ${THEME_COLORS.secondary};
  border-radius: 8px;
  padding: 16px 12px;
`
const SuccessTitle = styled.p`
  font-family: 'poppins-medium';
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 20px;
  /* line-height: 30px; */
  color: ${THEME_COLORS.secondary};
`
const Span = styled.span`
  cursor: pointer;
  display: inline-block;
  width: 16px;
  height: 16px;
`
const SuccessDetails = styled.p`
  font-family: 'poppins-medium';
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${THEME_COLORS.secondary};
`
const ReferralInput = styled.input`
  background: ${THEME_COLORS.white};
  /* width: 80%; */
  font-family: 'poppins-medium';
  font-size: 14px;
  border-radius: 6px 0 0 6px;
  border: 1px solid ${THEME_COLORS.border_3};
  padding: 0px 12px;
  height: 40px;
  @media screen and (max-width: 1080px) {
    /* width: 90%; */
    /* margin-bottom: 8px; */
  }
  @media screen and (max-width: 980px) {
    /* width: 100%; */
  }
`
const ApplyButton = styled.a`
  cursor: pointer;
  background: ${THEME_COLORS.secondary};
  border-radius: 0px 6px 6px 0px;
  padding: 0px 15px;
  font-family: 'poppins-medium';
  font-size: 14px;
  height: 40px;
  color: ${THEME_COLORS.white};
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1280px) {
    /* border-radius: 6px;
    width: 90%;
    text-align: center; */
  }
  @media screen and (max-width: 360px) {
    font-size: 12px;
    padding: 0px 5px;
  }
`
const DeliveryPrice = styled.h4`
  font-family: 'poppins-medium';
  font-size: 19px;
  line-height: 28px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 30px;
  @media screen and (max-width: 480px) {
    /* font-size: 18px; */
  }
`
const Availability = styled.p`
  /* display: none; */
  color: red;
  font-family: 'poppins-medium';
  font-size: 14px;
  margin-bottom: 10px;
  @media screen and (max-width: 640px) {
    font-size: 12px;
  }
`
