import React, { useState } from "react";
import styled from "styled-components";
import arrow from "./../../../assets/icons/arrow-right.svg";
import { NavLink } from "react-router-dom";
import {
    aboutSvg,
    // notificationSvg,
    privacySvg,
    termsSvg,
    deleteSvg,
} from "./SettingsSvgFiles";
import { THEME_COLORS } from "../../../ThemeConfig";
import DeleteWarnigModal from "../../includes/modal/deleteAccount/DeleteWarnigModal";
import ConfirmDeleteModal from "../../includes/modal/deleteAccount/ConfirmDeleteModal";
import Otp from "../loginpage/Otp";
import DeleteOtpModal from "../../includes/modal/deleteAccount/DeleteOtpModal";
import DeleteAccount from "../../includes/modal/deleteAccount/DeleteAccount";

export default function Settings() {
    // const navigate = useNavigate();
    const [deleteModal, setDeleteModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [otpModal, setOtpModal] = useState(false);
    const [finalModal, setFinalModal] = useState(false);

    return (
        <MainContainer>
            {deleteModal && (
                <DeleteWarnigModal
                    deleteModal={deleteModal}
                    setDeleteModal={setDeleteModal}
                    setConfirmModal={setConfirmModal}
                />
            )}
            {confirmModal && (
                <ConfirmDeleteModal
                    setConfirmModal={setConfirmModal}
                    setOtpModal={setOtpModal}
                />
            )}
            {otpModal && (
                <DeleteOtpModal
                    setOtpModal={setOtpModal}
                    setFinalModal={setFinalModal}
                />
            )}

            {finalModal && <DeleteAccount setFinalModal={setFinalModal} />}
            <List>
                <NavLink to="/about-page">
                    <Items>
                        <Left>
                            <Icon>{aboutSvg()}</Icon>
                            <Name>About Us</Name>
                        </Left>
                        <Icon>
                            <Img src={arrow} alt="image" />
                        </Icon>
                    </Items>
                </NavLink>
                {/* <NavLink to={""}>
                    <Items>
                        <Left>
                            <Icon>{notificationSvg()}</Icon>
                            <Name>Notification Settings</Name>
                        </Left>
                        <Icon>
                            <Img src={arrow} alt="" />
                        </Icon>
                    </Items>
                </NavLink> */}
                <NavLink to="/privacy-policies">
                    <Items>
                        <Left>
                            <Icon>{privacySvg()}</Icon>
                            <Name>Privacy Policy</Name>
                        </Left>
                        <Icon>
                            <Img src={arrow} alt="image" />
                        </Icon>
                    </Items>
                </NavLink>
                <NavLink to="/terms-and-conditions">
                    <Items>
                        <Left>
                            <Icon>{termsSvg()}</Icon>
                            <Name>Terms & Conditions</Name>
                        </Left>
                        <Icon>
                            <Img src={arrow} alt="image" />
                        </Icon>
                    </Items>
                </NavLink>
                <NavLink to="/cancellation-policy">
                    <Items>
                        <Left>
                            <Icon>{termsSvg()}</Icon>
                            <Name>Cancellation Policy</Name>
                        </Left>
                        <Icon>
                            <Img src={arrow} alt="image" />
                        </Icon>
                    </Items>
                </NavLink>
                <NavLink to="/shipping-policy">
                    <Items>
                        <Left>
                            <Icon>{termsSvg()}</Icon>
                            <Name>Shipping Policy</Name>
                        </Left>
                        <Icon>
                            <Img src={arrow} alt="image" />
                        </Icon>
                    </Items>
                </NavLink>
                <NavLink
                    to={""}
                    onClick={() => {
                        setDeleteModal(true);
                    }}
                >
                    <Items>
                        <Left>
                            <Icon>{deleteSvg()}</Icon>
                            <Name>Delete Account</Name>
                        </Left>
                        <Icon>
                            <Img src={arrow} alt="image" />
                        </Icon>
                    </Items>
                </NavLink>
            </List>
        </MainContainer>
    );
}

const MainContainer = styled.div`
    width: max-content;
    max-width: 1350px;
    margin: 0 auto;
    padding: 120px 0 70px;
`;
const List = styled.div`
    padding: 32px;
    background: ${THEME_COLORS.white};
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.04);
    @media screen and (max-width: 540px) {
        padding: 20px;
    }
`;

const Name = styled.p`
    color: ${THEME_COLORS.background_1};
    font-size: 16px;
    font-family: "poppins-regular";
`;
const Items = styled.div`
    display: flex;
    justify-content: space-between;
    width: 396px;
    padding: 20px;
    background: ${THEME_COLORS.white};
    border-radius: 6px;
    cursor: pointer;
    &:hover {
        background: rgba(16, 111, 57, 0.1);
        border-radius: 6px;
        path {
            stroke: ${THEME_COLORS.secondary};
        }
        ${Name} {
            color: ${THEME_COLORS.secondary};
        }
    }
    @media screen and (max-width: 540px) {
        width: 300px;
        padding: 16px;
    }
    @media screen and (max-width: 360px) {
        width: 260px;
    }
`;
const Left = styled.div`
    display: flex;
    gap: 10px;
`;
const Icon = styled.div`
    width: 24px;
    :hover {
        border-radius: 4px;
        color: ${THEME_COLORS.secondary};
        stroke: ${THEME_COLORS.secondary};

        /* path {
			stroke: green;
		} */
    }
`;
const Img = styled.img`
    display: block;
    width: 100%;
`;
