import React, { useState } from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'
import PrimaryButton from '../buttons/PrimaryButton'
import check from '../../../assets/icons/check-white.svg'

const FilterOrder = ({ setShowFilter, selectedValues, setSelectedValues }) => {
  const list = [
    {
      id: 1,
      name: 'All',
    },
    {
      id: 2,
      name: 'Shipped',
    },
    {
      id: 3,
      name: 'Packed',
    },
    {
      id: 4,
      name: 'Delivered',
    },
    {
      id: 5,
      name: 'Cancelled',
    },
  ]

  //   const [selectedValues, setSelectedValues] = useState([])

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target
    if (checked) {
      setSelectedValues((prevValues) => [...prevValues, value])
    } else {
      setSelectedValues((prevValues) =>
        prevValues.filter((val) => val !== value),
      )
    }
  }

  const handleFilter = () => {
    // filter orders
    setShowFilter(false)
  }

  return (
    <MainContainer>
      <Head>
        <Left>Filter By</Left>
        <Right onClick={() => setSelectedValues([])}>Reset</Right>
      </Head>
      <Status>
        <Title>Status</Title>
        {list.map((item) => (
          <CheckBox
            key={item.id}
            check={check}
            checked={selectedValues.includes(item.name)}
          >
            <input
              type="checkbox"
              value={item.name}
              id={item.name}
              onChange={handleCheckboxChange}
            />
            <label htmlFor={item.name}>{item.name}</label>
          </CheckBox>
        ))}
      </Status>
      <Bottom>
        <CancelButton onClick={() => setShowFilter(false)}>Cancel</CancelButton>
        <PrimaryButton text="Apply" size="xsmall" onClick={handleFilter} />
      </Bottom>
    </MainContainer>
  )
}

export default FilterOrder

const MainContainer = styled.div`
  width: 237px;
  background: ${THEME_COLORS.white};
  box-shadow: 14px 18px 33px -7px rgba(0, 0, 0, 0.19);
  border-radius: 8px;
  padding: 10px;
`
const Head = styled.div`
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Left = styled.h4`
  font-family: 'poppins-regular';
  font-weight: 600;
  font-size: 16px;
  color: ${THEME_COLORS.background_3};
`
const Right = styled.button`
  padding: 6px 16px;
  background: ${THEME_COLORS.white};
  border: 1px solid ${THEME_COLORS.border_3};
  border-radius: 37px;
  font-family: 'poppins-regular';
  font-weight: 400;
  font-size: 14px;
  color: ${THEME_COLORS.background_1};
`
const Status = styled.div`
  padding: 16px 12px;
  width: 217px;
  height: 314px;
  background: ${THEME_COLORS.border_5};
  border-radius: 12px;
  margin-bottom: 16px;
`
const Title = styled.h3`
  border-bottom: 1px solid ${THEME_COLORS.border_3};
  padding: 0px 0px 12px;
  font-family: 'poppins-regular';
  font-weight: 600;
  font-size: 16px;
  color: ${THEME_COLORS.background_3};
  margin-bottom: 6px;
`
const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const CancelButton = styled.button`
  font-family: 'poppins-regular';
  font-weight: 500;
  font-size: 14px;
  color: ${THEME_COLORS.primary};
  cursor: pointer;
`
const CheckBox = styled.div`
  padding: 12px 0px;
  position: relative;
  input {
    margin-right: 10px;
    opacity: 0;
    position: absolute;
  }
  label {
    display: flex;
    font-family: 'poppins-regular';
    font-weight: 400;
    font-size: 16px;
    color: ${(props) =>
      props.checked ? THEME_COLORS.secondary : THEME_COLORS.background_1};
    &::before {
      content: '';
      width: 20px;
      height: 20px;
      border-radius: 4px;
      margin-right: 0.5em;
      border: 1px solid
        ${(props) => (props.checked ? 'transparent' : THEME_COLORS.border_2)};
      background-color: ${(props) =>
        props.checked ? THEME_COLORS.secondary : THEME_COLORS.white};
      background-image: url(${(props) => props.checked && props.check});
      background-position: center;
      background-repeat: no-repeat;
    }
  }
`
