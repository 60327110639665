import React, { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'
import arrow from '../../../assets/icons/arrow-left.svg'
import tick_fill from '../../../assets/icons/tick-square.svg'
import tick from '../../../assets/icons/tick-bg.svg'
import ProductCard from './ProductCard'
import DeliveryCard from './DeliveryCard'
import alert from '../../../assets/icons/alert-triangle.svg'

import { Context } from '../../../contexts/Store'
import useAuth from '../../includes/functions/authFunctions'
import { baseConfig } from '../../../axiosConfig'
import Cancelation from './Cancelation'
import Steps from '../../includes/step/Step'

export default function SingleOrderResponsive() {
  const navigate = useNavigate()
  const { pk } = useParams()
  const [filteredOrder, setFilteredOrder] = useState([])
  const [filteredProducts, setFilteredProducts] = useState([])
  const [cancel, setCancel] = useState(false)

  const { state } = useContext(Context)

  var retrievedValue = localStorage.getItem('order_details')
  //   console.log(retrievedValue, 'retrievedValue')
  var parsedObject = JSON.parse(retrievedValue)
  //   console.log('pk:', parsedObject.pk)

  const [list, setList] = useState([
    {
      id: 1,
      name: 'Order Success',
      date: '10.34, Nov 3',
      status: true,
    },
    {
      id: 2,
      name: 'Order Packed',
      date: '10.34, Nov 4',
      status: false,
    },
    {
      id: 3,
      name: 'Order Shipped',
      date: '10.34, Nov 5',
      status: false,
    },
    {
      id: 4,
      name: 'Delivered',
      date: '10.34, Nov 6',
      status: false,
    },
  ])
  const [orderedlist, setOrderedlist] = useState([
    {
      id: 1,
      name: 'ordered',
      status: true,
    },
    {
      id: 2,
      name: 'packed',
      status: false,
    },
    {
      id: 3,
      name: 'shipped',
      status: false,
    },
    {
      id: 4,
      name: 'delivered',
      status: false,
    },
  ])

  const [Packedkedlist, setPackedkedlist] = useState([
    {
      id: 1,
      name: 'ordered',
      status: true,
    },
    {
      id: 2,
      name: 'packed',
      status: true,
    },
    {
      id: 3,
      name: 'shipped',
      status: false,
    },
    {
      id: 4,
      name: 'delivered',
      status: false,
    },
  ])
  const [shippedlist, setShippedlist] = useState([
    {
      id: 1,
      name: 'ordered',
      status: true,
    },
    {
      id: 2,
      name: 'packed',
      status: true,
    },
    {
      id: 3,
      name: 'shipped',
      status: true,
    },
    {
      id: 4,
      name: 'delivered',
      status: false,
    },
  ])
  const [deliveredlist, setDeliveredList] = useState([
    {
      id: 1,
      name: 'ordered',
      status: true,
    },
    {
      id: 2,
      name: 'packed',
      status: true,
    },
    {
      id: 3,
      name: 'shipped',
      status: true,
    },
    {
      id: 4,
      name: 'delivered',
      status: true,
    },
  ])
  console.log(cancel, 'cancel-------')

  const getOrder = () => {
    const accessToken = state.user_details.access
    baseConfig
      .get(`orders/order/${parsedObject.pk}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        // console.log('RESPONSE:', response.data.data)
        // setFilteredProducts(response.data.data)
        // console.log(filteredProducts, 'singleorderresponsive')
        const { StatusCode } = response.data
        console.log('RESPONSE:', response.data)
        if (StatusCode === 6000) {
          setFilteredOrder(response.data.data)
          console.log(filteredOrder, 'singleorderresponsive')
          setFilteredProducts(response?.data?.data?.order_items)
        }
      })
      .catch((error) => {
        // const { status, data } = error?.response;
        console.log('ERROR---------------:', error)
      })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    getOrder()
  }, [])

  // useEffect(() =>{
  //     const product = orderList.find(order => order.order_id === parseInt(id))
  //     setFilteredOrder(product)
  //     setFilteredProducts(product.products)
  // },[])
  console.log(filteredProducts, 'filteredProducts')

  return (
    <Container className="dilu">
      <HeadWrapper>
        <Arrow onClick={() => navigate(-1)}>
          <Img src={arrow} alt="left arrow" />
        </Arrow>
        <Head>
          Order ID : {filteredOrder?.order_id}{' '}
          <span>({filteredOrder?.items_count})</span>
        </Head>
      </HeadWrapper>

      <Details>
        <StatusDetails>
          <StatusHead>Order Status</StatusHead>
          {filteredOrder.order_status == 'pending' ? (
            ''
          ) : (
            <>
              {filteredOrder.order_status == 'ordered' ? (
                <Status>
                  {orderedlist.map((item) => (
                    <Content
                      key={item.id}
                      image={item.status ? tick_fill : tick}
                      status={item.status}
                    >
                      <CurrentStatus>{item.name}</CurrentStatus>
                      {/* <Date>{item.date}</Date> */}
                    </Content>
                  ))}
                </Status>
              ) : filteredOrder.order_status == 'packed' ? (
                <Status>
                  {Packedkedlist.map((item) => (
                    <Content
                      key={item.id}
                      image={item.status ? tick_fill : tick}
                      status={item.status}
                    >
                      <CurrentStatus>{item.name}</CurrentStatus>
                      {/* <Date>{item.date}</Date> */}
                    </Content>
                  ))}
                </Status>
              ) : filteredOrder.order_status == 'shipped' ? (
                <Status>
                  {shippedlist.map((item) => (
                    <Content
                      key={item.id}
                      image={item.status ? tick_fill : tick}
                      status={item.status}
                    >
                      <CurrentStatus>{item.name}</CurrentStatus>
                      {/* <Date>{item.date}</Date> */}
                    </Content>
                  ))}
                </Status>
              ) : filteredOrder.order_status == 'delivered' ? (
                <Status>
                  {deliveredlist.map((item) => (
                    <Content
                      key={item.id}
                      image={item.status ? tick_fill : tick}
                      status={item.status}
                    >
                      <CurrentStatus>{item.name}</CurrentStatus>
                      {/* <Date>{item.date}</Date> */}
                    </Content>
                  ))}
                </Status>
              ) : (
                <DetailsCount className="cancell">
                  <Action className="cancell">
                    Your Order has been cancelled
                  </Action>
                  <Count className="cancell">
                    <Img src={alert} alt="Image" />
                  </Count>
                </DetailsCount>
              )}
            </>
          )}
          {/* {filteredOrder.order_status == 'pending' ? (
            ''
          ) : (
            <StatusIndicator>
              <Status>
                <Steps order_status={filteredOrder.order_status} />
              </Status>
            </StatusIndicator>
          )} */}
        </StatusDetails>
        <ProductDetails>
          {filteredProducts?.map((product) => (
            <ProductCard
              key={product.product_id}
              product={product}
              responsive
            />
          ))}
        </ProductDetails>

        {/* <Product>
          <Name>Total</Name>
          <Name>₹ {filteredOrder?.total_amount}</Name>
        </Product> */}
      </Details>
      <DeliveryDetails>
        <DeliveryCard responsive active={filteredOrder} />
      </DeliveryDetails>
      <BottomLinks>
        {filteredOrder?.can_cancel && (
          <Cancel
            //   to="/cancelation"
            onClick={() => {
              setCancel(true)
            }}
          >
            Cancel order
          </Cancel>
        )}

        {/* <Help>Need Help ?</Help> */}
      </BottomLinks>
      {cancel && (
        <CancelContainer>
          {filteredProducts?.map((product) => (
            <Cancelation
              key={product.pk}
              product={product}
              active={filteredOrder}
              setCancel={setCancel}
              filteredProducts={filteredProducts}
              cancel={cancel}
              getOrderStatus={getOrder}
            />
          ))}
        </CancelContainer>
      )}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 100px 0 40px;
`
const HeadWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 24px;
  margin-bottom: 18px;
`
const Arrow = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`
const Head = styled.h4`
  font-family: 'poppins-regular';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${THEME_COLORS.background_2};
  span {
    color: ${THEME_COLORS.background_5};
  }
`
const Img = styled.img`
  display: block;
  width: 100%;
`
const Details = styled.div`
  padding: 40px 24px;
  background: ${THEME_COLORS.border_5};
  border-radius: 24px 24px 0px 0px;
`
const StatusDetails = styled.div``
const StatusHead = styled.h4`
  font-family: 'poppins-regular';
  font-weight: 600;
  font-size: 16px;
  color: ${THEME_COLORS.background_3};
  border-bottom: 1px solid ${THEME_COLORS.border_4};
  padding: 0 0 10px;
  margin-bottom: 32px;
`

const CurrentStatus = styled.div`
  font-family: 'poppins-regular';
  font-weight: 500;
  font-size: 14px;
  color: ${THEME_COLORS.background_1};
  margin-bottom: 4px;
`
const Date = styled.div`
  font-family: 'poppins-regular';
  font-weight: 500;
  font-size: 12px;
  color: ${THEME_COLORS.background_5};
`
const Content = styled.div`
  border-left: 1px solid ${THEME_COLORS.secondary};
  padding-left: 24px;
  padding-bottom: 46px;
  margin-left: 12px;
  position: relative;
  &:last-child {
    padding-bottom: 40px;
    border-left: none;
  }
  &:before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
    left: -13px;
    background-color: ${(props) => props.status && THEME_COLORS.white};
    background-image: url(${(props) => props.image});
    background-position: center;
    background-repeat: no-repeat;
  }
`
const ProductDetails = styled.div``
const DeliveryDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 24px;
`
const BottomLinks = styled.div`
  margin-top: 53px;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  width: max-content;
`
const Cancel = styled(Link)`
  font-family: 'poppins-regular';
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${THEME_COLORS.primary};
  margin-bottom: 20px;
`
const Help = styled(Link)`
  font-family: 'poppins-regular';
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${THEME_COLORS.background_3};
`
const Product = styled.div`
  display: flex;
  font-family: 'poppins-medium';
  justify-content: space-between;
  padding: 12px;
  background: ${THEME_COLORS.white};
  border: 2px solid #f1f1f1;
  border-radius: 12px;
`
const Name = styled.p`
  font-family: 'poppins-regular';
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: ${THEME_COLORS.background_1};
  margin-bottom: 4px;
  &.mobile-view {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }
`
const CancelContainer = styled.div`
  /* display: none; */
  position: fixed;
  top: 0%;
  left: 0%;
  /* display: flex; */
  z-index: 111;
  background: black;
  width: 100%;
  height: 100vh;
  overflow-x: scroll;
`
const StatusIndicator = styled.div`
  padding: 24px 0px;
  background: ${THEME_COLORS.white};
  border-radius: 8px;
  height: 133px;
  margin-bottom: 12px;
`
const Status = styled.div``
const DetailsCount = styled.li`
  width: 100%;
  list-style: none;
  border-radius: 10px;
  padding: 20px 0;
  margin-bottom: 10px;
  background: #fff;
`
const Count = styled.div`
  text-align: center;
  margin: 0 auto;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  @media all and (max-width: 480px) {
    width: 20px;
    height: 20px;
  }
`
// const Img = styled.img`
//   display: block;
//   width: 100%;
//   &.active {
//     display: block;
//   }
//   position: inherit;
//   z-index: 100;
// `
const Action = styled.div`
  font-family: 'poppins-regular';
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 10px;
  color: red;
  @media all and (max-width: 480px) {
    font-size: 16px;
  }
`
