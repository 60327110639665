import React from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../../ThemeConfig";

function ShippingPolicy() {
    return (
        <MainContainer className="wrapper">
            <Cover>
                <Heading>Shipping Policy</Heading>
                <Bottomcontainer>
                    <Description>
                        Shipping Methods We offer the following shipping methods
                        for deliveries within India: Local Delivery: Orders
                        shipped locally are usually delivered within 3 business
                        days from the date of dispatch. South India Delivery:
                        Deliveries to South India are typically completed within
                        4-5 business days from the date of dispatch. North India
                        Delivery: Orders shipped to North India may take up to
                        7-8 business days for delivery from the date of
                        dispatch.
                    </Description>
                    <Title>Shipping Zones</Title>
                    <Description>
                        {" "}
                        We currently provide shipping services only within
                        India. We do not offer international shipping at this
                        time.
                    </Description>
                    <Title>Shipping Charges</Title>
                    <Description>
                        We do not impose any delivery charges; however, DTDC
                        will apply charges based on the weight of the products.
                        Please refer to DTDC's pricing guidelines for more
                        information.
                    </Description>
                    <Title>Dispatch Time</Title>
                    <Description>
                        Orders are usually dispatched within 1-2 business days
                        from the date of order placement. During peak seasons or
                        promotional periods, please allow up to 3 business days
                        for dispatch.
                    </Description>
                    <Title>Tracking</Title>
                    <Description>
                        Upon dispatch, you will receive a shipping confirmation
                        email containing a tracking number. You can use this
                        tracking number to monitor the status of your delivery
                        on our website or through DTDC's portal.
                    </Description>{" "}
                    <Title>Delivery</Title>
                    <Description>
                        Deliveries are conducted during standard business hours
                        (9:00 AM - 6:00 PM) from Monday to Sunday, including
                        public holidays. Our logistics partner will attempt
                        delivery to the shipping address provided. In case of
                        unsuccessful attempts, a notification will be left, and
                        the package will be held at the nearest delivery center
                        for pickup or redelivery scheduling.
                    </Description>
                    <Title>Shipping Restrictions</Title>
                    <Description>
                        Certain products may be subject to shipping restrictions
                        based on their size, weight, or nature. We reserve the
                        right to refuse shipping of such items or to apply
                        additional shipping charges if necessary.
                    </Description>
                    <Contact>Contact Information</Contact>
                    <Description>
                        For any inquiries or concerns regarding our shipping
                        policy, please contact our customer service team at
                        &nbsp;
                        <Mail href="mailto:"> infopacess@gmail.com</Mail> or
                        call us at <Mail href="tel:+">+91 8089804605</Mail>
                    </Description>
                </Bottomcontainer>
            </Cover>
        </MainContainer>
    );
}

export default ShippingPolicy;
const MainContainer = styled.div`
    padding: 120px 0;

    @media screen and (max-width: 980px) {
        padding: 90px 0;
    }
`;
const Cover = styled.div`
    /* width: 70%;
    margin: 0 auto; */
    @media screen and (max-width: 640px) {
        /* width: 80%; */
    }
    @media screen and (max-width: 480px) {
        /* width: 90%; */
    }
`;
const Heading = styled.h1`
    font-size: 32px;
    line-height: 48px;
    margin: 0 0 25px 0;
    font-family: "poppins-medium";
    @media screen and (max-width: 640px) {
        font-size: 26px;
    }
    @media screen and (max-width: 480px) {
        font-size: 24px;
    }
`;
const Bottomcontainer = styled.div`
    background: ${THEME_COLORS.border_5};
    padding: 30px;
    border-radius: 7px;
`;
const Title = styled.h4`
    font-size: 20px;
    /* margin-bottom: 20px; */
    line-height: 42px;

    font-family: "poppins-regular";
    @media screen and (max-width: 640px) {
        font-size: 22px;
    }
    @media screen and (max-width: 480px) {
        font-size: 20px;
    }
`;
const Description = styled.p`
    color: ${THEME_COLORS.background_5};
    font-size: 20px;
    font-family: "poppins-medium";
    line-height: 35px;
    margin-bottom: 24px;
    br {
        margin-bottom: 32px;
    }
    @media screen and (max-width: 1080px) {
        line-height: 28px;
    }
    @media screen and (max-width: 980px) {
        font-size: 18px;
    }
    @media screen and (max-width: 980px) {
        font-size: 14px;
    }
`;
const Contact = styled.h6`
    font-family: "poppins-medium";
    font-size: 20px;
    line-height: 42px;
`;
const Mail = styled.a`
    font-family: "poppins-medium";
    font-size: 20px;
    line-height: 42px;
    /* color: #3464e4; */
    display: inline-block;
`;
