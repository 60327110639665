import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'
import OrderHistory from './OrderHistory'
import SingleOrder from './SingleOrder'
import emptyCart from './../../../assets/icons/bag-cross.svg'
import OrderHistoryResponsive from './OrderHistoryResponsive'
import DefaultLottie from '../../includes/common/loader/DefaultLottie'
import { Context } from '../../../contexts/Store'
import useAuth from '../../includes/functions/authFunctions'
import { baseConfig } from '../../../axiosConfig'
import EmptyPage from '../../includes/empty-page/EmptyPage'

export default function OrderStatus() {
  const [orderList, setOrderList] = useState([])
  const [active, setActive] = useState({})
  const [isLoading, setLoading] = useState(true)

  const { checkToken } = useAuth()
  const { state } = useContext(Context)

  const getOrderStatus = () => {
    const accessToken = state.user_details.access
    setLoading(true)
    baseConfig
      .get('orders/my-orders/', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response?.data
        // console.log("RESPONSE:",response)
        if (StatusCode === 6000) {
          setOrderList(data)
          setActive(data[0])
        }
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
      .catch((error) => {
        const { status, data } = error?.response
        // console.log("ERROR:",error.response)
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
  }

  useEffect(() => {
    getOrderStatus()
  }, [])

  // console.log(orderList, 'orderListorderList')

  return (
    <>
      {!isLoading ? (
        <>
          <MainContainer>
            <Title>Orders</Title>
            {orderList.length != 0 ? (
              <>
                <OrderBox>
                  <OrderHistory
                    active={active}
                    setActive={setActive}
                    orderList={orderList}
                    setOrderList={setOrderList}
                  />
                  <SingleOrder
                    active={active}
                    getOrderStatus={getOrderStatus}
                  />
                </OrderBox>
              </>
            ) : (
              // <div>You don't have any orders</div>
              <EmptyPage
                image={emptyCart}
                status={"You don't have any orders !"}
              />
            )}
          </MainContainer>
          <MainContainer className="responsive">
            <OrderHistoryResponsive orderList={orderList} />
          </MainContainer>
        </>
      ) : (
        <DefaultLottie />
      )}
    </>
  )
}

const MainContainer = styled.div`
  width: 90%;
  margin: 0px auto 138px;
  padding-top: 120px;
  @media all and (max-width: 980px) {
    display: none;
  }
  /* div {
    font-family: 'poppins-regular';
    font-weight: 600;
    font-size: 26px;
    text-align: center;
  } */
  &.responsive {
    display: none;
    margin: 0px auto;
    @media all and (max-width: 980px) {
      display: block;
    }
  }
`
const Title = styled.h3`
  font-family: 'poppins-regular';
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: ${THEME_COLORS.background_1};
  margin-bottom: 32px;
`
const OrderBox = styled.div`
  display: flex;
  justify-content: space-between;
`
