import React, { useState } from 'react'
import styled from 'styled-components'
import icon from '../../../assets/icons/hamburger.svg'
import { THEME_COLORS } from '../../../ThemeConfig'

export default function PrivacyPolicies() {
  const data = [
    {
      id: 1,
      heading: 'Privacy Policy for Pacess',
      paragraph:
        ' At pacess.com, accessible from pacess.com, one of our main priorities is ensuring the privacy of our visitors. This Privacy Policy document outlines the types of information collected and recorded by pacess.com, as well as how we utilise this information.If you have any additional questions or require further information about our Privacy Policy, please feel free to contact us. We are more than happy to assist you.',
      paragraph_2:
        ' Please note that this Privacy Policy solely applies to our online activities and is valid for visitors to our website in relation to the information they share and/or collect on pacess.com. This policy does not extend to any offline information or information obtained through channels other than this website.',
      points: [],
    },
    {
      id: 2,
      heading: 'Consent',
      paragraph:
        'By using our website, you hereby consent to our Privacy Policy and agree to its terms.',
      paragraph_2:
        'The personal information requested from you, along with the reasons for requesting it, will be clearly communicated to you at the point where we seek your personal details.',
      paragraph_3:
        'If you reach out to us directly, we may receive additional information about you, such as your name, email address, phone number, the contents of the message, any attachments you send us, and any other information you choose to provide.When you register for an Account, we may request your contact information, including details such as your name, address, email address, and telephone number.',
      points: [],
    },
    {
      id: 3,
      heading: 'Information we collect',
      paragraph:
        'The disclosure of personal information and the purpose behind its collection will be explicitly communicated to you when we request such information. In the event of direct communication with us, we may acquire additional details about you, such as your name, email address, phone number, the content of your message, attachments, and any other information you willingly share. During the registration process for an account, we may inquire about your contact information, encompassing particulars such as your name, address, email address, and telephone number.',
      points: [],
    },
    {
      id: 4,
      heading: 'How we use your information',
      paragraph:
        'We undertake the following activities in order to enhance your experience on our website:',
      points: [
        {
          id: 111222,
          point: 'We provide, operate, and maintain our website.',
          sub_point: [],
        },
        {
          id: 111333,
          point: 'We strive to improve, personalise, and expand our website.',
          sub_point: [],
        },

        {
          id: 111444,
          point:
            'We endeavour to understand and analyse your utilisation of our website.',
          sub_point: [],
        },
        {
          id: 111555,
          point:
            'We work on developing new products, services, features, and functionality.',
          sub_point: [],
        },
        {
          id: 111666,
          point:
            'We aim to communicate with you directly or through our partners, for customer service, updates, and other website-related information. Additionally, we may reach out for marketing and promotional purposes.',
          sub_point: [],
        },
        {
          id: 111777,
          point:
            'We will send you emails as part of our communication strategy.',
          sub_point: [],
        },
        {
          id: 111888,
          point: 'We employ measures to detect and prevent fraud.',
          sub_point: [],
        },
      ],
      paragraph_two:
        'These actions enable us to offer a seamless and secure online environment while catering to your specific needs.',
    },
    {
      id: 5,
      heading: 'Log Files',
      paragraph:
        "At pacess.com, we strictly adhere to a standardised procedure that involves the utilisation of log files. These files are generated when visitors browse our website. Similar to other hosting companies, we employ log files as an integral part of our hosting services' analytics. The information collected includes internet protocol (IP) addresses, browser types, Internet Service Providers (ISPs), date and time stamps, referring/exit pages, and possibly the number of clicks. Importantly, none of this information is linked to personally identifiable data. The primary purpose of collecting this information is to analyse trends, administer the site, track users' movements on the website, and gather valuable demographic insights. By employing log files, we can effectively assess the performance and functionality of our website, ensuring a smooth and seamless user experience while safeguarding the privacy and anonymity of our visitors.",
      points: [],
    },
    {
      id: 6,
      heading: 'Cookies and Web Beacons',
      paragraph:
        "As with any other website, pacess.com utilises 'cookies' to enhance user experience. These cookies serve the purpose of storing information, such as visitors' preferences and the specific pages they accessed or visited on our website. This information is employed to optimise the users' browsing experience by tailoring our web page content to match their browser type and other relevant details. By utilising cookies, we can provide a personalised and user-friendly environment that aligns with individual preferences and needs. Rest assured, these cookies do not compromise users' privacy and are employed solely for the purpose of enhancing their interaction with our website.",
      points: [],
    },
    {
      id: 7,
      heading: 'Advertising Partners Privacy Policies',
      paragraph:
        "To access the Privacy Policy for each of our advertising partners at pacess.com, please refer to the following list. It outlines the policies implemented by these third-party ad servers or ad networks. These entities utilise technologies such as cookies, JavaScript, or Web Beacons in their respective advertisements and links, which are directly sent to users' browsers. Consequently, your IP address is automatically received during this process. The purpose of employing these technologies is to gauge the effectiveness of their advertising campaigns and/or personalise the advertising content displayed on websites you visit.It is important to note that pacess.com does not have access to or control over the cookies utilised by third-party advertisers. Therefore, we recommend reviewing the respective Privacy Policies of these advertising partners for a comprehensive understanding of their data collection and usage practices.",
      points: [],
    },
    {
      id: 8,
      heading: 'Third Party Privacy Policies',
      paragraph:
        'Please note that the Privacy Policy of pacess.com is specifically applicable to pacess.com and does not extend to other advertisers or websites. Therefore, we strongly advise you to refer to the respective Privacy Policies of these third-party ad servers for more comprehensive information. These policies may provide details about their practices and instructions on how to opt-out of certain options.',
      paragraph_2:
        'In addition, you have the option to disable cookies through the individual settings of your web browser. For more detailed information regarding cookie management using specific web browsers, please consult the respective websites of those browsers.',
      paragraph_3:
        'CCPA Privacy Rights (Do Not Sell My Personal Information):Under the CCPA, California consumers possess various rights, including:',
      points: [
        {
          id: 111333,
          point:
            'The right to request that a business, which collects personal data, disclose the categories and specific pieces of personal data collected about consumers.',
          sub_point: [],
        },
        {
          id: 111444,
          point:
            'The right to request that a business delete any personal data collected about the consumer.',
          sub_point: [],
        },
        {
          id: 111555,
          point:
            "The right to request that a business, which sells personal data, refrain from selling the consumer's personal data.",
          sub_point: [],
        },
      ],
      paragraph_two:
        'Upon making a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.',
    },
    {
      id: 9,
      heading: 'GDPR Data Protection Rights',
      paragraph:
        'We want to ensure that you are fully aware of all your data protection rights. As a user, you are entitled to the following:',
      points: [
        {
          id: 111333,
          point:
            'The right to access: You have the right to request copies of your personal data. Please note that a small fee may be charged for this service.',
          sub_point: [],
        },
        {
          id: 111444,
          point:
            'The right to rectification: You have the right to request that we correct any information you believe to be inaccurate. You also have the right to request that we complete any information you believe to be incomplete.',
          sub_point: [],
        },
        {
          id: 111555,
          point:
            'The right to erasure - You have the right to request that we erase your personal data, under certain conditions.',
          sub_point: [],
        },
        {
          id: 111666,
          point:
            'The right to restrict processing: You have the right to request that we restrict the processing of your personal data under certain conditions.',
          sub_point: [],
        },
        {
          id: 111777,
          point:
            'The right to object to processing: You have the right to object to our processing of your personal data under certain conditions.',
          sub_point: [],
        },
        {
          id: 111888,
          point:
            'The right to data portability: You have the right to request that we transfer the data we have collected to another organisation or directly to you under certain conditions.',
          sub_point: [],
        },
      ],
      paragraph_two:
        'If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.',
    },
    {
      id: 10,
      heading: "Children's Information",
      paragraph:
        "Another aspect of our priority is to ensure the protection of children while they are using the internet. We strongly encourage parents and guardians to actively observe, participate in, and/or monitor and guide their children's online activities. At pacess.com, we do not knowingly collect any Personal Identifiable Information from children under the age of 13. If you believe that your child has provided this type of information on our website, we urge you to contact us immediately. We will make every effort to promptly remove such information from our records.",
      points: [],
    },
  ]

  const [selectedPrivacy, setSelectedPrivacy] = useState(data[0])
  const [show, setShow] = useState('false')

  //scroll top
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <MainContainer>
        <SubContainer>
          <TitleSub>Privacy Policy</TitleSub>
          <MainBox>
            <MenuIcon
              className={show === 'true' ? 'left-hide' : 'left-show'}
              onClick={() => {
                setShow('true')
              }}
            >
              <Img src={icon} alt="Image" />
            </MenuIcon>
            <Overlay
              className={show === 'true' ? 'left-show' : 'left-hide'}
              onClick={() => {
                setShow('false')
              }}
            />
            <LeftBox
              className={show === 'true' ? 'left-show' : 'left-hide'}
              onClick={(e) => {
                console.log(e.target, 'ddddddddddddddddd')
                // e.target !== LeftBox && setShow("false");
              }}
            >
              <Heading>Table of contents</Heading>
              {data.map((item, i) => (
                <Content
                  key={i}
                  className={selectedPrivacy.id === item.id ? 'actived' : ''}
                  onClick={() => {
                    setSelectedPrivacy(item)
                    setShow('false')
                  }}
                >
                  <Count>{i + 1}.</Count>
                  <Details>{item.heading}</Details>
                </Content>
              ))}
            </LeftBox>
            <RightBox>
              <>
                <Title>{selectedPrivacy.heading}</Title>
                <Paragraph>{selectedPrivacy.paragraph}</Paragraph>
                <Paragraph>{selectedPrivacy.paragraph_2}</Paragraph>
                <Paragraph>{selectedPrivacy.paragraph_3}</Paragraph>

                {selectedPrivacy.points.length > 0 && (
                  <>
                    {selectedPrivacy.points.map((point, i) => (
                      <BottomBox key={i}>
                        <DotSub
                          style={{
                            marginBottom: '10px',
                          }}
                        >
                          {point.point}
                        </DotSub>
                        <>
                          {point.sub_point.map((val, i) => (
                            <SubParagraph key={i}>
                              {i + 1}. {val.value}
                            </SubParagraph>
                          ))}
                        </>
                      </BottomBox>
                    ))}
                  </>
                )}
                <Paragraph>{selectedPrivacy.paragraph_two}</Paragraph>
              </>
            </RightBox>
          </MainBox>
        </SubContainer>
      </MainContainer>
    </>
  )
}
const MainContainer = styled.div`
  width: 90%;
  max-width: 1350px;
  margin: 0 auto;
  padding-top: 80px;
  @media screen and (max-width: 980px) {
    padding-top: 60px;
  }
`
const SubContainer = styled.div`
  margin: 70px 0px;
  @media screen and (max-width: 980px) {
    padding: 35px 0;
  }
`
const TitleSub = styled.h4`
  font-size: 32px;
  font-family: 'poppins-semibold';
  margin-bottom: 32px;
`
const MainBox = styled.div`
  display: flex;
  background: ${THEME_COLORS.border_5};
  border-radius: 24px;
  padding: 32px;
  gap: 20px;
`
const MenuIcon = styled.div`
  padding-top: 16px;
  display: none;
  @media screen and (max-width: 980px) {
    &.left-hide {
      display: none;
    }
    &.left-show {
      display: block;
    }
  }
`
const Overlay = styled.div`
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    cursor: pointer;
    height: 100vh;
    /* opacity: 0.5; */
    /* background: ${THEME_COLORS.background_1}; */

    &.left-hide {
        display: none;
    }
    &.left-show {
        display: block;
    }
`
const Img = styled.img`
  display: block;
  width: 100%;
`
const LeftBox = styled.div`
  background: ${THEME_COLORS.white};
  border-radius: 16px;
  padding: 16px;
  gap: 10px;
  width: 35%;
  height: 611px;
  /* display: none; */
  overflow-y: scroll;
  @media screen and (max-width: 980px) {
    min-width: max-content;
    position: absolute;
    z-index: 1000;
    &.left-hide {
      display: none;
    }
    &.left-show {
      display: block;
      height: 500px;
    }
  }
  @media screen and (max-width: 540px) {
    min-width: 70%;
    height: 400px;
  }
`
const Heading = styled.div`
  color: ${THEME_COLORS.background_1};
  font-size: 24px;
  font-family: 'poppins-medium';
  line-height: 42px;
  font-weight: 500;
  margin-bottom: 24px;
`
const Content = styled.div`
  display: flex;
  /* text-align: center; */
  padding: 8px 24px;
  gap: 10px;
  align-items: baseline;
  font-family: 'poppins-medium';
  cursor: pointer;
  &.actived {
    background: ${THEME_COLORS.border_5};
    border-radius: 12px;
  }
  @media screen and (max-width: 980px) {
    padding: 8px 16px;
  }
  @media screen and (max-width: 768px) {
    min-width: 100%;
  }
`
const Count = styled.p`
  color: ${THEME_COLORS.background_1};
  font-size: 14px;
`
const Details = styled.p`
  color: ${THEME_COLORS.background_1};
  font-size: 14px;
  line-height: 35px;
  @media screen and (max-width: 868px) {
    line-height: 25px;
  }
`
const RightBox = styled.div`
  width: 70%;
  padding: 16px;
  height: 100vh;
  overflow-y: scroll;
  @media screen and (max-width: 980px) {
    width: 100%;
  }
`
const Title = styled.h5`
  font-size: 24px;
  font-family: 'poppins-semibold';
  margin-bottom: 32px;
`
const Paragraph = styled.p`
  color: #8e8e8e;
  font-size: 20px;
  line-height: 35px;
  font-family: 'poppins-medium';
  margin-bottom: 25px;
  :last-child {
    margin-bottom: 0px;
    margin-top: 20px;
  }
  @media screen and (max-width: 980px) {
    font-size: 18px;
  }
  @media screen and (max-width: 980px) {
    font-size: 16px;
    line-height: 25px;
  }
`
const BottomBox = styled.div``
const DotSub = styled.li`
  font-size: 16px;
  /* color: #8e8e8e; */
  font-family: 'poppins-medium';
  list-style-type: disc;
`
const SubParagraph = styled.h6`
  font-size: 22px;
  font-family: 'poppins-medium';
`
