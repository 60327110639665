import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../../ThemeConfig'
import PrimaryButton from '../../buttons/PrimaryButton'
import SecondaryButton from '../../buttons/SecondaryButton'
import apple from '../../../../assets/images/delete-modal-apple.svg'

export default function ConfirmdeleteAdress({
  isDeleteModal,
  setDeleteModal,
  id,
  removeapi,
}) {
  return (
    <>
      {isDeleteModal && (
        <>
          {' '}
          {/* <Cover> */}
          <MainContainer>
            <ImageContainer>
              <Icon src={apple} alt="Image" />
            </ImageContainer>
            <Title>Are you sure to delete this address</Title>

            <Button>
              <SecondaryButton
                size={'small'}
                text={'Cancel'}
                onClick={() => {
                  setDeleteModal(false)
                }}
              />

              <PrimaryButton
                size={'small'}
                text={'Confirm'}
                onClick={() => {
                  removeapi(id)
                  // window.location.reload();
                  setDeleteModal(false)
                }}
              />
            </Button>
          </MainContainer>
          {/* </Cover> */}
        </>
      )}
    </>
  )
}

const MainContainer = styled.div`
  padding: 32px;
  background: ${THEME_COLORS.white};
  box-shadow: 12px 25px 25px 2px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  position: absolute;
  top: 19%;
  left: 30%;
  z-index: 1;
  width: 35%;
  @media screen and (max-width: 1280px) {
    width: 45%;
  }
  @media screen and (max-width: 1080px) {
    width: 50%;
  }
  @media screen and (max-width: 980px) {
    width: 55%;
  }
  @media screen and (max-width: 768px) {
    width: 75%;
  }
  @media screen and (max-width: 480px) {
    width: 98%;
  }
`
const ImageContainer = styled.div`
  margin: 0 auto;
`
const Icon = styled.img``

const Title = styled.h3`
  width: 100%;
  color: ${THEME_COLORS.background_1};
  font-size: 22px;
  margin-bottom: 20px;
  font-family: 'poppins-medium';
  text-align: center;
`
const Content = styled.p`
  width: 100%;
  color: ${THEME_COLORS.background_4};
  font-size: 13px;
  margin-bottom: 40px;
  font-family: 'poppins-regular';
  text-align: center;
  span {
    color: red;
  }
`
const Button = styled.div`
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`
