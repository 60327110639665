import React from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../../ThemeConfig";

export default function BadgeButton({text,onClick}) {
    return (
        <MainContainer  onClick={onClick} >
            {text}
        </MainContainer>
    );
}
const MainContainer = styled.div`
    padding: 8px 16px;
    cursor: pointer;
    color: ${THEME_COLORS.primary};
    border-radius: 6px;
    text-align: center;
    font-family: "poppins-medium";
    height: 37px;
    width: max-content;
    gap: 10px;
    background: #e71c2226;
    &:hover {
        background: #e71c2226;
        color: ${THEME_COLORS.primary};
    }
    font-size: 14px;
    font-weight: 500;
    @media screen and (max-width: 480px) {
        height: 37px;
    }
`;