import React from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../../ThemeConfig";

export default function DealOfTheDayCard({ item }) {
	return (
		<MainBoxConatiner>
			<ImgBox>
				<Img src={item.image} alt="image" />
			</ImgBox>
			<TitleSub>{item.product_name}</TitleSub>
			<DiscountTitle>{item.offer}</DiscountTitle>
		</MainBoxConatiner>
	);
}
const MainBoxConatiner = styled.div`
	background: ${THEME_COLORS.light_orange};
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 24px;
	@media screen and (max-width: 1280px) {
		height: 360px;
		width: 100%;
	}
	@media screen and (max-width: 1080px) {
		padding: 22px;
		height: 340px;
	}
	@media screen and (max-width: 980px) {
		height: 380px;
	}
	@media screen and (max-width: 768px) {
		height: 420px;
	}
	@media screen and (max-width: 640px) {
		height: 374px;
		padding: 35px;
	}
	@media screen and (max-width: 480px) {
		height: 305px;
		padding: 25px;
	}
	@media screen and (max-width: 380px) {
		
		padding: 20px;
		width: 100%;
	}
`;

const ImgBox = styled.div`
	height: 250px;
	width: 100%;
	margin-bottom: 20px;
	@media screen and (max-width: 480px) {
		height: 177px;
		width: 240px;
	}
`;
const Img = styled.img`
	display: block;
	width: 100%;
	border-radius: 9px;
	object-fit: contain;
	height: 100%;
`;
const TitleSub = styled.h4`
	font-size: 24px;
	font-family: "poppins-medium";
	color: ${THEME_COLORS.background_3};
	@media screen and (max-width: 1280px) {
		font-size: 21px;
	}
	@media screen and (max-width: 1080px) {
		font-size: 20px;
	}
	@media screen and (max-width: 980px) {
		font-size: 18px;
	}
	@media screen and (max-width: 480px) {
		font-size: 18px;
	}
`;
const DiscountTitle = styled.h6`
	color: ${THEME_COLORS.success};
	font-size: 20px;
	font-family: "poppins-medium";
	@media screen and (max-width: 980px) {
		font-size: 18px;
	}
	@media screen and (max-width: 768px) {
		font-size: 18px;
	}
	@media screen and (max-width: 480px) {
		font-size: 16px;
	}
`;
