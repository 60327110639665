import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import Button from '../../includes/buttons/Button'
import { THEME_COLORS } from './../../../ThemeConfig'
import Logo from '../../../assets/icons/logo.svg'
import Search from '../../includes/search/Search'
import favourite from '../../../assets/icons/favourite.svg'
import shoping from '../../../assets/icons/shopping-cart.svg'
import user from '../../../assets/icons/user.svg'
import option from '../../../assets/icons/hamburger.svg'
import { useNavigate, NavLink } from 'react-router-dom'
import Login from '../loginpage/Login'
import Otp from '../loginpage/Otp'
import GetStart from '../loginpage/GetStart'
import ForgotPage from '../loginpage/ForgotPage'
import ModalCenter from '../../includes/modal/ModalCenter'
import { Context } from '../../../contexts/Store'
import { AuthContext } from '../../../contexts/AuthStore'
import search from '../../../assets/icons/search-normal.svg'
import { Popover } from '@mui/material'
import Logout from '../logout/Logout'
import './Header.css'
import notification from '../../../assets/icons/notification.svg'
import {
  userSvg,
  locationSvg,
  orderSvg,
  whishlistSvg,
  notificationSvg,
  settingSvg,
  logoutSvg,
} from './HeaderSvgFiles'
import ResetPassword from '../loginpage/ResetPassword'
import SearchModalMobile from '../../includes/search/SearchModalMobile'
import NewLoginPage from '../loginpage/NewLoginPage'
import LoginOtp from '../loginpage/LoginOtp'
import OverlayModal from '../../includes/modal/OverlayModal'

function Header({ isNav, setisNav }) {
  const navigate = useNavigate()
  const { state, dispatch } = useContext(Context)
  const { authstate, authdispatch } = useContext(AuthContext)

  const closeModal = () => {
    const auth_modal_details = {
      auth_modal_name: '',
      is_auth_modal: false,
    }
    authdispatch({
      type: 'AUTH_MODAL',
      auth_modal_details,
    })
  }

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const [logoutModal, setLogoutModal] = useState(false)
  const [searchModal, setSearchModal] = useState(false)

  return (
    <>
      <MainContainer>
        <ItemContainer>
          <LeftBox>
            <LogoImage
              src={Logo}
              alt="Image"
              onClick={() => {
                navigate('/')
              }}
            />
          </LeftBox>
          <SearchBox>
            <Search />
          </SearchBox>
          <CenterBox>
            <NavLink
              to="/"
              className={({ isActive }) => (isActive ? 'actived' : 'normal')}
            >
              Home
            </NavLink>
            <NavLink
              to="/home-about"
              className={({ isActive }) => (isActive ? 'actived' : 'normal')}
            >
              About Us
            </NavLink>
            <NavLink
              to="/productlist"
              className={({ isActive }) => (isActive ? 'actived' : 'normal')}
            >
              Products
            </NavLink>
            <NavLink
              to="/offerpage"
              className={({ isActive }) => (isActive ? 'actived' : 'normal')}
            >
              Offers
            </NavLink>
          </CenterBox>
          {state.user_details.is_verified && (
            <RightBox>
              <NavLink
                className={({ isActive }) =>
                  isActive ? 'icon-actived' : 'normal-icon'
                }
                to="/wishlist"
              >
                <Img src={favourite} alt="Image" />
              </NavLink>
              <NavLink
                className={({ isActive }) =>
                  isActive ? 'icon-actived' : 'normal-icon'
                }
                to="/notification"
              >
                <Img src={notification} alt="Image" />
              </NavLink>
              <NavLink
                className={({ isActive }) => (isActive ? 'actived' : 'normal')}
                to="/cart"
              >
                <Img src={shoping} alt="Image" />
              </NavLink>
              {state.user_details.is_verified && (
                <>
                  <ProfileIcon
                    aria-describedby={id}
                    variant="contained"
                    onClick={handleClick}
                    opacity={open ? 1 : 0.5}
                  >
                    <Img src={user} alt="Image" />
                  </ProfileIcon>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    // anchorOrigin={{
                    //     vertical: "top",
                    //     horizontal: "left",
                    // }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    sx={{
                      '&& .MuiPopover-paper': {
                        boxShadow: 'rgba(0, 0, 0, 0.19) 14px 18px 33px -7px',
                        borderRadius: 3,
                        background: 'none',
                        border: `border: 1px solid #dbdbdb`,
                        marginTop: `40px`,
                      },
                    }}
                  >
                    <PopoverTable>
                      <TitleContent
                        onClick={() => {
                          navigate('/profile')
                          handleClose()
                        }}
                      >
                        <Icon>{userSvg()}</Icon>
                        My Account
                      </TitleContent>
                      <TitleContent
                        onClick={() => {
                          navigate('/address')
                          handleClose()
                        }}
                      >
                        <Icon>{locationSvg()}</Icon>
                        My Address
                      </TitleContent>
                      <TitleContent
                        onClick={() => {
                          navigate('/order-status')
                          handleClose()
                        }}
                      >
                        <Icon>{orderSvg()}</Icon>
                        My Orders
                      </TitleContent>
                      <TitleContent
                        onClick={() => {
                          navigate('/wishlist')
                          handleClose()
                        }}
                      >
                        <Icon>{whishlistSvg()}</Icon>
                        My Wishlist
                      </TitleContent>
                      {/* <TitleContent
                                            onClick={() => {
                                                navigate("/notification");
                                                handleClose();
                                            }}
                                        >
                                            <Icon>{notificationSvg()}</Icon>
                                            Notification
                                        </TitleContent> */}
                      <TitleContent
                        onClick={() => {
                          navigate('/settings')
                          handleClose()
                        }}
                      >
                        <Icon>{settingSvg()}</Icon>
                        Settings
                      </TitleContent>

                      <TitleContent
                        onClick={() => {
                          handleClose()
                          setLogoutModal(true)
                        }}
                      >
                        <Icon>{logoutSvg()}</Icon>
                        Logout
                      </TitleContent>
                    </PopoverTable>
                  </Popover>
                </>
              )}
            </RightBox>
          )}
          {!state.user_details.is_verified && (
            <ButtonDiv>
              <Button
                className="logbtn"
                text={'Login'}
                size={'small'}
                onClick={() => {
                  // setLoginModal(true);
                  // setModalName("login");
                  //
                  // const user_details = {
                  // 	is_verified: true,
                  // 	access_token: "access",
                  // 	refresh_token: "refresh",
                  // 	// role: response.userrole,
                  // };
                  // dispatch({
                  // 	type: "UPDATE_USER",
                  // 	user_details,
                  // });
                  //
                  const auth_modal_details = {
                    auth_modal_name: 'login',
                    is_auth_modal: true,
                  }
                  authdispatch({
                    type: 'AUTH_MODAL',
                    auth_modal_details,
                  })
                }}
              />
            </ButtonDiv>
          )}
          <RespIcon>
            <SearchMe
              onClick={() => {
                handleClose()
                // navigate("/searchbar");
                setSearchModal(true)
              }}
            >
              <Img src={search} alt="Image" />
            </SearchMe>
            <SideMenu
              onClick={() => {
                setisNav(!isNav)
              }}
            >
              <Img src={option} alt="Image" />
            </SideMenu>
          </RespIcon>
        </ItemContainer>
      </MainContainer>
      {authstate.auth_modal_details.is_auth_modal && (
        <ModalCenter
          // setModal={setLoginModal}
          closeModal={closeModal}
        >
          {authstate.auth_modal_details.auth_modal_name === 'login' ? (
            <NewLoginPage
              // setModal={setLoginModal}
              closeModal={closeModal}
              modal={authstate.auth_modal_details.auth_modal_name}
            />
          ) : authstate.auth_modal_details.auth_modal_name === 'register' ? (
            <GetStart />
          ) : authstate.auth_modal_details.auth_modal_name === 'forget' ? (
            <ForgotPage />
          ) : authstate.auth_modal_details.auth_modal_name === 'otp' ? (
            <Otp />
          ) : // : authstate.auth_modal_details.auth_modal_name ===
          //   "password" ? (
          // 	<Signup />
          authstate.auth_modal_details.auth_modal_name === 'reset_password' ? (
            <ResetPassword />
          ) : authstate.auth_modal_details.auth_modal_name === 'login_otp' ? (
            <LoginOtp />
          ) : null}
        </ModalCenter>
      )}
      {logoutModal && (
        <OverlayModal setModal={setLogoutModal} logout={'logout'}>
          <Logout setModal={setLogoutModal} />
        </OverlayModal>
      )}
      {searchModal && (
        <ModalCenter search={'search'} setModal={setSearchModal}>
          <SearchModalMobile setModal={setSearchModal} />
        </ModalCenter>
      )}
    </>
  )
}

export default Header

const MainContainer = styled.div`
position: fixed;
    background-color: ${THEME_COLORS.white};
    width: 100%;
    height: 80px;
    padding: 15px 67px;
    z-index: 111;
    /* display: flex;
	justify-content: space-between;
	align-items: center; */
    /* border: 1px solid ${THEME_COLORS.background_3}; */
    @media screen and (max-width: 1280px) {
        padding: 15px 32px;
    }
    @media screen and (max-width: 768px) {
        padding: 15px 33px;
    }
    @media screen and (max-width: 480px) {
        padding: 15px 15px;
    }
    @media screen and (max-width: 380px) {
        padding: 22px 24px;
    }
`
const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const LeftBox = styled.h1`
  width: 164px;
  height: 46px;
  cursor: pointer;
  @media screen and (max-width: 640px) {
    width: 140px;
    height: 40px;
  }
  /* @media screen and (max-width: 360px) {
        width: 85px;
        height: 25px;
    } */
`
const LogoImage = styled.img`
  width: 100%;
  display: block;
`
const SearchBox = styled.div`
  width: 35%;
  @media screen and (max-width: 1280px) {
    width: 30%;
  }
  @media screen and (max-width: 1180px) {
    width: 30%;
  }
  @media screen and (max-width: 1080px) {
    display: none;
  }
`
const CenterBox = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  @media screen and (max-width: 1080px) {
    display: none;
  }
`

const RightBox = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 32px;
  @media screen and (max-width: 1080px) {
    display: none;
  }
`

const PopoverTable = styled.div`
  width: 200px;
  height: max-content;
  background: ${THEME_COLORS.white};
  padding: 10px;
  border-radius: 8px;
  /* border: 1px solid #dbdbdb; */
  box-shadow: 14px 18px 33px -7px rgba(0, 0, 0, 0.19);
  overflow: hidden;
`

const Icon = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;

  svg {
    width: 20px;
    height: 20px;
  }
`

const TitleContent = styled.div`
  cursor: pointer;
  font-family: 'poppins-regular';
  /* border-bottom: 1px solid #33383f; */
  font-size: 16px;
  text-align: left;
  padding: 6px 16px;
  align-items: center;
  display: flex;
  color: ${THEME_COLORS.background_3};
  width: 100%;

  &:last-child {
    border-bottom: none;
  }
  :hover {
    background: rgba(16, 111, 57, 0.1);
    border-radius: 4px;
    color: ${THEME_COLORS.secondary};
    stroke: ${THEME_COLORS.secondary};

    path {
      stroke: ${THEME_COLORS.secondary};
    }
  }
`
const ProfileIcon = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  opacity: ${(props) => (props.opacity ? props.opacity : 0.5)};
  :hover {
    opacity: 1;
  }
`
const Img = styled.img`
  width: 100%;
  display: block;
`
const SideMenu = styled.span`
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: none;
  @media screen and (max-width: 1080px) {
    display: block;
  }
`
const SearchMe = styled.a`
  display: inline-block;
  cursor: pointer;
  display: none;
  margin-right: 20px;
  @media screen and (max-width: 1080px) {
    display: block;
  }
`
const RespIcon = styled.div`
  display: none;
  @media screen and (max-width: 1080px) {
    display: flex;
    align-items: center;
  }
`
const ButtonDiv = styled.div``
