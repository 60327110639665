import { useMemo, useContext} from "react";
import { baseConfig } from "../../../axiosConfig";
import { Context } from "../../../contexts/Store";

const useAuth = () => {
	const { state, dispatch } = useContext(Context);

	const actions = useMemo(
		() => ({
			async updateToken(token, callback) {
				const user_details = await {
					...state.user_details,
					access_token: token,
				};

				dispatch({
					type: "UPDATE_USER",
					user_details,
				});
				setTimeout(() => {
					callback(token);
				}, 100);

				// const details = await {
				// 	...state.user_details,
				// 	access_token: token,
				// };

				// localStorage.setItem("user_details", JSON.stringify(details));

				// setTimeout(() => {
				// callback();
				// }, 500);
			},

			async checkToken(refresh_token, callback) {
				baseConfig
					.post(`/iam/token/refresh`, {
						refresh: refresh_token,
					})
					.then((response) => {
						if (response.status === 200) {
							actions.updateToken(response.data.access, callback);
						}
					})
					.catch((error) => {
						// if (error.response.status === 400) {
						// 	actions.logout();
						// }
					});
			},
		}),
		[]
	);

	return actions;
};

export default useAuth;
