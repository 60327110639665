import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import loginimage from '../../../assets/images/Remedy-rafiki 1.png'
import PrimaryButton from '../../includes/buttons/PrimaryButton'
import InputForm from '../../includes/form/InputForm'
import close from '../../../assets/icons/close-square.svg'
import tick from '../../../assets/icons/tick-square.svg'
import { AuthContext } from '../../../contexts/AuthStore'
import { Context } from '../../../contexts/Store'
import { baseConfig } from '../../../axiosConfig'
import { THEME_COLORS } from '../../../ThemeConfig'
import { Message } from '@mui/icons-material'
import useScrollBlock from '../../includes/functions/useScrollBlock'
// import useScrollBlock from '../../functions/useScrollBlock'

export default function GetStart({ setModal }) {
  const [username, setUsername] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [password, setPassword] = useState('')
  const [check, setCheck] = useState(false)
  const { authstate, authdispatch } = useContext(AuthContext)
  const [email, setEmail] = useState('')
  const [emailValidation, setEmailValidation] = useState(false)
  const [isError, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [errorEmailMsg, setErrorEmailMsg] = useState('')
  const [message, setMessage] = useState('')
  const [blockScroll, allowScroll] = useScrollBlock()
  //
  useEffect(() => {
    blockScroll()
  }, [])
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === 'enter') {
      const form = event.target.form
      const index = [...form].indexOf(event.target)
      form.elements[index + 1].focus()
      event.preventDefault()
    }
  }
  const closeModal = () => {
    const auth_modal_details = {
      auth_modal_name: '',
      is_auth_modal: false,
    }
    authdispatch({
      type: 'AUTH_MODAL',
      auth_modal_details,
    })
  }
  const handlePassword = () => {
    const formData = new FormData()
    formData.append('name', username)
    formData.append('email', email)
    formData.append('phone', phoneNumber)

    baseConfig
      .post('users/register/', formData, {})
      .then((response) => {
        const { StatusCode, data, token, message } = response.data
        if (StatusCode === 6000) {
          const auth_modal_details = {
            auth_modal_name: 'otp',
            is_auth_modal: true,
            email: email,
            phone: phoneNumber,
            name: username,
          }
          authdispatch({
            type: 'AUTH_MODAL',
            auth_modal_details,
          })
        } else if (StatusCode === 6001) {
          setError(true)
          setErrorMsg('Email exists')
          setErrorEmailMsg(message)
        }
      })
      .catch((error) => {})
  }
  //Validating the function of entering the phone number
  // const onChange = (e) => {
  //     setError(false);
  //     const re = /^[0-9\b]+$/;
  //     if (e.target.value === "" || re.test(e.target.value)) {
  //         setPhoneNumber(e.target.value);
  //     }
  // };

  // Mail Validation
  const validate = (text) => {
    // let reg = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w\w+)+$/;
    let reg = /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/
    if (reg.test(text) === false) {
      setEmailValidation(false)
      setEmail(text)
      return false
    } else {
      setEmail(text)
      setEmailValidation(true)
    }
  }

  // const addUserValidation = () => {
  // 	if (!props.isEditPage) {
  // 		if (name === "") {
  // 			activeError("error", "Enter full name");
  // 		} else if (phone === "") {
  // 			activeError("error", "Enter phone number");
  // 		} else if (!emailValidation) {
  // 			activeError("error", "Enter a valid email");
  // 		} else {
  // 			addStudent();
  // 		}
  // 	} else {
  // 		addStudent();
  // 	}
  // };

  const handleInputChange = (e) => {
    let inputValue = e.target.value
    // Remove non-digit characters and limit to 10 digits
    inputValue = inputValue.replace(/\D/g, '').slice(0, 10)
    setPhoneNumber(inputValue)
  }
  const handleChange = (e) => {
    let inputValue = e.target.value
    // Remove non-digit characters and limit to 10 digits
    inputValue = e.target.value.replace(/[^A-Za-z " "]/g, '')
    setUsername(inputValue)
  }

  return (
    <MainContiner>
      <LeftBox>
        <div>
          <Heading>Hello there 👋🏻</Heading>
          <Title>
            Welcome to the wellness journey! Browse our range of top-quality
            products and make a purchase today to take the first step towards a
            healthier you.
          </Title>
        </div>
        <ImageBox>
          <Img src={loginimage} alt="Image" />
        </ImageBox>
      </LeftBox>
      <RightBox>
        <SubHeading>Create Account</SubHeading>
        <div>
          <Form>
            {/* <InputForm
              setValue={setUsername}
              onChange={handleInputChange}
              focus={'autoFocus'}
              placeholder={''}
              name={'Full Name'}
              onKeyDown={handleEnter}
              value={username}
              type="text"
              pattern="[^0-9]*"
            /> */}
            <FormDiv>
              <Name>Full Name</Name>
              <div style={{ position: 'relative' }}>
                <FormInput
                  name={'Phone Number'}
                  // onKeyDown={handleEnter}
                  value={username}
                  type="text"
                  className="active"
                  maxLength={30}
                  // placeholder="Enter mobile number"
                  onChange={handleChange}
                  id="name"
                />
              </div>
              {/* <ErrorMsg>{errorEmailMsg && errorEmailMsg}</ErrorMsg> */}
            </FormDiv>
          </Form>
          <Form>
            <InputForm
              // setValue={setEmail}
              placeholder={''}
              name={'Email'}
              onKeyDown={handleEnter}
              value={email}
              size={'small'}
              type="email"
              setValue={validate}
            />
          </Form>
          <Form>
            {/* <InputForm
              name={'Phone Number'}
              // onKeyDown={handleEnter}
              value={phoneNumber}
              type="tel"
              id="Mobile"
              maxLength={10}
              className="active"
              placeholder="Enter mobile number"
              onChange={handleInputChange}
            /> */}

            <FormDiv>
              <Name>Phone Number</Name>
              <div style={{ position: 'relative' }}>
                <FormInput
                  name={'Phone Number'}
                  // onKeyDown={handleEnter}
                  value={phoneNumber}
                  type="tel"
                  id="Mobile"
                  maxLength={10}
                  className="active"
                  // placeholder="Enter mobile number"
                  onChange={handleInputChange}
                />
              </div>
            </FormDiv>
            <ErrorDiv>
              <Error></Error>
            </ErrorDiv>
            <ErrorMsg>{errorEmailMsg && errorEmailMsg}</ErrorMsg>
          </Form>
          <Form>
            {/* <InputForm
                            setValue={setPassword}
                            placeholder={""}
                            name={"Password"}
                            onKeyDown={handleEnter}
                            value={password}
                            type={check ? "text" : "password"}
                        /> */}
            {/* <CheckContainer>
                            <Checkbox
                                onClick={() => {
                                    setCheck(!check);
                                }}
                                className={check && "checked"}
                            >
                                <Tick
                                    className={check && "checked"}
                                    src={tick}
                                    alt="Image"
                                />
                            </Checkbox>
                            <Content>Show password</Content>
                        </CheckContainer> */}
          </Form>
          <div className="context">
            <PrimaryButton
              className="category_button"
              text={'Sign Up'}
              size={'small'}
              onClick={() => {
                if (username === '') {
                  alert('Add Fullname')
                } else if (!emailValidation) {
                  alert('Enter a valid email')
                } else if (phoneNumber.length < 10 || phoneNumber.length > 10) {
                  alert(
                    'Invalid phone number. Phone number must be at least 10 characters',
                  )
                } else if (phoneNumber === 0) {
                  alert(
                    'Invalid phone number.',
                  )
                }else {
                  handlePassword()
                }
              }}
            />
          </div>
        </div>
        <BottomContainer>
          <Text>Already have an account ? </Text>
          <HomeLink
            onClick={() => {
              allowScroll()
              const auth_modal_details = {
                auth_modal_name: 'login',
                is_auth_modal: true,
              }
              authdispatch({
                type: 'AUTH_MODAL',
                auth_modal_details,
              })
            }}
          >
            Login
          </HomeLink>
        </BottomContainer>
        <RightIcon
          onClick={() => {
            closeModal()
            // allowScroll()
            allowScroll()
          }}
        >
          <Img src={close} alt="Image" />
        </RightIcon>
      </RightBox>
    </MainContiner>
  )
}
const MainContiner = styled.div`
  display: flex;
  /* width: 894px; */
  @media screen and (max-width: 640px) {
    flex-direction: column;
  }
`
const LeftBox = styled.div`
  background: #44a46d;
  width: 50%;
  padding: 55px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 640px) {
    width: 100%;
    padding: 24px;
  }
`
const Heading = styled.h3`
  font-size: 24px;
  line-height: 36px;
  color: ${THEME_COLORS.white};
  margin-bottom: 16px;
  font-family: 'poppins-medium';
  @media screen and (max-width: 540px) {
    margin-bottom: 12px;
  }
`
const Title = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${THEME_COLORS.border_5};
  font-family: 'poppins-medium';
`
const ImageBox = styled.div`
  width: 230px;
  height: 211px;
  margin: 0 auto;
  @media screen and (max-width: 640px) {
    display: none;
  }
`
const Img = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`
const RightBox = styled.div`
  width: 70%;
  padding: 55px;
  background: ${THEME_COLORS.white};
  &.context {
    box-shadow: 7px 11px 30px rgba(0, 0, 0, 0.06);
  }
  width: max-content;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media screen and (max-width: 980px) {
    padding: 40px 36px;
    width: 62%;
  }
  @media screen and (max-width: 640px) {
    width: 100%;
    padding: 24px;
  }
`
const SubHeading = styled.h3`
  font-size: 24px;
  line-height: 36px;
  font-family: 'poppins-medium';
  margin-bottom: 24px;
`
const Form = styled.div`
  @media screen and (max-width: 980px) {
    width: 100%;
  }
  @media screen and (max-width: 480px) {
    width: 250px;
  }
`
const ErrorMsg = styled.p`
  font-size: 12px;
  font-family: 'poppins-regular';
  color: red;
  margin-bottom: 12px;
`
const CheckContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
`
// const ErrorMsg = styled.p`
// 	color: red;
// 	font-size: 18px;
// 	text-align: right;
// `;
const Checkbox = styled.div`
  margin-right: 10px;
  width: 18px;
  height: 18px;
  border: 1px solid ${THEME_COLORS.background_3};
  border-radius: 3px;
  cursor: pointer;
`
const Tick = styled.img`
  display: none;
  width: 100%;
  object-fit: contain;
  &.checked {
    display: block;
  }
`
const Content = styled.h1`
  color: ${THEME_COLORS.background_4};
  font-size: 16px;
  line-height: 36px;
  font-family: 'poppins-medium';
`
const BottomContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
`
const Text = styled.h4`
  color: ${THEME_COLORS.background_1};
  font-size: 16px;
  font-family: 'poppins-medium';
  line-height: 30px;
  align-items: center;
  text-align: center;
  width: max-content;
  @media screen and (max-width: 980px) {
    font-size: 18px;
  }
  @media screen and (max-width: 640px) {
    font-size: 16px;
  }
`
const HomeLink = styled.a`
  color: ${THEME_COLORS.secondary};
  text-decoration: underline;
  font-size: 16px;
  line-height: 30px;
  font-family: 'poppins-medium';
  cursor: pointer;
  @media screen and (max-width: 980px) {
    font-size: 18px;
  }
  @media screen and (max-width: 640px) {
    font-size: 16px;
  }
`
const RightIcon = styled.span`
  position: absolute;
  display: inline-block;
  width: 32px;
  height: 32px;
  top: 10px;
  right: 10px;
  cursor: pointer;
`
const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`
const Name = styled.label`
  font-family: 'poppins-regular';
  color: ${THEME_COLORS.border_1};
  margin-bottom: 4px;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
`
const FormInput = styled.input`
    font-family: "poppins-medium";
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    /* color: ${THEME_COLORS.text_2}; */
    width: 100%;
    height: 48px;
    border: 1px solid ${THEME_COLORS.border_3};
    border-radius: 4px;
    padding: 0 10px;
    background: ${THEME_COLORS.white};
    &:focus-within {
        background: ${THEME_COLORS.white};
        border: 1.5px solid #126f39;
    }
    &:hover {
        background: ${THEME_COLORS.white};
        /* border: 1px solid ${THEME_COLORS.primary}; */
    }
    /* &.error {
        border: 1px solid ${THEME_COLORS.error_text};
    }
    &.success {
        border: 1px solid ${THEME_COLORS.success_text};
    }
    &.warning {
        border: 1px solid ${THEME_COLORS.warning_text};
    } */
`
const ErrorDiv = styled.div`
  height: ${(props) => (props.size === 'small' ? '3px' : '16px')};
`
const Error = styled.p`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: fadeIn 0.5s;
  font-family: 'poppins-regular';
  font-size: 12px;
  &.error {
    color: ${THEME_COLORS.error};
  }
  &.success {
    color: ${THEME_COLORS.success};
  }
  &.warning {
    color: ${THEME_COLORS.warning};
  }
`
