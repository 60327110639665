import React, { useEffect, useState, useContext, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'
import PrimaryButton from '../buttons/PrimaryButton'
// import ModalTop from "../modal/ModalTop";
// import SearchModal from "./SearchModal";
import SearchSuggestions from './SearchSuggestions'
import { Context } from '../../../contexts/Store'
import { baseConfig } from '../../../axiosConfig'
import RecentSearch from './RecentSearch'

export default function Search() {
  // const [searchSuggestions, setSearchSuggestions] = useState(false);
  const [inputStatus, setInputStatus] = useState(false)
  const [searchmodal, setSearchmodal] = useState(false)
  const [sucess, setsucess] = useState(false)

  const [input, setInput] = useState('')
  const { state } = useContext(Context)
  const [searchSuggestions, setSearchSuggestions] = useState([])
  const [showRecentSearch,setShowRecentSearch] = useState(false)
  const inputElement = useRef()
  const navigate = useNavigate()
  const inputRef = useRef(null);

  const getSuggestions = () => {
    let accessToken = state.user_details.access
    console.log("here worked");
    baseConfig
      .get('filter/search-results/', {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
        params: {
          q: input,
        },
      })
      .then((response) => {
        console.log(response, 'response')
        const { data, StatusCode } = response.data
        if (StatusCode === 6000) {
          setSearchSuggestions(data)
            // setsucess(true)
        }
      })
      .catch((error) => {
        console.log('ERROR:', error)
      })
  }

  useEffect(() => {
    if (!input == '') {
      setInputStatus(true)
      setsucess(true)
    }
    getSuggestions()
  }, [input])


  const handleInputFocus = () => {
    setsucess(true);
    getSuggestions()
  };
  const handleInputBlur = (event) => {
    if (!inputRef.current.contains(event.relatedTarget)) {
      console.log('Input blurred');
      setsucess(false);
    }
  };

  return (
    <>
      <Cover
        onClick={(e) => setSearchSuggestions('')}
      ></Cover>

      <MainContainer className="outer-click">
        <SearchContainer>
          <SearchInput
            placeholder="Search for products"
            type="text"
            id="search"
            ref={inputElement}
            onChange={(e) => setInput(e.target.value)}
            onClick={handleInputFocus}
            // onBlur={handleInputBlur}
            value={input}
            maxLength={25}
          />
          <PrimaryButton
            onClick={() => {
              getSuggestions()
            }}
            text={'Search'}
            size={'small'}
          />
        </SearchContainer>
        {sucess && (
          <SearchSuggestions
            searchSuggestions={searchSuggestions}
            setInput={setInput}
            // closeModal={closeModal}
            input={input}
            sucess={sucess}
            setsucess={setsucess}
            inputElement={inputElement}
          />
        )}
      {/* { showRecentSearch &&(
        <RecentSearch
        showRecentSearch={showRecentSearch}
        setShowRecentSearch={setShowRecentSearch}
        />
      ) 
      } */}
      </MainContainer>
    </>
  )
}

const Cover = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  display: none;
  &.show {
    display: block;
  }
`

const MainContainer = styled.div`
  position: relative;
`
const SearchContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "poppins-regular";
    width: 100%;
    height: 50px;
    border: 2px solid ${THEME_COLORS.secondary};
    border-radius: 6px;
    border-width: 2px 0px 2px 2px;
    padding: 0px 0px 0px 12px;
    overflow: hidden;
    &:focus-within {
        background-color: ${THEME_COLORS.white};
        /* border: 2px solid ${THEME_COLORS.secondary}; */
    }
`
const SearchInput = styled.input`
  font-family: 'poppins-regular';
  font-size: 16px;
  color: ${THEME_COLORS.text_1};
  width: 100%;
  height: 100%;
`
