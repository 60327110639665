import React, { useState, useContext, useEffect } from "react";
// import DealOfTheDay from './deal_of_day/DealOfTheDay'
import Footer from "./footer/Footer";
import ProductSingleList from "./product_single_page/ProductSingleList";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Header from "./header/Header";
import WishList from "./wishlist/WishList";
import CartPage from "./cartlist/CartPage";
import CheckOutPage from "./cartlist/CheckOutPage";
import NoDataFound from "../includes/no data/NoDataFound";
import ProductList from "./product_list/ProductList";
import TermsAndConditions from "./terms_and_condition/TermsAndConditions";
import Orders from "./cartlist/Orders";
import OfferPage from "./offers/OfferPage";
// import Login from "./loginpage/Login";
// import Signup from "./loginpage/Signup";
// import Otp from "./loginpage/Otp";
import SearchModal from "../includes/search/SearchModal";
import Navbar from "../includes/navbar/Navbar";
import Home from "./spotlight/Home";
import { Context } from "../../contexts/Store";
import AboutPage from "./about_us/AboutPage";
import Profile from "./profile/Profile";
import Notification from "./notification/Notification";
import PrivacyPolicies from "./Privacy_Policies/PrivacyPolicies";
import AddressList from "./address/AddressList";
import OrderStatus from "./order/OrderStatus";
import Settings from "./settings/Settings";
import OrderCancelation from "./order/OrderCancelation";
import Cancelation from "./order/Cancelation";
import SingleOrderResponsive from "./order/SingleOrderResponsive";
import CancellationPolicy from "./Privacy_Policies/CancellationPolicy";
import NoItemsFound from "./no_items_found/NoItemsFound";
import { baseConfig } from "../../axiosConfig";
import PageNotFound from "../includes/not_found/PageNotFound";
import ConfirmOrder from "../includes/modal/payment/ConfirmOrder";
import PaymentConfirmation from "../includes/modal/payment/Paymentconfirmation";
import ShippingPolicy from "./Privacy_Policies/ShippingPolicy";
import About from "./about_us/About";
import TermsAndConditionsMobile from "./terms_and_condition/TermsAndConditionsMobile";
import CancellationPolicyMobile from "./Privacy_Policies/CancelationPolicyMobile";
import PrivacyPoliciesMobile from "./Privacy_Policies/PrivacyPoliciesMobile";
import AboutPageMobile from "./about_us/AboutPageMobile";

function MainPage() {
    const [isNav, setisNav] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const location = useLocation();
    const { state, dispatch } = useContext(Context);

    async function fetchUserData() {
        // console.log('fetchUserData')
        // let promise = new Promise((resolve, reject) => {
        let user_details = localStorage.getItem("user_details");
        // console.log(user_details, 'user details in mainpage')
        if (!user_details) {
            localStorage.setItem("user_details", JSON.stringify(user_details));
            user_details = localStorage.getItem("user_details");
        }
        let user_details_stored = await JSON.parse(user_details);
        dispatch({
            type: "UPDATE_USER",
            user_details: user_details_stored,
        });
        // setTimeout(() => {
        // resolve("done!");
        setLoading(false);
        // }, 200);
        // });

        // let result = await promise;
    }
    // console.log(state.order_details.orderId, 'in Home')

    useEffect(() => {
        fetchUserData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const shouldShowHeaderAndFooter = 
    location.pathname !== "/terms-and-conditions-mobile" && 
    location.pathname !== "/cancellation-policy-mobile" &&
    location.pathname !== "/privacy-policies-mobile" &&
    location.pathname !== "/about-us-mobile"
    
    return (
        !isLoading && (
            <>
                {/* <Header isNav={isNav} setisNav={setisNav} /> */}
                {shouldShowHeaderAndFooter && <Header isNav={isNav} setisNav={setisNav} />}
                <Navbar isNav={isNav} setisNav={setisNav} />

                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/home" element={<Home />} />
                    {/* <Route path="/" element={<AboutPage />} /> */}
                    <Route path="/home-about" element={<AboutPage />} />
                    <Route path="/about-us-mobile" element={<AboutPageMobile/>} />
                    <Route
                        path="/product/:productListId/"
                        element={<ProductSingleList />}
                    />
                    <Route path="/wishlist" element={<WishList />} />
                    <Route path="/cart" element={<CartPage />} />
                    <Route path="/checkout" element={<CheckOutPage />} />
                    <Route path="/no-data" element={<NoDataFound />} />
                    <Route path="/productlist" element={<ProductList />} />
                    <Route path="/no-items-found" element={<NoItemsFound />} />
                    <Route
                        path="/terms-and-conditions"
                        element={<TermsAndConditions />}
                    />
                    {/* <Route path="/orderlist" element={<Orders />} /> */}
                    <Route path="/offerpage" element={<OfferPage />} />
                    <Route path="/navbar" element={<Navbar />} />
                    <Route path="/search" element={<SearchModal />} />
                    <Route path="/about-page" element={<About />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/address" element={<AddressList />} />
                    <Route path="/order-status" element={<OrderStatus />} />
                    <Route
                        path="/orders-order"
                        element={<SingleOrderResponsive />}
                    />
                    <Route path="/notification" element={<Notification />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route
                        path="/privacy-policies"
                        element={<PrivacyPolicies />}
                    />
                    <Route
                        path="/privacy-policies-mobile"
                        element={<PrivacyPoliciesMobile />}
                    />
                    <Route
                        path="/cancellation-policy"
                        element={<CancellationPolicy />}
                    />
                    <Route
                        path="/cancellation-policy-mobile"
                        element={<CancellationPolicyMobile />}
                    />
                    <Route
                        path="/shipping-policy"
                        element={<ShippingPolicy />}
                    />
                    <Route
                        path="/order-cancelation"
                        element={<OrderCancelation />}
                    />
                    <Route path="/cancelation" element={<Cancelation />} />
                    {/* <Route path="/payment-confirmation" element={<ConfirmOrder />} /> */}
                    <Route
                        path="/payment-confirmation"
                        element={<PaymentConfirmation />}
                    />

                    <Route path="/404" element={<PageNotFound />} />
                    <Route exact path="*" element={<Navigate to="/404" />} />
                    {/* <Route
                        path="/order/:orderId/"
                        element={<SingleOrderResponsive />}
                    /> */}
                    <Route path="/terms-and-conditions-mobile" element={<TermsAndConditionsMobile/>}></Route>
                </Routes>
                {shouldShowHeaderAndFooter && <Footer />}
            </>
        )
    );
}

export default MainPage;
