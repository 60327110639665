import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'
import logo from '../../../assets/icons/footer-logo.svg'
import facebook from '../../../assets/icons/facebook.svg'
import twitter from '../../../assets/icons/twitter.svg'
import instagram from '../../../assets/icons/insta.svg'
import linkedin from '../../../assets/icons/linkedin.svg'
import place from '../../../assets/icons/location-filled.svg'
import mail from '../../../assets/icons/mail-filled.svg'
import phone from '../../../assets/icons/phone-call.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { baseConfig } from '../../../axiosConfig'
import { Context } from '../../../contexts/Store'

export default function Footer() {
  const navigate = useNavigate()
  const [contactUs, setContactUs] = useState({})
  const { state, dispatch } = useContext(Context)
  let ContactData = (token) => {
    baseConfig
      .get('general/contact-us/')
      .then((response) => {
        setContactUs(response.data.data[0])
      })
      .catch((error) => {
        const { status, data } = error?.response
      })
  }
  useEffect(() => {
    ContactData()
  }, [])
  const location = useLocation()
  const pathname = location.pathname
  const handleClick = () => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  };
  return (
    <>
      <MainContainer className={pathname.includes('/single/') ? 'none' : ''}>
        <FooterContainer>
          <SubContainer>
            <LeftContainer>
              <LogoContainer>
                <Img src={logo} alt="Image" />
              </LogoContainer>
              <SocialMedia>
                <Media>
                  <Url
                    href={contactUs?.facebook_url}
                    target={'_blank'}
                    onClick={(e) => {
                      !contactUs.facebook_url && e.preventDefault()
                    }}
                  >
                    <Img src={facebook} alt="Image" />
                  </Url>
                </Media>
                <Media>
                  <Url
                    href={contactUs?.twitter_url}
                    target={'_blank'}
                    onClick={(e) => {
                      !contactUs.twitter_url && e.preventDefault()
                    }}
                  >
                    <Img src={twitter} alt="Image" />
                  </Url>
                </Media>
                <Media>
                  <Url
                    href={contactUs?.instagram_url}
                    target={'_blank'}
                    onClick={(e) => {
                      !contactUs.instagram_url && e.preventDefault()
                    }}
                  >
                    <Img src={instagram} alt="Image" />
                  </Url>
                </Media>
                <Media>
                  <Url
                    href={contactUs?.linkedin_url}
                    target={'_blank'}
                    onClick={(e) => {
                      !contactUs.linkedin_url && e.preventDefault()
                    }}
                  >
                    <Img src={linkedin} alt="Image" />
                  </Url>
                </Media>
              </SocialMedia>
            </LeftContainer>
            <RightContainer>
              <ContactDetails className="first">
                <TitleName>Contact Us</TitleName>
                <DetailContainer>
                  <Logo>
                    <Img src={place} alt="Image" />
                  </Logo>

                  <Url href={contactUs?.location_url} target={'_blank'}>
                    {contactUs?.location}
                  </Url>
                </DetailContainer>
                <DetailContainer>
                  <Logo>
                    <Img src={mail} alt="Image" />
                  </Logo>

                  <Url href={'mailto:' + contactUs?.email}>
                    {contactUs?.email}
                  </Url>
                </DetailContainer>
                <DetailContainer>
                  <Logo>
                    <Img src={phone} alt="Image" />
                  </Logo>

                  <Url href={'tel:' + contactUs?.phone}>{contactUs?.phone}</Url>
                </DetailContainer>
              </ContactDetails>
              {state.user_details.is_verified ? (
                <ContactDetails className="second">
                  <TitleName>Quick Links</TitleName>
                  <DetailContainer>
                    <ContentName to="/#spot">Home</ContentName>
                  </DetailContainer>
                  {/* <DetailContainer>
                    <ContentName to="/about">About Us</ContentName>
                  </DetailContainer> */}
                  <DetailContainer>
                    <ContentName to="/order-status">My Orders</ContentName>
                  </DetailContainer>
                  <DetailContainer>
                    <ContentName to="/address">My Addresses</ContentName>
                  </DetailContainer>
                </ContactDetails>
              ) : (
                <ContactDetails className="second">
                  <TitleName>Quick Links</TitleName>
                  <DetailContainer>
                    <ContentName to="/">Home</ContentName>
                  </DetailContainer>
                  <DetailContainer>
                    <ContentName to="/home-about">About Us</ContentName>
                  </DetailContainer>
                  <DetailContainer>
                    <ContentName to="/address">My Adresses</ContentName>
                  </DetailContainer>
                </ContactDetails>
              )}
              <ContactDetails className="third">
                <TitleName>Company Policies</TitleName>
                <DetailContainer>
                  <ContentName to="/privacy-policies">
                    Privacy Policies
                  </ContentName>
                </DetailContainer>
                <DetailContainer>
                  <ContentName to="/terms-and-conditions">
                    Terms and Conditions
                  </ContentName>
                </DetailContainer>
                <DetailContainer>
                  <ContentName to="/cancellation-policy" 
                  onClick={()=>{
                    handleClick()
                  }}>
                    Cancellation Policy
                  </ContentName>
                </DetailContainer>
              </ContactDetails>
            </RightContainer>
          </SubContainer>
        </FooterContainer>
        <BottomContainer>
          {new Date().getFullYear()} Pacess. All right reseved | Developed by{' '}
          <TegainUrl href="https://tegain.com/" target="blank">
            Tegain
          </TegainUrl>
        </BottomContainer>
      </MainContainer>
    </>
  )
}
const MainContainer = styled.div`
  background-color: ${THEME_COLORS.footer};
  @media screen and (max-width: 360px) {
    &.none {
      display: none;
    }
  }
`
const FooterContainer = styled.div`
  width: 90%;
  max-width: 1350px;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
const SubContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 72px 0px;
  @media screen and (max-width: 1280px) {
    padding: 72px 0px;
  }
  @media screen and (max-width: 980px) {
    flex-direction: column;
    padding: 50px 0px;
  }
  @media screen and (max-width: 768px) {
    padding: 30px;
    display: block;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 360px) {
    padding: 10px;
  }
`
const LeftContainer = styled.div`
  width: 24%;
  @media screen and (max-width: 1280px) {
  }
  @media screen and (max-width: 980px) {
    margin: 0 auto;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 768px) {
    margin: 0 auto;
  }
  @media screen and (max-width: 640px) {
    width: 33%;
  }
  @media screen and (max-width: 580px) {
  }
  @media screen and (max-width: 480px) {
    width: 46%;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 380px) {
    width: 57%;
  }
`
const LogoContainer = styled.h1`
  width: 269px;
  height: 108px;
  margin-bottom: 24px;
  @media screen and (max-width: 1280px) {
    width: 163px;
    height: 55px;
    margin-bottom: 26px;
  }
  @media screen and (max-width: 768px) {
  }

  @media screen and (max-width: 640px) {
  }
  @media screen and (max-width: 380px) {
  }
`
const Img = styled.img`
  width: 100%;
  /* height: 100%; */
  display: block;
`
const SocialMedia = styled.div`
  display: flex;
  gap: 15px;
`
const Media = styled.span`
  display: inline-block;
  /* height: 32px; */
  width: 32px;
  &.fb {
    padding: 5px;
  }
`
const RightContainer = styled.div`
  width: 65%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 120px;
  @media screen and (max-width: 1280px) {
    width: 73%;
    grid-gap: 100px;
  }
  @media screen and (max-width: 980px) {
    grid-gap: 65px;
    margin: 0 auto;
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    grid-gap: 40px;
    margin-top: 30px;
  }
  @media screen and (max-width: 640px) {
  }
  @media screen and (max-width: 580px) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  @media screen and (max-width: 480px) {
    grid-gap: 25px;
    margin-top: 0px;
  }
  @media screen and (max-width: 380px) {
    grid-gap: 10px;
  }
`
const ContactDetails = styled.div`
  @media screen and (max-width: 580px) {
    &.first {
      display: flex;
      flex-direction: column;
      align-items: left;
      width: 100%;
    }
    &.second {
      width: 46%;
    }
    &.third {
      width: 44%;
    }
  }
`
const TitleName = styled.h4`
  font-size: 20px;
  font-family: 'poppins-medium';
  color: ${THEME_COLORS.white};
  line-height: 30px;
  margin-bottom: 16px;
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
  @media screen and (max-width: 640px) {
    font-size: 16px;
  }
  @media screen and (max-width: 580px) {
    font-size: 20px;
  }
  @media screen and (max-width: 480px) {
    font-size: 17px;
  }
`
const DetailContainer = styled.div`
  display: flex;
  gap: 9px;
  align-items: flex-start;
  margin-bottom: 16px;
  cursor: pointer;
  :last-child {
    margin-bottom: 0px;
  }
`
const Logo = styled.div`
  width: 24px;
  height: 24px;
`
const ContentName = styled(Link)`
  color: ${THEME_COLORS.border_5};
  font-size: 15px;
  line-height: 22px;
  font-family: 'poppins-medium';
  width: 100%;
  @media screen and (max-width: 768px) {
    font-size: 13px;
  }
  @media screen and (max-width: 640px) {
    font-size: 12px;
  }
  @media screen and (max-width: 580px) {
    font-size: 14px;
  }
  @media screen and (max-width: 480px) {
    font-size: 13px;
  }
`
const Url = styled.a`
  color: ${THEME_COLORS.border_5};
  font-size: 15px;
  line-height: 22px;
  font-family: 'poppins-medium';
  width: 100%;

  @media screen and (max-width: 768px) {
    font-size: 13px;
  }
  @media screen and (max-width: 640px) {
    font-size: 12px;
  }
  @media screen and (max-width: 580px) {
    font-size: 14px;
  }
  @media screen and (max-width: 480px) {
    font-size: 13px;
  }
`
const BottomContainer = styled.h6`
  font-size: 14px;
  font-family: 'poppins-medium';
  color: ${THEME_COLORS.white};
  text-align: center;
  padding: 24px;
  border-top: 1px solid ${THEME_COLORS.white};
  @media screen and (max-width: 480px) {
    font-size: 12px;
  }
  @media screen and (max-width: 360px) {
    font-size: 10px;
  }
`
const TegainUrl = styled.a`
  color: ${THEME_COLORS.white};
  &:hover {
    text-decoration: underline;
  }
`
