import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Steps from '../../includes/step/Step'
import { THEME_COLORS } from '../../../ThemeConfig'
import ProductCard from './ProductCard'
import DeliveryCard from './DeliveryCard'
import Cancelation from './Cancelation'

export default function SingleOrder({ active, getOrderStatus }) {
  // console.log(active, 'kkkkk')
  const [cancel, setCancel] = useState(false)

  const [trackingList, setTrackingList] = useState([
    {
      id: 1,
      name: 'Order confirmed',
      status_list: [
        {
          title: 'Your Order has been placed.',
          date: 'Fri, 02 May 2023 - 3 : 08 pm',
        },
        {
          title: 'Seller has  processed your order',
          date: 'Fri, 02 May 2023 - 3 : 30 pm',
        },
        {
          title: 'Your item has been picked up by courier partner',
          date: 'Fri, 02 May 2023 - 3 : 30 pm',
        },
      ],
      status: true,
    },
    {
      id: 2,
      name: 'Shipped',
      shipped_by: 'Ekart - HGHF1566886532',
      status_list: [
        {
          title: 'Your item has been shipped',
          date: 'Fri, 02 May 2023 - 3 : 08 pm',
        },
      ],
      status: true,
    },
  ])

  const trackingStatus = () => {
    if (active?.order_status == 'pending') {
      // setList(list[0].status === 'pending')
    }
  }
  useEffect(() => {
    trackingStatus()
  })
  // console.log(active, 'activeactiveactive')
  return (
    <RightContainer>
      <HeadWrapper>
        <Head>
          Order ID : {active?.order_id} <span>({active?.items_count})</span>
        </Head>
        {cancel && (
          <CancelContainer>
            {active?.order_items?.map((product) => (
              <Cancelation
                key={product.pk}
                product={product}
                active={active}
                setCancel={setCancel}
                getOrderStatus={getOrderStatus}
              />
            ))}
          </CancelContainer>
        )}
        {active?.can_cancel && (
          <CancelButton
            //   to="/cancelation"
            onClick={() => {
              setCancel(true)
            }}
          >
            Cancel Order{' '}
          </CancelButton>
        )}
      </HeadWrapper>
      {active.order_status == 'pending' ? (
        ''
      ) : (
        <StatusIndicator>
          <Status>
            <Steps order_status={active.order_status} />
          </Status>
        </StatusIndicator>
      )}
      <Details>
        <ProductDetails>
          {active?.order_items?.map((product) => (
            <ProductCard key={product.pk} product={product} />
          ))}
        </ProductDetails>
        <DeliveryDetails>
          <DeliveryCard active={active} />
        </DeliveryDetails>
      </Details>
      {active?.tracking?.length != 0 ? (
        <TrackingDetails>
          <Head>Tracking ID : #AED5622</Head>
          <TrackStatus>
            {trackingList?.map((item) => (
              <Content key={item.id}>
                <CurrentStatus>{item.name}</CurrentStatus>
                <Ship>{item.shipped_by && item.shipped_by}</Ship>
                {item.status_list.map((status, index) => (
                  <StatusItem key={index}>
                    <Title>{status.title}</Title>
                    <Date>{status.date}</Date>
                  </StatusItem>
                ))}
              </Content>
            ))}
          </TrackStatus>
        </TrackingDetails>
      ) : (
        ''
      )}
    </RightContainer>
  )
}

const RightContainer = styled.div`
  width: 58%;
  display: flex;
  flex-direction: column;
  padding: 12px;
  background: ${THEME_COLORS.border_5};
  border-radius: 8px;
  // max-height:700px;
  // min-height:700px;
  // height:700px;
`
const HeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`
const Head = styled.h4`
  font-family: 'poppins-regular';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${THEME_COLORS.background_3};
  span {
    color: ${THEME_COLORS.border_1};
  }
`
const CancelButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 18px;
  background: ${THEME_COLORS.warning};
  opacity: 0.7;
  border-radius: 4px;
  font-family: 'poppins-regular';
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${THEME_COLORS.white};
`
const StatusIndicator = styled.div`
  padding: 24px 0px;
  background: ${THEME_COLORS.white};
  border-radius: 8px;
  height: 133px;
  margin-bottom: 12px;
`
const Status = styled.div``
const Details = styled.div`
  padding: 0px 12px 12px;
  background: ${THEME_COLORS.white};
  border-radius: 8px;
  margin-bottom: 12px;
`
const ProductDetails = styled.div`
  margin-bottom: 32px;
`
const DeliveryDetails = styled.div`
  display: flex;
  justify-content: space-between;
`
const TrackingDetails = styled.div`
  padding: 16px;
  background: ${THEME_COLORS.white};
  border-radius: 8px;
`
const TrackStatus = styled.div`
  border-left: 3px solid ${THEME_COLORS.secondary};
  padding: 0 24px;
  margin-top: 16px;
`
const Content = styled.div`
  position: relative;
  padding-bottom: 20px;
  &:last-child {
    padding-bottom: 0;
  }
  &:before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: ${THEME_COLORS.secondary};
    position: absolute;
    top: 0;
    left: -33px;
  }
`
const CurrentStatus = styled.div`
  font-family: 'poppins-regular';
  font-weight: 600;
  font-size: 16px;
  color: ${THEME_COLORS.background_3};
  margin-bottom: 12px;
`
const Ship = styled.div`
  font-family: 'poppins-regular';
  font-weight: 400;
  font-size: 16px;
  color: ${THEME_COLORS.background_2};
  margin-bottom: 8px;
`
const StatusItem = styled.div`
  margin-bottom: 12px;
`
const Title = styled.div`
  font-family: 'poppins-regular';
  font-weight: 400;
  font-size: 16px;
  color: ${THEME_COLORS.background_2};
  margin-bottom: 8px;
`
const Date = styled.div`
  font-family: 'poppins-regular';
  font-weight: 400;
  font-size: 14px;
  color: ${THEME_COLORS.background_4};
`
const CancelContainer = styled.div`
  /* display: none; */
  position: fixed;
  top: 0%;
  left: 0%;
  /* display: flex; */
  z-index: 111;
  background: black;
  width: 100%;
  height: 100vh;
  overflow-x: scroll;
`
