import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import loginimage from '../../../assets/images/Remedy-rafiki 1.png'
import PrimaryButton from '../../includes/buttons/PrimaryButton'
import InputForm from '../../includes/form/InputForm'
import close from '../../../assets/icons/close-square.svg'
import tick from '../../../assets/icons/tick-square.svg'
import { AuthContext } from '../../../contexts/AuthStore'
import { Context } from '../../../contexts/Store'
import { baseConfig } from '../../../axiosConfig'
import { THEME_COLORS } from '../../../ThemeConfig'
import useScrollBlock from '../../includes/functions/useScrollBlock'

export default function NewLoginPage({ setModal, closeModal, modal }) {
  const [password, setPassword] = useState('')
  const { authstate, authdispatch } = useContext(AuthContext)
  const [phone, setPhone] = useState('')
  const [isError, setError] = useState(false)
  const [errorEmailMsg, setErrorEmailMsg] = useState('')
  const { state, dispatch } = useContext(Context)

  const [blockScroll, allowScroll] = useScrollBlock()

  useEffect(() => {
    if (modal == 'login') {
      blockScroll()
    } else {
      allowScroll()
    }
  }, [modal])

  const handleEnter = (event) => {
    const keyCode = event.which || event.keyCode
    if (event.key.toLowerCase() === 'enter') {
      const form = event.target.form
      const index = [...form].indexOf(event.target)
      form.elements[index + 1].focus()
      event.preventDefault()
    } else if (keyCode < 8 || keyCode > 57) {
      event.preventDefault()
    }
  }

  const handleLogin = () => {
    if (phone.length == 10) {
      setError(false)
      const formData = new FormData()
      formData.append('phone', phone)
      baseConfig
        .post('users/send-otp/', formData, {})
        .then((response) => {
          const { StatusCode, data, token, message } = response.data
          if (StatusCode === 6000) {
            const auth_modal_details = {
              auth_modal_name: 'login_otp',
              is_auth_modal: true,
              phone: phone,
            }
            authdispatch({
              type: 'AUTH_MODAL',
              auth_modal_details,
            })

            const user_details = {
              is_verified: true,
              role: token.role,
              access: token.access,
              refresh: token.refresh,
            }
            dispatch({
              type: 'UPDATE_USER',
              user_details,
            })
          } else if (StatusCode === 6001) {
            setErrorEmailMsg(message)
          }
        })
        .catch((error) => {})
    } else {
      setError('Enter a valid phone number')
    }
  }

  // const validation = () => {
  //     if (phone === "") {
  //         console.log("iffffffffffff");
  //         setMessage("Enter Phone");
  //     } else if (phone.length < 10 || phone.length > 10) {
  //         setMessage(
  //             "Invalid phone number. Phone number must be at least 10 characters"
  //         );
  //     } else {
  //         console.log("elseeeeeeeeeeeee");
  //         const auth_modal_details = {
  //             auth_modal_name: "login_otp",
  //             is_auth_modal: true,
  //         };
  //         authdispatch({
  //             type: "AUTH_MODAL",
  //             auth_modal_details,
  //         });
  //         handleLogin();
  //     }
  // };

  const handleInputChange = (e) => {
    let inputValue = e.target.value
    // Remove non-digit characters and limit to 10 digits
    inputValue = inputValue.replace(/\D/g, '').slice(0, 10)
    setPhone(inputValue)
  }

  return (
    <MainContiner>
      <LeftBox>
        <div>
          <Heading>Hello there 👋🏻</Heading>
          <Title>
            Welcome to the wellness journey! Browse our range of top-quality
            products and make a purchase today to take the first step towards a
            healthier you.
          </Title>
        </div>
        <ImageBox>
          <Img src={loginimage} alt="Image" />
        </ImageBox>
      </LeftBox>
      <RightBox>
        <SubHeading>Login</SubHeading>
        <Form>
          {/* <InputForm
            setValue={setPhone}
            size={'small'}
            focus={'autoFocus'}
            placeholder={''}
            name={'Phone'}
            onKeyDown={handleEnter}
            value={phone}
            type="number"
            minLength={10}
            maxLength={10}
            inputProps={{
              maxLength: 10,
            }}
          /> */}
          <FormDiv>
            <Name>Phone Number</Name>
            <div style={{ position: 'relative' }}>
              <FormInput
                name={'Phone Number'}
                // onKeyDown={handleEnter}
                value={phone}
                type="tel"
                id="Mobile"
                maxLength={10}
                className="active"
                // placeholder="Enter mobile number"
                onChange={handleInputChange}
              />
            </div>
          </FormDiv>
          <ErrorMsg>{errorEmailMsg && errorEmailMsg}</ErrorMsg>
          {isError && <ErrorMsg>{isError}</ErrorMsg>}
        </Form>
        <div className="context">
          <PrimaryButton
            className="category_button"
            text={'Login'}
            size={'small'}
            onClick={() => {
              handleLogin()
              allowScroll()
            }}
          />
        </div>
        <LoginBottom>
          <Text>Don't have an Account! </Text>{' '}
          <HomeLink
            onClick={() => {
              const auth_modal_details = {
                auth_modal_name: 'register',
                is_auth_modal: true,
              }
              authdispatch({
                type: 'AUTH_MODAL',
                auth_modal_details,
              })
            }}
          >
            Create Account
          </HomeLink>
        </LoginBottom>
        <RightIcon
          onClick={() => {
            closeModal()
            allowScroll()
          }}
        >
          <Img src={close} alt="Image" />
        </RightIcon>
      </RightBox>
    </MainContiner>
  )
}
const MainContiner = styled.div`
  display: flex;

  @media screen and (max-width: 640px) {
    flex-direction: column;
  }
`
const LeftBox = styled.div`
  background: #44a46d;
  padding: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    padding: 36px 18px 26px 18px;
  }
`
const Heading = styled.h3`
  font-size: 24px;
  line-height: 36px;
  color: ${THEME_COLORS.white};
  margin-bottom: 16px;
  font-family: 'poppins-medium';
  @media screen and (max-width: 540px) {
    margin-bottom: 12px;
  }
`
const Title = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${THEME_COLORS.border_5};
  font-family: 'poppins-medium';
`
const ImageBox = styled.div`
  width: 230px;
  height: 211px;
  margin: 0 auto;
  @media screen and (max-width: 640px) {
    display: none;
  }
`
const Img = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`
const RightBox = styled.div`
  padding: 64px;
  background: ${THEME_COLORS.white};
  &.context {
    box-shadow: 7px 11px 30px rgba(0, 0, 0, 0.06);
  }
  @media screen and (max-width: 768px) {
    padding: 20;
  }
`
const SubHeading = styled.h3`
  font-size: 24px;
  line-height: 36px;
  font-family: 'poppins-medium';
  margin-bottom: 24px;
`
const ErrorBox = styled.p`
  position: absolute;
  bottom: -20px;
  right: 0;
  color: red;
  align-items: center;
  font-size: 18px;
  display: flex;
  justify-content: right;
`
const Form = styled.div``
const CheckContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  @media screen and (max-width: 540px) {
    margin-bottom: 16px;
  }
`
const Checkbox = styled.div`
  margin-right: 10px;
  width: 18px;
  height: 18px;
  border: 1px solid ${THEME_COLORS.background_3};
  border-radius: 3px;
  cursor: pointer;
  &.checked {
    border-color: ${THEME_COLORS.secondary};
  }
`
const Tick = styled.img`
  display: none;
  width: 100%;
  object-fit: contain;
  &.checked {
    display: block;
  }
`
const Content = styled.h1`
  color: ${THEME_COLORS.background_2};
  font-size: 16px;
  line-height: 24px;
  font-family: 'poppins-medium';
  width: max-content;
`
const LoginBottom = styled.div`
  margin-top: 50px;
  display: flex;
  width: max-content;
  gap: 8px;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 240px;
  }
  @media screen and (max-width: 640px) {
    width: 100%;
    flex-direction: row;
  }
  @media screen and (max-width: 540px) {
    width: 200px;
  }
  @media screen and (max-width: 540px) {
  }
`
const Text = styled.h4`
  color: ${THEME_COLORS.background_1};
  font-size: 16px;
  font-family: 'poppins-medium';
  line-height: 30px;
  align-items: center;
  text-align: center;
`
const ErrorMsg = styled.p`
  font-size: 12px;
  font-family: 'poppins-regular';
  color: red;
  margin-bottom: 12px;
`
const HomeLink = styled.a`
  color: ${THEME_COLORS.secondary};
  text-decoration: underline;
  font-size: 16px;
  line-height: 30px;
  font-weight: 600;
  font-family: 'poppins-medium';
  cursor: pointer;
  display: inline-block;
  width: max-content;
  text-align: center;
  display: flex;
`
const RightIcon = styled.span`
  position: absolute;
  display: inline-block;
  width: 32px;
  height: 32px;
  top: 10px;
  right: 10px;
  cursor: pointer;
`
const ForgotButton = styled.a`
  margin-top: 24px;
  font-family: 'poppins-medium';
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 77px;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    margin-bottom: 60px;
  }
  @media screen and (max-width: 540px) {
    margin-bottom: 30px;
  }
  @media screen and (max-width: 480px) {
    margin: 14px 0;
  }
`
const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`
const Name = styled.label`
  font-family: 'poppins-regular';
  color: ${THEME_COLORS.border_1};
  margin-bottom: 4px;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
`
const FormInput = styled.input`
    font-family: "poppins-medium";
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    /* color: ${THEME_COLORS.text_2}; */
    width: 100%;
    height: 48px;
    border: 1px solid ${THEME_COLORS.border_3};
    border-radius: 4px;
    padding: 0 10px;
    background: ${THEME_COLORS.white};
    &:focus-within {
        background: ${THEME_COLORS.white};
        border: 1.5px solid #126f39;
    }
    &:hover {
        background: ${THEME_COLORS.white};
        /* border: 1px solid ${THEME_COLORS.primary}; */
    }
    /* &.error {
        border: 1px solid ${THEME_COLORS.error_text};
    }
    &.success {
        border: 1px solid ${THEME_COLORS.success_text};
    }
    &.warning {
        border: 1px solid ${THEME_COLORS.warning_text};
    } */
`
const ErrorDiv = styled.div`
  height: ${(props) => (props.size === 'small' ? '3px' : '16px')};
`
const Error = styled.p`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: fadeIn 0.5s;
  font-family: 'poppins-regular';
  font-size: 12px;
  &.error {
    color: ${THEME_COLORS.error};
  }
  &.success {
    color: ${THEME_COLORS.success};
  }
  &.warning {
    color: ${THEME_COLORS.warning};
  }
`
