import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'
import PrimaryButton from '../../includes/buttons/PrimaryButton'
import SecondaryButton from '../../includes/buttons/SecondaryButton'
import apple from '../../../assets/images/delete-modal-apple.svg'
import useScrollBlock from '../functions/useScrollBlock'

export default function CartRemoveConfirmModal({
  isconfirmRemove,
  setConfirmRemove,
  id,
  removeapi,
  setIsStock,
}) {
  const [blockScroll, allowScroll] = useScrollBlock()

  useEffect(() => {
    if (isconfirmRemove) {
      blockScroll()
    } else {
      allowScroll()
    }
  }, [isconfirmRemove])
  return (
    <>
      {isconfirmRemove && (
        <>
          {' '}
          {/* <Cover> */}
          <MainContainer>
            <ImageContainer>
              <Icon src={apple} alt="Image" />
            </ImageContainer>
            <Title>Are you sure to remove</Title>
            <Button>
              <SecondaryButton
                size={'small'}
                text={'Cancel'}
                onClick={() => {
                  setConfirmRemove(false)
                  allowScroll()
                }}
              />

              <PrimaryButton
                size={'small'}
                text={'Confirm'}
                onClick={() => {
                  allowScroll()
                  removeapi(id)
                  setConfirmRemove(false)
                }}
              />
            </Button>
          </MainContainer>
          {/* </Cover> */}
        </>
      )}
    </>
  )
}

const MainContainer = styled.div`
  padding: 32px;
  background: ${THEME_COLORS.white};
  box-shadow: 12px 25px 25px 2px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  position: fixed;
  top: 19%;
  left: 30%;
  z-index: 1;
  width: 35%;
  @media screen and (max-width: 1280px) {
    width: 45%;
  }
  @media screen and (max-width: 1080px) {
    width: 50%;
  }
  @media screen and (max-width: 980px) {
    width: 55%;
  }
  @media screen and (max-width: 768px) {
    width: 75%;
  }
  @media screen and (max-width: 480px) {
    width: 95%;
    left: 10px;
    top: 25%;
  }
`
const ImageContainer = styled.div`
  margin: 0 auto;
`
const Icon = styled.img``

const Title = styled.h3`
  width: 100%;
  color: ${THEME_COLORS.background_1};
  font-size: 22px;
  margin-bottom: 30px;
  font-family: 'poppins-medium';
  text-align: center;
  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`
const Content = styled.p`
  width: 100%;
  color: ${THEME_COLORS.background_4};
  font-size: 13px;
  margin-bottom: 40px;
  font-family: 'poppins-regular';
  text-align: center;
  span {
    color: red;
  }
`
const Button = styled.div`
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`
