const AuthReducer = (state, action) => {
	switch (action.type) {
		case "AUTH_MODAL":
			const auth_modal_details = {
				...state.auth_modal_details,
				...action.auth_modal_details,
			};
			// localStorage.setItem("auth_modal_details", JSON.stringify(auth_modal_details));
			return {
				...state,
				auth_modal_details: auth_modal_details,
			};
		default:
			return state;
	}
};

export default AuthReducer;
