// NewAddress
import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import close from './../../../../assets/icons/close-square.svg'
import tick from './../../../../assets/icons/tick-square.svg'
import arrow from '../../../../assets/images/list_down.svg'

import { THEME_COLORS } from '../../../../ThemeConfig'
import TextField from '@mui/material/TextField'
import { baseConfig } from '../../../../axiosConfig'
import { Context } from '../../../../contexts/Store'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ConfirmdeleteAdress from './ConfirmdeleteAdress'
import CssTextField from '../../muistyle/MuiStyle'
import useScrollBlock from '../../functions/useScrollBlock'

export default function NewAddressModal({
  closeModal,
  edit = false,
  selectedAddress,
  refreshApi,
  setNewAddress,
  newAddress,
  editAddress,
  setEditAddress,
}) {
  const { state, selectedAdress, dispatch } = useContext(Context)
  const [check, setCheck] = useState(false)

  //fields
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [pinCode, setPinCode] = useState('')
  const [location, setLocation] = useState('')
  const [stateADD, setStateAdd] = useState('')
  const [country, setCountry] = useState('India')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [stateModal, setStateModal] = useState(false)
  const [selected, setSelected] = useState()
  const [Error, setError] = useState('')

  const [blockScroll, allowScroll] = useScrollBlock()
  useEffect(() => {
    if (newAddress) {
      blockScroll()
    } else {
      allowScroll()
    }
  }, [newAddress])

  const handleLocationChange = (e) => {
    const value = e.target.value;
    if (/\d/.test(value)) {
      setErrorField({ location: 'Numbers are not allowed in the location field' });
    } else {
      setErrorField({});
      setLocation(value); 
    }
  };
  //picode
  // const handleInputChange = (event) => {
  //   const value = event.target.value
  //   if (value.length <= 6) {
  //     setPinCode(value)
  //   }
  //   else{
  //     alert("enter correct pincode")
  //   }
  // }
  const handleInputChange = (event) => {
    const value = event.target.value;
    if (value.length <= 6) {
      setPinCode(value);
    } else {
      event.preventDefault();
      alert("Enter correct pincode (max 6 characters)");
    }
  };

  //error
  const [errorField, setErrorField] = useState({})
  
  const showToastCart = () => {
    toast.success('New address added ', {
      autoClose: 500,
      hideProgressBar: true,
    })
  }
  const showToastEdit = () => {
    toast.success('Address Edited', {
      autoClose: 500,
      hideProgressBar: true,
    })
  }
  const handleWishlistData = () => {
    if (
      firstName &&
      lastName &&
      address1 &&
      pinCode &&
      stateADD &&
      phone &&
      location
    ) {
      let access_token = state.user_details.access
      let formdata = new FormData()
      formdata.append('first_name', firstName)
      formdata.append('last_name', lastName)
      formdata.append('address', address1)
      formdata.append('address_2', address2)
      formdata.append('pincode', pinCode)
      formdata.append('location', location)
      formdata.append('state', stateADD)
      formdata.append('country', country)
      formdata.append('phone', phone)
      formdata.append('email', email)
      formdata.append('is_default', check)

      // var method = edit ? baseConfig.put : baseConfig.post;
      let URL = edit
        ? `customers/edit-address/${selectedAddress.id}/`
        : `customers/create-address/`

      // method(URL, formdata, {

      baseConfig
        .post(URL, formdata, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((response) => {
          if(edit){
            showToastEdit()
          }else{
            showToastCart()
          }
          refreshApi()
          setError('')
          // setNewAddress(false)
          closeModal()
          // editAddress(false)
         })
        .catch((error) => {
          const { data } = error.response
          setErrorField(data)
        })
    } else {
      setError('* These fields should not be empty')
    }
  }
  useEffect(() => {
    if (edit) {
      setFirstName(selectedAddress.first_name)
      setLastName(selectedAddress.last_name)
      setAddress1(selectedAddress.address)
      setAddress2(selectedAddress.address_2)
      setPinCode(selectedAddress.pincode)
      setLocation(selectedAddress.location)
      setStateAdd(selectedAddress.state)
      setCountry(selectedAddress.country)
      setPhone(selectedAddress.phone)
      setEmail(selectedAddress.email)
      setCheck(selectedAddress.is_default)
    }
  }, [selectedAddress])

  const [lists, setLists] = useState([
    {
      id: 1,
      title: 'Andhra Pradesh',
    },
    {
      id: 2,
      title: 'Arunachal Pradesh',
    },
    {
      id: 3,
      title: 'Assam',
    },
    {
      id: 4,
      title: 'Bihar',
    },
    {
      id: 5,
      title: 'Chhattisgarh',
    },
    {
      id: 6,
      title: 'Goa',
    },
    {
      id: 7,
      title: 'Gujarat',
    },
    {
      id: 8,
      title: 'Haryana',
    },
    {
      id: 9,
      title: 'Himachal Pradesh',
    },
    {
      id: 10,
      title: 'Jharkhand',
    },
    {
      id: 11,
      title: 'Karnataka',
    },
    {
      id: 12,
      title: 'Kerala',
    },
    {
      id: 13,
      title: 'Madhya Pradesh',
    },
    {
      id: 14,
      title: 'Maharashtra',
    },
    {
      id: 15,
      title: 'Manipur',
    },
    {
      id: 16,
      title: 'Meghalaya',
    },
    {
      id: 17,
      title: 'Mizoram',
    },
    {
      id: 18,
      title: 'Nagaland',
    },
    {
      id: 19,
      title: 'Odisha',
    },
    {
      id: 20,
      title: 'Punjab',
    },
    {
      id: 21,
      title: 'Rajasthan',
    },
    {
      id: 22,
      title: 'Sikkim',
    },
    {
      id: 23,
      title: 'Tamil Nadu',
    },
    {
      id: 24,
      title: 'Telangana	',
    },
    {
      id: 25,
      title: 'Tripura',
    },
    {
      id: 26,
      title: 'Uttar Pradesh',
    },
    {
      id: 27,
      title: 'Uttarakhand',
    },
    {
      id: 28,
      title: 'West Bengal',
    },
  ])

  return (
    <>
      {/* <ToastContainer /> */}
      <MainContainer>
        <TopContainer>
          <Title>{edit ? "Edit Address" : "Add New Address"}</Title>
          <Image
            onClick={() => {
              // setNewAddress(false)
              allowScroll()
              closeModal()
            }}
          >
            <Img src={close} alt="Image" />
          </Image>
        </TopContainer>
        <Forms>
          <SingleInput>
            <CssTextField
              fullWidth={true}
              placeholder="First Name*"
              label="First Name*"
              onChange={(e) => {
                const textValue = e.target.value.replace(/[^A-Za-z " "]/g, '')
                setFirstName(textValue)
              }}
              value={firstName}
              error={'first_name' in errorField}
              // maxLength={40}
              inputProps={{
                maxLength: 40 
              }}
            />
          </SingleInput>
          <SingleInput>
            <CssTextField
              fullWidth={true}
              placeholder="Last Name"
              label="Last Name*"
              onChange={(e) => {
                const textValue = e.target.value.replace(/[^A-Za-z " "]/g, '')
                setLastName(textValue)
              }}
              value={lastName}
              error={'last_name' in errorField}
            />
          </SingleInput>
          <SingleInput className="">
            <CssTextField
              fullWidth={true}
              placeholder="Address line 1"
              label="Address line*"
              onChange={(e) => {
                setAddress1(e.target.value)
              }}
              value={address1}
              error={'address' in errorField}
            />
          </SingleInput>
          <SingleInput className="">
            <CssTextField
              fullWidth={true}
              placeholder="Address line 2"
              label="Address line 2"
              onChange={(e) => {
                setAddress2(e.target.value)
              }}
              value={address2}
              // error={true}
            />
          </SingleInput>
          <SingleInput>
            <CssTextField
              fullWidth={true}
              placeholder="Pincode*"
              label="Pincode*"
              onChange={handleInputChange}
              value={pinCode}
              error={'pincode' in errorField}
              type="number"
            />
          </SingleInput>
          <SingleInput>
            <CssTextField
              fullWidth={true}
              placeholder="Location"
              label="Location*"
              onChange={(e) => {
                // setLocation(e.target.value)
                handleLocationChange(e)
              }}
              value={location}
              error={'location' in errorField}
            />
          </SingleInput>
          <SingleInput
            className="State"
            onClick={() => setStateModal(!stateModal)}
          >
            <CssTextField
              fullWidth={true}
              placeholder="State*"
              // label="State"
              onChange={(e) => {
                setStateAdd(selected)
              }}
              value={stateADD}
              error={'state' in errorField}
              readOnly={true}
            />
            <ListDown onClick={() => setStateModal(!stateModal)}>
              <ListIcon src={arrow} alt="list_down" />
            </ListDown>
            {stateModal && (
              <DropList className={stateModal && 'view-modal'}>
                {lists.map((item) => (
                  <DropDowns
                    key={item.id}
                    onClick={() => {
                      setStateAdd(item.title)
                      setStateModal(false)
                    }}
                  >
                    {item.title}
                  </DropDowns>
                ))}
              </DropList>
            )}
          </SingleInput>

          <SingleInput className="country">
            <CssTextField
              fullWidth={true}
              placeholder="Country"
              label="Country"
              onChange={(e) => {
                setCountry('India')
              }}
              value={'India'}
              error={'country' in errorField}
              disabled
            />
          </SingleInput>
          <Contact>What’s Your contact Information?</Contact>
          <MiddleContainer>
            <SingleInput className="Phone">
              <CssTextField
                fullWidth={true}
                placeholder="Phone"
                label="Phone*"
                type="number"
                onChange={(e) => {
                  const numericValue = e.target.value
                    .replace(/\D/g, '')
                    .slice(0, 10)
                  setPhone(numericValue)
                }}
                value={phone}
                error={'phone' in errorField}
                inputProps={{
                  maxLength: 10,
                }}
              />
               {/* {Error && <p style={{
              color:"#bf1818"
            }}>{Error}</p>} */}
            </SingleInput>

            <SingleInput>
              <CssTextField
                fullWidth={true}
                placeholder="Email"
                label="Email"
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
                value={email}
                error={'email' in errorField}
              />
            </SingleInput>
          </MiddleContainer>
          {Error.length != 0 && <ErrorDiv>{Error}</ErrorDiv>}
        </Forms>

        <BottomContainer>
          <DefaultButton>
            <Checkbox
              onClick={() => {
                setCheck(!check)
              }}
              className={check && 'checked'}
            >
              {check ? <Tick src={tick} alt="Image" /> : null}
            </Checkbox>
            <Text>Use this as my Default Address</Text>
          </DefaultButton>
          <Button
            // onClick={() => {
            //   handleWishlistData()
            //   allowScroll()
            //   allowScroll()
            //   setNewAddress(false)
            // }}
            // onClick={() => {
            //   if (phone.length === 10){
            //     handleWishlistData()
            //   }
            //   else{
            //     setError('Please enter correct values');
            // }
            //   allowScroll()
            // }}
            onClick={() => {
              if (phone.length < 10) {
                setError('Please enter a correct phone number');
              } else if (pinCode.length < 6) {
                setError('Please enter a correct pin code');
              } else if (stateADD == "") {
                setError('please select a state from the options')
              } else {
                handleWishlistData();
                allowScroll();
              }
            }}
          >
            UPDATE
          </Button>
        </BottomContainer>
      </MainContainer>
    </>
  )
}

const MainContainer = styled.div`
  width: 800px;
  max-height: 90vh;
  overflow-y: scroll;
  padding: 32px;
  height: 1000px;

  @media screen and (max-width: 1280px) {
    width: 729px;
  }
  @media screen and (max-width: 980px) {
    width: 630px;
  }
  @media screen and (max-width: 640px) {
    width: 520px;
    height: 580px;
    overflow: scroll;
  }
  @media screen and (max-width: 540px) {
    width: 430px;
  }
  @media screen and (max-width: 480px) {
    width: 360px;
  }
  @media screen and (max-width: 360px) {
    width: 300px;
  }
`
const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`
const MiddleContainer = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  align-items: center;
  // margin-bottom: 32px;
  width: 100%;
  @media screen and (max-width: 640px) {
    flex-wrap: wrap;
  }
`
const Title = styled.h3`
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  color: ${THEME_COLORS.background_1};
  @media screen and (max-width: 640px) {
    font-size: 20px;
  }
`
const Image = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
`
const Img = styled.img`
  width: 100%;
  height: 100%;
  display: block;
`
const Forms = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
`
const SingleInput = styled.div`
  width: 48%;
  &.address {
    width: 100%;
  }
  &.State {
    position: relative;
  }
  &.country {
    input[type='text'] {
      cursor: not-allowed;
    }
    &.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
    }
  }
  &.Phone {
    /* width: 50%; */
    /* margin-bottom: 20px; */
    @media screen and (max-width: 640px) {
      margin-bottom: 20px;
    }
  }
  input[type='text'] {
    cursor: pointer;
  }
  @media screen and (max-width: 640px) {
    width: 100%;
  }
`
const Contact = styled.h6`
  /* width: 100%; */
  font-size: 15px;
  color: ${THEME_COLORS.background_1};
  font-family: 'poppins-medium';
  /* margin-bottom: 20px; */
`
const BottomContainer = styled.div``
const DefaultButton = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`
const Button = styled.div`
  background-color: ${THEME_COLORS.secondary};
  display: flex;
  justify-content: center;
  border-radius: 6px;
  width: 100%;
  padding: 12px 0;
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${THEME_COLORS.white};
  cursor: pointer;
`
const Checkbox = styled.div`
  margin-right: 10px;
  width: 18px;
  height: 18px;
  border: 1px solid ${THEME_COLORS.background_3};
  border-radius: 3px;
  cursor: pointer;
  &.checked {
    border-color: ${THEME_COLORS.secondary};
  }
`
const Tick = styled.img`
  height: 100%;
  width: 100%;
`
const Text = styled.p`
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${THEME_COLORS.background_5};
`
const ListDown = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 25px;
`
const ListIcon = styled.img``
const DropDowns = styled.div`
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 8px;
  margin: 5px;
  font-size: 16px;
  font-family: 'poppins-regular';
  color: ${THEME_COLORS.text_title};
  transition: all 0.2s;
  :hover {
    background: ${THEME_COLORS.light_200};
  }
`
const DropList = styled.div`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 0;
  overflow: hidden;
  transition: all 0.5 ease-in-out;
  border-color: rgba(0, 0, 0, 0.23);
  border: 1px solid;
  /* border-width: 1px; */
  &.view-modal {
    height: 250px;
    transition: all 0.5 ease-in-out;
    box-shadow: ${THEME_COLORS.elevation_user_u4};
  }
  position: absolute;
  z-index: 10;
  width: 100%;
  top: 70px;
  left: 0;
  border-radius: 5px;
  background-color: ${THEME_COLORS.white};
  max-height: 400px;
  overflow-y: scroll;
`
const ErrorDiv = styled.p`
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: red;
`
