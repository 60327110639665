import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'
import Slider from '@mui/material/Slider'

import Checkbox from 'react-custom-checkbox'

import search_icon from '../../../assets/icons/search-normal.svg'
import { Context } from '../../../contexts/Store'
import { baseConfig } from '../../../axiosConfig'
import { useParams } from 'react-router-dom'

export default function Filter({
  search,
  setSearch,
  filteredList,
  setFilteredList,
  setFilterPagination,
  setFilterData,
  filterData,
  setOpenFilter,
}) {
  const [range, setRange] = useState([0, 3000])
  const [searchValue, setSearchValue] = useState('')
  const [isLoading, setLoading] = useState(true)
  const { state } = useContext(Context)
  const [SubCategoryList, setSubCategoryList] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [totalCountOfItems, setTotalCountOfItems] = useState('')
  const [minPrice, setMinPrice] = useState('0')
  const [maxPrice, setMaxPrice] = useState('3000')
  const [list, setList] = useState(categoryList)
  const [currentList, setCurrentList] = useState([])
  const [category, setCategory] = useState([])
  const [clickedIndex, setClickedIndex] = useState({})
  const [isCheckbox, setCheckbox] = useState(false)
  const [reminder, setReminder] = useState(false)

  const handleClick = (index) => () => {
    setClickedIndex((state) => ({
      ...state, // <-- copy previous state
      [index]: !state[index], // <-- update value by index key
    }))
  }

  const [activePage, setActivePage] = useState(1)
  const itemsPerPage = 10

  const { pk } = useParams()
  // const [id, setId] = useState([{}])
  const [selectedId, setSelectedId] = useState('')

  const indexOfLastItem = activePage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage

  const handleInputChange = (value) => {
    setSearchValue(value)
    setSearch(searchValue)
    setList(
      categoryList.category.filter((client) =>
        client.name.toLowerCase().startsWith(value.toLowerCase()),
      ),
      setActivePage(1),
    )
  }

  const handleSearch = () => {
    // You can use the searchValue state variable for further processing
  }

  function handleChanges(event, newValue) {
    setRange(newValue)
    setMinPrice(range[0] * 30)
    setMaxPrice(range[1] * 30)
  }

  let getCategory = () => {
    let accessToken = state.user_details.access
    setLoading(true)
    baseConfig
      .get('filter/get-categories/', {
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : null,
        },
      })
      .then((response) => {
        setCategoryList(response.data)
        setTotalCountOfItems(response.data.paginator.length)
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
      .catch((error) => {
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
  }

  // let getSubCategory = () => {
  //   let accessToken = state.user_details.access
  //   setLoading(true)
  //   baseConfig
  //     .get(`filter/sub-categories/?catagory=${id}`, {
  //       headers: {
  //         Authorization: accessToken ? `Bearer ${accessToken}` : null,
  //       },
  //     })
  //     .then((response) => {
  //       setSubCategoryList(response.data)

  //       setTimeout(() => {
  //         setLoading(false)
  //       }, 100)
  //     })
  //     .catch((error) => {
  //       setTimeout(() => {
  //         setLoading(false)
  //       }, 100)
  //     })
  // }
  // const handleFilter = () => {
  //   if (filteredList.length == 0) {
  //     console.log('suceccss')
  //     setFilterData(false)
  //     console.log(filterData, 'filterData in if')
  //   } else {
  //     console.log(filterData, 'filterData in else')
  //     console.log('failed')
  //   }

  // }

  let getFilterList = () => {
    let accessToken = state.user_details.access
    let catagory = selectedId.toString(selectedId)
    setLoading(true)
    if (selectedId.length > 0) {
      baseConfig
        .get('filter/filter/', {
          headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : null,
          },
          params: {
            category: catagory,
            min_price: minPrice,
            max_price: maxPrice,
          },
        })
        .then((response) => {
          const { StatusCode, data } = response?.data
          if (StatusCode === 6000) {
            // handleFilter()
            setFilterData(StatusCode)
            setFilteredList(response.data.results)
            setTotalCountOfItems(response.data.paginator.count)
          }

          setTimeout(() => {
            setLoading(false)
          }, 100)
        })
        .catch((error) => {
          setTimeout(() => {
            setLoading(false)
          }, 100)
        })
    } else {
      baseConfig
        .get('filter/filter/', {
          headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : null,
          },
          params: {
            min_price: minPrice,
            max_price: maxPrice,
          },
        })
        .then((response) => {
          const { StatusCode, data } = response?.data
          if (StatusCode === 6000) {
            // handleFilter()
            setFilterData(StatusCode)
            setFilteredList(response.data.results)
            setTotalCountOfItems(response.data.paginator.count)
          }

          setTimeout(() => {
            setLoading(false)
          }, 100)
        })
        .catch((error) => {
          setTimeout(() => {
            setLoading(false)
          }, 100)
        })
    }
  }

  useEffect(() => {
    setCurrentList(list.slice(indexOfFirstItem, indexOfLastItem))
    // console.log(currentList, '-----currentList-----')
  }, [list])

  useEffect(() => {
    getCategory()
    window.scrollTo(0, 0)
  }, [])

  const handleItemClick = (value) => {
    if (!selectedId.includes(value)) {
      setSelectedId([...selectedId, value])
      // console.log(selectedId, 'selectedId')
    }
  }
  // console.log(currentList, '-----currentList-----')
  // console.log(categoryList, '-----categoryList-----')

  return (
    <>
      <MainContainer className="filter">
        <Head>
          <Heading>Filter By</Heading>
          <Button
            onClick={() => {
              setRange([0, 3000])
              setSearchValue('')
              setCurrentList('')
              setFilteredList('')
              setCheckbox(true)
              setFilterData('')
              setSelectedId('')
              window.location.reload()
              getCategory()
            }}
          >
            Reset
          </Button>
        </Head>
        <FilterBox>
          <Box>
            <CategoryContainer>
              <FilterHeading
                onClick={() => {
                  handleSearch()
                }}
              >
                Category
              </FilterHeading>
              <SearchContainer>
                <SearchIcon
                  onClick={() => {
                    handleSearch()
                  }}
                  src={search_icon}
                  alt="search icon"
                />
                <SearchInput
                  value={searchValue}
                  onChange={(e) => handleInputChange(e.target.value)}
                  placeholder="Search"
                  type="text"
                  id="search"
                  inputProps={{
                    maxLength: 10
                  }}
                />
              </SearchContainer>
              {currentList?.length != 0 ? (
                <>
                  {currentList?.map((category) => (
                    <CheckboxContainer
                      key={category?.id}
                      onClick={() => {
                        handleItemClick(category?.id)
                      }}
                    >
                      <CheckContainer
                        onClick={() => {
                          handleClick(category.name)
                        }}
                      >
                        <Checkbox
                          isCheckbox={isCheckbox}
                          checked={false}
                          icon={
                            <img
                              src={
                                require('../../../assets/icons/tick-square.svg')
                                  .default
                              }
                              style={{
                                width: 23,
                              }}
                              alt="Tick Icon"
                            />
                          }
                          borderColor="#DBDBDB"
                          borderRadius={3}
                          borderWidth={1.7}
                        />
                        <CheckboxName>{category.name} </CheckboxName>
                      </CheckContainer>
                      <Count>{category.products_count}</Count>
                    </CheckboxContainer>
                  ))}
                </>
              ) : (
                <>
                  {categoryList?.category?.map((category) => (
                    <CheckboxContainer
                      key={category.id}
                      onClick={() => {
                        handleItemClick(category?.id)
                      }}
                    >
                      <CheckContainer
                        onClick={() => {
                          handleClick(category.name)
                          // setCheckbox(true)
                        }}
                      >
                        <Checkbox
                          // checked={reminder}
                          // onChange={(e) => setReminder(e.currentTarget.checked)}
                          checked={false}
                          icon={
                            <img
                              src={
                                require('../../../assets/icons/tick-square.svg')
                                  .default
                              }
                              style={{
                                width: 23,
                              }}
                              alt="Tick Icon"
                            />
                          }
                          borderColor="#DBDBDB"
                          borderRadius={3}
                          borderWidth={1.7}
                        />
                        <CheckboxName>{category.name} </CheckboxName>
                      </CheckContainer>
                      <Count>{category.products_count}</Count>
                    </CheckboxContainer>
                  ))}
                </>
              )}
            </CategoryContainer>
          </Box>
          <Box>
            <PriceContainer>
              <FilterHeading>Price</FilterHeading>
              <Slider
                value={range}
                onChange={handleChanges}
                valueLabelDisplay="off"
                sx={{
                  '& .MuiSlider-track': {
                    border: '2px solid #106F39',
                    color: 'success.main',
                  },
                  '& .MuiSlider-thumb:before': {
                    boxShadow: 'none',
                  },
                  '& .MuiSlider-thumb:hover,.MuiSlider-thumb.Mui-focusVisible': {
                    boxShadow: '0px 0px 0px 8px rgb(42 111 65 / 16%)',
                  },
                  '& .MuiSlider-thumb': {
                    backgroundColor: '#fff',
                    border: '1.5px solid #106f39',
                  },
                  '& .MuiSlider-rail': {
                    backgroundColor: '#BBBBBB',
                  },
                }}
              />
              <PriceRange>
                {minPrice ? (
                  <Range>Min: ₹ {minPrice} </Range>
                ) : (
                  <Range>Min: ₹0 </Range>
                )}
                {maxPrice ? (
                  <Range>Max: ₹ {maxPrice} </Range>
                ) : (
                  <Range>Max: ₹3000 </Range>
                )}
              </PriceRange>
            </PriceContainer>
          </Box>
          <ButtonDiv>
            <InsideButton
              className="active"
              onClick={() => {
                getFilterList()
                setOpenFilter(false)
              }}
            >
              Apply Filter
            </InsideButton>
          </ButtonDiv>
        </FilterBox>
      </MainContainer>
    </>
  )
}
const MainContainer = styled.div`
  border-radius: 6px;
  /* width: 25%; */
  background: #f4f4f4;
  padding: 10px 6px;
  bottom: 0;
  left: 0;
  top: 0;
  /* width: 25%; */
  transition: all 2s ease;
  /* height: 815px; */
  height: -webkit-fill-available;
  overflow-y: scroll;
  z-index: 1;
  ::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: 1280px) {
    position: absolute;
    width: 25%;
    height: fit-content;
    right: 18px;
    left: unset;
    z-index: 3;
    /* top: 29%; */
    top: 770px;
    -webkit-transition: width 2s ease;
    transition: width 2s ease;
  }
  @media screen and (max-width: 1080px) {
    width: 30%;
    top: 870px;
  }

  @media screen and (max-width: 980px) {
    /* top: 7%; */
    top: 135px;
    right: 0px;
    width: 35%;
  }
  @media screen and (max-width: 768px) {
    width: 40%;
  }
  @media screen and (max-width: 640px) {
    width: 65%;
  }
  @media screen and (max-width: 480px) {
    width: 75%;
    top: 195px;
    left: 0px;
  }
  @media screen and (max-width: 360px) {
    width: 85%;
  }
`
// const SubContainer = styled.div``;
const Head = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px;
`
const Heading = styled.h4`
  font-family: 'poppins-medium';
`
const Button = styled.div`
  font-family: 'poppins-regular';
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  background: #fff;
  border-radius: 22px;
  padding: 6px 16px;
  border: 1px solid ${THEME_COLORS.border_3};
`
const FilterBox = styled.div`
  border-radius: 6px;
  background: #fff;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  /* position: absolute; */
  top: 65px;
  left: 4px;
  right: 0;
  bottom: 10px;
  overflow-y: scroll;
  /* width: 24.4%; */
  /* width: 97.5%; */
  @media screen and (max-width: 980px) {
  }
`
const Box = styled.div`
  border-bottom: 1px solid #f0f0f0;
  :last-child {
    border: none;
  }
`
const GenderContainer = styled.div`
  padding: 25px 14px;
`
const FilterHeading = styled.h4`
  font-family: 'poppins-semibold';
  font-size: 16px;
  margin-bottom: 20px;
`
const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  :last-child {
    margin-bottom: 0;
  }
`
const Count = styled.span`
  font-size: 14px;
  font-family: 'poppins-regular';
  color: ${THEME_COLORS.background_5};
`

const CheckContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`
const CheckboxName = styled.h5`
  color: ${THEME_COLORS.background_2};
  font-size: 16px;
  line-height: 24px;
  font-family: 'poppins-regular';
  width: max-content;
  margin-left: 10px;
  cursor: default;
`
const CategoryContainer = styled.div`
  padding: 25px 14px;
`

const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'poppins-regular';
  width: 100%;
  height: 35px;
  border: 1px solid ${THEME_COLORS.border_3};
  border-radius: 8px;
  padding: 0px 0px 0px 12px;
  overflow: hidden;
  margin-bottom: 20px;
  &:focus-within {
    background-color: ${THEME_COLORS.white};
  }
`
const SearchInput = styled.input`
  font-family: 'poppins-regular';
  font-size: 14px;
  color: ${THEME_COLORS.text_1};
  width: 100%;
  height: 100%;
`
const SearchIcon = styled.img`
  width: 18px;
  display: inline-block;
  margin-right: 8px;
`

const PriceContainer = styled.div`
  padding: 18px 20px 25px;
`
const PriceRange = styled.div`
  display: flex;
  justify-content: space-between;
`
const Range = styled.div`
  font-family: 'poppins-regular';
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  background: #fff;
  border-radius: 9px;
  padding: 8px;
  border: 1px solid ${THEME_COLORS.border_3};
  width:110px;
`

const DiscountContainer = styled.div`
  padding: 18px 14px 5px;
`
const RadioButton = styled.div``
const Label = styled.div`
  cursor: default;
  --radio: #dbdbdb;
  --radio-checked: #106f39;
  border-radius: 10px;
  color: #414856;
  position: relative;
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin-bottom: 20px;
`
const Input = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  height: 20px;
  width: 20px;
  outline: none;
  /* margin: 0; */
  margin-right: 10px;
  cursor: pointer;
  border: 1.7px solid var(--radio);
  background: transparent;
  border-radius: 50%;
  display: grid;
  justify-self: end;
  justify-items: center;
  align-items: center;
  overflow: hidden;
  transition: border 0.5s ease;
  &::before,
  &::after {
    content: '';
    display: flex;
    justify-self: center;
    border-radius: 50%;
  }
  ::before {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: var(--opacity, 1);
  }
  &::after {
    position: relative;
    width: calc(100% / 1.8);
    height: calc(100% / 1.8);
    background: #106f39;
    top: var(--y, 100%);
  }
  &:checked {
    --radio: #106f39;
    &::after {
      --y: 0%;
    }
    &::before {
      --opacity: 0;
    }
    ~ input[type='radio'] {
      &::after {
        --y: -100%;
      }
    }
  }
  &:not(:checked) {
    &::before {
      --opacity: 1;
    }
  }
`
const Div2 = styled.div`
  display: flex;
  align-items: center;
  /* gap: 80px; */
  /* justify-content: space-between; */
`
const RadiobuttonName = styled.label`
  color: ${THEME_COLORS.background_2};
  font-size: 16px;
  line-height: 24px;
  font-family: 'poppins-regular';
  width: max-content;
`

const BrandContainer = styled.div`
  padding: 18px 14px 20px;
`
const InsideButton = styled.a`
  width: max-content;
  display: inline-block;
  font-size: 12px;
  font-family: 'poppins-medium';
  /* height: 46px; */
  padding: 8px 15px;
  cursor: pointer;
  color: ${THEME_COLORS.white};
  background: ${THEME_COLORS.secondary};
  border-radius: 6px;
  /* @media screen and (max-width: 980px) {
   
  }
  @media screen and (max-width: 868px) {
    min-width: max-content;
    padding: 11px 22px;
  }
  @media screen and (max-width: 640px) {
    font-size: 13px;
  }
  @media screen and (max-width: 480px) {
    height: 40px;
  } */
`
const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`
