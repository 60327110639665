import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import close from './../../../../assets/icons/close-square.svg'
import medicine from './../../../../assets/images/medicine1.png'
import medicine2 from './../../../../assets/images/medicine2.png'
import { Link, useNavigate } from 'react-router-dom'
import { baseConfig } from '../../../../axiosConfig'
import { Context } from '../../../../contexts/Store'
import { AuthContext } from '../../../../contexts/AuthStore'

export default function PaymentConfirmation({}) {
  const { state, dispatch } = useContext(Context)
  const { authdispatch } = useContext(AuthContext)
  const [shippingFee, setSippingFee] = useState('0')
  const [confirmOrderData, setconfirmOrderData] = useState([])
  const [errormessage, setErrormessage] = useState('')

  // console.log(localStorage.order_details, 'localStorage')

  var retrievedValue = localStorage.getItem('order_details')
  // console.log(retrievedValue, 'retrievedValue')

  var parsedObject = JSON.parse(retrievedValue)

  // console.log('Retrieved Value (Parsed JSON):', parsedObject.orderId)

  var newOrderId = parsedObject?.orderId


  // Create a Date object from the input string

  const dateString = confirmOrderData[0]?.time

  const date = new Date(dateString)

  // Get day, month, and year components from the Date object
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0') // Note: Month is zero-based, so add 1
  const year = date.getFullYear()

  // Format the date as "dd-mm-yyyy"
  const formattedDate = `${day}-${month}-${year}`

  // console.log(formattedDate) // Output: "29-09-2023"

  const CreateTransaction = () => {
    let access_token = state.user_details.access
    baseConfig
      .get(`payments/check-payment-status/${newOrderId}/`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })

      .then((response) => {
        const { data, StatusCode } = response.data
        if (StatusCode === 6000) {
          setconfirmOrderData(data)
        } else {
          setErrormessage(response.data.message)
        }
      })
      .catch((error) => {
        setTimeout(() => {
          // setLoading(false)
        }, 100)
      })
    // }, 100);
  }
  useEffect(() => {
    CreateTransaction()
  }, [])

  // console.log(confirmOrderData[0], 'Totalamount')

  return (
    <MainContainer>
      {confirmOrderData?.length != 0 ? (
        <>
          <TopContainer key={confirmOrderData.pk}>
            <Titlecontainer>
              <Heading>Your Order Confirmed!</Heading>
            </Titlecontainer>

            <Name>Hello {confirmOrderData[0].customer_name}</Name>
            <Message>
              Your Order has been confirmed and will be shipping within next
              seven days.
            </Message>
            {/* </Titlecontainer> */}
          </TopContainer>
          <OrderDetails>
            <Details>
              <Title>Order Date</Title>
              <Info>{formattedDate}</Info>
            </Details>
            <Details>
              <Title>Order No</Title>
              <Info>{confirmOrderData[0].order_id}</Info>
            </Details>
            {/* <Details>
              <Title>Pyment</Title>
              <Info>03 Nav 2022</Info>
            </Details> */}
            <Details className="shipping-address">
              <Title>Shipping Address</Title>
              <Info>
                {confirmOrderData[0]?.address.address}, address,
                {confirmOrderData[0]?.address.pincode}, pincode,
                {confirmOrderData[0]?.address.location},location
              </Info>
            </Details>
          </OrderDetails>
          {confirmOrderData[0]?.order_items?.map((product) => (
            <ProductDetails>
              <SingleItem>
                <ItemLeft>
                  <ImageContainer>
                    <Img src={product.image} alt="Image" />
                  </ImageContainer>
                  <Product>
                    <Name>{product.product_name}</Name>
                    <PackDetail>Pack of {product.pack}</PackDetail>
                    <PackDetail>{product.quantity_in_pack} Capsules</PackDetail>
                  </Product>
                </ItemLeft>
                <ItemRight>
                  <Price>₹ {product.subtotal}</Price>
                </ItemRight>
              </SingleItem>
            </ProductDetails>
          ))}

          <PriceTable>
            <PriceDetails>
              <PriceTop>
                <PriceName>Subtotal</PriceName>
                <Price>₹{confirmOrderData[0]?.total_amount}</Price>
              </PriceTop>
              <PriceTop>
                <PriceName>Shipping Fee</PriceName>
                <Price>₹{shippingFee}.00</Price>
              </PriceTop>
              {/* <PriceTop>
                        <PriceName>Discount</PriceName>
                        <Price>₹ 36.00</Price>
                    </PriceTop> */}

              <PriceBottom>
                <Total>Total</Total>
                <TotalPrice>₹{confirmOrderData[0]?.total_amount}</TotalPrice>
              </PriceBottom>
            </PriceDetails>
          </PriceTable>
          <BottomContainer>
            <Paragraph>
              We'll be sending a shipping confirmation the items shipped
              successfully.
            </Paragraph>
          </BottomContainer>
          <TrackyourOrder to="/order-status">Track Your Order</TrackyourOrder>
        </>
      ) : (
        <ErrorMessage>Payment is not completed </ErrorMessage>
      )}
    </MainContainer>
  )
}
const MainContainer = styled.div`
  width: 80%;
  padding: 120px 30px;
  /* height: 100vh; */
  margin: 0 auto;

  @media screen and (max-width: 1280px) {
    width: 70%;
  }
  @media screen and (max-width: 1080px) {
    width: 80%;
  }
  @media screen and (max-width: 980px) {
  }
  @media screen and (max-width: 768px) {
    width: 90%;
  }
  @media screen and (max-width: 360px) {
    width: 100%;
  }
`
const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* margin-bottom: 32px; */
  border-bottom: 1px solid #bbbbbb;
  padding-bottom: 26px;
`
const Titlecontainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Heading = styled.h3`
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  color: #0fc642;
  @media screen and (max-width: 640px) {
    font-size: 20px;
  }
`
const Image = styled.div`
  width: 24px;
  cursor: pointer;
`
const Img = styled.img`
  width: 100%;
  display: block;
`
const Name = styled.p`
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #161616;
  margin-bottom: 10px;
  @media screen and (max-width: 640px) {
    font-size: 18px;
  }
`
const Message = styled.p`
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #464646;
  @media screen and (max-width: 640px) {
    font-size: 12px;
  }
`
const OrderDetails = styled.div`
  display: flex;
  border-bottom: 1px solid #bbbbbb;
  justify-content: space-between;
  padding: 26px 0;
  @media screen and (max-width: 980px) {
    flex-direction: column;
    gap: 19px;
  }
`
const Details = styled.div`
  &.shipping-address {
    width: 30%;
  }
  @media screen and (max-width: 980px) {
    &.shipping-address {
      width: 40%;
    }
  }
  @media screen and (max-width: 640px) {
    &.shipping-address {
      width: 40%;
    }
  }
  @media screen and (max-width: 640px) {
    width: 48%;
    &.shipping-address {
      width: 48%;
    }
  }
  @media screen and (max-width: 480px) {
    &.shipping-address {
      width: 100%;
    }
  }
`
const Title = styled.p`
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #6b6b6b;
  @media screen and (max-width: 640px) {
    font-size: 13px;
  }
`
const Info = styled.p`
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #464646;
  @media screen and (max-width: 640px) {
    font-size: 12px;
  }
`
const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  border-bottom: 1px solid #bbbbbb;
  padding: 26px 0;
`
const SingleItem = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    gap: 30px;
  }
`
const ItemLeft = styled.div`
  display: flex;
  gap: 20px;
`
const ImageContainer = styled.div`
  width: 93px;
  border-radius: 8px;
  /* @media screen and (max-width: 1280px) {
    display: none;
  } */
`
const Product = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const PackDetail = styled.p`
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  color: #6b6b6b;
  @media screen and (max-width: 640px) {
    font-size: 14px;
  }
`
const ItemRight = styled.div`
  width: 30%;
  @media screen and (max-width: 1280px) {
    width: 40%;
  }
  @media screen and (max-width: 640px) {
    width: max-content;
  }
`
const Price = styled.td`
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #161616;
  @media screen and (max-width: 640px) {
    font-size: 18px;
  }
`
const PriceTable = styled.table`
  padding: 22px 0;
  display: flex;
  justify-content: end;
  @media screen and (max-width: 980px) {
    justify-content: flex-start;
  }
`
const PriceDetails = styled.table`
  /* display: flex; */
  /* justify-content: end; */
  width: 40%;
  align-items: flex-end;
  flex-direction: column;
  border-bottom: 1px solid #aaaaaa;
  @media screen and (max-width: 1280px) {
    width: 50%;
  }
  @media screen and (max-width: 980px) {
    width: 60%;
  }
  @media screen and (max-width: 640px) {
    width: 80%;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`
const PriceTop = styled.tr`
  /* display: flex; */
`
const PriceName = styled.td`
  font-family: 'poppins-regular';
  font-style: normal;
  padding: 7px 0;
  font-weight: 500;
  font-size: 20px;
  color: #6b6b6b;
  @media screen and (max-width: 640px) {
    font-size: 18px;
  }
`
const PriceBottom = styled.tr`
  width: 100%;
`
const Total = styled.td`
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #161616;
  padding: 16px 0;
  border-right: none;
  @media screen and (max-width: 640px) {
    font-size: 18px;
  }
`
const TotalPrice = styled.td`
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #161616;
  padding: 16px 0;
  @media screen and (max-width: 640px) {
    font-size: 18px;
  }
`
const BottomContainer = styled.div``
const Paragraph = styled.p`
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #464646;
  text-align: center;
  @media screen and (max-width: 640px) {
    font-size: 14px;
  }
`
const TrackyourOrder = styled(Link)`
  /* width: 200px; */
  padding: 10px;
  background-color: #106f39;
  color: #fff;
  font-size: 16px;
  font-family: 'poppins-medium';
  border-radius: 6px;
  text-align: center;
  display: inline-block;
  width: 100%;
  margin-top: 20px;
`
const ErrorMessage = styled.p`
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  text-align: center;
  color: #464646;
  padding: 100px;
  @media screen and (max-width: 640px) {
    font-size: 12px;
  }
`
