import React from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'
import about from '../../../assets/images/promise.png'
import tick from '../../../assets/images/check.svg'

function Promise() {
  return (
    <>
      <MainContainer>
        {' '}
        <AboutTop className="intro">
          <ImageCOntainer>
            <Image className="intro">
              <Img src={about} alt="Image" />
            </Image>
          </ImageCOntainer>
          <Content>
            <Title>
              <span>Our </span> Promise
            </Title>
            <Container>
              <CheckContainer>
                <Tick src={tick} alt="Image" />
              </CheckContainer>
              <SubTitle>
                {' '}
                To uphold the principles of a clean and green environment
              </SubTitle>
            </Container>
            <Container>
              <CheckContainer>
                <Tick src={tick} alt="Image" />
              </CheckContainer>
              <SubTitle>
                To ensure highest quality, safe, efficacious and cost effective
                products to our customers
              </SubTitle>
            </Container>
            <Container>
              <CheckContainer>
                <Tick src={tick} alt="Image" />
              </CheckContainer>
              <SubTitle>
                To fuel growth and provide opportunities for our stock holders
              </SubTitle>
            </Container>
            <Container>
              <CheckContainer className="last">
                <Tick src={tick} alt="Image" />
              </CheckContainer>
              <SubTitle>Real time response time</SubTitle>
            </Container>
          </Content>
        </AboutTop>
      </MainContainer>
    </>
  )
}

export default Promise
const MainContainer = styled.div`
  background-color: #f5fff9;
  width: 100%;
  padding: 100px 0;
  @media screen and (max-width: 1080px) {
    padding: 70px 0;
  }
  @media screen and (max-width: 980px) {
    padding: 50px 0;
  }
`
const AboutTop = styled.div`
  width: 80%;
  max-width: 1350px;
  margin: 0 auto;
  display: flex;
  gap: 60px;
  align-items: center;

  &.first {
    margin: 70px 0 0 0;
    align-items: center;
  }

  @media screen and (max-width: 980px) {
    flex-direction: column-reverse;
    /* margin: 60px 0; */
    gap: 30px;
    &:nth-child(2) {
      flex-direction: column;
    }
  }
`
const Content = styled.div`
  width: 60%;
  @media screen and (max-width: 980px) {
    width: 100%;
  }
`
const Title = styled.h4`
  font-family: 'poppins-medium';
  color: #1d1d46;
  font-size: 40px;
  margin-bottom: 35px;
  span {
    color: #106f39;
  }
  @media screen and (max-width: 1280px) {
    font-size: 34px;
  }
  @media screen and (max-width: 1080px) {
    font-size: 32px;
  }
  @media screen and (max-width: 980px) {
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 25px;
    font-size: 30px;
  }
  @media screen and (max-width: 480px) {
    font-size: 26px;
  }
`
const SubTitle = styled.p`
  color: #1d1d46;
  font-family: 'poppins-regular';
  font-size: 18px;
  @media screen and (max-width: 980px) {
    font-size: 16px;
  }
  @media screen and (max-width: 640px) {
    font-size: 14px;
    /* text-align: justify; */
  }
`

const ImageCOntainer = styled.div`
  width: 40%;
  @media screen and (max-width: 1280px) {
    width: 50%;
  }
  @media screen and (max-width: 1080px) {
    width: 55%;
  }
  @media screen and (max-width: 980px) {
    margin: 0 auto;
    width: 70%;
  }
  @media screen and (max-width: 480px) {
    width: 85%;
  }
  @media screen and (max-width: 360px) {
    width: 95%;
  }
`
const Image = styled.div`
  width: 100%;
`
const Img = styled.img`
  width: 100%;
  display: block;
`
const CheckContainer = styled.div`
  /* width: 30px;
  height: 30px; */
  margin-right: 10px;
  /* &.last {
    margin-right: 0px;
  } */
`

const Tick = styled.img`
  /* width: 100%;
  display: block; */
`
const Container = styled.div`
  display: flex;
  align-items: baseline;
  /* justify-content: flex-start; */
  /* width: 500px; */
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0px;
  }
`
