import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { baseConfig } from '../../../axiosConfig'
import { Context } from '../../../contexts/Store'
import CategoriesCard from '../../includes/CategoriesCard/CategoriesCard'
import EmptyPage from '../../includes/empty-page/EmptyPage'
import empytWishlist from './../../../assets/icons/empty-wishlist.svg'
import DefaultLottie from '../../includes/common/loader/DefaultLottie'

export default function WishList() {
  const [productList, setProductList] = useState([])
  const { state } = useContext(Context)
  const [isLoading, setLoading] = useState(true)

  //get products
  let getWhishLlist = () => {
    setLoading(true)
    let accessToken = state.user_details.access
    baseConfig
      // .get("wishlists/wishlist-list/", {
      .get('products/products-list/', {
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : null,
        },
      })
      .then((response) => {
        const { data, StatusCode } = response.data
        if (StatusCode === 6000) {
          let filter_data = data.filter((item) => item.is_wishlist && item)
          setProductList(filter_data)
          setTimeout(() => {
            setLoading(false)
          }, 100)
        }
      })
      .catch((error) => {
        const { status, data } = error?.response
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
  }

  useEffect(() => {
    getWhishLlist()
  }, [])

  return (
    <>
      {!isLoading ? (
        <MainContainer>
          <WishListContainer>
            <TitleName>Wishlist</TitleName>
            <>
              {productList.length > 0 ? (
                <CategoryList>
                  {productList.map((data, i) => (
                    <CategoriesCard
                      refreshApi={getWhishLlist}
                      data={data}
                      key={i}
                    />
                  ))}
                </CategoryList>
              ) : (
                <EmptyContainer>
                  <EmptyPage
                    image={empytWishlist}
                    status={'Your Wishlist is Empty !'}
                  />
                </EmptyContainer>
              )}
            </>
          </WishListContainer>
        </MainContainer>
      ) : (
        <DefaultLottie />
      )}
    </>
  )
}
const MainContainer = styled.div`
  width: 90%;
  max-width: 1350px;
  margin: 0 auto;
  padding-bottom: 55px;
  padding-top: 120px;
  @media screen and (max-width: 980px) {
    padding-top: 80px;
  }
`
const WishListContainer = styled.div`
  /* padding-top: 70px; */
  @media screen and (max-width: 480px) {
    /* padding-top: 10px; */
  }
`
const TitleName = styled.h3`
  font-family: 'poppins-medium';
  font-size: 32px;
  line-height: 48px;
  margin-bottom: 32px;
  @media screen and (max-width: 980px) {
    font-size: 28px;
  }
  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
`
const CategoryList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  @media screen and (max-width: 1280px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (max-width: 980px) {
    grid-template-columns: 1fr 1fr;
  }
  /* @media screen and (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
    } */
  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
  }
  /* @media screen and (max-width: 480px) {
        grid-template-columns: 1fr;
    } */
`
const EmptyContainer = styled.div`
  min-height: calc(100vh - 410px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
