import React from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'

function Button({ text, onClick, size, className }) {
  return (
    <MainContainer className={className} onClick={onClick} size={size}>
      {text}
    </MainContainer>
  )
}

const MainContainer = styled.div`
    padding: ${(props) => (props.size === 'small' ? '12px 24px' : '16px 32px')};
    cursor: pointer;
    text-align: center;
    color: ${THEME_COLORS.secondary};
    font-family: "poppins-medium";
    border-radius: 6px;
    width: max-content;
    background-color: ${THEME_COLORS.white};
    border: 2px solid ${THEME_COLORS.secondary};
    /* &:hover {
        background-color: ${THEME_COLORS.secondary};
        color: ${THEME_COLORS.white};
    } */
    font-size: ${(props) => (props.size === 'small' ? '14px' : '16px')};
    font-weight: ${(props) => (props.size === 'small' ? '500' : '600')};
    &.category_button {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
    }
     
    &.not-available{
      filter: opacity(0.5);
      cursor: not-allowed;
       width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
    }
`
export default Button
