import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import coverpic from '../../../assets/images/product-spot.jpg'
import coverSecond from '../../../assets/images/productspotlight-mobile.png'
import { THEME_COLORS } from '../../../ThemeConfig'
import CategoriesCard from '../../includes/CategoriesCard/CategoriesCard'
import Filter from '../../includes/filter/Filter'
import Tabs from '../../includes/tabs/Tabs'
import offer from '../../../assets/images/Group.png'
import { Context } from '../../../contexts/Store'
import { baseConfig } from '../../../axiosConfig'
import Pagination from '../../includes/pagination/Pagination'
import spotlightimg from '../../../assets/images/wepik-photo-mode.png'
import spotlightresp from '../../../assets/images/product-spot-res.png'
import filter_icon from '../../../assets/icons/filter-list.svg'
import DefaultLottie from '../../includes/common/loader/DefaultLottie'
import NoResultFound from '../../includes/not_found/NoResultFound'
import { useRef } from 'react'

export default function ProductList() {
  const [isLoading, setLoading] = useState(true)
  const [pagination, setPagination] = useState(true)
  const refObj = useRef()
  const [openFilter, setOpenFilter] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [countShow, setCountShow] = useState(9)
  const [totalCountOfItems, setTotalCountOfItems] = useState('')
  const [filterPagination, setFilterPagination] = useState('')

  const [clickedTab, setClickedTab] = useState('')
  const [search, setSearch] = useState('')

  // pagination
  const [activePage, setActivePage] = useState(1)
  const [currentList, setCurrentList] = useState([])
  const itemsPerPage = 6
  const indexOfLastItem = activePage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const [filteredList, setFilteredList] = useState([])
  const [filterData, setFilterData] = useState('')
  const [productSpotlight, setProductSpotlight] = useState([])
  const { state } = useContext(Context)
  const [productCategoryList, setproductCategoryList] = useState([])
  const [productList, setProductList] = useState([])

  let ProductData = (token) => {
    setLoading(true)
    baseConfig
      .get('general/spotlight/', {
        params: {
          page: 20,
        },
      })
      .then((response) => {
        setProductSpotlight(response.data.data)
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
      .catch((error) => {
        const { status, data } = error?.response
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
  }

  let productCategoryData = (token) => {
    setLoading(true)
    baseConfig
      .get('products/category/', {})
      .then((response) => {
        const { data } = response.data
        setproductCategoryList(data)
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
      .catch((error) => {
        const { status, data } = error?.response
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
  }

  //get products
  let getProductsList = () => {
    let accessToken = state.user_details.access
    setLoading(true)
    if (clickedTab.length > 0) {
      baseConfig
        .get('products/products-list/', {
          headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : null,
          },
          params: {
            category: clickedTab,
            page: currentPage,
            items: countShow,
          },
        })
        .then((response) => {
          setProductList(response.data.data)
          setTotalCountOfItems(response.data.paginator)
          setTimeout(() => {
            setLoading(false)
          }, 100)
        })
        .catch((error) => {
          setTimeout(() => {
            setLoading(false)
          }, 100)
        })
    } else {
      baseConfig
        .get('products/products-list/', {
          headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : null,
          },
          params: {
            page: activePage,
            page_size: countShow,
          },
        })
        .then((response) => {
          setProductList(response.data.data)
          setTotalCountOfItems(response.data.paginator)
          setTimeout(() => {
            setLoading(false)
          }, 100)
        })
        .catch((error) => {
          setTimeout(() => {
            setLoading(false)
          }, 100)
        })
    }
  }

  useEffect(() => {
    getProductsList()
    productCategoryData()
  }, [clickedTab,activePage,countShow])

  useEffect(() => {
    ProductData()
    // window.scrollTo(0, 0)
  }, [])

    useEffect(() => {
      if (productList && Array.isArray(productList)) {
        setCurrentList(productList.slice(indexOfFirstItem, indexOfLastItem));
      }
    }, [productList, activePage]);


  const handleMouseover = (evt) => {
    console.log(evt.target, 'current')
    console.log((refObj.current.style.display = `none`), 'refObj current')
  }

  return (
    <MainContainer>
      {/* {!isLoading ? ( */}
        <div className="wrapper">
          <SpotLightBox>
            {productSpotlight?.image ? (
              <MainPage
                style={{
                  backgroundImage: `url(${coverpic})`,
                }}
                alt="Image"
              />
            ) : (
              <MainPage
                style={{
                  backgroundImage: `url(${coverpic})`,
                }}
                alt="Image"
              />
            )}
            {productSpotlight?.image ? (
              <MobileView
                style={{
                  backgroundImage: `url(${spotlightresp})`,
                }}
                alt="Image"
              />
            ) : (
              <MobileView
                style={{
                  backgroundImage: `url(${spotlightresp})`,
                }}
                alt="Image"
              />
            )}
          </SpotLightBox>
          <Categories>
            <TopContainer>
              <TitleBox id="products">Products</TitleBox>
            </TopContainer>
            <TabsSection>
              {productCategoryList?.length > 0 && (
                <Tabs
                  // setFilteredList={setFilteredList}
                  data={productCategoryList}
                  clickedTab={clickedTab}
                  setClickedTab={setClickedTab}
                />
              )}
              <FilterContainer>
                <ProductFilter
                  setOpenFilter={setOpenFilter}
                  onClick={() => {
                    setOpenFilter(!openFilter)
                  }}
                >
                  Filter
                  <FilterIcon src={filter_icon} />
                </ProductFilter>
                <OpenFilter className={openFilter ? 'active' : 'inactive'}>
                  <Filter
                    setOpenFilter={setOpenFilter}
                    filteredList={filteredList}
                    setFilteredList={setFilteredList}
                    setSearch={setSearch}
                    search={search}
                    setFilterPagination={setFilterPagination}
                    setFilterData={setFilterData}
                    filterData={filterData}
                  />
                </OpenFilter>
              </FilterContainer>
            </TabsSection>
            <ProductsView>
              <InnerContainer>
                <FilterBox>
                  <Filter
                    filteredList={filteredList}
                    setFilteredList={setFilteredList}
                    setSearch={setSearch}
                    search={search}
                    setFilterPagination={setFilterPagination}
                    setFilterData={setFilterData}
                    filterData={filterData}
                  />
                </FilterBox>
                {filterData == '' ? (
                  <CategoriesListContainer>
                    {productList?.map((data, i) => (
                      <CategoriesCard
                        refreshApi={getProductsList}
                        data={data}
                        key={i}
                      />
                    ))}
                  </CategoriesListContainer>
                ) : (
                  <>
                    {filteredList.length !== 0 ? (
                      <CategoriesListContainer>
                        {filteredList?.map((data, i) => (
                          <CategoriesCard
                            refreshApi={getProductsList}
                            data={data}
                            key={i}
                          />
                        ))}
                      </CategoriesListContainer>
                    ) : (
                      <CategoriesListContainer
                        onLoad={handleMouseover}
                        className={filteredList.length == 0 ? 'noData' : ''}
                      >
                        <NoResultFound />
                      </CategoriesListContainer>
                    )}
                  </>
                )}
              </InnerContainer>
            </ProductsView>
                  <PaginationBox ref={refObj} className="not">
                    <Pagination
                      paginationData={totalCountOfItems}
                      list={productList}
                      itemsPerPage={itemsPerPage}
                      activePage={activePage}
                      setActivePage={setActivePage}
                    />
                  </PaginationBox>
          </Categories>
        </div>
      {/* ) : (
        <DefaultLottie />
      )} */}
    </MainContainer>
  )
}

const MainContainer = styled.div`
  padding-top: 80px;
  text-transform: capitalize;
  @media screen and (max-width: 980px) {
    padding-top: 60px;
  }
`
const SpotLightBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 60px 0px;
  @media screen and (max-width: 980px) {
    margin: 30px 0;
  }
  @media screen and (max-width: 800px) {
    margin: 20px 0;
  }
  @media screen and (max-width: 768px) {
    margin: 0px;
  }
  @media screen and (max-width: 640px) {
    margin: 50px 0px;
  }
`
const MainPage = styled.div`
  background: url(${coverpic}) no-repeat center center;
  background-size: contain;
  height: 70vh;

  @media screen and (max-width: 1280px) {
    height: 60vh;
  }
  @media screen and (max-width: 1080px) {
    height: 55vh;
  }
  @media screen and (max-width: 980px) {
    height: 50vh;
  }
  @media screen and (max-width: 640px) {
    display: none;
  }
`
const MobileView = styled.div`
  display: none;
  background: url(${coverSecond}) no-repeat center center;
  background-size: 100% 100%;
  /* background-repeat: no-repeat;  */

  @media screen and (max-width: 640px) {
    display: block;
    height: 900px;
  }
  @media screen and (max-width: 500px) {
    height: 100vh;
  }
  @media screen and (max-width: 480px) {
    height: 100vh;
  }
  @media screen and (max-width: 360px) {
    height: 70vh;
  }
`

// const MiddleBox = styled.div`
//     width: 56%;
//     padding-top: 200px;
//     @media screen and (max-width: 980px) {
//         width: 84%;
//         padding-top: 150px;
//     }
//     @media screen and (max-width: 480px) {
//         width: 100%;
//         padding-top: 100px;
//     }
// `;
// const Title = styled.h3`
//     font-size: 52px;
//     line-height: 78px;
//     color: #d87400;
//     margin-bottom: 16px;
//     font-family: "poppins-medium";
//     @media screen and (max-width: 768px) {
//         font-size: 46px;
//         line-height: 60px;
//     }
//     @media screen and (max-width: 640px) {
//         font-size: 32px;
//         line-height: 46px;
//     }
//     @media screen and (max-width: 360px) {
//         font-size: 28px;
//         line-height: 42px;
//     }
// `;
// const Paragraph = styled.p`
//     color: #6b6b6b;
//     font-size: 24px;
//     line-height: 36px;
//     margin-bottom: 36px;
//     font-family: "poppins-medium";
//     @media screen and (max-width: 480px) {
//         font-size: 20px;
//         line-height: 31px;
//         margin-bottom: 29px;
//     }
//     @media screen and (max-width: 360px) {
//         font-size: 18px;
//         line-height: 30px;
//         margin-bottom: 25px;
//     }
// `;
const Categories = styled.div`
  margin-bottom: 48px;
  @media screen and (max-width: 980px) {
    position: relative;
  }
  @media screen and (max-width: 480px) {
    margin-top: 50px;
  }
`
const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  align-items: center;
`
const TitleBox = styled.h3`
  font-size: 32px;
  font-family: 'poppins-medium';
  color: ${THEME_COLORS.background_3};
  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
`
const TabsSection = styled.div`
  margin-bottom: 23px;
  /* new added */
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 480px) {
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }
`
const FilterContainer = styled.div`
  display: none;
  @media screen and (max-width: 1280px) {
    display: block;
  }
`
const ProductFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'poppins-regular';
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  background: #fff;
  border-radius: 22px;
  padding: 6px 16px;
  border: 1px solid ${THEME_COLORS.border_3};
  position: relative;
`
const FilterIcon = styled.img`
  margin-left: 8px;
`
const OpenFilter = styled.div`
  transition: all 2s ease;
  &.active {
    display: block;
  }
  &.inactive {
    display: none;
  }
`
const ProductsView = styled.div`
  position: relative;
`
const InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
const FilterBox = styled.div`
  width: 25%;
  @media screen and (max-width: 1280px) {
    display: none;
  }
`
const CategoriesListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  width: 73%;
  &.noData {
    display: grid;
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 1280px) {
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    grid-gap: 20px;
  }
  @media screen and (max-width: 1080px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (max-width: 980px) {
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }
  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
    width: 70%;
    margin: 0 auto;
  }
  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
    width: 85%;
    margin: 0 auto;
  }
  @media screen and (max-width: 360px) {
    grid-template-columns: 1fr;
    width: 95%;
    margin: 0 auto;
  }
`
const PaginationBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`
const BoxContainer = styled.div`
  margin-bottom: 93px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 480px) {
    margin-bottom: 55px;
  }
`
// const OfferPrice = styled.h4`
//     font-size: 12px;
//     line-height: 36px;
//     font-family: "poppins-medium";
// `;
// const PriceChange = styled.span`
//     font-size: 24px;
//     display: inline-block;
//     color: #d7615d;
//     font-family: "poppins-medium";
// `;
const SubContainer = styled.div`
  height: 400px;
  background: url(${offer});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  border-radius: 6px;
  @media screen and (max-width: 980px) {
    height: 355px;
  }
  @media screen and (max-width: 768px) {
    height: 290px;
  }
  @media screen and (max-width: 640px) {
    height: 230px;
  }
  @media screen and (max-width: 480px) {
    height: 144px;
  }
`
// const Img = styled.img`
//     width: 100%;
//     height: 100%;
//     display: block;
//     border-radius: 6px;
// `;
// const SubHeading = styled.h3`
//     font-family: "poppins-medium";
//     font-size: 52px;
//     line-height: 78px;
//     color: ${THEME_COLORS.background_1};
//     margin-bottom: 32px;
//     @media screen and (max-width: 768px) {
//         font-size: 42px;
//         line-height: 57px;
//     }
//     @media screen and (max-width: 480px) {
//         font-size: 30px;
//         line-height: 50px;
//     }
// `;
// const SpanHeading = styled.span`
//     display: inline-block;
//     font-size: 52px;
//     color: ${THEME_COLORS.secondary};
//     @media screen and (max-width: 768px) {
//         font-size: 42px;
//     }
//     @media screen and (max-width: 480px) {
//         font-size: 30px;
//     }
// `;
