const Reducer = (state, action) => {
  // console.log(state.confirmAddressId)
  switch (action.type) {
    case 'UPDATE_USER':
      const user_details = {
        ...state.user_details,
        ...action.user_details,
      }
      localStorage.setItem('user_details', JSON.stringify(user_details))
      // console.log("update user");
      return {
        ...state,
        user_details: user_details,
      }
    case 'UPDATE_ORDER':
      const order_details = {
        ...state.order_details,
        ...action.order_details,
      }
      localStorage.setItem('order_details', JSON.stringify(order_details))
      console.log('update order')
      return {
        ...state,
        order_details: order_details,
      }

    case 'SELECTED_ADDRESS':
      return {
        ...state,
        selectedAdress: action.selectedAdress,
        confirmAddressId: action.confirmAddressId,
        orderId: action.orderId,
      }
    default:
      return state
  }
}

export default Reducer
