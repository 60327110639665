import React from 'react'

import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'

// const Pagination = ({ list, itemsPerPage, activePage, setActivePage }) => {
//   let numberOfPages = []

//   for (let i = 1; i <= Math.ceil(list?.length / itemsPerPage); i++) {
//     numberOfPages.push(i)
//   }

//   const showNextList = (pageNumber) => {
//     window.scrollTo(0, 0)
//     setActivePage(pageNumber)
//   }

// console.log(activePage,"activePage" ,itemsPerPage,"itemsPerPage",list,"list");
//   return (
//     <>
//       {/* {numberOfPages?.length > 1 && ( */}
//         <Container>
//           <List
//             onClick={() => activePage !== 1 && showNextList(activePage - 1)}
//             className={activePage > 1 ? 'arrow' : 'disable arrow'}
//           >
//             <img
//               src={
//                 require('../../../assets/icons/arrow-angle-left.svg').default
//               }
//               alt="left arrow"
//             />
//           </List>

//           {numberOfPages.length <= 3 ? (
//             numberOfPages.map((pageNumber) => (
//               <List
//                 key={pageNumber}
//                 onClick={() => showNextList(pageNumber)}
//                 className={pageNumber === activePage ? 'active-page' : ''}
//               >
//                 {pageNumber}
//               </List>
//             ))
//           ) : (
//             <>
//               <List
//                 onClick={() =>
//                   showNextList(
//                     activePage < numberOfPages.length - 1
//                       ? activePage
//                       : activePage === numberOfPages.length
//                       ? activePage
//                       : activePage - 1,
//                   )
//                 }
//                 className={
//                   activePage < numberOfPages.length - 1 ? 'active-page' : ''
//                 }
//               >
//                 {activePage < numberOfPages.length - 1
//                   ? activePage
//                   : activePage === numberOfPages.length
//                   ? activePage
//                   : '...'}
//               </List>
//               <List
//                 onClick={() =>
//                   showNextList(
//                     activePage >= numberOfPages.length - 1
//                       ? numberOfPages.length - 1
//                       : activePage + 1,
//                   )
//                 }
//                 className={
//                   activePage === numberOfPages.length - 1 ? 'active-page' : ''
//                 }
//               >
//                 {activePage === numberOfPages.length - 1
//                   ? numberOfPages.length - 1
//                   : '...'}
//               </List>
//               <List
//                 onClick={() => showNextList(numberOfPages.length)}
//                 className={
//                   numberOfPages.length === activePage ? 'active-page' : ''
//                 }
//               >
//                 {numberOfPages.length}
//               </List>
//             </>
//           )}

//           <List
//             onClick={() =>
//               activePage !== numberOfPages.length &&
//               showNextList(activePage + 1)
//             }
//             className={
//               activePage < numberOfPages.length ? 'arrow' : 'disable arrow'
//             }
//           >
//             <img
//               src={
//                 require('../../../assets/icons/arrow-angle-right.svg').default
//               }
//               alt="right arrow"
//             />
//           </List>
//         </Container>
//        {/* */} 
//     </>
//   )
// }
const Pagination = ({ list, itemsPerPage, activePage, setActivePage,paginationData }) => {
  console.log(paginationData,"paginationData");
  let numberOfPages = [];

  for (let i = 1; i <= paginationData.num_pages; i++) {
    numberOfPages.push(i);
  }

  console.log(numberOfPages,"number of pages");

  // const showNextList = (pageNumber) => {
  //   window.scrollTo(0, 0);
  //   setActivePage(pageNumber);
  // };
  const showNextList = (pageNumber) => {
    // window.scrollTo(0, 0);
    setActivePage(pageNumber);
  };

  return (
    <>
      <Container>
        <List
          onClick={() => activePage !== 1 && showNextList(activePage - 1)}
          className={activePage > 1 ? 'arrow' : 'disable arrow'}
        >
          <img
            src={require('../../../assets/icons/arrow-angle-left.svg').default}
            alt="left arrow"
          />
        </List>

        {numberOfPages.map((pageNumber) => (
          <List
            key={pageNumber}
            onClick={() => showNextList(pageNumber)}
            className={pageNumber === activePage ? 'active-page' : ''}
          >
            {pageNumber}
          </List>
        ))}

        <List
          onClick={() =>
            activePage !== numberOfPages.length &&
            showNextList(activePage + 1)
          }
          className={
            activePage < numberOfPages.length ? 'arrow' : 'disable arrow'
          }
        >
          <img
            src={require('../../../assets/icons/arrow-angle-right.svg').default}
            alt="right arrow"
          />
        </List>
      </Container>
    </>
  );
};


export default Pagination

const Container = styled.ul`
  display: flex;
  justify-content: right;
`
const List = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  cursor: pointer;
  width: 36px;
  height: 36px;
  border: 1px solid ${THEME_COLORS.border_2};
  border-radius: 6px;
  color: ${THEME_COLORS.background_2};
  &.active-page {
    background-color: ${THEME_COLORS.secondary};
    color: ${THEME_COLORS.white};
    border: 1px solid ${THEME_COLORS.secondary};
  }
  &.arrow {
    // width: auto;
    padding: 8px 15px;
    @media screen and (max-width: 980px) {
      padding: 8px 12px;
    }
    @media screen and (max-width: 640px) {
      padding: 8px 10px;
    }
  }
  &.disable {
    // visibility: hidden;
    // pointer-events: none;
    cursor: not-allowed;
    opacity: 0.6;
  }
  @media screen and (max-width: 980px) {
    width: 30px;
    height: 30px;
  }
  @media screen and (max-width: 640px) {
    width: 26px;
    height: 26px;
  }
`
