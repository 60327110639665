import React, { useEffect, useState } from 'react'
import AddButton from '../../includes/buttons/AddButton'
import Button from '../../includes/buttons/Button'
import cart from '../../../assets/icons/cart.svg'
import styled from 'styled-components'
import medicine1 from '../../../assets/images/medicine1.png'
import heart from '../../../assets/icons/heart-border.svg'
import { THEME_COLORS } from '../../../ThemeConfig'
import heartfill from '../../../assets/icons/heart-fill.svg'
import { baseConfig } from '../../../axiosConfig'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useContext } from 'react'
import { Context } from '../../../contexts/Store'
import { AuthContext } from '../../../contexts/AuthStore'
import CheckOutPage from '../cartlist/CheckOutPage'
import ModalCenter from '../../includes/modal/ModalCenter'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export default function ProductSingleList() {
  const { state } = useContext(Context)
  const { productListId } = useParams()
  const location = useLocation()
  const [fav, setFav] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [singlePageList, setSinglePageList] = useState([])
  const [selectedVarient, setSelectedVarient] = useState('')
  const [productDetails, setProductDetails] = useState([])
  const [safetyDetails, setSafetyDetails] = useState([])
  const [varients, setVarient] = useState([])
  const [clickedMedicine, setClickedMedicine] = useState()


  const { authdispatch } = useContext(AuthContext)
  const navigate = useNavigate()
  const [checkoutModal, setCheckoutModal] = useState(false)
  console.log(singlePageList,"singlePageList")
  const showToastCart = () => {
    toast.success('Item added successfully', {
      autoClose: 200,
      hideProgressBar: true,
    })
  }

  const showToastWishlist = () => {
    toast.success('Item added successfully', {
      autoClose: 200,
      hideProgressBar: true,
    })
  }
  const ErrorToast = () => {
    toast.error('item Out of stock', {
      autoClose: 200,
      hideProgressBar: true,
    })
  }

  let GetProductSingleList = () => {
    console.log("here-------------------1",location, productListId)
    let access_token = state.user_details.access
    setLoading(true)
    if (access_token != null && access_token) {
      baseConfig
        .get(`products/product/${productListId}/${location.search}`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((response) => {
          setSinglePageList(response.data.data)
          setProductDetails(response.data.data.product)
          setSafetyDetails(response.data.data.safety_infomation)
          setVarient(response.data.data.more_variants)
          setTimeout(() => {
            setLoading(false)
          }, 200)
        })
        .catch((error) => {
          const { status, data } = error?.response
          setTimeout(() => {
            setLoading(false)
          }, 200)
        })
    } else {
      baseConfig
        .get(`products/product/${productListId}/`, {})
        .then((response) => {
          setSinglePageList(response.data.data)
          if(response.data.data?.more_images.length>0){
            setClickedMedicine(response.data.data?.more_images[0].image)
          }
          setProductDetails(response.data.data.product)
          setSafetyDetails(response.data.data.safety_infomation)
          setVarient(response.data.data.more_variants)
          setTimeout(() => {
            setLoading(false)
          }, 200)
        })
        .catch((error) => {
          const { status, data } = error?.response
          setTimeout(() => {
            setLoading(false)
          }, 200)
        })
    }
  }
  useEffect(() => {
    if (productListId) {
      GetProductSingleList()
    }
  }, [productListId])

  const addToCart = (id) => {
    let access_token = state.user_details.access
    let formdata = {
      // quantity: 1,
    }

    baseConfig
      .post(`/cart/add-to-cart/${id}/`, formdata, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        // getProductsList();
        showToastCart()
        GetProductSingleList()
      })
      .catch((error) => {
        ErrorToast()
      })
  }

  const handleWishlistData = (id) => {
    let access_token = state.user_details.access
    let formdata = {}

    baseConfig
      .post(`/wishlists/wishlist/${id}/`, formdata, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        GetProductSingleList()
        showToastWishlist()
      })
      .catch((error) => {})
  }

  return (
    <>
      {!isLoading ? (
        <MainContainer>
          <ToastContainer />
          <SubContainer>
            <LeftContainer>
              <MedicinieConatainer>
                  <LeftSideBox>
                  {
                  singlePageList.more_images.map((item)=>(
                    <SingleBox
                      key={item.id}
                      onClick={() => setClickedMedicine(item.image)}
                    >
                      <Img src={item.image} alt="Image" />
                    </SingleBox>
                    ))
                }
                  </LeftSideBox>
                <BoxContainer>
                  <RightSideBox>
                    <MainBox>
                      <Imgdiv>
                        <ImgBox src={clickedMedicine} alt="Image" />
                        <Favourite
                          onClick={() => handleWishlistData(productListId)}
                        >
                          <HeartBox
                            src={singlePageList.is_wishlist ? heartfill : heart}
                            alt="Image"
                          />
                        </Favourite>
                      </Imgdiv>
                    </MainBox>
                  </RightSideBox>

                  <BottomBox
                    className={
                      singlePageList.stock === 0 ? 'not-available' : ''
                    }
                  >
                    <Button
                      // className={'category_button'}
                      className={
                        singlePageList.stock === 0
                          ? 'not-available category_button'
                          : 'category_button'
                      }
                      text={'BUY NOW'}
                      size={'small'}
                      onClick={() => {
                        addToCart(productListId)
                        // if (singlePageList.stock != 0) {
                        //   setCheckoutModal(true)
                        // } else {
                        // }
                        if (state.user_details.is_verified) {
                          if (singlePageList.stock != 0) {
                            setCheckoutModal(true)
                          } else {
                          }
                        } else {
                          const auth_modal_details = {
                            auth_modal_name: 'login',
                            is_auth_modal: true,
                          }
                          authdispatch({
                            type: 'AUTH_MODAL',
                            auth_modal_details,
                          })
                        }
                      }}
                    />
                    <AddButton
                      className={
                        singlePageList.stock === 0
                          ? 'not-available category_button'
                          : 'category_button'
                      }
                      image={cart}
                      size={'small'}
                      onClick={() => {
                        if (state.user_details.is_verified) {
                          if (
                            singlePageList.is_cart &&
                            singlePageList.stock != 0
                          ) {
                            navigate('/cart')
                          } else if (singlePageList.stock != 0) {
                            addToCart(productListId)
                            // showToastCart()
                          } else {
                          }
                        } else {
                          // alert('login')
                          const auth_modal_details = {
                            auth_modal_name: 'login',
                            is_auth_modal: true,
                          }
                          authdispatch({
                            type: 'AUTH_MODAL',
                            auth_modal_details,
                          })
                        }
                      }}
                      text={
                        singlePageList.is_cart ? 'Go To Cart' : 'Add to Cart'
                      }
                    />
                  </BottomBox>
                </BoxContainer>
              </MedicinieConatainer>
            </LeftContainer>
            <RightContainer>
              <TitleContainer>
                <CategoryName>
                  {/* {productDetails.name}, */}
                  {singlePageList.name}
                </CategoryName>
                <PriceBox>
                  {/* <DiscountPrice>₹ {singlePageList.price}</DiscountPrice> */}
                  {singlePageList.offer_price == 0 ? (
                    <DiscountPrice>₹ {singlePageList.price}</DiscountPrice>
                  ) : (
                    <>
                      {' '}
                      <DiscountPrice>
                        ₹{singlePageList.offer_price}{' '}
                      </DiscountPrice>
                      <NormalPrice>₹{singlePageList.price} </NormalPrice>
                    </>
                  )}
                  {/* <NormalPrice>₹ {singlePageList.mrp}</NormalPrice> */}
                </PriceBox>
                {singlePageList.stock == 0 ? (
                  <Availability>Out of Stock</Availability>
                ) : (
                  // ) : singlePageList.stock < 10 || > 5 ? (
                  //   <Stock>only few items left</Stock>
                  singlePageList.stock <= 5 && (
                    <Availability>
                      Only {singlePageList.stock} items left
                    </Availability>
                  )
                )}
                {varients.length > 0 ? (
                  <PackCountBox>
                    {varients?.map((data, i) => (
                      <CategoryBox
                        key={i}
                        // selectedVarient={}
                        onClick={() => {
                          setSelectedVarient(data.id)
                        }}
                        className={
                          selectedVarient == data.id ? 'activated' : ''
                        }
                      >
                        <SubName>Pack of {data.name}</SubName>
                        <TotalPrice>Rs {data.price}</TotalPrice>
                      </CategoryBox>
                    ))}
                  </PackCountBox>
                ) : (
                  ''
                )}
                <PackDetails>
                  <PackName>
                    Pack of : <PackSub>{singlePageList.pack}</PackSub>
                  </PackName>
                  <PackName>
                    Quantity : <PackSub> {singlePageList.quantity}</PackSub>
                  </PackName>
                  <PackName>
                    Products Type :{' '}
                    <PackSub>{productDetails.product_type}</PackSub>
                  </PackName>
                  {productDetails.container_type && (
                    <PackName>
                      Container Type :{' '}
                      <PackSub>{productDetails.container_type}</PackSub>
                    </PackName>
                  )}
                  {productDetails.use_for && (
                    <PackName>
                      Use for : <PackSub>{productDetails.use_for}</PackSub>
                    </PackName>
                  )}
                  {productDetails.mfg_licence && (
                    <PackName>
                      Mfg Licence No :{' '}
                      <PackSub>{productDetails.mfg_licence}</PackSub>
                    </PackName>
                  )}
                </PackDetails>
                {productDetails.infomation && (
                  <ProductInformation>
                    <Title>Product information:</Title>
                    <Description>{productDetails.infomation}</Description>
                  </ProductInformation>
                )}
                {productDetails.increadiants && (
                  <ProductInformation>
                    <Title>Ingredients:</Title>
                    <Description>{productDetails.increadiants}</Description>
                  </ProductInformation>
                )}
                {productDetails.direction_for_use && (
                  <ProductInformation>
                    <Title>Direction for use:</Title>
                    <Description>
                      {productDetails.direction_for_use}
                    </Description>
                  </ProductInformation>
                )}
                {safetyDetails && (
                  <ProductInfo>
                    <Title>Safety Information:</Title>
                    <SafetyInformation>
                      {safetyDetails?.map((data, i) => (
                        <SafetyInfo key={i}>{data.information}</SafetyInfo>
                      ))}
                    </SafetyInformation>
                  </ProductInfo>
                )}
                {productDetails.description && (
                  <Discription>
                    <Title>Description:</Title>
                    <Peragraph>{productDetails.description}</Peragraph>
                  </Discription>
                )}
              </TitleContainer>
            </RightContainer>
          </SubContainer>
        </MainContainer>
      ) : null}
      {checkoutModal && (
        <ModalCenter>
          <CheckOutPage
            setModal={setCheckoutModal}
            datasingle={singlePageList}
          />
        </ModalCenter>
      )}
    </>
  )
}

const MainContainer = styled.div`
  text-transform: capitalize;
  width: 90%;
  max-width: 1350px;
  margin: 0 auto;
  padding-top: 80px;
  @media screen and (max-width: 980px) {
    padding-top: 60px;
  }
`
const SubContainer = styled.div`
  border-top: 1px solid ${THEME_COLORS.border_1};
  padding: 40px 0;
  display: flex;
  gap: 20px;
  @media screen and (max-width: 980px) {
    flex-direction: column;
  }
`
const LeftContainer = styled.div`
  /* width: 50%; */
  @media screen and (max-width: 480px) {
    margin: 0 auto;
  }
`
const MedicinieConatainer = styled.div`
  display: flex;
  /* padding-bottom: 33px; */
  gap: 32px;
  @media screen and (max-width: 1080px) {
    flex-direction: column-reverse;
  }
`
const BoxContainer = styled.div`
  @media screen and (max-width: 980px) {
    margin: 0 auto;
  }
`
const LeftSideBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  height: max-content;
  padding: 20px 0;
  cursor: pointer;
`
const SingleBox = styled.div`
  width: 97px;
  height: 97px;
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid ${THEME_COLORS.border_3};
  overflow: hidden;
  &:active {
    border: 2px solid ${THEME_COLORS.primary};
  }
`
const Img = styled.img`
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
`
const RightSideBox = styled.div`
  margin-bottom: 20px;
`
const MainBox = styled.div``
const ImgBox = styled.img`
  width: 100%;
  display: block;
  border-radius: 16px;
`
const Favourite = styled.span`
  width: 44px;
  height: 44px;
  position: absolute;
  top: 17px;
  right: 15px;
  background: ${THEME_COLORS.white};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.07);
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const HeartBox = styled.img`
  display: block;
  width: 24px;
  height: 24px;
`
const BottomBox = styled.div`
  /* display: flex;
    gap: 24px; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  /* &.not-available {
    filter: opacity(0.5);
    cursor: not-allowed;
  } */
  @media screen and (max-width: 980px) {
    grid-gap: 20px;
    width: auto;
  }
  @media screen and (max-width: 840px) {
    grid-gap: 20px;
    /* width: 65%; */
  }
  @media screen and (max-width: 768px) {
    grid-gap: 20px;
    /* width: 78%; */
  }
  @media screen and (max-width: 640px) {
    grid-gap: 20px;
    /* width: 90%; */
  }
  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
    width: 100%;
  }
`
const RightContainer = styled.div`
  width: 55%;
  gap: 32px;
  @media screen and (max-width: 1080px) {
    width: 100%;
  }
`
const TitleContainer = styled.div`
  margin-bottom: 24px;
`
const CategoryName = styled.h4`
  font-size: 20px;
  font-family: 'poppins-medium';
  color: ${THEME_COLORS.background_1};
  margin-bottom: 10px;
`
const PriceBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`
const DiscountPrice = styled.div`
  font-size: 28px;
  font-family: 'poppins-medium';
  color: ${THEME_COLORS.background_1};
  margin-right: 16px;
`
const NormalPrice = styled.small`
  color: ${THEME_COLORS.background_5};
  font-size: 26px;
  font-family: 'poppins-medium';
  /* margin-top: 10px; */
  text-decoration-line: line-through;
`
const PackCountBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 24px;
  margin-bottom: 24px;
  flex-wrap: wrap;
`
const CategoryBox = styled.div`
  /* width: max-content; */
  min-width: 35%;
  max-width: 35%;
  /* height: 90px; */
  /* width: 30%; */
  text-align: center;
  border: 2px solid ${THEME_COLORS.border_3};
  border-radius: 16px;
  padding: 10px;
  /* flex-direction: column; */
  justify-content: center;
  cursor: pointer;
  &.activated {
    border: 2px solid ${THEME_COLORS.primary};
  }
  @media screen and (max-width: 640px) {
    min-width: 70%;
    max-width: 70%;
  }
  @media screen and (max-width: 768px) {
    min-width: 50%;
    max-width: 50%;
  }
  @media screen and (max-width: 360px) {
    min-width: 100%;
    max-width: 30%;
  }
`
const SubName = styled.h4`
  font-size: 16px;
  line-height: 30px;
  font-family: 'poppins-medium';
  color: ${THEME_COLORS.background_1};
  width: max-content;
`
const TotalPrice = styled.h4`
  font-size: 20px;
  line-height: 30px;
  font-family: 'poppins-medium';
`
const PackDetails = styled.div`
  margin-bottom: 24px;
`
const PackName = styled.p`
  margin-bottom: 8px;
  font-size: 17px;
  font-family: 'poppins-medium';
  color: ${THEME_COLORS.text_1};
  &:last-child {
    margin-bottom: 0;
  }
`
const PackSub = styled.span`
  color: ${THEME_COLORS.background_4};
`
const ProductInformation = styled.div`
  margin-bottom: 24px;
`
const ProductInfo = styled.ul`
  margin-bottom: 24px;
`
const SafetyInformation = styled.div``
const Title = styled.h3`
  font-family: 'poppins-medium';
  font-size: 20px;
  line-height: 30px;
  color: ${THEME_COLORS.text_1};
  margin-bottom: 8px;
`
const Description = styled.p`
  font-family: 'poppins-medium';
  font-size: 16px;
  line-height: 24px;
  color: ${THEME_COLORS.background_4};
`
const SafetyInfo = styled.li`
  font-size: 17px;
  line-height: 26px;
  color: ${THEME_COLORS.background_4};
  font-family: 'poppins-medium';
  list-style-type: disc;
`
const Discription = styled.div``
const Peragraph = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${THEME_COLORS.background_4};
  font-family: 'poppins-medium';
`
const Imgdiv = styled.div`
  width: 486px;
  position: relative;
  // height:400px;
  /* width: 50%; */
  &.not_available {
    filter: opacity(0.5);
  }
  @media screen and (max-width: 640px) {
    width: 425px;
  }
  @media screen and (max-width: 480px) {
    width: auto;
  }
  @media screen and (max-width: 320px) {
    /* width: 255px; */
  }
`
const Availability = styled.p`
  /* display: none; */
  color: red;
  font-family: 'poppins-medium';
  font-size: 20px;
  margin-bottom: 10px;
  @media screen and (max-width: 980px) {
    font-size: 20px;
  }
  @media screen and (max-width: 640px) {
    font-size: 18px;
  }
`
