import "./App.css";
import "./assets/css/style.css";
import MainPage from "./components/screens/MainPage";
import { BrowserRouter as Router } from "react-router-dom";
import Store from "./contexts/Store";
import AuthStore from "./contexts/AuthStore";

function App() {
    return (
        <Store>
            <AuthStore>
                <Router>
                    <MainPage />
                </Router>
            </AuthStore>
        </Store>
    );
}

export default App;
