import React, { useEffect, useState, useContext } from 'react'
import DealOfTheDayCard from '../../includes/dealofthedaycard/DealOfTheDayCard'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'
import Tabs from '../../includes/tabs/Tabs'
import CategoriesCard from '../../includes/CategoriesCard/CategoriesCard'
import health1 from '../../../assets/images/slider1.svg'
import health2 from '../../../assets/images/slider2.svg'
import health3 from '../../../assets/images/slider3.svg'
import { Link, useNavigate } from 'react-router-dom'
import { Context } from '../../../contexts/Store'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import BadgeButton from '../../includes/buttons/BadgeButton'
import { baseConfig } from '../../../axiosConfig'
import Bannerad from '../../../assets/images/leaf.png'

export default function DealOfTheDay() {
  var settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  // const tab = [
  //     {
  //         id: 1,
  //         name: "All",
  //     },
  //     {
  //         id: 2,
  //         name: "Tablets",
  //     },
  //     {
  //         id: 3,
  //         name: "Nutrition",
  //     },
  //     {
  //         id: 4,
  //         name: "Skincare",
  //     },
  //     {
  //         id: 5,
  //         name: "Capsules",
  //     },
  //     {
  //         id: 6,
  //         name: "Oralspray",
  //     },
  // ];
  const navigate = useNavigate()

  const [bannerList, setBannerList] = useState([])

  const [productList, setProductList] = useState([])

  const [dealOfTheDayList, setDealOfTheDayList] = useState([])

  const [clickedTab, setClickedTab] = useState('')
  const { state } = useContext(Context)

  let OfferBannerData = (token) => {
    baseConfig
      .get('general/banners/', {
        // params: {
        //     page: 30,
        // },
      })
      .then((response) => {
        setBannerList(response.data.featured)
      })
      .catch((error) => {
        const { status, data } = error?.response
      })
  }
  useEffect(() => {
    OfferBannerData()
  }, [])

  const [productCategoryList, setproductCategoryList] = useState([])
  let ProductCategoryData = (token) => {
    let accessToken = state.user_details.access

    baseConfig
      .get('products/category/', {
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : null,
        },
      })
      .then((response) => {
        const { data } = response.data
        setproductCategoryList(data)
        // setClickedTab(data[0].id)
      })
      .catch((error) => {
        const { status, data } = error?.response
      })
  }

  //get products
  let getProductsList = () => {
    let accessToken = state.user_details.access
    baseConfig
      .get('products/products-list/', {
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : null,
        },
        params: {
          category: clickedTab,
        },
      })
      .then((response) => {
        const { data } = response.data
        setProductList(data)
      })
      .catch((error) => {
        const { status, data } = error?.response
      })
  }

  //get deal of the days
  let getDealOfTheDay = () => {
    let accessToken = state.user_details.access
    baseConfig
      .get('offers/deal-of-the-days/', {
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : null,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data
        if (StatusCode === 6000) {
          setDealOfTheDayList(data)
        }
      })
      .catch((error) => {
        const { status, data } = error?.response
      })
  }

  useEffect(() => {
    getProductsList()
  }, [clickedTab])

  useEffect(() => {
    window.scrollTo(0, 0)
    ProductCategoryData()
    getDealOfTheDay()
  }, [])

  return (
    <MainContainer>
      <SubContainer>
        {dealOfTheDayList.length > 0 && (
          <>
            <TopContainer>
              <TitleBox>Deal of the Day</TitleBox>
            </TopContainer>
            <DealContainer>
              {dealOfTheDayList.map((item, i) => (
                <DealOfTheDayCard item={item} key={i} />
              ))}
            </DealContainer>
          </>
        )}
      </SubContainer>
      <BannerContainer to="/offerpage">
        <BannerSub>
          <ImageBox>
            {bannerList?.image ? (
              <Img src={bannerList.image} alt="Image" />
            ) : (
              <Img src={Bannerad} alt="Image" />
            )}
          </ImageBox>
        </BannerSub>
      </BannerContainer>
      <Categories>
        <TopContainer>
          <TitleBox>Product Categories</TitleBox>
          <BadgeButton
            text={'View All'}
            onClick={() => {
              navigate('/productlist')
            }}
          />
        </TopContainer>
        <TabsSection>
          {productCategoryList?.length > 0 && (
            <Tabs
              data={productCategoryList}
              clickedTab={clickedTab}
              setClickedTab={setClickedTab}
            />
          )}
        </TabsSection>
        <CategoriesListContainer>
          {productList.map((data, i) => (
            <CategoriesCard refreshApi={getProductsList} data={data} key={i} />
          ))}
        </CategoriesListContainer>
      </Categories>
      <SliderContainer>
        <Slider {...settings}>
          <SliderSingleBox to="offerpage">
            <SlideImage src={health1} alt="Image" />
          </SliderSingleBox>
          <SliderSingleBox to="offerpage">
            <SlideImage src={health2} alt="Image" />
          </SliderSingleBox>
          <SliderSingleBox to="offerpage">
            <SlideImage src={health3} alt="Image" />
          </SliderSingleBox>
          <SliderSingleBox to="offerpage">
            <SlideImage src={health2} alt="Image" />
          </SliderSingleBox>
          <SliderSingleBox to="offerpage">
            <SlideImage src={health1} alt="Image" />
          </SliderSingleBox>
        </Slider>
      </SliderContainer>
    </MainContainer>
  )
}
const MainContainer = styled.div`
  width: 90%;
  max-width: 1350px;
  margin: 80px auto 0;
`
const SubContainer = styled.div``
const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  align-items: center;
`
const DealContainer = styled.div`
  margin-bottom: 70px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  @media screen and (max-width: 1080px) {
    grid-gap: 12px;
  }
  @media screen and (max-width: 980px) {
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (max-width: 768px) {
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 480px) {
    margin-bottom: 50px;
    grid-template-columns: 1fr;
  }
`
const TitleBox = styled.h3`
  font-size: 32px;
  font-family: 'poppins-medium';
  color: ${THEME_COLORS.background_3};
  // margin-bottom: 32px;
  @media screen and (max-width: 980px) {
    font-size: 24px;
  }
  @media screen and (max-width: 480px) {
    font-size: 22px;
    /* margin-bottom: 28px; */
  }
  @media screen and (max-width: 360px) {
    font-size: 18px;
    margin-bottom: 5px;
  }
`
const BannerContainer = styled(Link)`
  margin-bottom: 80px;
  @media screen and (max-width: 980px) {
    margin-bottom: 80px;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 45px;
  }
`
const BannerSub = styled.div`
  /* background: linear-gradient(264.86deg, #106f39 -7.41%, rgba(16, 111, 57, 0.637357) 13.46%, rgba(16, 111, 57, 0) 118.44%); */
  border-radius: 6px;
  /* width: 1520px; */
  object-fit: contain;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: space-between; */
  position: relative;
  /* @media screen and (max-width: 1180px) {
        height: 320px;
    }
    @media screen and (max-width: 1080px) {
        height: 270px;
    }
    @media screen and (max-width: 768px) {
        height: 228px;
    } */
  margin-bottom: 80px;
  @media screen and (max-width: 980px) {
    margin-bottom: 80px;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 45px;
  }
`
const ImageBox = styled.div`
  border-radius: 6px;
  cursor: pointer;
  @media screen and (max-width: 980px) {
    height: 295px;
    /* padding-right: 25px; */
  }
  @media screen and (max-width: 768px) {
    height: 210px;
  }
  @media screen and (max-width: 480px) {
    height: 180px;
    width: 100%;
  }
`
const Img = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
`
// const OfferBox = styled.span`
//     display: flex;
//     background: #e71c22;
//     border-radius: 8px;
//     width: max-content;
//     height: 62px;
//     text-align: center;
//     align-items: center;
//     color: #ffffff;
//     justify-content: center;
//     font-family: "poppins-medium";
//     padding: 16px;
//     position: absolute;
//     right: 36px;
//     top: 43px;
//     @media screen and (min-width: 1280px) {
//         height: 50px;
//         right: 27px;
//         top: 22px;
//     }
//     @media screen and (min-width: 980px) {
//         height: 50px;
//         right: 24px;
//         top: 14px;
//     }
//     @media screen and (max-width: 768px) {
//         height: 50px;
//         right: 15px;
//         top: 17px;
//     }
//     @media screen and (max-width: 640px) {
//         height: 40px;
//         right: 8px;
//         top: 7px;
//     }
//     @media screen and (max-width: 480px) {
//         padding: 8px;
//         height: 26px;
//     }
// `;
// const DiscountTitle = styled.small`
//     display: flex;
//     align-items: center;
//     text-align: center;
//     font-size: 16px;
//     @media screen and (max-width: 580px) {
//         font-size: 14px;
//     }
//     @media screen and (max-width: 480px) {
//         font-size: 12px;
//     }
// `;
// const Price = styled.h4`
//     font-size: 18px;
//     line-height: 36px;
//     @media screen and (max-width: 480px) {
//         font-size: 14px;
//     }
// `;
const Categories = styled.div`
  margin-bottom: 120px;
  @media screen and (max-width: 980px) {
    margin-bottom: 80px;
  }
`
const TabsSection = styled.div`
  margin-bottom: 23px;
`
const CategoriesListContainer = styled.div`
  // padding-bottom: 120px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  @media screen and (max-width: 1280px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (max-width: 980px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
  }
  @media screen and (max-width: 360px) {
    grid-template-columns: 1fr;
  }
`
const SliderContainer = styled.div`
  margin-bottom: 120px;
  @media screen and (max-width: 980px) {
    margin-bottom: 80px;
  }
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr 1fr; */
  /* grid-gap: 20px; */
`
const SliderSingleBox = styled(Link)`
  /* width: 615px;
    height: 359px; */
`
const SlideImage = styled.img`
  display: block;
  width: 95%;
  margin: 0 auto;
  border-radius: 16px;
`
