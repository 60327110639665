import { TextField } from '@mui/material'
import React, { useState } from 'react'
import styled from 'styled-components'
import PrimaryButton from '../../includes/buttons/PrimaryButton'
import { baseConfig } from '../../../axiosConfig'
import { Context } from '../../../contexts/Store'
import { useContext } from 'react'
import { THEME_COLORS } from '../../../ThemeConfig'
import profile from '../../../assets/icons/avatar-icon.svg'
import person from '../../../assets/icons/person.svg'

import { useEffect } from 'react'
import useScrollBlock from '../../includes/functions/useScrollBlock'

export default function EditProfile({
  profileDetails,
  setEditProfile,
  getProfile,
  editProfile,
  // handleFileChange,
  // handleProfilePicChange,
}) {
  const { state } = useContext(Context)
  // const [image, setImage] = useState(profileDetails?.profileDetails?.image);
  const [fullName, setFullName] = useState(profileDetails?.name)
  const [email, setEmail] = useState(profileDetails?.email)
  const [phoneNumber, setPhoneNumber] = useState(profileDetails?.phone)
  const [editModal, setEditModal] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [files, setFile] = useState(null)
  const [isUploadpic, setUploadPic] = useState(false)
  const [isRemovePic, setRemovePic] = useState(false)
  const [image, setimage] = useState('')
  const [remove, setRemove] = useState(false)
  const [errorField, setErrorField] = useState(false);

  const inputColor = { background: '#F9F9F9' }
  const [blockScroll, allowScroll] = useScrollBlock()

  useEffect(() => {
    if (editProfile) {
      blockScroll()
    } else {
      allowScroll()
    }
  }, [editProfile])

  const handleProfileChange = () => {
    let accessToken = state.user_details.access
    const formData = new FormData()
    formData.append('name', fullName)
    formData.append('email', email)
    formData.append('phone', phoneNumber)
    formData.append('image',image)

    baseConfig
      .post('customers/update-profile/', formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        console.log(response)
        const { StatusCode, data, token } = response.data
        if (StatusCode === 6000) {
          setEditProfile(false)
          getProfile()
        } else if (StatusCode === 6001) {
        }
      })
      .catch((error) => {})
  }

  let photo = []
  const handleFileChange = async (e) => {
    photo = await e.target.files[0]
    setimage(photo)
    console.log(photo,'handlefilechnage')

    if (photo) {
      handleProfilePicChange()
    }
  }

  const handleProfilePicChange = () => {
    let accessToken = state.user_details.access
    const formData = new FormData()
    formData.append('image', photo)

    baseConfig
      .post('customers/update-profile-image/', formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => { 
        const { StatusCode, data, token } = response.data
        if (StatusCode === 6000) {
          console.log(response.data.data.image, 'image upload ok ')
          setRefresh(true)
          setimage(response.data.data.image)
          getProfile()
        console.log(data,"get profile2")
        } else if (StatusCode === 6001) {
        }
      })
      .catch((error) => {
      })
      
  }
  const removePic = () => {
    const accessToken = state.user_details.access
    baseConfig
      .post(
        'customers/remove-profile-image/',
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
      .then((res) => {
        console.log(res)
        setRemove(true)
      })
      .catch((err) => {
        console.log(err)
      })
  }
 
  const handleChange = (e) => {
    const input = e.target.value;
    // Allow only characters (letters) using regex
    const onlyLetters = input.replace(/[^A-Za-z]/g, '');
    setFullName(onlyLetters);
  };
  return (
    <MainContainer>
      <Edit>
        <Title>Edit Profile</Title>
        <Close
          onClick={() => {
            setEditProfile(false)
            allowScroll()
          }}
        >
          <img
            src={require('../../../assets/icons/close-square.svg').default}
            alt="Close"
          />
        </Close>
      </Edit>
      <SubContainer>
        <Profile>
          <Image>
            {profileDetails.image === null && remove ? (
              <>
                <Img src={profile} alt="Image" />
              </>
            ) : refresh ? (
              <Img src={image} alt="Image" />
            ) : (
              <Img src={person} alt="Image" />
            )}

            <Camera
              onClick={() => {
                setEditModal(true)
              }}
            >
              <img
                src={require('../../../assets/icons/camera.svg').default}
                alt="Icon"
              />
            </Camera>
          </Image>
        
        </Profile>
        <EditFrom>
          <SingleInput>
            <TextField
              fullWidth={true}
              size="small"
              label="Full Name"
              style={inputColor}
              onChange={(e) => {
                // setFullName(e.target.value)
                handleChange(e)
              }}
              value={fullName}
              inputProps={{
                maxLength: 40
              }}
              type='text'
            />
          </SingleInput>
          <SingleInput>
            <TextField
              fullWidth={true}
              size="small"
              // disabled
              label="Email"
              value={email}
             
            />
          </SingleInput>
          <SingleInput>
            <TextField
              fullWidth={true}
              size="small"
              label="Mobile Number"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value)
                setErrorField('');
              }}
              inputProps={{
                maxLength: 10
              }}
              type='number'
              disabled
            />
            {errorField && <p style={{
              color:"#bf1818"
            }}>{errorField}</p>}
          </SingleInput>
        </EditFrom>
      </SubContainer>
      <Bottom>
        <PrimaryButton
          size={'small'}
          text={'Save'}
          onClick={() => {
            if (phoneNumber.length === 10 ){
            handleProfileChange()
            }
            else{
              setErrorField('Please enter a phone number');
          }
            allowScroll()
          }}
        />
      </Bottom>
   
      <PRofilePicEditDiv
        className={editModal ? 'edit' : ''}
      >
        <Close
          className="modal"
          onClick={() => {
            setEditModal(false)
            allowScroll()
          }}
        >
          <img
            src={require('../../../assets/icons/close-square.svg').default}
            alt="Close"
          />
        </Close>
        <EditPhoto
          onClick={() => {
            setUploadPic(true)
            setRemovePic(false)
          }}
          className={isUploadpic ? ' active' : ''}
        >
          Upload New Pictures
          <InputPic
            type="file"
            accept=".png, .jpg, *"
            hidden
            // onChange={(e) => {
            //     setPhoto(e.target.value);
            // }}
            onChange={handleFileChange}
            onClick={()=>{
              handleProfilePicChange();
              setEditModal(false);
            }}
          />
        </EditPhoto>
        <RemovePhoto
          onClick={() => {
            setRemovePic(true)
            setUploadPic(false)
            removePic()
          }}
          className={isRemovePic ? ' active' : ''}
        >
          Remove Picture
        </RemovePhoto>
      </PRofilePicEditDiv>
    </MainContainer>
  )
}

const MainContainer = styled.div`
  padding: 32px;
  width: 600px;
  /* @media screen and (max-width: 768px) {
        width: 100%;
    } */
  @media screen and (max-width: 768px) {
    width: 520px;
    overflow: scroll;
  }
  @media screen and (max-width: 640px) {
    width: 480px;
  }
  @media screen and (max-width: 540px) {
    width: 440px;
  }
  @media screen and (max-width: 480px) {
    width: 340px;
  }
  @media screen and (max-width: 360px) {
    width: 320px;
  }
`
const Title = styled.h3`
  font-family: 'poppins-medium';
  color: ${THEME_COLORS.background_1};
  font-size: 28px;
  margin-bottom: 20px;
  @media screen and (max-width: 640px) {
    margin-bottom: 20px;
  }
`
const SubContainer = styled.div``
const Profile = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
const Image = styled.div`
  width: 125px;
  height: 125px;
  border: 4px solid ${THEME_COLORS.white};
  border-radius: 50%;
  overflow: hidden;
`
const Img = styled.img`
  display: block;
  width: 100%;
  object-fit: cover;
`
const UploadButton = styled.label`
  color: ${THEME_COLORS.secondary};
  font-size: 14px;
  background: #bae4cc;
  border-radius: 6px;
  font-family: 'poppins-regular';
  padding: 4px 8px;
  cursor: pointer;
`
const EditFrom = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 29px 0;
  @media screen and (max-width: 640px) {
    margin: 20px 0;
  }
`
const Input = styled.input``
const SingleInput = styled.div`
  width: 48%;
  margin-bottom: 13px;
  &:last-child {
    margin-bottom: 0;
  }
  &:nth-child(3),
  &:nth-child(4) {
    width: 100%;
  }
  @media screen and (max-width: 640px) {
    width: 100%;
  }
`
const Bottom = styled.div`
  display: flex;
  justify-content: end;
`
const Edit = styled.div`
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
`
const Close = styled.div`
  &.modal {
    position: absolute;
    height: 20px;
    width: 20px;
    right: 11px;
    top: 7px;

    img {
      display: block;
      width: 100%;
    }
  }
`
const Camera = styled.div`
  position: absolute;
  z-index: 1;
  top: 170px;
  left: 120px;
  img {
    display: block;
    width: 100%;
  }
`
const PRofilePicEditDiv = styled.div`
  display: none;
  padding: 15px;
  background: ${THEME_COLORS.white};
  box-shadow: 12px 25px 25px 2px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  /* display: flex;
    flex-wrap: wrap;
    justify-content: end; */
  text-align: center;
  position: absolute;
  top: 190px;
  left: 135px;
  z-index: 10;
  width: 35%;
  &.edit {
    display: block;
  }
  @media screen and (max-width: 1280px) {
    width: 45%;
  }
  @media screen and (max-width: 1080px) {
    width: 50%;
  }
  @media screen and (max-width: 980px) {
    width: 55%;
  }
  @media screen and (max-width: 768px) {
    width: 50%;
  }
  @media screen and (max-width: 480px) {
    width: 56%;
    padding: 10px;
  }
`
const ImageContainer = styled.div`
  margin: 0 auto;
  width: 80px;
`
const Icon = styled.img`
  display: block;
  width: 100%;
`

// const Title = styled.h3`
//     width: 100%;
//     color: ${THEME_COLORS.background_1};
//     font-size: 22px;
//     margin-bottom: 20px;
//     font-family: "poppins-medium";
//     text-align: center;
// `;
const Content = styled.p`
  width: 100%;
  color: ${THEME_COLORS.background_4};
  font-size: 13px;
  margin-bottom: 40px;
  font-family: 'poppins-regular';
  text-align: center;
  span {
    color: red;
  }
`
const Button = styled.div`
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`
const EditPhoto = styled.label`
  cursor: pointer;
  padding: 10px;
  font-family: 'poppins-medium';
  font-size: 14px;
  color: #8e8e8e;
  width: 100%;
  border-radius: 3px;
  display: inline-block;
  &.active {
    background-color: #106f391a;
    color: #106f39;
  }
`
const RemovePhoto = styled.button`
  font-family: 'poppins-medium';
  padding: 10px;
  font-size: 14px;
  color: #8e8e8e;
  width: 100%;
  border-radius: 3px;
  &.active {
    background-color: #106f391a;
    color: #106f39;
  }
`
const InputPic = styled.input``
