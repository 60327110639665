import React, { useState } from 'react'
import styled from 'styled-components'
import bottlem1 from '../../../assets/icons/bottle1.svg'
import PrimaryButton from '../../includes/buttons/PrimaryButton'
import tick from '../../../assets/icons/tick-square.svg'
import { THEME_COLORS } from '../../../ThemeConfig'
import { useContext } from 'react'
import { Context } from '../../../contexts/Store'
import { baseConfig } from '../../../axiosConfig'
import Header from '../header/Header'

export default function Cancelation({
  product,
  active,
  setCancel,
  getOrderStatus,
}) {
  const [check, setCheck] = useState(false)
  const { state } = useContext(Context)
  const [submitReason, setSubmitReason] = useState('')
  const [error, setError] = useState('')

  console.log(active, 'active-------')
  console.log(active.pk, 'active.pk')

  const cancelReason = [
    {
      id: 1,
      reason: 'I want to change address for that order',
    },
    {
      id: 2,
      reason: 'Price for the product is decreased',
    },
    {
      id: 3,
      reason: 'Expected delivery time is very long',
    },
    {
      id: 4,
      reason: 'I purchased product somewhere else',
    },
    {
      id: 5,
      reason: 'Other',
    },
  ]
  const cancelCancelation = () => {
    setCancel(false)
    setCheck(false)
    setSubmitReason('')
  }
  const cancelation = () => {
    //   if(files){
    let accessToken = state.user_details.access
    const formData = new FormData()
    formData.append('cancelled_reason', submitReason)

    baseConfig
      .post(`orders/cancel-order/${active?.pk}/`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const { StatusCode, data, token } = response.data
        if (StatusCode === 6000) {
          setCancel(false)
          getOrderStatus()
        } else if (StatusCode === 6001) {
          setError(response)
          console.log(error, 'Error')
          // setCancel(false)
        }
      })
      .catch((error) => {
        // console.log(error.response.msg);
      })
    //   }else{
    //     console.log('no file found!!')
    //   }
  }

  return (
    <>
      {/* <Header /> */}
      <MainContainer>
        <Container>
          <Title>Request Cancellation</Title>
          <Top>
            <Product>
              <Image>
                <Img src={product?.image} alt="Image" />
              </Image>
              <ProductName>
                <ProductId>#45231556</ProductId>
                {/* {active.items_count > 2 ? (
                  <Name>
                    {product?.product_name} +{' '}
                    <Small> {active.items_count - 1} others</Small>
                  </Name>
                ) : active.items_count == 2 ? (
                  <Name>
                    {product?.product_name} +{' '}
                    <Small> {active.items_count - 1} other</Small>
                  </Name>
                ) : (
                  <Name>{product?.product_name}</Name>
                )} */}
                <Name>{product?.product_name}</Name>
                <Price>₹ {product?.subtotal}</Price>
              </ProductName>
            </Product>
          </Top>
          <Reason>
            <SubTitle>Reason for cancellations</SubTitle>
            <CheckContainer>
              {cancelReason.map((data) => (
                <CheckBoxSection key={data.id}>
                  <Checkbox
                    onClick={() => {
                      setCheck(data.id)
                      setSubmitReason(data.reason)
                      // handleDefaultAddress(item.id);
                      // setSelectedAddress(item);
                    }}
                    className={check === data.id && 'checked'}
                  >
                    {check === data.id && <Tick src={tick} alt="Image" />}
                  </Checkbox>
                  <Text>{data.reason}</Text>
                </CheckBoxSection>
              ))}
            </CheckContainer>
          </Reason>
          <Bottom>
            <TextArea
              value={submitReason}
              onChange={(e) => {
                setSubmitReason(e.target.value)
                console.log(submitReason)
              }}
              placeholder="Enter a comment"
            />
            <div>{error}</div>
            <Buttons>
              <Button>
                <PrimaryButton
                  onClick={cancelCancelation}
                  text={'Cancel'}
                  size="small"
                  className="white"
                />
              </Button>
              <Button>
                <PrimaryButton
                  onClick={cancelation}
                  text={'Submit'}
                  size="small"
                />
              </Button>
            </Buttons>
          </Bottom>
        </Container>
      </MainContainer>
    </>
  )
}

const MainContainer = styled.div`
  padding: 50px 0;
  background: ${THEME_COLORS.border_5};
  /* &:first-child {
    padding-top: 200px;
  } */
  @media screen and (max-width: 1280px) {
    padding: 30px;
  }
  @media screen and (max-width: 580px) {
    padding: 30px 38px;
  }
  @media screen and (max-width: 480px) {
    padding: 0;
  }
`
const Container = styled.div`
  background: ${THEME_COLORS.white};
  box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.04);
  margin: 0 auto;
  padding: 32px;
  gap: 8px;
  width: 748px;
  @media screen and (max-width: 1280px) {
    width: 90%;
    padding: 30px;
  }
  @media screen and (max-width: 980px) {
    flex-direction: column;
  }
  @media screen and (max-width: 580px) {
    width: 100%;
  }
  @media screen and (max-width: 480px) {
    padding: 25px;
  }
`
const Title = styled.p`
  font-family: 'poppins-regular';
  font-size: 28px;
  color: ${THEME_COLORS.background_1};
  margin-bottom: 22px;
  @media screen and (max-width: 1280px) {
    font-size: 24px;
  }
  @media screen and (max-width: 480px) {
    font-size: 22px;
  }
`
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 46px;

  @media screen and (max-width: 580px) {
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
`
const Product = styled.div`
  display: flex;
  @media screen and (max-width: 580px) {
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }
`
const Image = styled.div`
  margin-right: 20px;
  width: 90px;
  @media screen and (max-width: 580px) {
    /* width: 80%; */
    width: 70%;
    margin: 0 auto 30px;
  }
  @media screen and (max-width: 480px) {
    /* width: 100%; */
    width: 85%;
  }
`
const Img = styled.img`
  display: block;
  width: 100%;
`

const ProductName = styled.div`
  /* margin-top: 10px; */
  @media screen and (max-width: 580px) {
    text-align: center;
  }
`

const ProductId = styled.p`
  font-family: 'poppins-medium';
  color: ${THEME_COLORS.text_1};
  font-size: 17px;
  @media screen and (max-width: 1280px) {
    font-size: 14px;
  }
`
const Name = styled.p`
  display: flex;
  align-items: center;
  font-family: 'poppins-regular';
  color: ${THEME_COLORS.background_1};
  font-size: 17px;
  @media screen and (max-width: 980px) {
    font-size: 18px;
  }
`
const Small = styled.p`
  font-size: 20px;
  /* font-family: "popins-regular"; */
  color: #8e8e8e;
`
const Price = styled.p`
  color: ${THEME_COLORS.background_1};
  font-size: 20px;
  font-family: 'poppins-regular';
`
const Reason = styled.div`
  margin-bottom: 30px;
`
const CheckContainer = styled.div`
  /* display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 580px) {
        flex-wrap: wrap;
    } */
`
const SubTitle = styled.p`
  font-family: 'poppins-regular';
  color: ${THEME_COLORS.background_1};
  font-size: 20px;
  margin-bottom: 27px;
`
const CheckBoxSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`

const Checkbox = styled.div`
  margin-right: 10px;
  width: 18px;
  height: 18px;
  border: 1px solid ${THEME_COLORS.background_3};
  border-radius: 3px;
  cursor: pointer;
  &.checked {
    border-color: ${THEME_COLORS.secondary};
    width: 19px;
    height: 19px;
  }
`
const Text = styled.p`
  color: ${THEME_COLORS.background_1};
  font-size: 16px;
  font-family: 'poppins-medium';
  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`
const Tick = styled.img`
  width: 18px;
  height: 18px;
`
const Bottom = styled.div``
const TextArea = styled.textarea`
  max-width: 100%;
  min-width: 100%;
  min-height: 166px;
  font-family: 'poppins-regular';
  padding: 14px 16px;
  color: ${THEME_COLORS.background_4};
  background: #f9f9f9;
  border-radius: 8px;
  font-size: 15px;
  margin-bottom: 40px;
  resize: none;
`
const Buttons = styled.div`
  display: flex;
  justify-content: end;
  gap: 8px;
`
const Button = styled.div``
