import { TextField } from '@mui/material'
import React, { useState } from 'react'
import styled from 'styled-components'
// import PrimaryButton from '../../includes/buttons/PrimaryButton'
import { useContext } from 'react'
import { THEME_COLORS } from '../../../../ThemeConfig'
import { baseConfig } from '../../../../axiosConfig'
import { AuthContext } from '../../../../contexts/AuthStore'
import { Context } from '../../../../contexts/Store'
import InputForm from '../../form/InputForm'

export default function ConfirmDeleteModal({ setConfirmModal, setOtpModal }) {
  // const [image, setImage] = useState(profileDetails?.profileDetails?.image);

  const inputColor = { background: '#F9F9F9' }

  const { authstate, authdispatch } = useContext(AuthContext)
  // const phone = authstate?.auth_modal_details?.phone
  const [isError, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const { state, dispatch } = useContext(Context)
  const [phone, setPhone] = useState('')
  let access_token = state.user_details.access


  const handleEnter = (event) => {
    const keyCode = event.which || event.keyCode
    if (event.key.toLowerCase() === 'enter') {
      const form = event.target.form
      const index = [...form].indexOf(event.target)
      form.elements[index + 1].focus()
      event.preventDefault()
    } else if (keyCode < 8 || keyCode > 10) {
      event.preventDefault()
    }
  }
  const CssTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#56b57f',
        color: '#56b57f',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
    },
    '& label.Mui-focused': {
      color: 'green',
    },
  })
  const handleClick = () => {
    setError(false)
    const formData = new FormData()
    formData.append('phone', phone)

    baseConfig
      .post('users/send-delete-otp/', formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        const { StatusCode, data, token, message } = response.data
        if (StatusCode === 6000) {
          const auth_modal_details = {
            auth_modal_name: '',
            is_auth_modal: true,
            phone: phone,
          }
          authdispatch({
            type: 'AUTH_MODAL',
            auth_modal_details,
          })

          const user_details = {
            is_verified: true,
            role: token.role,
            access: token.access,
            refresh: token.refresh,
          }
          dispatch({
            type: 'UPDATE_USER',
            user_details,
          })
        } else if (StatusCode === 6001) {
          setErrorMsg(message)
        }
      })
      .catch((error) => {})
  }
  const handleInputChange = (e) => {
    let inputValue = e.target.value
    // Remove non-digit characters and limit to 10 digits
    inputValue = inputValue.replace(/\D/g, '').slice(0, 10)
    setPhone(inputValue)
  }

  return (
    <Cover
    // onClick={() => {
    //   setConfirmModal(false)
    // }}
    >
      <MainContainer>
        <Title>Are you sure you want to leave?</Title>
        <SubContainer>
          {/* <Form>
            <InputForm
              setValue={setPhone}
              size={'small'}
              focus={'autoFocus'}
              placeholder={''}
              name={'Enter Phone Number'}
              onKeyDown={handleEnter}
              value={phone}
              type="tel"
              minLength={10}
              maxLength={10}
            />
            {phone == '' && <ErrorMsg>{errorMsg && errorMsg}</ErrorMsg>}
          </Form> */}
          <FormDiv>
            {/* <Name>Phone Number</Name> */}
            <div style={{ position: 'relative' }}>
              <FormInput
                setValue={setPhone}
                name={'Phone Number'}
                // onKeyDown={handleEnter}
                focus={'autoFocus'}
                value={phone}
                type="tel"
                id="Mobile"
                maxLength={10}
                className="active"
                placeholder="Mobile number"
                onChange={handleInputChange}
              />
            </div>
          <ErrorMsg>{errorMsg && errorMsg}</ErrorMsg>
          </FormDiv>
        </SubContainer>
        <Bottom>
          <SecondaryButton
            onClick={() => {
              setConfirmModal(false)
            }}
          >
            CANCEL
          </SecondaryButton>
          <PrimaryButton
            onClick={() => {
              if (phone !== '') {
                setOtpModal(true)
                setConfirmModal(false)
                handleClick()
              }
              else{
                setErrorMsg("Please enter phone number")
              }
            }}
          >
            Confirm
          </PrimaryButton>
        </Bottom>
      </MainContainer>
    </Cover>
  )
}
const Cover = styled.div`
  width: 100%;
  height: 100vh;
  background: #14111194;
  position: fixed;
  top: 0;
  left: 0;
`
const MainContainer = styled.div`
  padding: 32px;
  background: ${THEME_COLORS.white};
  box-shadow: 12px 25px 25px 25px rgba(0, 0, 0, 0.15);
  /* border-radius: 16px; */
  position: absolute;
  width: 40%;
  top: 25%;
  left: 30%;
  z-index: 11111;
  @media screen and (max-width: 1280px) {
    width: 40%;
  }
  @media screen and (max-width: 1080px) {
    width: 50%;
    left: 25%;
  }
  @media screen and (max-width: 980px) {
    width: 55%;
    left: 22%;
  }
  @media screen and (max-width: 768px) {
    width: 65%;
    left: 18%;
  }
  @media screen and (max-width: 640px) {
    width: 80%;
    left: 10%;
  }
  @media screen and (max-width: 480px) {
    width: 90%;
    left: 5%;
    top: 20%;
  }
`
const Title = styled.h3`
  font-family: 'poppins-medium';
  color: ${THEME_COLORS.background_1};
  font-size: 22px;
  margin-bottom: 30px;
  @media screen and (max-width: 640px) {
    margin-bottom: 20px;
    font-size: 20px;
  }
  @media screen and (max-width: 340px) {
    font-size: 18px;
  }
`
const SubContainer = styled.div`
  display: flex;
  margin-bottom: 30px;
`

const Bottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const Form = styled.div`
  width: 100%;
  .placeholder {
    color: red;
  }
`
const ErrorMsg = styled.p`
  font-size: 12px;
  font-family: 'poppins-regular';
  color: red;
  margin-bottom: 12px;
`
const PrimaryButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  margin-left: 20px;
  cursor: pointer;
  color: ${THEME_COLORS.white};
  font-family: 'poppins-semibold';
  border-radius: 6px;
  width: max-content;
  background-color: ${THEME_COLORS.secondary};
  border: 2px solid ${THEME_COLORS.secondary};
  font-size: 14px;

  @media screen and (max-width: 480px) {
    /* padding: 10px 30px; */
  }
  @media screen and (max-width: 360px) {
    /* padding: 10px 30px; */
    font-size: 13px;
  }
`
const SecondaryButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  margin-left: 20px;
  cursor: pointer;
  color: ${THEME_COLORS.secondary};
  border-radius: 6px;
  width: max-content;
  background-color: ${THEME_COLORS.white};
  border: 2px solid ${THEME_COLORS.secondary};
  font-family: 'poppins-medium';
  font-size: 14px;
  cursor: pointer;
  @media screen and (max-width: 480px) {
    /* padding: 10px 30px; */
  }
  @media screen and (max-width: 360px) {
    /* padding: 10px 30px; */
    font-size: 13px;
  }
`
const FormDiv = styled.div`
  /* display: flex;
  flex-direction: column;
  justify-content: flex-end; */
  width: 100%;
`
const Name = styled.label`
  font-family: 'poppins-regular';
  color: ${THEME_COLORS.border_1};
  margin-bottom: 4px;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
`
const FormInput = styled.input`
    font-family: "poppins-medium";
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    /* color: ${THEME_COLORS.text_2}; */
    width: 100%;
    height: 48px;
    border: 1px solid ${THEME_COLORS.border_3};
    border-radius: 4px;
    padding: 0 10px;
    background: ${THEME_COLORS.white};
    &:focus-within {
        background: ${THEME_COLORS.white};
        border: 1.5px solid #126f39;
    }
    &:hover {
        background: ${THEME_COLORS.white};
        /* border: 1px solid ${THEME_COLORS.primary}; */
    }
    /* &.error {
        border: 1px solid ${THEME_COLORS.error_text};
    }
    &.success {
        border: 1px solid ${THEME_COLORS.success_text};
    }
    &.warning {
        border: 1px solid ${THEME_COLORS.warning_text};
    } */
`
const ErrorDiv = styled.div`
  height: ${(props) => (props.size === 'small' ? '3px' : '16px')};
`
const Error = styled.p`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: fadeIn 0.5s;
  font-family: 'poppins-regular';
  font-size: 12px;
  &.error {
    color: ${THEME_COLORS.error};
  }
  &.success {
    color: ${THEME_COLORS.success};
  }
  &.warning {
    color: ${THEME_COLORS.warning};
  }
`
