import React from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'
import about_1 from '../../../assets/images/new-spot.png'
import download from '../../../assets/images/download.svg'
import arrow from '../../../assets/images/arrow-narrow-right.svg'

import { Link } from 'react-router-dom'

function Spotlight() {
  return (
    <>
      <div className="wrapper">
        <AboutTop className="first">
          <Content>
            <Title>
              Your <span>Ultimate</span> Health and Wellness{' '}
              <span>Solution</span>
            </Title>
            <SubTitle>
              We transform lives through comprehensive health resources and
              expert guidance available now.
            </SubTitle>
            <ButtonContainer>
              <BuyButton
                to={'/productlist'}
                className="category_button"
                onClick={() => {}}
              >
                Explore
                <DwnldIcon src={arrow} alt="image" />
              </BuyButton>
            </ButtonContainer>
          </Content>
          <ImageCOntainer>
            <Image>
              <Img src={about_1} alt="Image" />
            </Image>
          </ImageCOntainer>
        </AboutTop>
      </div>
    </>
  )
}

export default Spotlight
const AboutTop = styled.div`
  /* width: 90%;
    max-width: 1350px;
    margin: 0 auto; */
  padding: 100px 0;
  display: flex;
  gap: 60px;
  align-items: center;
  @media screen and (max-width: 1080px) {
    gap: 30px;
    padding: 70px 0;
  }
  @media screen and (max-width: 980px) {
    flex-direction: column-reverse;
    padding: 50px 0;

    &:nth-child(2) {
      flex-direction: column;
    }
  }
  @media screen and (max-width: 640px) {
    padding: 50px 0;
  }
`
const Content = styled.div`
  width: 50%;
  @media screen and (max-width: 980px) {
    width: 100%;
  }
`
const Title = styled.h4`
  font-family: 'poppins-medium';
  color: #1d1d46;
  font-size: 40px;
  margin-bottom: 35px;
  span {
    color: #106f39;
  }
  @media screen and (max-width: 1280px) {
    font-size: 34px;
  }
  @media screen and (max-width: 1080px) {
    font-size: 32px;
  }
  @media screen and (max-width: 980px) {
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 25px;
    font-size: 30px;
  }
  @media screen and (max-width: 480px) {
    font-size: 26px;
  }
  @media screen and (max-width: 360px) {
    font-size: 24px;
  }
`
const SubTitle = styled.p`
  color: #1d1d46;
  font-family: 'poppins-regular';
  font-size: 20px;
  margin-bottom: 40px;
  @media screen and (max-width: 980px) {
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    font-size: 18px;
    /* margin-bottom: 20px; */
  }
  @media screen and (max-width: 640px) {
    font-size: 16px;
  }
  @media screen and (max-width: 360px) {
    font-size: 15px;
    /* text-align: justify; */
  }
`
const AppTitle = styled.p`
  color: #1d1d46;
  font-family: 'poppins-regular';
  /* margin-bottom: 22px; */
  line-height: 36px;
  font-size: 24px;
  @media screen and (max-width: 1280px) {
    font-size: 22px;
  }
  @media screen and (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 20px;
  }
`
const AppButton = styled.div`
  border-radius: 6px;
  width: max-content;
  cursor: pointer;

  /* @media screen and (max-width: 1280px) {
        font-size: 20px;
    }
    @media screen and (max-width: 768px) {
        font-size: 18px;
    }
    @media screen and (max-width: 480px) {
        padding: 11px;
        font-size: 16px;
    } */
`
const BuyButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  cursor: pointer;
  color: ${THEME_COLORS.white};
  font-family: 'poppins-semibold';
  border-radius: 6px;
  width: max-content;
  background-color: ${THEME_COLORS.secondary};
  border: 2px solid ${THEME_COLORS.secondary};
  font-size: 16px;
  font-weight: ${(props) => (props.size === 'small' ? '500' : '600')};
  @media screen and (max-width: 768px) {
    font-size: 14px;
    padding: 5px 15px;
  }
  @media screen and (max-width: 360px) {
    font-size: 12px;
    /* padding: 10px; */
  }
`
const ButtonContainer = styled.p`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: max-content;
  @media screen and (max-width: 980px) {
    margin: 0 auto;
  }
`
const DwnldIcon = styled.img`
  display: block;
  margin-left: 10px;
`
const ImageCOntainer = styled.div`
  width: 60%;
  position: relative;
  @media screen and (max-width: 1200px) {
    width: 55%;
  }
  @media screen and (max-width: 980px) {
    margin: 0 auto;

    width: 70%;
  }
  @media screen and (max-width: 768px) {
    width: 80%;
  }
  @media screen and (max-width: 640px) {
    width: 90%;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
  }
  /* @media screen and (max-width: 360px) {
    width: 90%;
  } */
`
const Image = styled.div`
  width: 100%;
  &.intro {
    z-index: 1;
    position: absolute;
    /* &::after {
      content: '';
      width: 100px;
      height: 100px;
      background-color: #b2ffd1;
      position: absolute;
      top: -30px;
      left: -40px;
      z-index: -1;
    }
    ::before {
      content: '';
      width: 100px;
      height: 100px;
      background-color: #b2ffd1;
      position: absolute;
      bottom: -30px;
      right: -40px;
      z-index: -1;
    } */
  }
`
const Img = styled.img`
  width: 100%;
  display: block;
`
