export const THEME_COLORS = {
    //main
    primary: "#E71C22",
    secondary: "#106F39",
    light_orange: "#FDF7F0",
    white: "#FFFFFF",
    footer: "#252525",

    //dark
    background_1: "#161616",
    background_2: "#303030",
    background_3: "#464646",
    background_4: "#6B6B6B",
    background_5: "#8E8E8E",

    //Light
    border_1: "#AAAAAA",
    border_2: "#BBBBBB",
    border_3: "#DBDBDB",
    border_4: "#EEEEEE",
    border_5: "#F4F4F4",

    //text color
    text_1: "#000000",
    text_2: "#403F3C",

    //label color
    success: "#0FC642",
    info: "#0097D8",
    warning: "#D87400",

    //chip color
    yellow_chip: "#FFBA01",
    green_chip: "#82DD55",

    //box shadow
    box_shadow_1: "#000000b3",
};
