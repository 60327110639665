import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import medicine from '../../../assets/icons/search_med.svg'
import { THEME_COLORS } from '../../../ThemeConfig'
import { Context } from '../../../contexts/Store'
import { baseConfig } from '../../../axiosConfig'
import EmptyPage from '../empty-page/EmptyPage'
import empytWishlist from '../../../assets/icons/empty-wishlist.svg'

export default function SearchModal() {
  const { state } = useContext(Context)
  const [searchDetails, setSearchDetails] = useState({})

  const getDetailes = () => {
    let accessToken = state.user_details.access
    // setTimeout(() => {
    baseConfig
      .get('filter/search/', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        // console.log(response.data);
        const { data, StatusCode } = response.data
        if (StatusCode === 6000) {
          setSearchDetails(data)
          console.log(searchDetails.data)
        }
        // setTimeout(() => {
        //     setLoading(false);
        // }, 100);
      })
      .catch((error) => {
        // setTimeout(() => {
        //     setLoading(false);
        // }, 100);
      })
    // }, 100);
  }
  useEffect(() => {
    getDetailes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <MainContainer>
        {searchDetails?.length >= 0 ? (
          <ProductSearchBox>
            <LeftBox>{/* <Img src={searchDetails.image} /> */}</LeftBox>
            <RightBox>
              <Title>{searchDetails.name}</Title>
              <Tablet>{searchDetails.name}</Tablet>
            </RightBox>
          </ProductSearchBox>
        ) : (
          <EmptyContainer>
            <EmptyPage
              image={empytWishlist}
              status={'Your Search is Empty !'}
            />
          </EmptyContainer>
        )}
        {/* <ProductSearchBox>
                    <LeftBox>
                        <Img src={medicine} />
                    </LeftBox>
                    <RightBox>
                        <Title>Curcumin Tablet</Title>
                        <Tablet>Curcumin Tablet</Tablet>
                    </RightBox>
                </ProductSearchBox>
                <ProductSearchBox>
                    <LeftBox>
                        <Img src={medicine} />
                    </LeftBox>
                    <RightBox>
                        <Title>Curcumin Tablet</Title>
                        <Tablet>Curcumin Tablet</Tablet>
                    </RightBox>
                </ProductSearchBox>
                <ProductSearchBox>
                    <LeftBox>
                        <Img src={medicine} />
                    </LeftBox>
                    <RightBox>
                        <Title>Curcumin Tablet</Title>
                        <Tablet>Curcumin Tablet</Tablet>
                    </RightBox>
                </ProductSearchBox> */}
      </MainContainer>
    </>
  )
}
const MainContainer = styled.div`
  padding: 42px;
`
const ProductSearchBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 39px;
`
const LeftBox = styled.div`
  width: 72px;
  height: 72px;
`
const Img = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`
const RightBox = styled.div``
const Title = styled.h4`
  font-size: 24px;
  line-height: 36px;
  color: ${THEME_COLORS.background_1};
  font-family: 'poppins-medium';
  margin-bottom: 6px;
  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 26px;
  }
  @media screen and (max-width: 640px) {
    font-size: 18px;
    line-height: 14px;
  }
`
const Tablet = styled.p`
  color: ${THEME_COLORS.secondary};
  font-size: 20px;
  line-height: 30px;
  font-family: 'poppins-medium';
  @media screen and (max-width: 640px) {
    font-size: 16px;
  }
  @media screen and (max-width: 340px) {
    font-size: 13px;
  }
`
const EmptyContainer = styled.div`
  min-height: calc(100vh - 410px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
