import React from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'
import nodata from '../../../assets/icons/error page not.svg'
import { useNavigate } from 'react-router-dom'

export default function NoDataFound() {
  const navigate = useNavigate()
  return (
    <Container>
      <SubContainer>
        <NoData>
          <Img src={nodata} alt="Image" />
        </NoData>
        <Text>
          Page not found{' '}
          <HomeLink
            onClick={() => {
              navigate('/')
            }}
          >
            Back to home
          </HomeLink>
        </Text>
      </SubContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
  padding: 0 15px;
  margin-top: 10px;
  min-height: calc(100vh - 60vh);
  width: '100%';
`
const SubContainer = styled.div`
  flex-wrap: wrap;
`
const Text = styled.text`
    /* color: ${THEME_COLORS.text_4}; */
    color: ${THEME_COLORS.border_2};
    font-size: 20px;
    font-family: "poppins-medium";
    line-height: 30px;
`
const NoData = styled.div`
  width: 302px;
  height: 269px;
  margin-bottom: 28px;
`
const Img = styled.img`
  width: 100%;
  height: 100%;
  display: block;
`
const HomeLink = styled.a`
  color: ${THEME_COLORS.secondary};
  text-decoration: underline;
  font-size: 20px;
  line-height: 30px;
  font-family: 'poppins-medium';
  cursor: pointer;
`
