import React from "react";
import styled from "styled-components";
import Search from "./Search";
import SearchModal from "./SearchModal";

export default function SearchModalMobile() {
    return (
        <>
            <MainContainer>
                <SearchBar>
                    <Search />
                </SearchBar>
                <SearchResulte>
                    <SearchModal />
                </SearchResulte>
            </MainContainer>
        </>
    );
}
const MainContainer = styled.div``;
const SearchBar = styled.div``;
const SearchResulte = styled.div``;
