import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import OtpInput from 'react-otp-input'
import './otp.css'
import { THEME_COLORS } from '../../../../ThemeConfig'
import { AuthContext } from '../../../../contexts/AuthStore'
import { Context } from '../../../../contexts/Store'
import { baseConfig } from '../../../../axiosConfig'

export default function DeleteOtpModal({ setOtpModal, setFinalModal }) {
  const [otp, setOtp] = useState('')

  const handleChange = (e) => {
    setOtp(e)
  }
  const { authstate, authdispatch } = useContext(AuthContext)
  const [email, setEmail] = useState(authstate?.auth_modal_details?.email)
  const [errorCodeMsg, setErrorCodeMsg] = useState('')
  const [message, setMessage] = useState('')
  const [errorMsg, setError] = useState('')
  const { state, dispatch } = useContext(Context)
  const phone = authstate?.auth_modal_details?.phone
  let access_token = state.user_details.access

  const closeModal = () => {
    const auth_modal_details = {
      auth_modal_name: '',
      is_auth_modal: false,
    }
    authdispatch({
      type: 'AUTH_MODAL',
      auth_modal_details,
    })
  }
  const validation = () => {
    if (!otp || otp.length !== 4) {
      setError('Invalid otp')
    } else {
      verifyOtp()
    }
  }

  const verifyOtp = () => {
    const formData = new FormData()
    formData.append('phone', phone)
    formData.append('otp', otp)

    baseConfig
      .post(`users/delete-account/`, formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        const { StatusCode, data, token, message } = response.data
        if (StatusCode === 6000) {
          const user_details = {
            is_verified: true,
            role: token.role,
            access: token.access,
            refresh: token.refresh,
          }
          dispatch({
            type: 'UPDATE_USER',
            user_details,
          })
          closeModal()
        } else if (StatusCode === 6001) {
          setErrorCodeMsg(message)
        }
      })
      .catch((error) => {})
  }
  return (
    <Cover>
      <MainContiner>
        <TopBox>
          <RightTitle>OTP Verification</RightTitle>
          <Text>Verify the OTP that we have sent to your number</Text>
        </TopBox>
        <OtpContainer>
          <Form>
            <OtpInput
              onChange={handleChange}
              value={otp}
              numInputs={4}
              autoFocus
              separator={<Seperate>---</Seperate>}
              inputStyle="input_otp"
              containerStyle="container_otp"
            />
          </Form>
        </OtpContainer>
        <BottomContainer>
          <SecondaryButton
            onClick={() => {
              setOtpModal(false)
            }}
          >
            CANCEL
          </SecondaryButton>
          <PrimaryButton
            onClick={() => {
              setOtpModal(false)
              setFinalModal(true)
              verifyOtp()
            }}
          >
            Confirm
          </PrimaryButton>
        </BottomContainer>
      </MainContiner>
    </Cover>
  )
}
const Cover = styled.div`
  width: 100%;
  height: 100vh;
  background: #14111194;
  position: fixed;
  top: 0;
  left: 0;
  z-index:1000;
`
const MainContiner = styled.div`
  padding: 32px;
  background: ${THEME_COLORS.white};
  box-shadow: 12px 25px 25px 25px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  position: absolute;
  width: 40%;
  top: 25%;
  left: 30%;
  z-index: 11111;
  @media screen and (max-width: 1200px) {
    width: 50%;
    left: 26%;
  }
  @media screen and (max-width: 1080px) {
    width: 50%;
  }
  @media screen and (max-width: 980px) {
    width: 55%;
    left: 23%;
    /* top: 20%; */
  }
  @media screen and (max-width: 768px) {
    width: 65%;
    left: 18%;
  }
  @media screen and (max-width: 640px) {
    width: 75%;
    left: 13%;
  }
  @media screen and (max-width: 480px) {
    width: 85%;
    left: 8%;
  }
  @media screen and (max-width: 360px) {
    width: 95%;
    left: 3%;
    top: 20%;
  }
`

const TopBox = styled.div`
  margin-bottom: 48px;
  @media screen and (max-width: 860px) {
    margin-bottom: 38px;
  }
  @media screen and (max-width: 728px) {
    margin-bottom: 28px;
  }
`
const RightTitle = styled.h4`
  color: ${THEME_COLORS.background_1};
  font-size: 24px;
  font-family: 'poppins-medium';
`
const Form = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  gap: 16px;
  width: 400px;

  @media screen and (max-width: 1280px) {
    width: 400px;
  }
  @media screen and (max-width: 860px) {
    width: 300px;
    /* margin-bottom: 100px; */
  }
  @media screen and (max-width: 728px) {
    width: 250px;
    margin-bottom: 38px;
  }
`

const BottomContainer = styled.div`
  /* width: 70%; */
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 640px) {
    padding-top: 8px;
  }
`

const Seperate = styled.div`
  color: ${THEME_COLORS.white};
  width: 15px;
`
const Text = styled.h4`
  color: ${THEME_COLORS.border_1};
  font-weight: 400;
  font-size: 14px;
  font-family: 'poppins-medium';
  line-height: 28px;
  align-items: flex-start;
  @media screen and (max-width: 980px) {
    /* font-size: 17px; */
  }
  @media screen and (max-width: 768px) {
    /* font-size: 15px; */
  }
`

const OtpContainer = styled.span`
  width: 100%;
`
const PrimaryButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  margin-left: 20px;
  cursor: pointer;
  color: ${THEME_COLORS.white};
  font-family: 'poppins-semibold';
  border-radius: 6px;
  width: max-content;
  background-color: ${THEME_COLORS.secondary};
  border: 2px solid ${THEME_COLORS.secondary};
  font-size: 14px;

  @media screen and (max-width: 480px) {
    /* padding: 10px 30px; */
  }
  @media screen and (max-width: 360px) {
    /* padding: 10px 30px; */
    font-size: 13px;
  }
`
const SecondaryButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  margin-left: 20px;
  cursor: pointer;
  color: ${THEME_COLORS.secondary};
  border-radius: 6px;
  width: max-content;
  background-color: ${THEME_COLORS.white};
  border: 2px solid ${THEME_COLORS.secondary};
  font-family: 'poppins-medium';
  font-size: 14px;
  cursor: pointer;
  @media screen and (max-width: 480px) {
    /* padding: 10px 30px; */
  }
  @media screen and (max-width: 360px) {
    /* padding: 10px 30px; */
    font-size: 13px;
  }
`
