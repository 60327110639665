import React from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'
import about from '../../../assets/images/about.png'

function About() {
  return (
    <>
      <MainContainer>
        {' '}
        <AboutTop className="intro">
          <ImageCOntainer>
            <Image className="intro">
              <Img src={about} alt="Image" />
            </Image>
          </ImageCOntainer>
          <Content>
            <Title>
              <span>About</span> Pacess
            </Title>
            <SubTitle>
              Pacess Wellness India LLP founded in Sep 2022 is the 7th venture
              of the "Wake Economic Forum," aiming to create a significant
              impact in the global nutraceutical industry. With ambitious goals,
              we embrace challenges and possibilities, valuing teamwork and
              trust to achieve success. Discover ZAINUTRA from Pacess Wellness
              India LLP – your source for high-quality nutraceutical
              supplements. Elevate well-being and longevity with our premium
              offerings.
            </SubTitle>
          </Content>
        </AboutTop>
      </MainContainer>
    </>
  )
}

export default About
const MainContainer = styled.div`
  background-color: #f5fff9;
  width: 100%;
  padding: 100px 0;
  @media screen and (max-width: 1200px) {
    padding: 0 0 70px 0;
  }
  @media screen and (max-width: 980px) {
    padding: 0 0 50px 0;
  }
`
const AboutTop = styled.div`
  width: 80%;
  max-width: 1350px;
  margin: 0 auto;
  display: flex;
  gap: 60px;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 980px) {
    flex-direction: column-reverse;
    gap: 30px;
  }
`
const Content = styled.div`
  width: 60%;
  @media screen and (max-width: 980px) {
    width: 100%;
  }
`
const Title = styled.h4`
  font-family: 'poppins-medium';
  color: #1d1d46;
  font-size: 40px;
  margin-bottom: 35px;
  span {
    color: #106f39;
  }
  @media screen and (max-width: 1280px) {
    font-size: 34px;
  }
  @media screen and (max-width: 1080px) {
    font-size: 32px;
  }
  @media screen and (max-width: 980px) {
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 25px;
    font-size: 30px;
  }
  @media screen and (max-width: 480px) {
    font-size: 26px;
  }
`
const SubTitle = styled.p`
  color: #1d1d46;
  font-family: 'poppins-regular';
  font-size: 18px;
  text-align: justify;
  @media screen and (max-width: 980px) {
    text-align: justify;
    font-size: 16px;
  }
  @media screen and (max-width: 640px) {
    font-size: 14px;
    text-align: justify;
  }
`

const ImageCOntainer = styled.div`
  width: 40%;
  @media screen and (max-width: 1280px) {
    width: 50%;
  }
  @media screen and (max-width: 1080px) {
    width: 55%;
  }
  @media screen and (max-width: 980px) {
    margin: 0 auto;
    width: 70%;
  }
  @media screen and (max-width: 480px) {
    width: 85%;
  }
  @media screen and (max-width: 360px) {
    width: 95%;
  }
`
const Image = styled.div`
  width: 100%;
`
const Img = styled.img`
  width: 100%;
  display: block;
`
