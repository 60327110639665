import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import loginimage from '../../../assets/images/Remedy-rafiki 1.png'
import PrimaryButton from '../../includes/buttons/PrimaryButton'
import close from '../../../assets/icons/close-square.svg'
import OtpInput from 'react-otp-input'
import { baseConfig } from '../../../axiosConfig'
import { AuthContext } from '../../../contexts/AuthStore'
import './otp.css'
import { Context } from '../../../contexts/Store'
import { THEME_COLORS } from '../../../ThemeConfig'

export default function LoginOtp({ setModal }) {
  const [otp, setOtp] = useState('')
  const { authstate, authdispatch } = useContext(AuthContext)
  const [email, setEmail] = useState(authstate?.auth_modal_details?.email)
  const [errorCodeMsg, setErrorCodeMsg] = useState('')
  const [message, setMessage] = useState('')
  const [errorMsg, setError] = useState('')
  const [counter, setCounter] = useState(30)
  const [lastSentTime, setLastSentTime] = useState(null);
  const [remainingTime, setRemainingTime] = useState(0);
  const { state, dispatch } = useContext(Context)
  const phone = authstate?.auth_modal_details?.phone
  console.log(phone, 'loginotp')
  // const handleChange = (e) => {
  //   const numericValue = e.target.value.replace(/[^0-9]/g, '')
  //   setOtp(numericValue)
  // }
  const closeModal = () => {
    const auth_modal_details = {
      auth_modal_name: '',
      is_auth_modal: false,
    }
    authdispatch({
      type: 'AUTH_MODAL',
      auth_modal_details,
    })
  }
  const validation = () => {
    if (!otp || otp.length !== 4) {
      setError('Invalid otp')
    } else {
      verifyOtp()
    }
  }
  const [countdown, setCountdown] = useState(0)
  const [resendDisabled, setResendDisabled] = useState(false)

  // Function to start the countdown
  const startCountdown = () => {
    setCountdown(30)
    setResendDisabled(true)
    decrementCountdown()
  }

  const handleChange = (otp) => {
    // Check if all characters are numeric
    if (/^\d+$/.test(otp)) {
      setOtp(otp);
    }
  };
  // Function to decrement the countdown
  const decrementCountdown = () => {
    if (countdown > 0) {
      setCountdown(countdown - 1)
    } else {
      setResendDisabled(false)
    }
  }

  useEffect(() => {
    const countdownTimer = setTimeout(() => {
      if (counter > 0) {
        setCountdown(counter - 1);
      }
    }, 1000);

    return () => clearTimeout(countdownTimer);
  }, [counter])


  const handleResendOTP = () => {
    setCounter(60)
    for (let i = 30; i >= 0; i--) {
      setCounter(i)
      console.log(counter)
    }
  }
  useEffect(() => {
    setCounter(60)
    for (let i = 30; i >= 0; i--) {
      setCounter(i)
      console.log(counter)
    }
  },  [counter])

  const verifyOtp = () => {
    if (otp.length == 4) {
      const formData = new FormData()
      formData.append('phone', phone)
      formData.append('otp', otp)

      baseConfig
        .post(`users/verify-otp/`, formData, {})
        .then((response) => {
          const { StatusCode, data, token, message } = response.data
          if (StatusCode === 6000) {
            const user_details = {
              is_verified: true,
              role: token.role,
              access: token.access,
              refresh: token.refresh,
            }
            dispatch({
              type: 'UPDATE_USER',
              user_details,
            })
            closeModal()
          } else if (StatusCode === 6001) {
            setErrorCodeMsg(message)
          }
        })
        .catch((error) => {})
    } else {
      setError('Enter a valid OTP')
    }
  }
// const timestamp = lastSentTime;
// const [, time] = timestamp.split("T");
// console.log(time);

  const verifyResendOtp = () => {
    setError(false)
    const formData = new FormData()
    formData.append('phone', phone)

    baseConfig
      .post('users/send-otp/', formData, {})
      .then((response) => {
        const { StatusCode, data, token, message } = response.data
        console.log(response,"sent otp response")
        if (StatusCode === 6000) {
          const auth_modal_details = {
            auth_modal_name: 'login_otp',
            is_auth_modal: true,
            phone: phone,
          }
          authdispatch({
            type: 'AUTH_MODAL',
            auth_modal_details,
          })
          setLastSentTime(data.timeout);
          
          const user_details = {
            is_verified: true,
            role: token.role,
            access: token.access,
            refresh: token.refresh,
          }
          dispatch({
            type: 'UPDATE_USER',
            user_details,
          })
        }
        else if (StatusCode === 6001) {
          setErrorCodeMsg(message)
        }
      })
      .catch((error) => {})
  }
  const formatTime = (ms) => {
    const minutes = Math.floor(ms / 60000);
    const seconds = Math.floor((ms % 60000) / 1000);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };
  return (
    <MainContiner>
      <LeftBox>
        <div>
          <Heading>Hello there 👋🏻</Heading>
          <Title>
            Welcome to the wellness journey! Browse our range of top-quality
            products and make a purchase today to take the first step towards a
            healthier you.
          </Title>
        </div>
        <ImageBox>
          <Img src={loginimage} alt="Image" />
        </ImageBox>
      </LeftBox>
      <RightBox>
        <TopBox>
          <RightTitle>OTP Verification</RightTitle>
          <Text>Verify the OTP that we have sent to your Phone</Text>
        </TopBox>
        <OtpContainer>
          <Form>
            <OtpInput
              onChange={(e) => {
                // e.target.value.replace(/[^0-9]/g, '')
                // setOtp(e)
                handleChange(e)
              }}
              value={otp}
              numInputs={4}
              autoFocus
              type="otp"
              separator={<Seperate>---</Seperate>}
              inputStyle="input_otp"
              containerStyle="container_otp"
            />
          </Form>
           <Resend
      // onClick={() => {if(counter == 0){
      //   verifyResendOtp();
      // }
      // }}
    >
      {/* {counter > 0 ? (
        <OtpText className={counter == 0 && "active" }>Resend OTP in 00:{countdown} sec</OtpText>
      ) : (
        <OtpText onClick={startCountdown}>Resend OTP</OtpText>
      )} */}
       <div>
      {/* {remainingTime === 0 ? ( */}
        <button onClick={verifyResendOtp}>Resend OTP</button>
      {/* ) : (
        <button disabled>{`Resend OTP (${formatTime(remainingTime)})`}</button>
      )} */}
    </div>
    </Resend>
          <ErrorMsg className={errorCodeMsg && 'active'}>
            {errorCodeMsg}
          </ErrorMsg>
          <ErrorMsg className={errorMsg && 'active'}>{errorMsg}</ErrorMsg>
          <div className="context">
            <PrimaryButton
              className="category_button"
              text={'Verify'}
              onClick={validation}
              size={'small'}
            />
          </div>
        </OtpContainer>
        <BottomContainer>
          <TextSubTitle>
            Back to{' '}
            <HomeLink
              onClick={() => {
                const auth_modal_details = {
                  auth_modal_name: 'login',
                  is_auth_modal: true,
                }
                authdispatch({
                  type: 'AUTH_MODAL',
                  auth_modal_details,
                })
              }}
            >
              Login
            </HomeLink>
          </TextSubTitle>
        </BottomContainer>
        <RightIcon
          onClick={() => {
            closeModal()
          }}
        >
          <Img src={close} alt="Image" />
        </RightIcon>
      </RightBox>
    </MainContiner>
  )
}
const MainContiner = styled.div`
  display: flex;
  max-width: 794px;
  @media screen and (max-width: 128px) {
    width: 100%;
  }
  @media screen and (max-width: 640px) {
    flex-direction: column;
    height: 100%;
  }
`
const LeftBox = styled.div`
  background: #56b57f;
  width: 40%;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media screen and (max-width: 640px) {
    width: 100%;
  }
`
const Heading = styled.h3`
  font-size: 24px;
  line-height: 36px;
  color: ${THEME_COLORS.white};
  margin-bottom: 16px;
  font-family: 'poppins-medium';
  @media screen and (max-width: 1425px) {
    min-width: max-content;
    font-size: 23px;
  }
  @media screen and (max-width: 1329px) {
    min-width: 100%;
    font-size: 24px;
  }
  @media screen and (max-width: 1280px) {
    font-size: 22px;
    width: max-content;
  }
  @media screen and (max-width: 980px) {
    font-size: 22px;
    width: 100%;
  }
`
const Title = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: ${THEME_COLORS.border_5};
  font-family: 'poppins-medium';
`
const ImageBox = styled.div`
  width: 156px;
  height: 143px;
  margin: 0 auto;
  @media screen and (max-width: 640px) {
    display: none;
  }
`
const Img = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`
const RightBox = styled.div`
  width: 60%;
  padding: 64px;
  background: ${THEME_COLORS.white};
  &.context {
    box-shadow: 7px 11px 30px rgba(0, 0, 0, 0.06);
  }
  @media screen and (max-width: 1280px) {
    padding: 40px 36px;
  }
  @media screen and (max-width: 640px) {
    width: 100%;
    padding: 24px;
  }
`
const TopBox = styled.div`
  margin-bottom: 48px;
  /* @media screen and (max-width: 860px) {
    margin-bottom: 38px;
  } */
  @media screen and (max-width: 728px) {
    margin-bottom: 28px;
  }
`
const RightTitle = styled.h4`
  color: ${THEME_COLORS.background_1};
  font-size: 24px;
  font-family: 'poppins-medium';
`
const Form = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 16px;
  width: 400px;
  font-family: 'poppins-regular';
  @media screen and (max-width: 1280px) {
    width: 400px;
  }

  @media screen and (max-width: 728px) {
    width: 250px;
    margin-bottom: 38px;
  }
  &.input_otp {
    font-family: 'poppins-regular';
  }
`
const ErrorMsg = styled.p`
  font-size: 12px;
  font-family: 'poppins-regular';
  color: red;
  margin-bottom: 20px;
  opacity: 0;
  &.active {
    opacity: 1;
  }
`
const BottomContainer = styled.div`
  @media screen and (max-width: 640px) {
    padding-top: 8px;
  }
`
// const BottomTop = styled.div`
// 	display: flex;
// 	flex-wrap: wrap;
// 	justify-content: center;
// 	align-items: center;
// 	border-bottom: 1px solid #dbdbdb;
// 	margin-bottom: 34px;
// 	padding: 16px 0;
// 	@media screen and (max-width: 980px) {
// 		padding: 8px 0;
// 		margin-bottom: 26px;
// 	}
// 	@media screen and (max-width: 980px) {
// 		margin-bottom: 16px;
// 	}
// `;
const Seperate = styled.div`
  color: ${THEME_COLORS.white};
  width: 15px;
`
const Text = styled.h4`
  color: #161616;
  font-weight: 400;
  font-size: 13px;
  font-family: 'poppins-medium';
  line-height: 28px;
  align-items: flex-start;
  @media screen and (max-width: 980px) {
    /* font-size: 17px; */
  }
  @media screen and (max-width: 768px) {
    /* font-size: 15px; */
  }
`
// const TextSub = styled.h4`
// 	color: #aaaaaa;
// 	font-weight: 400;
// 	font-size: 14px;
// 	font-family: "poppins-medium";
// 	line-height: 28px;
// 	align-items: flex-start;
// 	@media screen and (max-width: 1280px) {
// 		/* font-size: 17px; */
// 	}
// 	@media screen and (max-width: 640px) {
// 		/* font-size: 15px; */
// 	}
// `;
const TextSubTitle = styled.h4`
  color: ${THEME_COLORS.border_1};
  font-weight: 400;
  font-size: 14px;
  font-family: 'poppins-medium';
  line-height: 28px;
  align-items: center;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 640px) {
    /* font-size: 15px; */
  }
`
const HomeLink = styled.a`
  color: ${THEME_COLORS.secondary};
  text-decoration: underline;
  font-size: 14px;
  line-height: 30px;
  font-family: 'poppins-medium';
  cursor: pointer;
  margin-left: 6px;
  @media screen and (max-width: 1280px) {
    /* font-size: 17px; */
  }
  @media screen and (max-width: 768px) {
    /* font-size: 15px; */
  }
`
const OtpContainer = styled.span`
  width: 100%;
`
const RightIcon = styled.span`
  position: absolute;
  display: inline-block;
  width: 32px;
  height: 32px;
  top: 10px;
  right: 10px;
  cursor: pointer;
`
const OtpText = styled.span`
opacity: 0.5;
text-decoration: underline;
    font-size: 12px;
    font-family: 'poppins-regular';
    color: black;
    cursor: pointer;
    /* position: absolute; */
    right: 140px;
    top: 220px;
    @media screen and (max-width: 640px) {
      /* font-size: 15px; */
    }
    &.active{
opacity: 1;

    }
`
// const Resend = styled.p`
//     text-decoration: underline;
//     font-size: 12px;
//     font-family: "poppins-regular";
//     color: black;
//     margin-bottom: 140px;
//     cursor: pointer;
//     position: absolute;
//     right: 140px;
//     margin-top: 10px;
// `;
const Resend = styled.div`
  p {
    
  }
`
