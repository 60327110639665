import React, { useContext, useState } from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../../ThemeConfig";
import PrimaryButton from "../../includes/buttons/PrimaryButton";
import SecondaryButton from "../../includes/buttons/SecondaryButton";
import apple from "../../../assets/images/delete-modal-apple.svg";
import { baseConfig } from "../../../axiosConfig";
import { Context } from "../../../contexts/Store";

export default function EditProfilePicModal({
    editModal,
    setEditModal,
    handleFileChange,
}) {
    const { state } = useContext(Context);
    const [isUploadpic, setUploadPic] = useState(false);
    const [isRemovePic, setRemovePic] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [Photo, setPhoto] = useState("");
    const [files, setFile] = useState(null);
    // console.log(state, "remove");
    // const photo = [];
    // const handleFileChange = async (e) => {
    //     setFile(e.target.files[0]);
    //     // photo = await e.target.files[0];
    //     console.log("handlefilechnage");

    //     if (photo) {
    //         handleProfilePicChange();
    //     }
    // };

    // const handleProfilePicChange = () => {
    //     //   if(files){
    //     let accessToken = state.user_details.access;
    //     const formData = new FormData();
    //     formData.append("image", photo);

    //     baseConfig
    //         .post("customers/update-profile-image/", formData, {
    //             headers: {
    //                 Authorization: `Bearer ${accessToken}`,
    //             },
    //         })
    //         .then((response) => {
    //             // console.log(response.data);
    //             const { StatusCode, data, token } = response.data;
    //             if (StatusCode === 6000) {
    //                 // console.log("image upload ok ");
    //                 setRefresh(!refresh);
    //             } else if (StatusCode === 6001) {
    //                 // console.log("image upload error");
    //             }
    //         })
    //         .catch((error) => {
    //             // console.log(error.response.msg);
    //         });
    //     //   }else{
    //     //     console.log('no file found!!')
    //     //   }
    // };

    // const removePic = () => {
    //     const accessToken = state.user_details.access;
    //     // console.log(access_token, "*********");

    //     baseConfig
    //         .post(
    //             "customers/remove-profile-image/",
    //             {},
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${accessToken}`,
    //                 },
    //             }
    //         )
    //         .then((res) => {
    //             console.log(res);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // };
    const removePic = () => {
        const accessToken = state.user_details.access;

        baseConfig
            .post(
                "customers/remove-profile-image/",
                {},
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            )
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <>
            {editModal && (
                <>
                    <MainContainer>
                        <EditPhoto
                            onClick={() => {
                                setUploadPic(true);
                                setRemovePic(false);
                                editModal(false)
                            }}
                            className={isUploadpic ? " active" : ""}
                        >
                            Upload New Picture
                            <Input
                                type="file"
                                accept=".png, .jpg, *"
                                hidden
                                // onChange={(e) => {
                                //     setPhoto(e.target.value);
                                // }}
                                onChange={handleFileChange}
                                // onClick={handleProfilePicChange}
                            />
                        </EditPhoto>
                        <RemovePhoto
                            onClick={() => {
                                setRemovePic(true);
                                setUploadPic(false);
                                removePic();
                            }}
                            className={isRemovePic ? " active" : ""}
                        >
                            Remove Picture
                        </RemovePhoto>
                    </MainContainer>
                </>
            )}
        </>
    );
}

const MainContainer = styled.div`
    padding: 15px;
    background: ${THEME_COLORS.white};
    box-shadow: 12px 25px 25px 2px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    /* display: flex;
    flex-wrap: wrap;
    justify-content: end; */
    text-align: center;
    position: absolute;
    top: 190px;
    left: 135px;
    z-index: 10;
    width: 35%;
    @media screen and (max-width: 1280px) {
        width: 45%;
    }
    @media screen and (max-width: 1080px) {
        width: 50%;
    }
    @media screen and (max-width: 980px) {
        width: 55%;
    }
    @media screen and (max-width: 768px) {
        width: 75%;
    }
    @media screen and (max-width: 480px) {
        width: 98%;
    }
`;
const ImageContainer = styled.div`
    margin: 0 auto;
    width: 80px;
`;
const Icon = styled.img`
    display: block;
    width: 100%;
`;

const Title = styled.h3`
    width: 100%;
    color: ${THEME_COLORS.background_1};
    font-size: 22px;
    margin-bottom: 20px;
    font-family: "poppins-medium";
    text-align: center;
`;
const Content = styled.p`
    width: 100%;
    color: ${THEME_COLORS.background_4};
    font-size: 13px;
    margin-bottom: 40px;
    font-family: "poppins-regular";
    text-align: center;
    span {
        color: red;
    }
`;
const Button = styled.div`
    width: 70%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
`;
const EditPhoto = styled.label`
    cursor: pointer;
    padding: 10px;
    font-family: "poppins-medium";
    font-size: 14px;
    color: #8e8e8e;
    width: 100%;
    border-radius: 3px;
    display: inline-block;
    &.active {
        background-color: #106f391a;
        color: #106f39;
    }
`;
const RemovePhoto = styled.button`
    font-family: "poppins-medium";
    padding: 10px;
    font-size: 14px;
    color: #8e8e8e;
    width: 100%;
    border-radius: 3px;
    &.active {
        background-color: #106f391a;
        color: #106f39;
    }
`;
const Input = styled.input``;
