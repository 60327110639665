import React from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'
import chairman from '../../../assets/images/team/chairman.svg'
import operation_director from '../../../assets/images/team/operation-director.svg'
import dOne from '../../../assets/images/team/director-1.svg'
import dTwo from '../../../assets/images/team/Director-2.svg'
import dThree from '../../../assets/images/team/director-3.svg'
import dFour from '../../../assets/images/team/director-4.svg'
import cmo from '../../../assets/images/team/cmo.svg'
import medical from '../../../assets/images/team/medical-officer.svg'

import rightarrow from '../../../assets/icons/slick-left.svg'
import leftarrow from '../../../assets/icons/slick-right.svg'

function Team() {
  const team = [
    {
      id: 1,
      name: 'Sashidharan KP',
      image: chairman,
      designation: 'Chairman',
    },

    {
      id: 3,
      name: 'Sharafudheen V K',
      image: dOne,
      designation: 'Director',
    },
    {
      id: 4,
      name: 'Noorudheen Aboobacker',
      image: dTwo,
      designation: 'Director',
    },
    {
      id: 5,
      name: 'Satheeshan C',
      image: dThree,
      designation: 'Director',
    },
    {
      id: 6,
      name: 'Fasila Muslih',
      image: dFour,
      designation: 'Director',
    },
    {
      id: 2,
      name: 'Muneer Darimi',
      image: operation_director,
      designation: 'Operation Manager',
    },
    {
      id: 7,
      name: 'Dr. Thafseela T',
      image: cmo,
      designation: 'Chief Medical Officer',
    },
    {
      id: 8,
      name: 'Dr. Aarsha Mahesh',
      image: medical,
      designation: 'Medical Advisor, GCC',
    },
  ]
  return (
    <>
      <MainContainer>
        <OurTeam>
          <SectionTop>
            <Heading>Our Team</Heading>
            <SubHeading>
              Founders and co-founders of Pacess Wellness India LLP
            </SubHeading>
          </SectionTop>
          <TeamContainer>
            {team.map((team, i) => (
              <div key={i}>
                <TeamCard>
                  <TeamImage>
                    <Img src={team.image} alt="Image" />
                  </TeamImage>
                  <AboutTeam>
                    <Name>{team.name}</Name>
                    <Designation>{team.designation}</Designation>
                  </AboutTeam>
                </TeamCard>
              </div>
            ))}
          </TeamContainer>
        </OurTeam>
      </MainContainer>
    </>
  )
}

export default Team
const MainContainer = styled.div`
  background: #fcfcfc;
  width: 100%;
  padding: 100px 0;
  @media screen and (max-width: 1080px) {
    padding: 70px 0;
  }
  @media screen and (max-width: 980px) {
    padding: 50px 0;
  }
`
const OurTeam = styled.div`
  background: #fcfcfc;
  width: 90%;
  margin: 0 auto;
  @media screen and (max-width: 1280px) {
    width: 80%;
  }
  @media screen and (max-width: 768px) {
    width: 90%;
  }
`
const SectionTop = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
const SubHeading = styled.p`
  color: #1d1d46;
  font-size: 24px;
  margin-bottom: 50px;
  font-family: 'poppins-regular';
  text-align: center;
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
  @media screen and (max-width: 480px) {
    font-size: 20px;
    margin-bottom: 20px;
  }
`
const TeamContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  @media screen and (max-width: 1300px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (max-width: 980px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px 0;
  }
  @media screen and (max-width: 680px) {
    grid-gap: 0;
  }
  /* @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  } */
`

const Heading = styled.h6`
  color: #1d1d46;
  font-family: 'poppins-medium';
  font-size: 40px;
  margin-bottom: 15px;
  @media screen and (max-width: 1280px) {
    font-size: 34px;
  }
  @media screen and (max-width: 1080px) {
    font-size: 32px;
  }
  @media screen and (max-width: 980px) {
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 25px;
    font-size: 30px;
  }
  @media screen and (max-width: 480px) {
    font-size: 26px;
  }
`
const TeamCard = styled.div`
  padding: 20px;
  margin: 0 auto;
  @media screen and (max-width: 1280px) {
    padding: 15px;
  }
  @media screen and (max-width: 980px) {
    padding: 20px 0;
  }
`
const AboutTeam = styled.div`
  padding: 15px;
  filter: drop-shadow(7px 11px 19px rgba(0, 0, 0, 0.06));
  background: ${THEME_COLORS.white};
  border-radius: 20px;
  text-align: center;
  width: 240px;
  margin: 0 auto;
  @media screen and (max-width: 1280px) {
    width: 235px;
    filter: drop-shadow(7px 16px 17px rgba(0, 0, 0, 0.06));
  }
  @media screen and (max-width: 680px) {
    width: auto;
    filter: none;
    background: none;
    padding: 0px;
  }
`
const TeamImage = styled.div`
  width: 60%;
  margin: 0 auto;
  margin-bottom: 20px;
  border-radius: 235px;
  border: 4px solid #f1f9f1;
  @media screen and (max-width: 1280px) {
    width: 60%;
  }
  @media screen and (max-width: 1080px) {
    width: 50%;
  }
  @media screen and (max-width: 980px) {
    width: 45%;
  }
  @media screen and (max-width: 480px) {
    width: 50%;
  }
  @media screen and (max-width: 360px) {
    width: 60%;
  }
`
const Name = styled.p`
  color: ${THEME_COLORS.footer};
  font-size: 16px;
  font-family: 'poppins-medium';
  font-weight: 400;
  text-align: center;
  @media screen and (max-width: 1280px) {
    font-size: 16px;
  }
  @media screen and (max-width: 980px) {
    /* margin-bottom: 8px; */
  }
  @media screen and (max-width: 480px) {
    font-size: 12px;
  }
`
const Designation = styled.p`
  font-family: 'poppins-regular';
  color: ${THEME_COLORS.footer};
  font-size: 14px;
  line-height: 30px;
  @media screen and (max-width: 1280px) {
    font-size: 14px;
  }
  @media screen and (max-width: 480px) {
    font-size: 10px;
  }
`
const Img = styled.img`
  width: 100%;
  display: block;
`
