import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import remove from './../../../assets/icons/delete-black.svg'
import editIcon from './../../../assets/icons/edit-black.svg'
import { THEME_COLORS } from '../../../ThemeConfig'
import { baseConfig } from '../../../axiosConfig'
import { Context } from '../../../contexts/Store'
import ModalCenter from '../../includes/modal/ModalCenter'
import NewAddressModal from '../../includes/modal/address/NewAddressModal'
import DefaultLottie from '../../includes/common/loader/DefaultLottie'
import ConfirmdeleteAdress from '../../includes/modal/address/ConfirmdeleteAdress'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-toastify/dist/ReactToastify.css'
import useScrollBlock from '../../includes/functions/useScrollBlock'

export default function AddressList() {
  const { state, dispatch, selectedAdress } = useContext(Context)
  // const [check, setCheck] = useState(false);
  const [addressList, setAddressList] = useState([])
  const [isLoading, setLoading] = useState(true)

  const [selectedAddress, setSelectedAddress] = useState({})

  const [editAddress, setEditAddress] = useState(false)
  const [newAddress, setNewAddress] = useState(false)
  const [isDeleteModal, setDeleteModal] = useState(false)
  const [id, setId] = useState('')
  const [isget, setGet] = useState(false)
  const [firstAdress, setFirstAdress] = useState('')

  // console.log(firstAdress.id, 'neewwadress')
  // console.log(state.confirmAddressId, 'State in adresslist')

  const [blockScroll, allowScroll] = useScrollBlock()

  const closeModal = () => {
    setEditAddress(false)
    setNewAddress(false)
  }

  const showToast = () => {
    toast.success('adress removed successfully ', {
      autoClose: 500,
      hideProgressBar: true,
    })
  }
  //get address
  const getCustomerAddress = () => {
    setLoading(true)
    let accessToken = state.user_details.access
    // setTimeout(() => {
    baseConfig
      .get('customers/address-list/', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const { data, StatusCode } = response.data
        if (StatusCode === 6000) {
          setLoading(false)
          setAddressList(data)
          // let firstAdress = data[0];
          // dispatch({
          //     type: "SELECTED_ADDRESS",
          //     selectedAdress: firstAdress,
          //     confirmAddressId: firstAdress.id,
          // });
        }
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
      .catch((error) => {
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
    // }, 100);
  }

  // set default address
  const handleDefaultAddress = (id) => {
    let access_token = state.user_details.access
    let formdata = {}
    baseConfig
      .post(`customers/set-default-address/${id}/`, formdata, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        getCustomerAddress()
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
      .catch((error) => {
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
  }

  //remove address
  const removeFromCart = (id) => {
    let access_token = state.user_details.access
    let formdata = {
      // quantity: 1,
    }
    baseConfig
      .post(`customers/delete-address/${id}/`, formdata, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        console.log(response, 'remove')
        // getCustomerAddress();
        setAddressList((prevData) => prevData.filter((item) => item.id !== id))
        setTimeout(() => {
          setLoading(false)
        }, 100)
        showToast()
      })
      .catch((error) => {
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
  }

  useEffect(() => {
    getCustomerAddress()
    window.scrollTo(0, 0)
  }, [])

  return (
    <MainContainer>
      <ToastContainer />
      <ConfirmdeleteAdress
        setDeleteModal={setDeleteModal}
        isDeleteModal={isDeleteModal}
        id={id}
        removeapi={removeFromCart}
      />
      {!isLoading ? (
        <>
          <SubContainer>
            <TopContainer>
              <Title>Address List</Title>
              <SubTitltes>
                <Sub>{addressList.length} Saved Address</Sub>
                <AddButton
                  onClick={() => {
                    setNewAddress(true)
                  }}
                >
                  + ADD NEW ADDRESS
                </AddButton>
              </SubTitltes>
            </TopContainer>
            <Body>
              {addressList.map((item, i) => (
                <AddressCard>
                  <AddressTop>
                    <NameCard>
                      <Name>
                        {item.first_name} {item.last_name}
                      </Name>
                      {item.is_default && (
                        <DefaultAddress>Default Address</DefaultAddress>
                      )}
                    </NameCard>
                    <CardIcons>
                      <CardIcon
                        onClick={() => {
                          blockScroll()
                          setSelectedAddress(item)
                          setEditAddress(true)
                        }}
                      >
                        <Img src={editIcon} alt="Image" />
                      </CardIcon>
                      <CardIcon
                        onClick={() => {
                          // setCheck(!check);
                          setDeleteModal(true)
                          setId(item.id)
                          // removeFromCart(item.id);
                        }}
                      >
                        <Img src={remove} alt="Image" />
                      </CardIcon>
                    </CardIcons>
                  </AddressTop>
                  <AddressContainer>
                    <Address>
                      {item.address}
                      <br />
                      {item.location} , {item.state}
                      <br />
                      {item.pincode}
                      <br />
                      {item.phone}
                      <br />
                      {item.email}
                    </Address>
                  </AddressContainer>
                  {!item.is_default && (
                    <BottomContainer>
                      <DefaultButton
                        onClick={() => {
                          handleDefaultAddress(item.id)
                        }}
                      >
                        Set as Default Address
                      </DefaultButton>
                    </BottomContainer>
                  )}
                </AddressCard>
              ))}
            </Body>
          </SubContainer>

          {editAddress && (
            <ModalCenter
              children={
                <NewAddressModal
                  allowScroll={allowScroll}
                  closeModal={closeModal}
                  edit={true}
                  selectedAddress={selectedAddress}
                  refreshApi={getCustomerAddress}
                  editAddress={editAddress}
                  setEditAddress={setEditAddress}
                />
              }
              closeModal={closeModal}
            />
          )}

          {newAddress && (
            <ModalCenter
              children={
                <NewAddressModal
                  closeModal={closeModal}
                  refreshApi={getCustomerAddress}
                  setNewAddress={setNewAddress}
                  newAddress={newAddress}
                />
              }
              closeModal={closeModal}
            />
          )}
        </>
      ) : (
        <DefaultLottie />
      )}
    </MainContainer>
  )
}

const MainContainer = styled.div`
  /* padding: 32px; */

  width: 100%;
  background: #e6e6e6;
  padding: 130px 0 30px 0;
  /* max-width: 1350px; */
  /* padding: 120px 0; */

  @media screen and (max-width: 768px) {
    padding: 100px 30px 15px 30px;
  }
  @media screen and (max-width: 480px) {
    padding: 70px 0px 0px 0px;
  }
`
const SubContainer = styled.div`
  width: 90%;
  padding: 55px 260px;
  margin: 0 auto;
  background: ${THEME_COLORS.white};
  box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.04);
  @media screen and (max-width: 1280px) {
    padding: 55px 100px;
  }
  @media screen and (max-width: 768px) {
    padding: 45px 60px;
    width: 100%;
  }
  @media screen and (max-width: 640px) {
    padding: 30px;
  }
`
const TopContainer = styled.div`
  margin-bottom: 55px;
  @media screen and (max-width: 540px) {
    margin-bottom: 28px;
  }
`
const Title = styled.h3`
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  color: ${THEME_COLORS.background_1};
  margin-bottom: 8px;
`
const SubTitltes = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 560px) {
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;
  }
  @media screen and (max-width: 480px) {
  }
`
const AddButton = styled.div`
  background: ${THEME_COLORS.warning};
  opacity: 0.7;
  border-radius: 6px;
  padding: 8px 16px;
  color: ${THEME_COLORS.white};
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  width: max-content;
  @media screen and (max-width: 560px) {
    padding: 6px 12px;
    font-size: 14px;
  }
`
const Sub = styled.div`
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 28px;
  color: ${THEME_COLORS.background_3};
  margin-right: 44px;
  @media screen and (max-width: 560px) {
    font-size: 20px;
    margin: 0;
  }
`
const Body = styled.div``
const AddressCard = styled.div`
  padding: 24px;
  border: 2px solid ${THEME_COLORS.border_3};
  border-radius: 8px;
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }
  @media screen and (max-width: 560px) {
    padding: 16px;
  }
`

const AddressTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  @media screen and (max-width: 540px) {
    align-items: end;
  }
`
const NameCard = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  @media screen and (max-width: 540px) {
    flex-direction: column-reverse;
    align-items: start;
  }
  @media screen and (max-width: 480px) {
    /* width: 100%; */
    gap: 4px;
  }
`
const Name = styled.div`
  font-family: 'poppins-medium';
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${THEME_COLORS.background_1};
`
const DefaultAddress = styled.div`
  font-family: 'poppins-medium';
  background: ${THEME_COLORS.light_orange};
  border-radius: 48px;
  padding: 4px 16px;
  color: #e19200;
  cursor: pointer;
  min-width: max-content;
  @media screen and (max-width: 560px) {
    padding: 4px 12px;
    font-size: 14px;
  }
`
const CardIcons = styled.div`
  display: flex;
  gap: 24px;
  @media screen and (max-width: 560px) {
    gap: 8px;
    margin-bottom: 6px;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    justify-content: end;
  }
`
const CardIcon = styled.div`
  cursor: pointer;
  width: 24px;
  @media screen and (max-width: 560px) {
    width: 20px;
  }
`
const Img = styled.img`
  display: block;
  width: 100%;
`
const AddressContainer = styled.div``
const Address = styled.p`
  font-family: 'poppins-regular';
  /* font-weight: 400; */
  font-size: 14px;
  /* line-height: 22px; */
  color: ${THEME_COLORS.background_4};
`
const BottomContainer = styled.div`
  margin-top: 10px;
`
const DefaultButton = styled.div`
  color: ${THEME_COLORS.primary};
  border: 1px solid ${THEME_COLORS.secondary};
  border-radius: 6px;
  padding: 8px 18px;
  width: max-content;
  font-family: 'poppins-regular';
  font-size: 14px;
  cursor: pointer;
  @media screen and (max-width: 640px) {
    padding: 6px 16px;
    font-size: 12px;
  }
`
