import React from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'

const DeliveryCard = ({ responsive, active }) => {
  return (
    <>
      <AddressDetails className={responsive && 'mobile-view'}>
        <Top className={responsive && 'mobile-view'}>Delivery Address</Top>
        <AddressContainer className={responsive && 'mobile-view'}>
          <Customer className={responsive && 'mobile-view'}>
            {active?.customer_name}
          </Customer>
          {/* <Address className={responsive && 'mobile-view'}>Mellano Rode, East station322, Banaglore, 605040</Address> */}
          <Address className={responsive && 'mobile-view'}>
            {active?.address?.first_name} {active?.address?.last_name}
          </Address>
          <Address className={responsive && 'mobile-view'}>
            {' '}
            {active?.address?.address}, {active?.address?.address_2}
          </Address>
          <Address className={responsive && 'mobile-view'}>
            {' '}
            {active?.address?.location}, {active?.address?.state}
          </Address>
          <Address className={responsive && 'mobile-view'}>
            {' '}
            {active?.address?.country}, {active?.address?.pincode}
          </Address>
          <Address className={responsive && 'mobile-view'}>
            {' '}
            {active?.address?.phone}
          </Address>
          <Address className={responsive && 'mobile-view'}>
            {' '}
            {active?.address?.email}
          </Address>
        </AddressContainer>
      </AddressDetails>
      {responsive && <Top className="price">Price Details</Top>}
      <PriceDetails className={responsive && 'mobile-view'}>
        <PriceBox>
          <Row className="subtotal">
            <Left className={responsive && 'mobile-view'}>Subtotal</Left>
            <Right className={responsive && 'mobile-view'}>
              ₹{active.total_amount}
            </Right>
          </Row>
          <Row>
            <Left className={responsive && 'mobile-view'}>Shipping</Left>
            <Right className={responsive && 'mobile-view'}>₹0</Right>
          </Row>
        </PriceBox>
        <PriceBox className="total">
          <Row>
            <Left className={responsive ? 'mobile-view total' : 'total'}>
              Total
            </Left>
            <Right className={responsive && 'mobile-view'}>
              ₹{parseInt(active.total_amount) + 0}
            </Right>
          </Row>
        </PriceBox>
      </PriceDetails>
    </>
  )
}

export default DeliveryCard

const AddressDetails = styled.div`
  width: 40%;
  @media all and (max-width: 1280px) {
    width: 48%;
  }
  &.mobile-view {
    width: 100%;
    margin-bottom: 40px;
  }
`
const Top = styled.p`
  font-family: 'poppins-regular';
  font-weight: 600;
  font-size: 16px;
  color: ${THEME_COLORS.background_3};
  margin-bottom: 22px;
  &.mobile-view {
    margin-bottom: 20px;
    padding: 0px 0px 10px;
    border-bottom: 1px solid ${THEME_COLORS.border_4};
  }
  &.price {
    margin-bottom: 12px;
    padding: 0;
    border-bottom: none;
  }
`
const AddressContainer = styled.div`
  &.mobile-view {
    width: 30%;
    @media all and (max-width: 480px) {
      width: 80%;
    }
  }
`
const Customer = styled.p`
  font-family: 'poppins-regular';
  font-weight: 600;
  font-size: 16px;
  color: ${THEME_COLORS.background_3};
  margin-bottom: 8px;
  &.mobile-view {
    font-weight: 500;
    color: ${THEME_COLORS.background_1};
    margin-bottom: 6px;
    line-height: 24px;
  }
`
const Address = styled.p`
  font-family: 'poppins-regular';
  font-weight: 400;
  font-size: 19px;
  color: ${THEME_COLORS.background_4};
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
  &.mobile-view {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 4px;
  }
`
const PriceDetails = styled.div`
  width: 40%;
  background: ${THEME_COLORS.white};
  border-radius: 8px;
  overflow: hidden;
  height: max-content;
  @media all and (max-width: 1280px) {
    width: 48%;
  }
  &.mobile-view {
    width: 100%;
  }
`
const PriceBox = styled.div`
  padding: 24px;
  background: ${THEME_COLORS.border_5};
  border-bottom: 1px solid ${THEME_COLORS.border_4};
  margin-bottom: 2px;
  &:last-child {
    margin-bottom: 0;
  }
  &.total {
    padding: 16px 24px 24px;
  }
`
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.subtotal {
    margin-bottom: 16px;
  }
`
const Left = styled.div`
  font-family: 'poppins-regular';
  font-weight: 400;
  font-size: 17px;
  color: ${THEME_COLORS.background_4};
  &.total {
    color: ${THEME_COLORS.background_1};
  }
  &.mobile-view {
    font-size: 15px;
  }
`
const Right = styled.div`
  font-family: 'poppins-regular';
  font-weight: 500;
  font-size: 19px;
  color: ${THEME_COLORS.background_1};
  &.mobile-view {
    font-size: 16px;
  }
`
