import React from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'
export default function Tabs({
  data,
  clickedTab,
  setClickedTab,
  setClickedRequest,
  setFilteredList,
}) {
  // console.log(data, 'clickedTab')

  return (
    <TabFrame>
      {data.map((item) => (
        <InsideButton
          key={item.id}
          className={clickedTab === item.id ? 'active' : ''}
          onClick={() => {
            setClickedTab(item.id)
            setClickedRequest && setClickedRequest(false)
            // setFilteredList('')
          }}
        >
          {item.name}
        </InsideButton>
      ))}
    </TabFrame>
  )
}

const TabFrame = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px;
  gap: 10px;
  width: max-content;
  max-width: -webkit-fill-available;
  // height: 58px;
  background: ${THEME_COLORS.light_orange};
  border-radius: 8px;
  overflow-x: scroll;
  // width: max-content;
  // gap: 6px;
  &::-webkit-scrollbar {
    display: none;
  }

  // @media screen and (max-width: 868px) {
  //     overflow-x: scroll;
  //     width: max-content;
  //     gap: 6px;
  //     &::-webkit-scrollbar {
  //         display: none;
  //     }
  // }
  // @media screen and (max-width: 620px) {
  //     width: 100%;
  // }
`
const InsideButton = styled.a`
  width: max-content;
  display: inline-block;
  font-size: 15px;
  font-family: 'poppins-medium';
  height: 46px;
  padding: 10px 20px;
  font-weight: 600;
  color: ${THEME_COLORS.background_3};
  cursor: pointer;
  &.active {
    color: ${THEME_COLORS.white};
    background: ${THEME_COLORS.secondary};
    border-radius: 8px;
  }
  @media screen and (max-width: 980px) {
    font-size: 14px;
  }
  @media screen and (max-width: 868px) {
    min-width: max-content;
    padding: 11px 22px;
  }
  @media screen and (max-width: 640px) {
    font-size: 13px;
  }
  @media screen and (max-width: 480px) {
    height: 40px;
  }
`
