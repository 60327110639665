import React from 'react'
import { THEME_COLORS } from '../../../ThemeConfig'
import styled from 'styled-components'
import noitems from '../../../assets/icons/no-items-found.svg'

function NoItemsFound() {
  return (
    <Container>
      <NoData>
        <Img src={noitems} alt="Image" />
      </NoData>
      <Text>No result found</Text>
    </Container>
  )
}

export default NoItemsFound
const Container = styled.div`
  width: 15%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 1080px) {
    width: 20%;
  }
  @media screen and (max-width: 980px) {
    width: 25%;
  }
  @media screen and (max-width: 640px) {
    width: 35%;
  }
  @media screen and (max-width: 480px) {
    width: 55%;
  }
`
const Text = styled.text`
  color: ${THEME_COLORS.Dark / 200};
  font-size: 20px;
  font-family: 'poppins-medium';
`
const NoData = styled.div`
  width: 120px;
  height: 110px;
  margin: 0 auto;
`
const Img = styled.img`
  width: 100%;
  display: block;
`
