import {
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
} from "@mui/material";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import Button from "../../includes/buttons/Button";
import PrimaryButton from "../../includes/buttons/PrimaryButton";
import loginimage from "../../../assets/icons/forgot-icon.svg";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { AuthContext } from "../../../contexts/AuthStore";
import { baseConfig } from "../../../axiosConfig";
import { THEME_COLORS } from "../../../ThemeConfig";

export default function ResetPassword() {
	const { authstate, authdispatch } = useContext(AuthContext);
	const [email, setEmail] = useState(authstate?.auth_modal_details?.email);

	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	

	const handleShowPassword = () => setShowPassword((show) => !show);
	const handleShowConfirmPassword = () =>
		setShowConfirmPassword((show) => !show);

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const closeModal = () => {
		const auth_modal_details = {
			auth_modal_name: "",
			is_auth_modal: false,
		};
		authdispatch({
			type: "AUTH_MODAL",
			auth_modal_details,
		});
	};

	const handleResetPassword = () => {
		const formData = new FormData();
		formData.append("email", email);
		formData.append("password", password);

		baseConfig
			.post("users/forget-password/", formData, {})
			.then((response) => {
				const { StatusCode, data, token, message } = response.data;
				if (StatusCode === 6000) {
					if (StatusCode === 6000) {
						alert(message);
						const auth_modal_details = {
							auth_modal_name: "login",
							is_auth_modal: true,
						};
						authdispatch({
							type: "AUTH_MODAL",
							auth_modal_details,
						});
					}
				} else if (StatusCode === 6001) {
					
				}
			})
			.catch((error) => {});
	};

	return (
		<MainContainer>
			<HeaderContainer>
				<Image>
					<Img src={loginimage} alt="Image" />
				</Image>
				<Title>Reset Password</Title>
			</HeaderContainer>
			<CenterContainer>
				<SingleForm>
					<FormControl
						// sx={{ m: 1, width: "25ch" }}
						// variant="outlined"
						fullWidth="true"
					>
						<InputLabel
							// style={{ background: "#ffff" }}
							htmlFor="Enter New Password"
							id="Enter New Password"
						>
							Enter New Password
						</InputLabel>
						<OutlinedInput
							// sx={{
							//     border: "1px solid #106F39",
							//     borderRadius: 1.5,
							// }}
							id="Enter New Password"
							type={showPassword ? "text" : "password"}
							onChange={(e) => {
								setPassword(e.target.value);
							}}
							value={password}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{showPassword ? (
											<Visibility />
										) : (
											<VisibilityOff />
										)}
									</IconButton>
								</InputAdornment>
							}
							label="Enter New Password"
						/>
					</FormControl>
				</SingleForm>
				<SingleForm>
					<FormControl
						// sx={{ m: 1 }}
						variant="outlined"
						fullWidth="true"
					>
						<InputLabel htmlFor="Confirm New Password">
							Confirm New Password
						</InputLabel>
						<OutlinedInput
							style={{ background: "#F9F9F9" }}
							id="Confirm New Password"
							type={showConfirmPassword ? "text" : "password"}
							onChange={(e) => {
								setConfirmPassword(e.target.value);
							}}
							value={confirmPassword}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleShowConfirmPassword}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{showConfirmPassword ? (
											<Visibility />
										) : (
											<VisibilityOff />
										)}
									</IconButton>
								</InputAdornment>
							}
							label="Confirm new password"
						/>
					</FormControl>
				</SingleForm>
			</CenterContainer>
			<Buttons>
				<Button text={"Cancel"} size={"small"} onClick={closeModal} />
				<PrimaryButton
					size={"small"}
					text={"Submit"}
					onClick={() => {
						if (password === "" || confirmPassword === "") {
							alert("Please enter your password");
						} else if (password !== confirmPassword) {
							alert("Please make sure both passwords match");
						} else {
							handleResetPassword();
						}
					}}
				/>
			</Buttons>
		</MainContainer>
	);
}
const MainContainer = styled.div`
	padding: 32px;
	width: 600px;

	@media screen and (max-width: 768px) {
		width: 480px;
	}
	@media screen and (max-width: 540px) {
		width: 400px;
	}
	@media screen and (max-width: 480px) {
		width: 350px;
	}
`;
const HeaderContainer = styled.div``;
const Image = styled.div`
	width: 84px;
`;
const Img = styled.img`
	display: block;
	width: 100%;
	height: 100%;
`;
const Title = styled.h3`
	font-family: "poppins-medium";
	font-size: 28px;
	color: ${THEME_COLORS.background_1};
`;
const CenterContainer = styled.div`
	margin: 20px 0;
	/* display: flex;
    justify-content: center;
    flex-wrap: wrap; */
`;
const SingleForm = styled.div`
	margin-bottom: 10px;
`;
const Buttons = styled.div`
	display: flex;
	justify-content: end;
	gap: 8px;
`;
// const MainContainer = styled.div``
