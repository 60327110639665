import React from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../../ThemeConfig";

function CancellationPolicyMobile() {
    return (
        <MainContainer className="wrapper">
            <Cover>
                <Heading>Cancellation Policy</Heading>
                <Bottomcontainer>
                    <Description>
                        A cancellation fee may be imposed on customers for order
                        cancellations made on the Platform ("Cancellation Fee").
                        This fee will be determined based on the time at which
                        the customer chooses to cancel the order after placing
                        or confirming it on the platform. It should be noted
                        that free order cancellations are only available for the
                        first few hours after placing an order, as clearly
                        stated on the product page. Beyond this specified time
                        frame, a Cancellation Fee will be applied to eligible
                        products.
                    </Description>
                    <Title>Why is Cancellation Fee charged?</Title>
                    <Description>
                        The Cancellation Fee is levied to compensate for the
                        slot, time, and effort invested by the seller in
                        processing an order, as well as to reimburse the
                        logistics service providers for the costs incurred
                        during order shipment. <br></br>The Cancellation Fee
                        charged by PACESS will either be equivalent to or lower
                        than the expenses incurred by PACESS due to order
                        cancellations by customers.
                    </Description>
                    <Title>How will the Cancellation Fee be charged?</Title>
                    <Description>
                        The Cancellation Fee will be deducted from the amount
                        paid by the customer for the cancelled order. Flipkart
                        reserves the right to modify or waive the Cancellation
                        Fee periodically. The Cancellation Fee will be denoted
                        in Indian Rupees. You are solely responsible for
                        complying with all applicable laws regarding payments
                        made to PACESS or the sellers (as applicable) in the
                        event of order cancellations from your end.
                    </Description>
                    <Contact>CONTACT US</Contact>
                    <Description>
                        For any feedback, concerns, or queries, please feel free
                        to reach out to us using the contact details provided
                        below. <br></br> Customer Care: pacessgroups@gmail.com
                    </Description>
                </Bottomcontainer>
            </Cover>
        </MainContainer>
    );
}

export default CancellationPolicyMobile;
const MainContainer = styled.div`
    padding: 120px 0;

    @media screen and (max-width: 980px) {
        padding: 40px 0;
    }
`;
const Cover = styled.div``;
const Heading = styled.h1`
    font-size: 32px;
    line-height: 48px;
    margin: 0 0 25px 0;
    font-family: "poppins-medium";
    @media screen and (max-width: 640px) {
        font-size: 26px;
    }
    @media screen and (max-width: 480px) {
        font-size: 24px;
    }
`;
const Bottomcontainer = styled.div`
    background: ${THEME_COLORS.border_5};
    padding: 20px;
    border-radius: 7px;
`;
const Title = styled.h4`
    font-size: 28px;
    margin-bottom: 20px;
    line-height: 42px;

    font-family: "poppins-regular";
    @media screen and (max-width: 640px) {
        font-size: 22px;
    }
    @media screen and (max-width: 480px) {
        font-size: 20px;
    }
`;
const Description = styled.p`
    color: ${THEME_COLORS.background_5};
    font-size: 20px;
    font-family: "poppins-medium";
    line-height: 35px;
    margin-bottom: 24px;
    br {
        margin-bottom: 32px;
    }
    @media screen and (max-width: 1080px) {
        line-height: 28px;
    }
    @media screen and (max-width: 980px) {
        font-size: 18px;
    }
    @media screen and (max-width: 980px) {
        font-size: 14px;
    }
`;
const Contact = styled.h6`
    font-family: "poppins-medium";
    font-size: 20px;
    line-height: 42px;
`;
