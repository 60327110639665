import React, { useEffect } from 'react'
import styled from 'styled-components'
import tick from '../../../assets/icons/tick-square.svg'
import tickbg from '../../../assets/icons/tick-bg.svg'
import bottlem1 from '../../../assets/icons/bottle1.svg'
import bottlem2 from '../../../assets/icons/bottle2.svg'
import { THEME_COLORS } from '../../../ThemeConfig'

export default function Orders() {
  //scroll top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <MainContainer>
      <OrdersContainer>
        <Head>Orders...</Head>
        <ListContainer>
          <ProductContainer>
            <Left>
              <Image src={bottlem1} alt="Image" />
            </Left>
            <Right>
              <ProductName>Curcumin Tablet</ProductName>
              <Pack>Pack of 1</Pack>
              <Pack>30 capsules</Pack>
            </Right>
          </ProductContainer>
          <AmountContainer>
            <Amount>₹ 160.00</Amount>
          </AmountContainer>
          <StatusContainer>
            <LeftStatus>
              <Status>Shipped</Status>
              <DateContainer>
                <span>On</span>
                <Date>03 NOV 2022</Date>
              </DateContainer>
            </LeftStatus>
            <Tick className="white">
              <TickImage src={tickbg} alt="Image" />
            </Tick>
          </StatusContainer>
        </ListContainer>
        <ListContainer>
          <ProductContainer>
            <Left>
              <Image src={bottlem2} alt="Image" />
            </Left>
            <Right>
              <ProductName>Curcumin Tablet</ProductName>
              <Pack>Pack of 1</Pack>
              <Pack>30 capsules</Pack>
            </Right>
          </ProductContainer>
          <AmountContainer>
            <Amount>₹ 160.00</Amount>
          </AmountContainer>
          <StatusContainer>
            <LeftStatus>
              <Status>Shipped</Status>
              <DateContainer>
                <span>On</span>
                <Date>03 NOV 2022</Date>
              </DateContainer>
            </LeftStatus>
            <Tick>
              <TickImage src={tick} alt="Image" />
            </Tick>
          </StatusContainer>
        </ListContainer>
        <ListContainer>
          <ProductContainer>
            <Left>
              <Image src={bottlem1} alt="Image" />
            </Left>
            <Right>
              <ProductName>Curcumin Tablet</ProductName>
              <Pack>Pack of 1</Pack>
              <Pack>30 capsules</Pack>
            </Right>
          </ProductContainer>
          <AmountContainer>
            <Amount>₹ 160.00</Amount>
          </AmountContainer>
          <StatusContainer>
            <LeftStatus>
              <Status>Shipped</Status>
              <DateContainer>
                <span>On </span>
                <Date> 03 NOV 2022</Date>
              </DateContainer>
            </LeftStatus>
            <TickContainer>
              <Tick>
                <TickImage src={tick} alt="Image" />
              </Tick>
            </TickContainer>
          </StatusContainer>
        </ListContainer>
      </OrdersContainer>
    </MainContainer>
  )
}

const MainContainer = styled.div`
  width: 90%;
  max-width: 1350px;
  margin: 0 auto;
  padding: 120px 0 42px;
`
const OrdersContainer = styled.div`
  /* margin-top: 42px; */
  width: auto;
  margin: 0 auto;
  padding: 43px;
  box-shadow: 8px 20px 52px rgba(0, 0, 0, 0.25);
  @media screen and (max-width: 1180px) {
    /* max-width: 1050px; */
  }
  @media screen and (max-width: 1080px) {
    /* max-width: 950px; */
  }
  @media screen and (max-width: 980px) {
    /* max-width: 740px; */
  }
  @media screen and (max-width: 768px) {
    padding: 25px;
    /* max-width: 627px; */
  }
  @media screen and (max-width: 640px) {
    padding: 32px;
    max-width: 560px;
  }
  @media screen and (max-width: 570px) {
    max-width: 440px;
  }
  @media screen and (max-width: 480px) {
    max-width: 400px;
    padding: 18px;
  }
  @media screen and (max-width: 390px) {
    max-width: 336px;
  }
  @media screen and (max-width: 360px) {
    max-width: 325px;
    padding: 10px;
  }
`
const Head = styled.div`
  font-family: 'poppins-semibold';
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 20px;
  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
`
const ListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  @media screen and (max-width: 640px) {
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid;
  }
`
const ProductContainer = styled.div`
  width: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 1080px) {
    width: 40%;
  }
  @media screen and (max-width: 768px) {
    width: 38%;
  }
  @media screen and (max-width: 640px) {
    width: 60%;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 480px) {
    width: 54%;
  }
`
const Left = styled.div`
  /* width: 35%; */
  width: 93px;
  height: 93px;
  border-radius: 6px;
  @media screen and (max-width: 1080px) {
    width: 68px;
    height: 68px;
  }
  @media screen and (max-width: 768px) {
    width: 56px;
    height: 59px;
  }
  @media screen and (max-width: 480px) {
    width: 40px;
    height: 46px;
  }
`
const Image = styled.img`
  width: 100%;
  display: block;
`
const Right = styled.div`
  width: 65%;
`
const ProductName = styled.h4`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
  font-family: 'poppins-medium';
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
  @media screen and (max-width: 480px) {
    font-size: 10px;
  }
`
const Pack = styled.div`
  font-weight: 400;
  font-size: 17px;
  color: ${THEME_COLORS.background_4};
  margin-bottom: 5px;
  font-family: 'poppins-medium';
  line-height: 26px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 15px;
  }
  @media screen and (max-width: 480px) {
    font-size: 10px;
  }
`
const AmountContainer = styled.div`
  width: 20%;
  @media screen and (max-width: 640px) {
    width: 40%;
    margin-bottom: 20px;
  }
`
const Amount = styled.div`
  font-weight: 500;
  font-size: 20px;
  font-family: 'poppins-medium';
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
  @media screen and (max-width: 480px) {
    font-size: 13px;
  }
`
const StatusContainer = styled.div`
  width: 25%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 980px) {
    width: 33%;
  }
  @media screen and (max-width: 768px) {
    width: 38%;
  }
  @media screen and (max-width: 640px) {
    width: 100%;
    margin-bottom: 20px;
  }
  /* @media screen and (max-width: 480px) {
    width: 30%;
  } */
`
const LeftStatus = styled.div`
  width: 60%;
  @media screen and (max-width: 640px) {
    width: 100%;
  }
`
const Status = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 8px;
  font-family: 'poppins-medium';
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
  @media screen and (max-width: 480px) {
    font-size: 13px;
  }
`
const DateContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  span {
    color: ${THEME_COLORS.background_1};
    margin-right: 5px;
    font-weight: 500;
    font-size: 14px;
    font-family: 'poppins-medium';
    @media screen and (max-width: 480px) {
      font-size: 10px;
    }
  }
`
const Date = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: ${THEME_COLORS.background_4};
  font-family: 'poppins-medium';
  @media screen and (max-width: 480px) {
    font-size: 10px;
  }
`
const TickContainer = styled.div`
  /* width: 10%; */
`
const Tick = styled.div`
  width: 24px;
  /* height: 24px; */
  /* border: 1px solid ${THEME_COLORS.secondary}; */
  /* padding: 3px; */
  border-radius: 5px;
  .white{
  width: 30px;

  }
`
const TickImage = styled.img`
  width: 100%;
  height: 100%;
  display: block;
`
