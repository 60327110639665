import React, { useState } from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from './../../../ThemeConfig'
import tick from '../../../assets/icons/check-round.svg'
import red from '../../../assets/icons/check-red.svg'

import alert from '../../../assets/icons/alert-triangle.svg'

export default function Steps({ order_status }) {
  const [orderedlist, setOrderedlist] = useState([
    {
      id: 1,
      name: 'ordered',
      status: true,
    },
    {
      id: 2,
      name: 'packed',
      status: false,
    },
    {
      id: 3,
      name: 'shipped',
      status: false,
    },
    {
      id: 4,
      name: 'delivered',
      status: false,
    },
  ])

  const [Packedkedlist, setPackedkedlist] = useState([
    {
      id: 1,
      name: 'ordered',
      status: true,
    },
    {
      id: 2,
      name: 'packed',
      status: true,
    },
    {
      id: 3,
      name: 'shipped',
      status: false,
    },
    {
      id: 4,
      name: 'delivered',
      status: false,
    },
  ])
  const [shippedlist, setShippedlist] = useState([
    {
      id: 1,
      name: 'ordered',
      status: true,
    },
    {
      id: 2,
      name: 'packed',
      status: true,
    },
    {
      id: 3,
      name: 'shipped',
      status: true,
    },
    {
      id: 4,
      name: 'delivered',
      status: false,
    },
  ])
  const [deliveredlist, setDeliveredList] = useState([
    {
      id: 1,
      name: 'ordered',
      status: true,
    },
    {
      id: 2,
      name: 'packed',
      status: true,
    },
    {
      id: 3,
      name: 'shipped',
      status: true,
    },
    {
      id: 4,
      name: 'delivered',
      status: true,
    },
  ])
  const [cancelledList, setCancelledList] = useState([
    {
      id: 1,
      name: 'ordered',
      status: true,
    },
    {
      id: 2,
      name: 'cancelled',
      status: false,
    },
    {
      id: 3,
      name: 'shipped',
      status: false,
    },
    {
      id: 4,
      name: 'delivered',
      status: false,
    },
  ])

  // console.log(order_status, 'order_status')

  return (
    <DetailsContainer>
      {order_status == 'ordered' ? (
        <Details>
          {orderedlist?.map((item, i) => (
            <DetailsCount className="on" key={i}>
              <Action>{item.name}</Action>
              <Count
                className={item.status ? 'completed' : ''}
                success={item.status ? true : false}
              >
                {item.status ? <Img src={tick} alt="Image" /> : null}
              </Count>
            </DetailsCount>
          ))}
        </Details>
      ) : order_status == 'packed' ? (
        <Details>
          {Packedkedlist?.map((item, i) => (
            <DetailsCount className="on" key={i}>
              <Action>{item.name}</Action>
              <Count
                className={item.status ? 'completed' : ''}
                success={item.status ? true : false}
              >
                {item.status ? <Img src={tick} alt="Image" /> : null}
              </Count>
            </DetailsCount>
          ))}
        </Details>
      ) : order_status == 'shipped' ? (
        <Details>
          {shippedlist?.map((item, i) => (
            <DetailsCount className="on" key={i}>
              <Action>{item.name}</Action>
              <Count
                className={item.status ? 'completed' : ''}
                success={item.status ? true : false}
              >
                {item.status ? <Img src={tick} alt="Image" /> : null}
              </Count>
            </DetailsCount>
          ))}
        </Details>
      ) : order_status == 'delivered' ? (
        <Details>
          {deliveredlist?.map((item, i) => (
            <DetailsCount className="on" key={i}>
              <Action>{item.name}</Action>
              <Count
                className={item.status ? 'completed' : ''}
                success={item.status ? true : false}
              >
                {item.status ? <Img src={tick} alt="Image" /> : null}
              </Count>
            </DetailsCount>
          ))}
        </Details>
      ) : (
        // <DetailsCount className="cancell">
        //   <Action className="cancell">Your Order has been cancelled</Action>
        //   <Count className="cancell">
        //     <Img src={alert} alt="Image" />
        //   </Count>
        // </DetailsCount>

        <Details>
          {cancelledList?.map((item, i) => (
            <DetailsCount className="on" key={i}>
              <Action>{item.name}</Action>
              <Count
                className={
                  item.name == 'cancelled'
                    ? 'cancelled'
                    : item.name == 'ordered'
                    ? 'cancelled'
                    : ''
                }
                success={item.status ? true : false}
              >
                {item.name == 'ordered' ? (
                  <Img src={tick} alt="Image" />
                ) : item.name == 'cancelled' ? (
                  <Img src={red} alt="Image" />
                ) : null}
              </Count>
            </DetailsCount>
          ))}
        </Details>
      )}
    </DetailsContainer>
  )
}
const DetailsContainer = styled.div`
  border-radius: 16px;
`
const Details = styled.ul`
  position: relative;
  display: flex;
  align-items: center;
`
const DetailsCount = styled.li`
  position: relative;
  width: 24%;
  &.on {
    color: ${THEME_COLORS.text_2};
  }
  &.cancell {
    width: 100%;
    list-style: none;
  }
  &:last-child ::after {
    display: none;
  }
  &:first-child ::before {
    display: none;
  }
`
const Count = styled.div`
  text-align: center;
  margin: 0 auto;
  border: 1px solid ${THEME_COLORS.secondary};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: ${(props) => (props.success ? '' : 'inherit')};
  &:after {
    content: '';
    height: 3px;
    background-image: ${(props) =>
      props.success
        ? `  linear-gradient(to right, ${THEME_COLORS.secondary} , ${THEME_COLORS.white})`
        : ` ${THEME_COLORS.white}`};
    background-color: ${(props) =>
      props.success ? ` ${THEME_COLORS.secondary}` : ` ${THEME_COLORS.white}`};
    width: calc(70% - 20px);
    position: absolute;
    right: 15%;
    transform: translateX(50%);
  }
  &:before {
    content: '';
    border-bottom: 3px solid ${THEME_COLORS.secondary};
    width: ${(props) => (props.success ? `100%` : `0`)};
    position: absolute;
    right: 100%;
    transform: translateX(50%);
  }

  /* &.cancell {
    text-align: center;
    margin: 0 auto;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    &:after {
      content: '';
    }
    &:before {
      content: '';
    }
  } */

  &.cancelled {
    text-align: center;
    margin: 0 auto;
    border: 1px solid #e71d23;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: ${(props) => (props.success ? '' : 'inherit')};
    &:after {
      content: '';
      height: 3px;
      background-image: ${(props) =>
        props.success
          ? `  linear-gradient(to right,${THEME_COLORS.secondary} , #e71d23)`
          : ` ${THEME_COLORS.white}`};
      background-color: ${(props) =>
        props.success ? ` #e71d23` : ` #ffffff00`};
      width: 100%;
      position: absolute;
      right: 0%;
      transform: translateX(50%);
    }
    &:before {
      content: '';
      border-bottom: 3px solid ${THEME_COLORS.secondary};
      width: ${(props) => (props.success ? `100%` : `0`)};
      position: absolute;
      right: 100%;
      transform: translateX(50%);
    }
  }
`
const Img = styled.img`
  display: block;
  /* width: 100%; */
  &.active {
    display: block;
  }
  position: inherit;
  z-index: 100;
`
const Action = styled.div`
  font-family: 'poppins-regular';
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 10px;
  color: ${THEME_COLORS.background_1};
  &.cancell {
    color: red;
  }
`
