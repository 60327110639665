import React, { useState } from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'
import filter from '../../../assets/icons/filter-black.svg'
import OrderCard from './OrderCard'
import FilterOrder from '../../includes/filter/FilterOrder'

export default function OrderHistory({
  active,
  setActive,
  orderList,
  setOrderList,
}) {
  const [showFilter, setShowFilter] = useState(false)
  const [selectedValues, setSelectedValues] = useState([])
  // console.log(selectedValues, 'selectedValues in order')

  return (
    <LeftContainer>
      <HeadWrapper>
        <Head>Order History</Head>
        {/* <FilterBox onClick={() => setShowFilter(!showFilter)}>
          <Filter>Filter By</Filter>
          <FilterIcon>
            <Img src={filter} alt="filter" />
          </FilterIcon>
        </FilterBox> */}
        {showFilter && (
          <FilterModal>
            <FilterOrder
              setShowFilter={setShowFilter}
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
            />
          </FilterModal>
        )}
      </HeadWrapper>
      <OrderList>
        {orderList?.map((order) => {
          return (
            <OrderCard
              key={order.order_id}
              order={order}
              active={active}
              setActive={setActive}
            />
          )
        })}
      </OrderList>
    </LeftContainer>
  )
}

const LeftContainer = styled.div`
  width: 40%;
  // // height: 700px;
  // max-height:700px;
  // // min-height:700px;
  display: flex;
  flex-direction: column;
  padding: 10px 8px;
  background: ${THEME_COLORS.border_5};
  border-radius: 8px;
  overflow: scroll;
`
const HeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 6px;
  margin-bottom: 12px;
  position: relative;
`
const Head = styled.h4`
  font-family: 'poppins-regular';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${THEME_COLORS.background_3};
`
const FilterBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
  border: 1px solid ${THEME_COLORS.border_3};
  border-radius: 8px;
  cursor: pointer;
`
const Filter = styled.h5`
  margin-right: 10px;
  font-family: 'poppins-regular';
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${THEME_COLORS.background_3};
`
const FilterIcon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Img = styled.img`
  display: block;
  width: 100%;
`
const OrderList = styled.ul`
  display: flex;
  flex-direction: column;
  background: ${THEME_COLORS.white};
  border-radius: 8px;
  overflow: hidden;
`
const FilterModal = styled.div`
  position: absolute;
  right: 0;
  top: 48px;
`
