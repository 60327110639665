import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import tick from './../../../../assets/icons/tick-square.svg'
import { THEME_COLORS } from '../../../../ThemeConfig'
import { baseConfig } from '../../../../axiosConfig'
import { Context } from '../../../../contexts/Store'
import ModalCenter from '../ModalCenter'
import NewAddressModal from './NewAddressModal'
import PrimaryButton from '../../buttons/PrimaryButton'
import useScrollBlock from '../../functions/useScrollBlock'

export default function ChangeAddressModal({
  closeModal,
  refreshApi,
  selectedAddress,
  setSelectedAddress,
}) {
  const { state } = useContext(Context)
  const [isLoading, setLoading] = useState(true)
  // const [check, setCheck] = useState(false);
  const [addressList, setAddressList] = useState([])
  const [newAddress, setNewAddress] = useState(false)

  const [blockScroll, allowScroll] = useScrollBlock()

  useEffect(() => {
    blockScroll()
  }, [])

  //get address
  const getCustomerAddress = () => {
    setLoading(true)
    let accessToken = state.user_details.access
    // setTimeout(() => {
    baseConfig
      .get('customers/address-list/', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const { data, StatusCode } = response.data
        if (StatusCode === 6000) {
          setLoading(false)
          setAddressList(data)
        }
      })
      .catch((error) => {
        setLoading(false)
      })
    // }, 100);
  }

  // set default address
  // const handleDefaultAddress = (id) => {
  // 	let access_token = state.user_details.access;
  // 	let formdata = {
  // 		// quantity: 1,
  // 	};
  // 	baseConfig
  // 		.post(`customers/set-default-address/${id}/`, formdata, {
  // 			headers: {
  // 				Authorization: `Bearer ${access_token}`,
  // 			},
  // 		})
  // 		.then((response) => {
  // 			getCustomerAddress();
  // 			refreshApi();
  // 			closeModal();
  // 		})
  // 		.catch((error) => {});
  // };

  useEffect(() => {
    getCustomerAddress()
  }, [])

  return (
    <>
      <MainContainer>
        <Body>
          <TopContainer>
            <Header>
              <Title>Change Address</Title>
              <AddButton
                onClick={() => {
                  setNewAddress(true)
                  allowScroll()
                }}
              >
                + ADD NEW ADDRESS
              </AddButton>
            </Header>
            <SavedAddress>{addressList.length} Saved Address</SavedAddress>
          </TopContainer>
          {addressList.map((item, i) => (
            <AddressCard key={i}>
              <AddressTop>
                <NameCard>
                  <Name>
                    {item.first_name} {item.last_name}
                  </Name>
                </NameCard>
                <EditAddress>
                  {item.is_default && (
                    <DefaultButton>Default Address</DefaultButton>
                  )}

                  <Checkbox
                    onClick={() => {
                      // setCheck(!check);
                      // handleDefaultAddress(item.id);
                      setSelectedAddress(item)
                    }}
                    className={item.id === selectedAddress.id && 'checked'}
                  >
                    {item.id === selectedAddress.id && (
                      <Tick
                        // className={
                        // 	item.is_default && "checked"
                        // }
                        src={tick}
                        alt="Image"
                      />
                    )}
                  </Checkbox>
                </EditAddress>
              </AddressTop>
              <Address>
                {item.address}
                <br />
                {item.location} , {item.state}
                <br />
                {item.pincode}
                <br />
                {item.phone}
                <br />
                {item.email}
              </Address>
            </AddressCard>
          ))}
          <BottomContainer>
            <PrimaryButton
              className={'category_button'}
              size={'small'}
              text={'CONTINUE'}
              onClick={() => {
                allowScroll()
                closeModal()
              }}
            />
          </BottomContainer>
        </Body>
        {newAddress && (
          <ModalCenter
            children={
              <NewAddressModal
                closeModal={closeModal}
                refreshApi={getCustomerAddress}
              />
            }
            closeModal={closeModal}
          />
        )}{' '}
      </MainContainer>
    </>
  )
}

const MainContainer = styled.div`
  /* padding: 32px; */
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 800px;
  @media screen and (max-width: 980px) {
    width: 600px;
  }
  @media screen and (max-width: 640px) {
    width: 500px;
  }
  @media screen and (max-width: 560px) {
    width: 430px;
  }
  @media screen and (max-width: 480px) {
    width: 350px;
  }
`
const TopContainer = styled.div`
  margin-bottom: 30px;
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Title = styled.h3`
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  color: ${THEME_COLORS.background_1};
`
const AddButton = styled.div`
  background: #d87400;
  opacity: 0.7;
  border-radius: 6px;
  padding: 8px 16px;
  color: ${THEME_COLORS.white};
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
`
const SavedAddress = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 28px;
  color: ${THEME_COLORS.background_3};
`
const Body = styled.div`
  padding: 32px;
  /* width: 800px; */
  /* height: 300px; */
  /* overflow-y: scroll; */
  /* height: 705px; */
  /* overflow: scroll; */
  ::-webkit-scrollbar {
    display: none;
  }
`
const AddressCard = styled.div`
  padding: 24px;
  border: 2px solid ${THEME_COLORS.border_3};
  border-radius: 8px;
  margin-bottom: 24px;
  @media screen and (max-width: 560px) {
    padding: 18px;
  }
`

const AddressTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 16px; */
`
const NameCard = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
const Name = styled.p`
  font-family: 'poppins-medium';
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${THEME_COLORS.background_1};
`
const DefaultButton = styled.div`
  font-family: 'poppins-regular';
  background: ${THEME_COLORS.light_orange};
  border-radius: 48px;
  padding: 4px 10px;
  font-size: 12px;
  color: #e19200;
  cursor: pointer;
  /* max-width: max-content; */
  /* margin: 5px 0; */
  margin-right: 5px;
  @media screen and (max-width: 560px) {
  }
`
const EditAddress = styled.div`
  /* width: 24px; */
  /* cursor: pointer; */
  /* height: 24px; */
  display: flex;
  align-items: center;
`

const Checkbox = styled.div`
  margin-right: 10px;
  width: 18px;
  height: 18px;
  border: 1px solid ${THEME_COLORS.border_3};
  border-radius: 3px;
  cursor: pointer;
  &.checked {
    border-color: ${THEME_COLORS.secondary};
  }
`
const Address = styled.div`
  font-family: 'poppins-regular';
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: ${THEME_COLORS.background_4};
`

const Tick = styled.img`
  width: 18px;
  height: 18px;
`

const BottomContainer = styled.div``
