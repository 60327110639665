import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import DealOfTheDayCard from '../../includes/dealofthedaycard/DealOfTheDayCard'
import health1 from '../../../assets/images/slider1.svg'
import health2 from '../../../assets/images/slider2.svg'
import health3 from '../../../assets/images/slider3.svg'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import BadgeButton from '../../includes/buttons/BadgeButton'
import CategoriesCard from '../../includes/CategoriesCard/CategoriesCard'
import { Context } from '../../../contexts/Store'
// import useAuth from "../../includes/functions/authFunctions";
import { baseConfig } from '../../../axiosConfig'
import DefaultLottie from '../../includes/common/loader/DefaultLottie'
import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'

export default function OfferPage() {
  var settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  // var slide = {
  //     dots: false,
  //     autoplay: true,
  //     infinite: true,
  //     speed: 1000,
  //     slidesToShow: 4,
  //     slidesToScroll: 1,
  // };
  var bannerslide = {
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
  }
  var offerslide = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // dots: true,
        },
      },
    ],
  }
  const navigate = useNavigate()
  const [offerList, setofferList] = useState([])
  // const { checkToken } = useAuth();
  const { state } = useContext(Context)
  // const [productSpotlight, setProductSpotlight] = useState([]);
  const [productList, setProductList] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [dealOfTheDayList, setDealOfTheDayList] = useState([])
  const [offers, setOffers] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const [countShow, setCountShow] = useState(8)

  // console.log(
  //   dealOfTheDayList,
  //   'dealOfTheDayListdealOfTheDayListdealOfTheDayList',
  // )

  let OfferData = (token) => {
    setLoading(true)
    baseConfig
      .get('general/spotlight/', {
        params: {
          page: 30,
        },
      })
      .then((response) => {
        setofferList(response.data.data)

        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
      .catch((error) => {
        const { status, data } = error?.response
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
  }
  useEffect(() => {
    OfferData()
  }, [])

  const getOfferProducts = () => {
    const accessToken = state.user_details.access
    console.log(accessToken,"acces token in offer page")
    setLoading(true)
    baseConfig
      .get('offers/offers-products-list/', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response.data
        setOffers(response.data.data)
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
      .catch((error) => {
        const { status, data } = error?.response
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
  }

  //get products
  const getProductsList = () => {
    setLoading(true)
    const accessToken = state.user_details.access
    baseConfig
      .get('products/products-list/', {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
        params: {
          page: currentPage,
          page_size: countShow,
        },
      })
      .then((response) => {
        setProductList(response.data.data)
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
      .catch((error) => {
        const { status, data } = error?.response
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
  }

  const getDealOfTheDay = () => {
    const accessToken = state.user_details.access
    baseConfig
      .get('offers/deal-of-the-days/', {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      })
      .then((response) => {
        const { StatusCode, data } = response.data
        if (StatusCode === 6000) {
          setDealOfTheDayList(data)
        }
      })
      .catch((error) => {
        const { status, data } = error?.response
      })
  }

  useEffect(() => {
    getOfferProducts()
    getProductsList()
    getDealOfTheDay()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [bannerList, setBannerList] = useState([])
  const [bannerListSlide, setBannerListSlide] = useState([])
  let OfferBannerSliderData = (token) => {
    baseConfig
      .get('general/banners/', {
        params: {
          page: 30,
          banner_type: 30,
        },
      })

      .then((response) => {
        setBannerListSlide(response.data.data)
        // console.log(response.data.featured);
        // console.log(response.data.un_featured);
      })
      .catch((error) => {
        const { status, data } = error?.response
      })
  }

  let OfferBannerData = (token) => {
    baseConfig
      .get('general/banners/', {
        params: {
          page: 30,
          banner_type: 10,
        },
      })

      .then((response) => {
        setBannerList(response.data.data)
        // console.log(response.data.featured);
        // console.log(response.data.un_featured);
      })
      .catch((error) => {
        const { status, data } = error?.response
      })
  }

  useEffect(() => {
    OfferBannerSliderData()
    OfferBannerData()
  }, [])

  return (
    <>
      {!isLoading ? (
        <MainContainer>
          <SpotLight>
            <SliderContainer>
              <Slider {...settings}>
                {offerList?.map((data, i) => (
                  <SingleContainer key={i}>
                    <Img src={data?.image} alt="Image" />
                  </SingleContainer>
                ))}
              </Slider>
            </SliderContainer>
          </SpotLight>
          <div className="wrapper">
            {dealOfTheDayList?.length > 0 && (
              <>
                <Title>Deal of the Day</Title>
                <DealOff>
                  {/* <Slider {...slide}> */}
                  {dealOfTheDayList?.map((item, i) => (
                    <DealOfTheDayCard item={item} key={i} />
                  ))}
                  {/* </Slider> */}
                </DealOff>
              </>
            )}
            {bannerList && (
              <BannerContainer>
                {/* {bannerList?.map((data, i) => ( */}
                <>
                  <BannerBox>
                    <Img src={bannerList?.image} alt="Image" />
                  </BannerBox>
                  <ResponsBox>
                    <Img src={bannerList?.responsive_image} alt="Image" />
                  </ResponsBox>
                </>
                {/* ))} */}
              </BannerContainer>
            )}

            {bannerListSlide?.length > 0 && (
              <SlideContainer>
                <Slider {...bannerslide}>
                  {bannerListSlide?.map((data, i) => (
                    <SliderBox>
                      <ImgContainer key={i}>
                        <Img className="slider" src={data.image} alt="Image" />
                        {/* <SliderButton>Coming Soon</SliderButton> */}
                      </ImgContainer>
                    </SliderBox>
                  ))}
                </Slider>
              </SlideContainer>
            )}
            {offers?.length > 0 ? (
              <TopOffers>
                <TopContainer>
                  <TitleHead>Top Offers</TitleHead>
                  <BadgeButton
                    text={'View All'}
                    onClick={() => {
                      navigate('/productlist')
                    }}
                  />
                </TopContainer>
                {offers?.length > 0 && (
                  <CategoriesListContainer>
                    <>
                      {offers?.map((data, i) => (
                        <CategoriesCard
                          refreshApi={getProductsList}
                          data={data}
                          key={i}
                        />
                      ))}
                    </>
                  </CategoriesListContainer>
                )}
              </TopOffers>
            ) : (
              <TopContainer>
                <TitleHead>Top Offers</TitleHead>
                <BadgeButton
                  text={'View All'}
                  onClick={() => {
                    navigate('/productlist')
                  }}
                />
              </TopContainer>
            )}
            <SlickContainer>
              <Slider {...offerslide}>
                <SliderSingleBox>
                  <SlideImage src={health1} alt="Image" />
                </SliderSingleBox>
                <SliderSingleBox>
                  <SlideImage src={health2} alt="Image" />
                </SliderSingleBox>
                <SliderSingleBox>
                  <SlideImage src={health3} alt="Image" />
                </SliderSingleBox>
                <SliderSingleBox>
                  <SlideImage src={health2} alt="Image" />
                </SliderSingleBox>
                <SliderSingleBox>
                  <SlideImage src={health3} alt="Image" />
                </SliderSingleBox>
              </Slider>
            </SlickContainer>
          </div>
        </MainContainer>
      ) : (
        <DefaultLottie />
      )}
    </>
  )
}
const MainContainer = styled.div`
  margin-bottom: 70px;
  padding-top: 80px;
  @media screen and (max-width: 980px) {
    padding-top: 60px;
  }
  @media screen and (max-width: 480px) {
    margin-bottom: 35px;
  }
`
const SpotLight = styled.div`
  margin-bottom: 70px;
  @media screen and (max-width: 480px) {
    margin-bottom: 55px;
  }
`
const SliderContainer = styled.div``
const SingleContainer = styled.div`
  /* height: 660px; */
  width: 100%;
  @media screen and (max-width: 1080px) {
    /* height: 490px; */
    width: 90%;
  }
  @media screen and (max-width: 768px) {
    /* height: 380px; */
  }
  @media screen and (max-width: 480px) {
    /* height: 280px; */
  }
  @media screen and (max-width: 360px) {
    /* height: 200px; */
  }
`
const Img = styled.img`
  display: block;
  width: 100%;
  &.slider {
    object-fit: cover;
  }
  /* height: 700px; */
  /* object-fit: ; */
`
const Title = styled.h4`
  font-size: 32px;
  line-height: 48px;
  font-family: 'poppins-medium';
`
const DealOff = styled.div`
  margin-top: 32px;
  margin-bottom: 80px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;

  @media screen and (max-width: 1280px) {
    grid-gap: 12px;
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`
// const DealContainer = styled.div`
//     margin-bottom: 80px;
//     display: grid;
//     grid-template-columns: 1fr 1fr 1fr 1fr;
//     grid-gap: 20px;
//     @media screen and (max-width: 1080px) {
//         grid-gap: 12px;
//     }
//     @media screen and (max-width: 980px) {
//         grid-gap: 20px;
//         grid-template-columns: 1fr 1fr 1fr;
//     }
//     @media screen and (max-width: 768px) {
//         grid-template-columns: 1fr 1fr;
//     }
//     @media screen and (max-width: 640px) {
//         grid-template-columns: 1fr 1fr;
//     }
//     @media screen and (max-width: 480px) {
//         margin-bottom: 50px;
//         grid-template-columns: 1fr;
//     }
// `;
const BannerContainer = styled.div`
  position: relative;
  margin-bottom: 80px;
  .box_position {
    position: absolute;
    bottom: 95px;
    left: 198px;
  }
`
const SliderButton = styled.div`
  background: #9bb502;
  display: flex;
  width: fit-content;
  padding: 10px 20px;
  justify-content: center;
  border-radius: 3px;
  color: #fff;
  font-family: 'poppins-medium';
  /* font-size: 12px; */
  position: absolute;
  bottom: 85px;
  right: 80px;
`
const BannerBox = styled.div`
  @media screen and (max-width: 768px) {
    display: none;
  }
`
const ResponsBox = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
`
// const OfferBox = styled.span`
//     display: flex;
//     background: #97c439;
//     border-radius: 8px;
//     width: max-content;
//     height: 62px;
//     text-align: center;
//     align-items: center;
//     justify-content: center;
//     font-family: "poppins-medium";
//     padding: 16px;
//     position: absolute;
//     right: 26px;
//     top: 23px;
//     @media screen and (max-width: 1280px) {
//         height: 50px;
//         right: 19px;
//         top: 13px;
//     }
//     @media screen and (max-width: 768px) {
//         height: 41px;
//         right: 11px;
//         top: 11px;
//     }
//     @media screen and (max-width: 480px) {
//         padding: 8px;
//         height: 26px;
//     }
// `;
// const DiscountTitle = styled.small`
//     display: flex;
//     align-items: center;
//     text-align: center;
//     font-size: 16px;
//     @media screen and (max-width: 1280px) {
//         font-size: 14px;
//     }
//     @media screen and (max-width: 480px) {
//         font-size: 12px;
//     }
// `;
// const Price = styled.h4`
//     font-size: 24px;
//     line-height: 36px;
//     @media screen and (max-width: 1280px) {
//         font-size: 18px;
//     }
// `;
const SlideContainer = styled.div`
  /* margin-top: 80px; */
  cursor: pointer;

  .slick-dots li button:before {
    color: green;
  }
  .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: green;
  }
`
const SliderBox = styled.div`
  display: flex;
  width: 100%;
`
const ImgContainer = styled.div`
  width: 100%;
  position: relative;
  object-fit: cover;
`
const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`
const TopOffers = styled.div`
  margin-top: 60px;
`
const TitleHead = styled.h4`
  font-family: 'poppins-medium';
  font-size: 32px;
  line-height: 48px;
`
const CategoriesListContainer = styled.div`
  margin-bottom: 80px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  @media screen and (max-width: 1280px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (max-width: 980px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
  }
  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`
const SlickContainer = styled.div`
  margin-bottom: 80px;
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr 1fr; */
  /* grid-gap: 20px; */
  @media screen and (max-width: 480px) {
    margin-bottom: 55px;
  }
  /* .slick-slider .slick-track,
  .slick-slider .slick-list {
    display: flex;
  } */
  /* .slick-initialized .slick-slide {
    display: block;
    width: 300px !important;
  } */
`
const SliderSingleBox = styled.div`
  /* width: 615px;
    height: 359px; */
`
const SlideImage = styled.img`
  display: block;
  width: 95%;
  border-radius: 16px;
`
