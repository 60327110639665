import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../../ThemeConfig'
import $ from 'jquery'
import apple from '../../../../assets/images/delete-modal-apple.svg'

export default function DeleteWarnigModal({
  deleteModal,
  setDeleteModal,
  setConfirmModal,
}) {
  // useEffect(() => {
  //   if (deleteModal) {
  //     $('html').addClass('modal-enabled')
  //   } else {
  //     $('html').removeClass('modal-enabled')
  //   }
  //   document.body.style.overflow = 'hidden'
  // }, [deleteModal])
  return (
    <>
      <Cover
        onClick={() => {
          setDeleteModal(false)
        }}
      >
        <MainContainer>
          <ImageContainer>
            <Icon src={apple} alt="Image" />
          </ImageContainer>
          <Title>We’re sorry to see you go!</Title>
          <Content>
            Deleting your account will permanently remove all your personal
            information and data associated with it. This action
            cannot be undone.
          </Content>
          <Button>
            <PrimaryButton
              onClick={() => {
                setDeleteModal(false)
              }}
            >
              CANCEL
            </PrimaryButton>
            <SecondaryButton
              onClick={() => {
                setConfirmModal(true)
                setDeleteModal(false)
              }}
            >
              NEXT
            </SecondaryButton>
          </Button>
        </MainContainer>
      </Cover>
    </>
  )
}
const Cover = styled.div`
  width: 100%;
  height: 100vh;
  background: #14111194;
  position: fixed;
  top: 0;
  left: 0;
`

const MainContainer = styled.div`
  padding: 32px;
  background: ${THEME_COLORS.white};
  box-shadow: 12px 25px 25px 2px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  position: absolute;
  width: 40%;
  top: 20%;
  left: 30%;
  @media screen and (max-width: 1280px) {
    width: 45%;
  }
  @media screen and (max-width: 1080px) {
    width: 50%;
  }
  @media screen and (max-width: 980px) {
    width: 55%;
  }
  @media screen and (max-width: 768px) {
    width: 75%;
    left: 13%;
  }
  @media screen and (max-width: 480px) {
    width: 90%;
    left: 5%;
    top: 15%;
  }
`
const ImageContainer = styled.div`
  margin: 0 auto;
`
const Icon = styled.img``

const Title = styled.h3`
  width: 100%;
  color: ${THEME_COLORS.background_1};
  font-size: 22px;
  margin-bottom: 20px;
  font-family: 'poppins-medium';
  text-align: center;
  @media screen and (max-width: 480px) {
    font-size: 20px;
  }
`
const Content = styled.p`
  width: 100%;
  color: ${THEME_COLORS.background_4};
  font-size: 13px;
  margin-bottom: 40px;
  font-family: 'poppins-regular';
  text-align: center;
  span {
    color: red;
  }
`
const Button = styled.div`
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`
const PrimaryButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 50px;
  margin-left: 20px;
  cursor: pointer;
  color: ${THEME_COLORS.white};
  font-family: 'poppins-semibold';
  border-radius: 6px;
  width: max-content;
  background-color: ${THEME_COLORS.secondary};
  border: 2px solid ${THEME_COLORS.secondary};
  font-size: 14px;

  @media screen and (max-width: 480px) {
    padding: 10px 30px;
  }
  @media screen and (max-width: 360px) {
    padding: 10px 30px;
    font-size: 13px;
  }
`
const SecondaryButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 50px;
  margin-left: 20px;
  cursor: pointer;
  color: ${THEME_COLORS.secondary};
  border-radius: 6px;
  width: max-content;
  background-color: ${THEME_COLORS.white};
  border: 2px solid ${THEME_COLORS.secondary};
  font-family: 'poppins-medium';
  font-size: 14px;

  @media screen and (max-width: 480px) {
    padding: 10px 30px;
  }
  @media screen and (max-width: 360px) {
    padding: 10px 30px;
    font-size: 13px;
  }
`
