import React, { useRef } from 'react'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { THEME_COLORS } from '../../../ThemeConfig'
import styled from 'styled-components'
import Search from '../search/Search'
import { useState } from 'react'

export default function Dropdown({
  title,
  data,
  search = false,
  value,
  setValue,
  validation,
  msg,
  fieldName,
  isStatus,
  setIsStatus,
  id,
  submitApi,
  varientError,
  cartDetails,
  varientId,
  setVarientId
}) {
  // const handleChange = (e) => {
  // 	e.preventDefault();
  // 	setValue(e.target.value);
  // 	if (setIsStatus) {
  // 		setIsStatus(false);
  // 	}
  // };
  const [varient, setVarient] = useState('')
  let textInput = useRef(null)
  // console.log(data,"valluueessssdataaaa")
  return (
    <>
      {/* {varientError ? <Availability>{varientError}</Availability> : ''} */}

      <Outer>
        {/* {varientError ? <Availability>{varientError}</Availability> : ''} */}
        <Label
          onClick={() => {
            textInput.current.focus()
          }}
        >
          {title}
        </Label>
        <div>
          <div style={{ height: '100%' }}>
            <Box
              sx={{
                background: `#ffffff`,
                borderRadius: '8px',
                height: '100%',
              }}
            >
              <FormControl fullWidth variant="outlined" size="small">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={value}
                  onChange={(e) => {
                    // submitApi(id, e.target.value)
                    setValue(e.target.value)
                  }}
                  onClick={() => {
                    submitApi(value,varientId)
                  }}
                  sx={{
                    height: '100%',
                    color: `#6B6B6B`,
                    '&& .MuiSvgIcon-root': {
                      fill: `#6B6B6B`,
                    },
                    '&& fieldset': {
                      border: `1px solid #EFC1B4`,
                      borderRadius: '8px',
                    },
                    '&:hover': {
                      '&& fieldset': {
                        border: `1px solid #EFC1B4`,
                      },
                    },
                  }}
                  inputRef={textInput}
                  MenuProps={{
                    sx: {
                      '&& .MuiList-root': {
                        backgroundColor: `#ffffff`,
                        color: `#6B6B6B`,
                        borderRadius: 0,
                      },
                      '&& .MuiMenuItem-root.Mui-selected:hover': {
                        color: `#000`,
                      },
                    },
                  }}
                  displayEmpty={true}
                >
                  {search && (
                    <MenuItem>
                      <Search />
                    </MenuItem>
                  )}
                  {varient ? (
                    <MenuItem disabled value="">
                      {varient}
                    </MenuItem>
                  ) : (
                    <MenuItem disabled value="">
                      Choose Varient
                    </MenuItem>
                  )}

                  {(data || []).map((item, i) => {
                    return (
                      <MenuItem
                        onClick={() => {
                          setVarient(item?.name)
                          setVarientId(item.id)
                        }}
                        key={item.id}
                        value={item.id}
                      >
                        {item.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Box>
          </div>
        </div>
      </Outer>
    </>
  )
}

const Outer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
`

const Label = styled.p`
  font-family: 'poppins-medium';
  color: ${THEME_COLORS.border_1};
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 4px;
`
const Availability = styled.p`
  /* display: none; */
  color: red;
  font-family: 'poppins-medium';
  font-size: 14px;
  margin-bottom: 10px;
  @media screen and (max-width: 640px) {
    font-size: 12px;
  }
`
