import React from 'react'
import styled from 'styled-components'

export default function EmptyPage({ image, status }) {
  return (
    <MainContainer>
      <Container>
        <Img src={image} alt="Image" />
      </Container>
      <Status>{status}</Status>
    </MainContainer>
  )
}
const MainContainer = styled.div`
  min-height: calc(100vh - 470px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Container = styled.div`
  margin-bottom: 10px;
  width:50px;
`
const Img = styled.img`
  display: block;
  width:100%
`
const Status = styled.p`
  color: #8e8e8e;
  font-family: 'poppins-medium';
  font-size: 18px;
`
