import React from 'react'
import styled from 'styled-components'
import noData from '../../../assets/images/no-results.svg'
import { Link } from 'react-router-dom'

export default function NoResultFound() {
  return (
    <>
      <MainContainer>
        <NoData>
          <Img src={noData} alt="Image" />
        </NoData>
        <Text to="/">No results found</Text>
      </MainContainer>
    </>
  )
}
const MainContainer = styled.div`
  /* display: grid;
  place-items: center;
  height: 100%;
  padding-top: 150px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const NoData = styled.div`
  width: 40%;
  margin-bottom: 20px;
  @media all and (max-width: 1080px) {
    /* width: 30%; */
  }
  @media all and (max-width: 768px) {
    /* width: 40%; */
  }
  @media all and (max-width: 480px) {
    /* width: 45%; */
  }
`
const Img = styled.img`
  width: 100%;
  display: block;
`
const Text = styled.a`
  font-family: 'poppins-medium';
  color: #303030;
  @media all and (max-width: 1080px) {
    font-size: 16px;
  }
  @media all and (max-width: 640px) {
    font-size: 14px;
  }
  @media all and (max-width: 480px) {
    font-size: 13px;
  }
`
