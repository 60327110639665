import React, { useContext, useState } from "react";
import styled from "styled-components";
import loginimage from "../../../assets/images/Remedy-rafiki 1.png";
import PrimaryButton from "../../includes/buttons/PrimaryButton";
import close from "../../../assets/icons/close-square.svg";
import OtpInput from "react-otp-input";
import { baseConfig } from "../../../axiosConfig";
import { AuthContext } from "../../../contexts/AuthStore";
import "./otp.css";
import { Context } from "../../../contexts/Store";
import { THEME_COLORS } from "../../../ThemeConfig";

export default function Otp({ setModal }) {
    const [otp, setOtp] = useState("");
    const { authstate, authdispatch } = useContext(AuthContext);
    const [email, setEmail] = useState(authstate?.auth_modal_details?.email);
    const phone = authstate?.auth_modal_details?.phone;
    const [errorCodeMsg, setErrorCodeMsg] = useState("");
    const [message, setMessage] = useState("");
    const [errorMsg, setError] = useState('')
    const name = authstate?.auth_modal_details?.name;

    const { state, dispatch } = useContext(Context);

    // const handleChange = (e) => {
    //     setOtp(e);
    // };
    const handleChange = (otp) => {
        // Check if all characters are numeric
        if (/^\d+$/.test(otp)) {
          setOtp(otp);
        }
      };
    const closeModal = () => {
        const auth_modal_details = {
            auth_modal_name: "",
            is_auth_modal: false,
        };
        authdispatch({
            type: "AUTH_MODAL",
            auth_modal_details,
        });
    };

    const verifyOtp = () => {
        const formData = new FormData();
        formData.append("phone", phone);
        formData.append("otp", otp);

        let is_forget_otp = authstate?.auth_modal_details?.forget_otp;
        let url = is_forget_otp
            ? "/users/verify-forget-otp/"
            : "users/verify-register-otp/";

        baseConfig
            .post(url, formData, {})
            .then((response) => {
                const { StatusCode, data, token, message } = response.data;
                if (StatusCode === 6000) {
                    // const auth_modal_details = {
                    //     auth_modal_name: "password",
                    //     is_auth_modal: true,
                    // };
                    // authdispatch({
                    //     type: "AUTH_MODAL",
                    //     auth_modal_details,
                    // });

                    if (is_forget_otp) {
                        const auth_modal_details = {
                            auth_modal_name: "reset_password",
                            is_auth_modal: true,
                            email: email,
                        };
                        authdispatch({
                            type: "AUTH_MODAL",
                            auth_modal_details,
                        });
                    } else {
                        const user_details = {
                            is_verified: true,
                            role: token.role,
                            access: token.access,
                            refresh: token.refresh,
                        };
                        dispatch({
                            type: "UPDATE_USER",
                            user_details,
                        });
                        closeModal();
                    }
                } else if (StatusCode === 6001) {
                    setErrorCodeMsg(message);
                }
            })
            .catch((error) => {});
    };
    const verifyResendOtp = () => {
        setError(false)
        const formData = new FormData()
        formData.append('phone', phone)
    
        baseConfig
          .post('users/send-otp/', formData, {})
          .then((response) => {
            const { StatusCode, data, token, message } = response.data
            console.log(response,"sent otp response")
            if (StatusCode === 6000) {
              const auth_modal_details = {
                auth_modal_name: 'login_otp',
                is_auth_modal: true,
                phone: phone,
              }
              authdispatch({
                type: 'AUTH_MODAL',
                auth_modal_details,
              })
            //   setLastSentTime(data.timeout);
              
              const user_details = {
                is_verified: true,
                role: token.role,
                access: token.access,
                refresh: token.refresh,
              }
              dispatch({
                type: 'UPDATE_USER',
                user_details,
              })
            }
            else if (StatusCode === 6001) {
              setErrorCodeMsg(message)
            }
          })
          .catch((error) => {})
      }
    return (
        <MainContiner>
            <LeftBox>
                <div>
                    <Heading>Hello there 👋🏻</Heading>
                    <Title>
                        Welcome to the wellness journey! Browse our range of
                        top-quality products and make a purchase today to take
                        the first step towards a healthier you.
                    </Title>
                </div>
                <ImageBox>
                    <Img src={loginimage} alt="Image" />
                </ImageBox>
            </LeftBox>
            <RightBox>
                <TopBox>
                    <RightTitle>OTP Verification</RightTitle>
                    <Text>Verify the OTP that we have sent to your phone</Text>
                </TopBox>
                <OtpContainer>
                    <Form>
                        <OtpInput
                            onChange={handleChange}
                            value={otp}
                            numInputs={4}
                            autoFocus
                            separator={<Seperate>---</Seperate>}
                            inputStyle="input_otp"
                            containerStyle="container_otp"
                        />
                    </Form>
                    {/* <Resend
                    // onClick={() => {if(counter == 0){
                    //   verifyResendOtp();
                    // }
                    // }}
                    >
                    {/* {counter > 0 ? (
                        <OtpText className={counter == 0 && "active" }>Resend OTP in 00:{countdown} sec</OtpText>
                    ) : (
                        <OtpText onClick={startCountdown}>Resend OTP</OtpText>
                    )} */}
                    <div>
                    {/* {remainingTime === 0 ? ( */}
                        {/* <button onClick={verifyResendOtp}>Resend OTP</button> */}
                    {/* ) : (
                        <button disabled>{`Resend OTP (${formatTime(remainingTime)})`}</button>
                    )} */}
                    </div>
                    {/* </Resend>  */}
                    <ErrorMsg>{errorCodeMsg && errorCodeMsg}</ErrorMsg>
                    <div className="context">
                        <PrimaryButton
                            className="category_button"
                            text={"Verify"}
                            onClick={verifyOtp}
                            size={"small"}
                        />
                    </div>
                </OtpContainer>
                <BottomContainer>
                    <TextSubTitle>
                        Back to{" "}
                        <HomeLink
                            onClick={() => {
                                const auth_modal_details = {
                                    auth_modal_name: "login",
                                    is_auth_modal: true,
                                };
                                authdispatch({
                                    type: "AUTH_MODAL",
                                    auth_modal_details,
                                });
                            }}
                        >
                            Login
                        </HomeLink>
                    </TextSubTitle>
                </BottomContainer>
                <RightIcon
                    onClick={() => {
                        closeModal();
                    }}
                >
                    <Img src={close} alt="Image" />
                </RightIcon>
            </RightBox>
        </MainContiner>
    );
}
const MainContiner = styled.div`
    display: flex;
    max-width: 794px;
    @media screen and (max-width: 128px) {
        width: 100%;
    }
    @media screen and (max-width: 640px) {
        flex-direction: column;
        height: 100%;
    }
`;
const LeftBox = styled.div`
    background: #56b57f;
    width: 40%;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @media screen and (max-width: 640px) {
        width: 100%;
    }
`;
const Heading = styled.h3`
    font-size: 24px;
    line-height: 36px;
    color: ${THEME_COLORS.white};
    margin-bottom: 16px;
    font-family: "poppins-medium";
    @media screen and (max-width: 1425px) {
        min-width: max-content;
        font-size: 23px;
    }
    @media screen and (max-width: 1329px) {
        min-width: 100%;
        font-size: 24px;
    }
    @media screen and (max-width: 1280px) {
        font-size: 22px;
        width: max-content;
    }
    @media screen and (max-width: 980px) {
        font-size: 22px;
        width: 100%;
    }
`;
const Title = styled.p`
    font-size: 14px;
    line-height: 24px;
    color: ${THEME_COLORS.border_5};
    font-family: "poppins-medium";
`;
const ImageBox = styled.div`
    width: 156px;
    height: 143px;
    margin: 0 auto;
    @media screen and (max-width: 640px) {
        display: none;
    }
`;
const Img = styled.img`
    display: block;
    width: 100%;
    height: 100%;
`;
const RightBox = styled.div`
    width: 60%;
    padding: 64px;
    background: ${THEME_COLORS.white};
    &.context {
        box-shadow: 7px 11px 30px rgba(0, 0, 0, 0.06);
    }
    @media screen and (max-width: 1280px) {
        padding: 40px 36px;
    }
    @media screen and (max-width: 640px) {
        width: 100%;
        padding: 24px;
    }
`;
const TopBox = styled.div`
    margin-bottom: 48px;
    @media screen and (max-width: 860px) {
        margin-bottom: 38px;
    }
    @media screen and (max-width: 728px) {
        margin-bottom: 28px;
    }
`;
const RightTitle = styled.h4`
    color: ${THEME_COLORS.background_1};
    font-size: 24px;
    font-family: "poppins-medium";
`;
const Form = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
    gap: 16px;
    width: 400px;

    @media screen and (max-width: 1280px) {
        width: 400px;
    }
    @media screen and (max-width: 860px) {
        width: 300px;
        margin-bottom: 100px;
    }
    @media screen and (max-width: 728px) {
        width: 250px;
        margin-bottom: 38px;
    }
`;
const ErrorMsg = styled.p`
    font-size: 12px;
    font-family: "poppins-regular";
    color: red;
    margin-bottom: 140px;
`;
const BottomContainer = styled.div`
    @media screen and (max-width: 640px) {
        padding-top: 8px;
    }
`;
// const BottomTop = styled.div`
// 	display: flex;
// 	flex-wrap: wrap;
// 	justify-content: center;
// 	align-items: center;
// 	border-bottom: 1px solid #dbdbdb;
// 	margin-bottom: 34px;
// 	padding: 16px 0;
// 	@media screen and (max-width: 980px) {
// 		padding: 8px 0;
// 		margin-bottom: 26px;
// 	}
// 	@media screen and (max-width: 980px) {
// 		margin-bottom: 16px;
// 	}
// `;
const Seperate = styled.div`
    color: ${THEME_COLORS.white};
    width: 15px;
`;
const Text = styled.h4`
    color: ${THEME_COLORS.border_1};
    font-weight: 400;
    font-size: 14px;
    font-family: "poppins-medium";
    line-height: 28px;
    align-items: flex-start;
    @media screen and (max-width: 980px) {
        /* font-size: 17px; */
    }
    @media screen and (max-width: 768px) {
        /* font-size: 15px; */
    }
`;
// const TextSub = styled.h4`
// 	color: #aaaaaa;
// 	font-weight: 400;
// 	font-size: 14px;
// 	font-family: "poppins-medium";
// 	line-height: 28px;
// 	align-items: flex-start;
// 	@media screen and (max-width: 1280px) {
// 		/* font-size: 17px; */
// 	}
// 	@media screen and (max-width: 640px) {
// 		/* font-size: 15px; */
// 	}
// `;
const TextSubTitle = styled.h4`
    color: ${THEME_COLORS.border_1};
    font-weight: 400;
    font-size: 14px;
    font-family: "poppins-medium";
    line-height: 28px;
    align-items: center;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 640px) {
        /* font-size: 15px; */
    }
`;
const HomeLink = styled.a`
    color: ${THEME_COLORS.secondary};
    text-decoration: underline;
    font-size: 14px;
    line-height: 30px;
    font-family: "poppins-medium";
    cursor: pointer;
    margin-left: 6px;
    @media screen and (max-width: 1280px) {
        /* font-size: 17px; */
    }
    @media screen and (max-width: 768px) {
        /* font-size: 15px; */
    }
`;
const OtpContainer = styled.span`
    width: 100%;
`;
const RightIcon = styled.span`
    position: absolute;
    display: inline-block;
    width: 32px;
    height: 32px;
    top: 10px;
    right: 10px;
    cursor: pointer;
`;
const Resend = styled.div`
`