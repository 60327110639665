import React from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'
import about_2 from '../../../assets/images/intro-img.png'

function Intro() {
  return (
    <>
      <MainContainer>
        {' '}
        <AboutTop className="intro">
          <Content>
            <Title>
              <span>Let</span> Us Introduce
            </Title>
            <SubTitle>
              Welcome to PACESS WELLNESS INDIA LLP! We're a new and promising
              wellness initiative focusing on combating lifestyle diseases like
              diabetes, blood pressure, and cholesterol through proper nutrition
              and lifestyle choices. With 13+ years of experience, we understand
              the value of Nutraceutical Supplements in recovery and well-being.
              Our mission is to provide affordable, high-quality supplements
              alongside conventional treatments, promoting your overall health
              and happiness.
            </SubTitle>
          </Content>
          <ImageCOntainer>
            <Image className="intro">
              <Img src={about_2} alt="Image" />
            </Image>
            <Square className="bottom">
              Abdul Salam P P <br></br>
              <span>Managing Director</span>
            </Square>
          </ImageCOntainer>
        </AboutTop>
      </MainContainer>
    </>
  )
}

export default Intro
const MainContainer = styled.div`
  background-color: #f5fff9;
  width: 100%;
  padding: 100px 0;
  @media screen and (max-width: 1080px) {
    padding: 70px 0;
  }
  @media screen and (max-width: 980px) {
    padding: 50px 0;
  }
`
const AboutTop = styled.div`
  width: 80%;
  max-width: 1350px;
  margin: 0 auto;
  display: flex;
  gap: 60px;
  align-items: center;
  justify-content: space-between;
  &.first {
    margin: 70px 0 0 0;
    align-items: center;
  }

  @media screen and (max-width: 980px) {
    flex-direction: column-reverse;
    /* margin: 60px 0; */
    gap: 30px;
    &:nth-child(2) {
      flex-direction: column;
    }
  }
`
const Content = styled.div`
  width: 50%;
  @media screen and (max-width: 980px) {
    width: 100%;
  }
`
const Square = styled.div`
  font-family: 'poppins-regular';
  background-color: #fff;
  width: 180px;
  padding: 10px 15px;
  border-radius: 12px;
  font-size: 16px;
  position: absolute;
  top: 55%;
  left: -35px;
  box-shadow: 7px 11px 30px 0px #0000000f;
  span {
    color: #106f39;
    font-size: 14px;
    @media screen and (max-width: 1200px) {
      font-size: 12px;
    }
    @media screen and (max-width: 768px) {
      font-size: 10px;
    }
    @media screen and (max-width: 640px) {
      font-size: 8px;
    }
  }
  @media screen and (max-width: 1200px) {
    width: 160px;
    font-size: 14px;
  }
  @media screen and (max-width: 980px) {
    top: 60%;
    left: -25px;
  }
  @media screen and (max-width: 768px) {
    width: 140px;
    font-size: 12px;
    padding: 10px;
    border-radius: 10px;
  }

  @media screen and (max-width: 640px) {
    width: 125px;
    font-size: 11px;
    padding: 10px;
    left: -35px;
  }
  @media screen and (max-width: 520px) {
    width: 115px;
    font-size: 10px;
    padding: 7px;
    left: -40px;
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 360px) {
    width: 104px;
    left: -20px;
  }
`
const Title = styled.h4`
  font-family: 'poppins-medium';
  color: #1d1d46;
  font-size: 40px;
  margin-bottom: 35px;
  span {
    color: #106f39;
  }
  @media screen and (max-width: 1280px) {
    font-size: 34px;
  }
  @media screen and (max-width: 1080px) {
    font-size: 32px;
  }
  @media screen and (max-width: 980px) {
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 25px;
    font-size: 30px;
  }
  @media screen and (max-width: 480px) {
    font-size: 26px;
  }
`
const SubTitle = styled.p`
  color: #1d1d46;
  font-family: 'poppins-regular';
  font-size: 18px;
  /* margin-bottom: 40px; */
  text-align: justify;

  @media screen and (max-width: 980px) {
    text-align: justify;
    font-size: 16px;
  }
  @media screen and (max-width: 640px) {
    font-size: 14px;
    text-align: justify;
  }
`

const ImageCOntainer = styled.div`
  width: 40%;
  position: relative;
  @media screen and (max-width: 1280px) {
    width: 50%;
  }
  @media screen and (max-width: 1080px) {
    width: 55%;
  }
  @media screen and (max-width: 980px) {
    margin: 0 auto;
    width: 70%;
  }
  @media screen and (max-width: 480px) {
    width: 85%;
  }
  @media screen and (max-width: 360px) {
    width: 95%;
  }
`
const Image = styled.div`
  width: 100%;
`
const Img = styled.img`
  width: 100%;
  display: block;
`
