import React, { createContext, useReducer } from 'react'
import Reducer from './Reducer'

const initialState = {
  user_details: {
    is_verified: false,
    role: '',
  },
  order_details: {
    orderId: '',
    pk: 'cfef9d53-d7ba-4389-9327-5b749a334ecb',
  },
  selectedAdress: null,
  confirmAddressId: null,
}
// console.log(initialState.order_details.orderId, 'Storeeeeeeeeeee iddd')
const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState)

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  )
}

export const Context = createContext(initialState)

export default Store
