import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'
import { Link, useNavigate } from 'react-router-dom'
import close from '../../../../src/assets/icons/Closeme.svg'
import logo from '../../../../src/assets/icons/logo.svg'
import ModalCenter from '../../includes/modal/ModalCenter'
import Logout from '../../screens/logout/Logout'
import { Context } from '../../../contexts/Store'
import { AuthContext } from '../../../contexts/AuthStore'
import $ from 'jquery'
// import Logout from '../logout/Logout'

export default function Navbar({ isNav, setisNav }) {
  const navigate = useNavigate()
  const [logoutModal, setLogoutModal] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const { state, dispatch } = useContext(Context)
  const { authstate, authdispatch } = useContext(AuthContext)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    // setSelected(!selected);
  }

  const handleClose = () => {
    setAnchorEl(null)
    // setSelected(!selected);
  }
  useEffect(() => {
    if (isNav) {
      $('html').addClass('modal-enabled')
    } else {
      $('html').removeClass('modal-enabled')
    }
    // document.body.style.overflow = 'hidden'
  }, [isNav])
  return (
    <>
      <MainContainer className={isNav && 'active'}>
        {/* <CloseMe
          onClick={() => {
            setisNav(false)
          }}
        >
          <Img src={close} alt="Image" />
        </CloseMe> */}

        <Navlist>
          <Item>
            <ButtonLink
              onClick={() => {
                setisNav(false)
              }}
              to="/"
            >
              Home
            </ButtonLink>
          </Item>
          <Item>
            <ButtonLink
              onClick={() => {
                setisNav(false)
              }}
              to="/home-about"
            >
              About Us
            </ButtonLink>
          </Item>
          <Item>
            <ButtonLink
              onClick={() => {
                setisNav(false)
              }}
              to="/productlist"
            >
              Products
            </ButtonLink>
          </Item>
          <Item>
            <ButtonLink
              onClick={() => {
                setisNav(false)
              }}
              to="/offerpage"
            >
              Offers
            </ButtonLink>
          </Item>
          {state.user_details.is_verified == true ? (
            <>
              <Item>
                <ButtonLink
                  onClick={() => {
                    setisNav(false)
                  }}
                  to="/wishlist"
                >
                  Wishlist
                </ButtonLink>
              </Item>
              <Item>
                <ButtonLink
                  onClick={() => {
                    setisNav(false)
                  }}
                  to="/cart"
                >
                  Cart
                </ButtonLink>
              </Item>
              <Item>
                <ButtonLink
                  onClick={() => {
                    setisNav(false)
                  }}
                  to="/profile"
                >
                  My Account
                </ButtonLink>
              </Item>
              <Item>
                <ButtonLink
                  onClick={() => {
                    setisNav(false)
                  }}
                  to="/order-status"
                >
                  My Orders
                </ButtonLink>
              </Item>
              <Item>
                <ButtonLink
                  onClick={() => {
                    setisNav(false)
                  }}
                  to="/address"
                >
                  My Address
                </ButtonLink>
              </Item>
              <Item>
                <ButtonLink
                  onClick={() => {
                    setisNav(false)
                  }}
                  to="/notification"
                >
                  Notification
                </ButtonLink>
              </Item>
              <Item>
                <ButtonLink
                  onClick={() => {
                    setisNav(false)
                  }}
                  to="/settings"
                >
                  Settings
                </ButtonLink>
              </Item>
            </>
          ) : (
            ''
          )}
          {state.user_details.is_verified == true ? (
            <Item>
              <ButtonLink
                onClick={() => {
                  handleClose()
                  setLogoutModal(true)
                }}
              >
                Log Out
              </ButtonLink>
            </Item>
          ) : (
            <Item>
              <ButtonLink
                onClick={() => {
                  const auth_modal_details = {
                    auth_modal_name: 'login',
                    is_auth_modal: true,
                  }
                  authdispatch({
                    type: 'AUTH_MODAL',
                    auth_modal_details,
                  })
                  setisNav(false)
                }}
              >
                Log In
              </ButtonLink>
            </Item>
          )}
        </Navlist>
      </MainContainer>
      {logoutModal && (
        <ModalCenter setModal={setLogoutModal}>
          <Logout setModal={setLogoutModal} />
        </ModalCenter>
      )}

      <Overlay
        className={isNav && 'active'}
        onClick={() => {
          setisNav(false)
        }}
      />
    </>
  )
}

const MainContainer = styled.div`
  left: 0px;
  top: -1000px;
  position: fixed;
  width: 100%;
  z-index: 101;
  /* min-height: 100vh; */
  padding: 80px 0px;
  box-shadow: #64646f33 0px 7px 29px 0px;
  background: ${THEME_COLORS.white};
  max-height: 100vh;
  overflow-y: scroll;
  transition: all 0.5s ease 0s;
  &.active {
    top: 0px;
  }
`
const Navlist = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`
const Item = styled.li`
  display: inline-block;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`
const ButtonLink = styled(Link)`
  cursor: pointer;
  /* margin-top: 15px; */
  font-size: 1.0625rem;
  color: #333;
  font-size: 16px;
  font-family: 'poppins-regular';
  display: inline-block;
`
const Overlay = styled.div`
  top: 0px;
  left: 0px;
  z-index: 100;
  position: absolute;
  min-height: 100vh;
  width: 100%;
  cursor: pointer;
  opacity: 0.5;
  background: ${THEME_COLORS.background_1};
  display: none;
  &.active {
    display: block;
  }
`
const CloseMe = styled.div`
  position: relative;
  cursor: pointer;
`
const Img = styled.img`
  display: block;
  position: absolute;
  width: 44px;
  top: 0px;
  right: 54px;
  height: 44px;
  @media screen and (max-width: 768px) {
    /* top: -17px;
    right: 17px; */
  }
`
const LogoContainer = styled.div`
  width: 150px;
  padding-left: 30px;
`
const Logo = styled.img`
  width: 100%;
  height: 100%;
  display: block;
`
