import React from "react";

export const homeSvg = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.95272 8.99C5.92272 0.330002 18.7527 0.340003 20.7127 9C21.8627 14.08 18.7027 18.38 15.9327 21.04C13.9227 22.98 10.7427 22.98 8.72272 21.04C5.96272 18.38 2.80272 14.07 3.95272 8.99Z"
                stroke="#303030"
                stroke-width="1.5"
            />
            <path d="M9.58301 12L11.083 13.5L15.083 9.5" stroke="#303030" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export const wishlistSvg = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.1665 8.16952V7.19952C8.1665 4.94952 9.9765 2.73952 12.2265 2.52952C14.9065 2.26952 17.1665 4.37952 17.1665 7.00952V8.38952"
                stroke="#303030"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M9.66657 22.5H15.6666C19.6866 22.5 20.4066 20.89 20.6166 18.93L21.3666 12.93C21.6366 10.49 20.9366 8.5 16.6666 8.5H8.66657C4.39657 8.5 3.69657 10.49 3.96657 12.93L4.71657 18.93C4.92657 20.89 5.64657 22.5 9.66657 22.5Z"
                stroke="#303030"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path d="M16.162 12.5H16.171" stroke="#303030" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9.16101 12.5H9.16999" stroke="#303030" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export const ordersSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.62 20.8101C12.28 20.9301 11.72 20.9301 11.38 20.8101C8.48 19.8201 2 15.6901 2 8.6901C2 5.6001 4.49 3.1001 7.56 3.1001C9.38 3.1001 10.99 3.9801 12 5.3401C13.01 3.9801 14.63 3.1001 16.44 3.1001C19.51 3.1001 22 5.6001 22 8.6901C22 15.6901 15.52 19.8201 12.62 20.8101Z"
                stroke="#303030"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export const settingsSvg = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
                stroke="#303030"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M1.66675 10.7319V9.26523C1.66675 8.39856 2.37508 7.6819 3.25008 7.6819C4.75841 7.6819 5.37508 6.61523 4.61675 5.3069C4.18341 4.5569 4.44175 3.5819 5.20008 3.14856L6.64175 2.32356C7.30008 1.9319 8.15008 2.16523 8.54175 2.82356L8.63341 2.9819C9.38341 4.29023 10.6167 4.29023 11.3751 2.9819L11.4667 2.82356C11.8584 2.16523 12.7084 1.9319 13.3667 2.32356L14.8084 3.14856C15.5667 3.5819 15.8251 4.5569 15.3917 5.3069C14.6334 6.61523 15.2501 7.6819 16.7584 7.6819C17.6251 7.6819 18.3417 8.39023 18.3417 9.26523V10.7319C18.3417 11.5986 17.6334 12.3152 16.7584 12.3152C15.2501 12.3152 14.6334 13.3819 15.3917 14.6902C15.8251 15.4486 15.5667 16.4152 14.8084 16.8486L13.3667 17.6736C12.7084 18.0652 11.8584 17.8319 11.4667 17.1736L11.3751 17.0152C10.6251 15.7069 9.39175 15.7069 8.63341 17.0152L8.54175 17.1736C8.15008 17.8319 7.30008 18.0652 6.64175 17.6736L5.20008 16.8486C4.44175 16.4152 4.18341 15.4402 4.61675 14.6902C5.37508 13.3819 4.75841 12.3152 3.25008 12.3152C2.37508 12.3152 1.66675 11.5986 1.66675 10.7319Z"
                stroke="#303030"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};
