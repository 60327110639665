import React, { useContext, useEffect, useState } from 'react'
import SecondaryButton from '../../includes/buttons/SecondaryButton'
import styled from 'styled-components'
import coverpic from '../../../assets/images/spotlight_cover.png'
import DealOfTheDay from '../deal_of_day/DealOfTheDay'
import { baseConfig } from '../../../axiosConfig'
import { Context } from '../../../contexts/Store'
import { AuthContext } from '../../../contexts/AuthStore'
import { useNavigate } from 'react-router-dom'
import spotcover from '../../../assets/images/girls_spotlight 2.png'
import respocover from '../../../assets/images/spotlight2.png'
import DefaultLottie from '../../includes/common/loader/DefaultLottie'
import green from '../../../assets/images/green.svg'
import pink from '../../../assets/images/pink.svg'
import left from '../../../assets/images/spot1.svg'
import right from '../../../assets/images/spot2.svg'
import { THEME_COLORS } from '../../../ThemeConfig'

export default function Home() {
  const [spotlightList, setSpotlightList] = useState({})
  const { state } = useContext(Context)
  const navigate = useNavigate()

  const { authdispatch } = useContext(AuthContext)
  const [rondomProduct, setRandomProduct] = useState([])
  const [isLoading, setLoading] = useState(true)

  const SpotlightData = () => {
    baseConfig
      .get('general/spotlight/', {
        params: {
          page: 10,
        },
      })
      .then((response) => {
        const { data } = response.data
        setSpotlightList(data)
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
      .catch((error) => {
        // const { status, data } = error?.response;
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
  }

  //get products
  let getProductsList = () => {
    setLoading(true)
    let accessToken = state.user_details.access
    baseConfig
      .get('products/products-list/', {
        headers: {
          Authorization: accessToken ? 'Bearer ' + accessToken : null,
        },
      })
      .then((response) => {
        const { data } = response.data

        if (data.length > 1) {
          let randomIndex1 = Math.floor(Math.random() * data.length)
          let randomIndex2 = Math.floor(Math.random() * data.length)

          while (randomIndex1 === randomIndex2) {
            randomIndex2 = Math.floor(Math.random() * data.length)
          }
          const randomObject1 = data[randomIndex1]
          const randomObject2 = data[randomIndex2]

          setRandomProduct([randomObject1, randomObject2])

          setTimeout(() => {
            setLoading(false)
          }, 100)
        }
      })
      .catch((error) => {
        // const { status, data } = error?.response;
        setTimeout(() => {
          setLoading(false)
        }, 100)
      })
  }
  const [bannerList, setBannerList] = useState([])
  let OfferBannerData = (token) => {
    baseConfig
      .get('general/banners/', {
        params: {
          page: 10,
          banner_type: 20,
        },
      })
      .then((response) => {
        setBannerList(response.data.data)
      })
      .catch((error) => {
        const { status, data } = error?.response
      })
  }
  useEffect(() => {
    OfferBannerData()
  }, [])
  useEffect(() => {
    // Scroll to the top of the page when the component mounts (page reload)
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    getProductsList()
    SpotlightData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    const href = window.location.href.substring(
      window.location.href.lastIndexOf('#') + 1,
    )
    const element = document.getElementById(href)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])

  // console.log(bannerList, 'featured in homepage')

  return (
    <>
      {!isLoading ? (
        <>
          <MainContainer>
            <SpotLightBox>
              <MainPage>
                <MiddleBox
                  id="spot"
                  onClick={() => {
                    if (state.user_details.is_verified) {
                      navigate('productlist')
                    } else {
                      const auth_modal_details = {
                        auth_modal_name: 'login',
                        is_auth_modal: true,
                      }
                      authdispatch({
                        type: 'AUTH_MODAL',
                        auth_modal_details,
                      })
                    }
                  }}
                >
                  {spotlightList?.image ? (
                    <SpotlightImage src={spotcover} alt="Image" />
                  ) : (
                    <SpotlightImage src={spotcover} alt="Image" />
                  )}
                  <ResponsiveImage>
                    {spotlightList?.responsive_image ? (
                      <Image src={spotlightList.responsive_image} alt="Image" />
                    ) : (
                      <Image src={respocover} alt="Image" />
                    )}
                  </ResponsiveImage>
                </MiddleBox>
              </MainPage>

              {/* <BottomBox>
                <LeftImgContainer
                  onClick={() => {
                    navigate(`/productlist`)
                  }}
                >
                  <img src={green} alt="image" />
                </LeftImgContainer>
                <LeftImgContainer
                  onClick={() => {
                    navigate(`/productlist`)
                  }}
                >
                  <img src={pink} alt="image" />
                </LeftImgContainer>
              </BottomBox> */}

              {bannerList ? (
                <BottomBox>
                  {bannerList?.map((item, i) => (
                    <LeftImgContainer
                      onClick={() => {
                        navigate(`/productlist`)
                      }}
                    >
                      <img src={item.image} alt="image" />
                    </LeftImgContainer>
                  ))}
                </BottomBox>
              ) : (
                <BottomBox>
                  <LeftImgContainer
                    onClick={() => {
                      navigate(`/productlist`)
                    }}
                  >
                    <img src={left} alt="image" />
                  </LeftImgContainer>
                  <LeftImgContainer
                    onClick={() => {
                      navigate(`/productlist`)
                    }}
                  >
                    <img src={right} alt="image" />
                  </LeftImgContainer>
                </BottomBox>
              )}

              {/* <BottomBox>
                <LeftImgContainer
                  onClick={() => {
                    navigate(`/productlist`)
                  }}
                >
                  <img src={left} alt="image" />
                </LeftImgContainer>
                <LeftImgContainer
                  onClick={() => {
                    navigate(`/productlist`)
                  }}
                >
                  <img src={right} alt="image" />
                </LeftImgContainer>
              </BottomBox> */}
            </SpotLightBox>
          </MainContainer>
          <DealOfTheDay />
        </>
      ) : (
        <DefaultLottie />
      )}
    </>
  )
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  padding-top: 80px;
  @media screen and (max-width: 640px) {
    /* padding-bottom: 55px; */
    margin-bottom: 30px;
  }
  @media screen and (max-width: 480px) {
    /* padding-bottom: 50px; */
  }
`
// const SubContainer = styled.div`
//     min-height: calc(100vh - 410px);
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
// `;
const SpotLightBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const MainPage = styled.div`
  background: url(${coverpic});
  background-size: cover;
  background-repeat: no-repeat;
  /* height: calc(100vh - 80px); */
  @media screen and (max-width: 768px) {
    height: max-content;
  }
  @media screen and (max-width: 390px) {
    /* height: calc(75vh - 80px); */
  }
`

const MiddleBox = styled.div`
  cursor: pointer;
  margin: 50px 150px;
  /* height: 622px; */
  /* padding: 80px; */
  position: relative;
  @media screen and (max-width: 1280px) {
    margin: 100px 49px;
  }
`

const Img = styled.img`
  width: 100%;
  display: block;
`
const SpotlightImage = styled.img`
  width: 100%;
  display: block;
  object-fit: cover;
  height: 100%;
  @media screen and (max-width: 768px) {
    display: none;
  }
`
const ResponsiveImage = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    width: 73%;
    margin: 0 auto;
  }
  @media screen and (max-width: 640px) {
    width: 90%;
  }
  @media screen and (max-width: 580px) {
    width: 100%;
  }
`
const Image = styled.img`
  display: block;
  width: 100%;
  object-fit: cover;
`
const BottomBox = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  /* width: 100%; */
  justify-content: center;
  /* margin-top: 20px; */
  /* margin: 70px auto 0 auto; */
  width: 90%;
  max-width: 1350px;
  margin: 80px auto 0;
  @media screen and (max-width: 768px) {
    margin: 50px auto 0 auto;
  }
  @media screen and (max-width: 640px) {
    flex-direction: column;
    width: 90%;
    margin: 50px auto 0 auto;
  }
`
const LeftImgContainer = styled.div`
  width: 50%;
  /* height: 500px; */
  cursor: pointer;
  img {
    display: block;
    width: 100%;
  }
  @media screen and (max-width: 640px) {
    width: 100%;
  }
  .box_position {
    /* position: absolute; */
    bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    @media screen and (max-width: 768px) {
      bottom: 23px;
    }
    @media screen and (max-width: 480px) {
      bottom: 30px;
    }
  }
`
const ImageContainer = styled.div`
  width: 65%;
  margin: 50px auto;
  height: 510px;
  overflow: hidden;
  @media screen and (max-width: 1400px) {
    height: 450px;
  }
  @media screen and (max-width: 1280px) {
    height: 400px;
  }
  @media screen and (max-width: 1080px) {
    height: 360px;
  }
  @media screen and (max-width: 980px) {
    height: 310px;
  }
  @media screen and (max-width: 768px) {
    height: 250px;
  }
  @media screen and (max-width: 640px) {
    height: 200px;
  }
  @media screen and (max-width: 480px) {
    height: auto;
  }
`
const BuyButton = styled.div`
  padding: 10px 40px;
  cursor: pointer;
  color: ${THEME_COLORS.secondary};
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  border-radius: 6px;
  width: max-content;
  background-color: ${THEME_COLORS.white};
  font-family: 'poppins-medium';
  &:hover {
    background-color: ${THEME_COLORS.secondary};
    color: ${THEME_COLORS.light_orange};
  }
  font-size: 14px;
  @media screen and (max-width: 980px) {
    padding: 10px 40px;
  }
  @media screen and (max-width: 768px) {
    padding: 10px 40px;
    font-size: 13px;
  }
`
