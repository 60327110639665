import React from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'

function PrimaryButton({ text, onClick, size, className, margin, image }) {
  return (
    <MainContainer
      className={className}
      onClick={onClick}
      size={size}
      margin={margin}
    >
      <Text className={className}>{text}</Text>
      {image && <Image src={image} alt="image" />}
    </MainContainer>
  )
}

const MainContainer = styled.div`
    cursor: pointer;
    display: flex;
        height: 50px;
    align-items: center;
    justify-content: center;
    padding: ${(props) =>
      props.size === 'small'
        ? '12px 24px'
        : props.size === 'xsmall'
        ? '8px 20px'
        : '16px 32px'};
    margin-left: ${(props) => (props.margin === '20px' ? '20px' : '0')};
    cursor: pointer;
    color: ${THEME_COLORS.white};
    font-family: "poppins-semibold";
    border-radius: 6px;
    width: max-content;
    background-color: ${THEME_COLORS.secondary};
    border: 2px solid ${THEME_COLORS.secondary};
    // &:hover {
    //   background-color: ${THEME_COLORS.white};
    //   color: ${THEME_COLORS.secondary};
    // }
    font-size: ${(props) => (props.size === 'small' ? '14px' : '16px')};
    font-weight: ${(props) => (props.size === 'small' ? '500' : '600')};
    &.category_button {
        width: 100%;
        text-align: center;
        padding: 12px 18px;
        height: 48px;
    }
    &.white {
        background-color: ${THEME_COLORS.white};
    }
`
const Text = styled.p`
  &.white {
    color: ${THEME_COLORS.secondary};
  }
`
const Image = styled.img`
  margin-left: 10px;
  width: 24px;
  height: 24px;
  display: block;
`

export default PrimaryButton
