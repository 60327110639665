import React, { useEffect,useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'

const SearchSuggestions = ({
  searchSuggestions,
  setInput,
  closeModal,
  sucess,
  setsucess,
  inputElement,
}) => {
  const navigate = useNavigate()
  // const inputRef = useRef(null);
  const modalRef = useRef(null);

    const handleSearch = (product) => {
      navigate(`/product/${product.id}/?is_from_search=true`)
      setInput(product.name)
    }

    useEffect(() => {
      console.log("recenyttt")
      const handleClickOutside = (event) => {
        if (
          modalRef.current &&
          !modalRef.current.contains(event.target) &&
          inputElement.current &&
          !inputElement.current.contains(event.target)
        ) {
          setsucess(false);
        }
      };
  
      document.body.addEventListener('mousedown', handleClickOutside);
  
      return () => {
        document.body.removeEventListener('mousedown', handleClickOutside);
      };
    }, [inputElement, sucess]);


  return (
    <>
      {searchSuggestions?.length !== 0 ? (
        <Container ref={modalRef}>
          <>
            {searchSuggestions?.map((product) => (
              <Product
                key={product.id}
                onClick={() => {
                  // setsucess(false)
                  handleSearch(product)
                  // closeModal()
                }}
              >
                <LeftBox>
                  <Img src={product.image} alt="image" />
                </LeftBox>
                <RightBox>
                  <Title>{product.name}</Title>
                  <Tablet>{product.category}</Tablet>
                </RightBox>
              </Product>
            ))}
          </>
        </Container>
      ) : searchSuggestions?.length == 0 && sucess == true ? (
        <Container className="nodata">
          <Product>
            <RightBox>
              {/* <Title>No Items Found !</Title>
              {/* <Tablet>{product.category}</Tablet> */}
            </RightBox>
          </Product>
        </Container>
      ) : (
        ''
      )}
    </>
  )
}

export default SearchSuggestions

const Container = styled.div`
  width: 700px;
  height: 370px;
  overflow: scroll;
  background: ${THEME_COLORS.white};
  position: absolute;
  left: 0;
  top: 60px;
  border-radius: 22px;
  box-shadow: 14px 18px 33px -40x rgba(0, 0, 0, 0.19);
  padding: 10px;
  z-index: 1000;
  &::-webkit-scrollbar {
    display: none;
  }
  @media all and (max-width: 1440px) {
    // padding: 32px;
  }
  @media all and (max-width: 980px) {
    width: 100%;
  }
  &.nodata {
    height: 0;
    width: 465px;
    // top: 65px;
    box-shadow: -3px -11px 127px -10px rgba(0, 0, 0, 0.19);
    border-radius: 15px;
    display:none;
  }
`
const Product = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  cursor: default;
  &:last-child {
    margin-bottom: 0;
  }
`
const LeftBox = styled.div`
  width: 42px;
  height: 42px;
`
const Img = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`
const RightBox = styled.div``
const Title = styled.h4`
  font-size: 18px;
  font-weight: 500;
  color: ${THEME_COLORS.background_1};
  font-family: 'poppins-medium';
  @media screen and (max-width: 640px) {
    font-size: 14px;
  }
`
const Tablet = styled.p`
  color: ${THEME_COLORS.secondary};
  font-size: 14px;
  font-weight: 500;
  font-family: 'poppins-medium';
  @media screen and (max-width: 640px) {
    font-size: 12px;
  }
`
