import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'
import PrimaryButton from '../buttons/PrimaryButton'
import discount from '../../../assets/icons/discount.svg'
import heart from '../../../assets/icons/heart-border.svg'
import heartfill from '../../../assets/icons/heart-fill.svg'
import { Link, useNavigate } from 'react-router-dom'
import { baseConfig } from '../../../axiosConfig'
import { AuthContext } from '../../../contexts/AuthStore'
import { Context } from '../../../contexts/Store'
import cart from '../../../assets/icons/cart.svg'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export default function CategoriesCard({ data, refreshApi }) {
  const navigate = useNavigate()
  const { state } = useContext(Context)
  const { authdispatch } = useContext(AuthContext)
  // const showToast = () => {
  //   toast.success(
  //     data.is_wishlist
  //       ? 'Item removed from wishlist'
  //       : 'Item added to Wishlist ',
  //     {
  //       autoClose: 500,
  //       hideProgressBar: true,
  //     },
  //   )
  // }
  const showToast = () => {
    if (data.is_wishlist == true) {
      toast.error('Item removed from wishlist  ', {
        autoClose: 500,
        hideProgressBar: true,
      })
      refreshApi()
    } else {
      toast.success('Item added to Wishlist ', {
        autoClose: 500,
        hideProgressBar: true,
      })
      refreshApi()
    }
  }
  const showToastCart = () => {
    toast.success('Item added to Cart ', {
      autoClose: 500,
      hideProgressBar: true,
    })
    // refreshApi()
    setTimeout(() => {
      refreshApi()
    }, 1000)
  }
  const ErrorToast = () => {
    toast.error('item Out of stock', {
      autoClose: 200,
      hideProgressBar: true,
    })
  }

  const handleWishlistData = (id) => {
    let access_token = state.user_details.access
    let formdata = {}
    baseConfig
      .post(`/wishlists/wishlist/${id}/`, formdata, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        refreshApi()
        showToast()
      })
      .catch((error) => {})
  }

  const addToCart = (id) => {
    let access_token = state.user_details.access
    let formdata = {
      // quantity: 1,
    }

    baseConfig
      .post(`/cart/add-to-cart/${id}/`, formdata, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        const { StatusCode, data } = response?.data
        if (StatusCode === 6000) {
          // handleFilter()
          showToastCart()
          // refreshApi()
        } else if (StatusCode === 6001) {
          // handleFilter()
          ErrorToast()
        }
      })
      .catch((error) => {
        ErrorToast()
      })
  }
  const [list, setList] = useState([
    {
      id: 1,
      name: '1',
      status: true,
    },
    {
      id: 2,
      name: '5',
      status: false,
    },
    {
      id: 3,
      name: '0',
      status: false,
    },
    {
      id: 4,
      name: '6',
      status: false,
    },
    {
      id: 4,
      name: '8',
      status: false,
    },
    {
      id: 4,
      name: '11',
      status: false,
    },
    {
      id: 4,
      name: '15',
      status: false,
    },
    {
      id: 4,
      name: '20',
      status: false,
    },
  ])
  return (
    <>
      <MainContainer>
        <ToastContainer />
        <ImgContainer to={`/product/${data.id}/`}>
          <WiheConatiner>
            <Img src={data.image} alt="Image" />
          </WiheConatiner>
        </ImgContainer>
        <CategoryName>{data.product}</CategoryName>
        <PriceBox>
          {data.offer_price == 0 ? (
            <DiscountPrice>₹ {data.price}</DiscountPrice>
          ) : (
            <>
              {' '}
              <DiscountPrice>₹{data.offer_price} </DiscountPrice>
              <NormalPrice>₹{data.price} </NormalPrice>
            </>
          )}
          {data.stock == 0 ? (
            <Stock>Out of Stock</Stock>
          ) : data.stock <= 5 ? (
            <Stock>only {data.stock} items left</Stock>
          ) : data.stock <= 10 ? (
            <Stock>only few items left</Stock>
          ) : null}
        </PriceBox>
        <ButtonContainer>
          <BuyButton
            className="category_button"
            onClick={() => {
              if (state.user_details.is_verified) {
                if (data.is_cart == true) {
                  navigate('/cart')
                } else {
                  addToCart(data.id)
                }
              } else {
                // alert('login')
                const auth_modal_details = {
                  auth_modal_name: 'login',
                  is_auth_modal: true,
                }
                authdispatch({
                  type: 'AUTH_MODAL',
                  auth_modal_details,
                })
              }
            }}
          >
            {data.is_cart ? 'Go To Cart' : 'Add to Cart'}
            <Image src={cart} alt="image" />
          </BuyButton>
        </ButtonContainer>

        {data.offers && (
          <DiscountBox>
            <IconBox src={discount} alt="Image" />
            <DiscountRate>{data.offers} Off</DiscountRate>
          </DiscountBox>
        )}
        <Favourite
          onClick={() => {
            if (state.user_details.is_verified) {
              handleWishlistData(data.id)
            } else {
              // alert('login')
              const auth_modal_details = {
                auth_modal_name: 'login',
                is_auth_modal: true,
              }
              authdispatch({
                type: 'AUTH_MODAL',
                auth_modal_details,
              })
            }
          }}
        >
          <HeartBox src={data.is_wishlist ? heartfill : heart} alt="Image" />
        </Favourite>
      </MainContainer>
    </>
  )
}
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  padding: 20px;
  background: ${THEME_COLORS.light_orange};
  border-radius: 16px;
  position: relative;
  height:max-content;
  @media screen and (max-width: 1280px) {
    padding: 14px;
  }
  div.flex {
    display: flex;
  }
  /* &:last-child {
    display: none;
  } */
`
const ImgContainer = styled(Link)`
  margin-bottom: 20px;
  cursor: pointer;
`
const WiheConatiner = styled.div`
  width: 250px;
  height: 250px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 7px;
`
const Img = styled.img`
  display: block;
  border-radius: 9px;
  object-fit: contain;
  height: 230px;
  width: 250px;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    height: 210px;
    width: 200px;
  }
`
const CategoryName = styled.h4`
  font-family: 'poppins-medium';
  color: ${THEME_COLORS.background_1};
  margin-bottom: 10px;
  text-transform: capitalize;
  @media screen and (max-width: 1400px) {
    /* font-size: 18px; */
    min-height: 55px;
    margin-bottom: 0px;
  }
  @media screen and (max-width: 1280px) {
    min-height: 0px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 980px) {
    font-size: 18px;
  }
  @media screen and (max-width: 768px) {
    font-size: 18px;
    min-height: 55px;
  }
  @media screen and (max-width: 640px) {
    min-height: auto;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 480px) {
    font-size: 16px;
    text-align: center;
  }
`
const PriceBox = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  margin-bottom: 25px;
  position: relative;
  @media screen and (max-width: 480px) {
    justify-content: center;
  }
`
const DiscountPrice = styled.div`
  /* font-size: 28px; */
  font-size: 20px;
  font-family: 'poppins-medium';
  color: ${THEME_COLORS.background_1};
  margin-right: 16px;
  @media screen and (max-width: 980px) {
    font-size: 18px;
  }
  @media screen and (max-width: 480px) {
    font-size: 20px;
  }
  @media screen and (max-width: 360px) {
    font-size: 18px;
  }
`

const NormalPrice = styled.small`
  color: ${THEME_COLORS.background_5};
  font-size: 19px;
  font-family: 'poppins-medium';
  /* margin-top: 10px; */
  text-decoration-line: line-through;
  @media screen and (max-width: 580px) {
    margin-top: 0px;
  }
  @media screen and (max-width: 360px) {
    font-size: 12px;
  }
`
const DiscountBox = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 75px;
`
const IconBox = styled.img`
  display: block;
  width: 100%;
  /* height: 64px; */
`
const DiscountRate = styled.h6`
  position: absolute;
  top: 22px;
  right: 13px;
  width: 65%;
  font-size: 12px;
  font-family: 'poppins-medium';
  line-height: 16px;
  font-weight: 500;
  text-align: center;
  color: ${THEME_COLORS.white};
`
const Favourite = styled.span`
  width: 44px;
  height: 44px;
  position: absolute;
  top: 10px;
  right: 12px;
  background: ${THEME_COLORS.white};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.07);
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
const HeartBox = styled.img`
  display: block;
  width: 24px;
  height: 24px;
`
const BuyButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 32px;
    margin-left: ${(props) => (props.margin === '20px' ? '20px' : '0')};
    cursor: pointer;
    color: ${THEME_COLORS.white};
    font-family: "poppins-semibold";
    border-radius: 6px;
    width: max-content;
    background-color: ${THEME_COLORS.secondary};
    border: 2px solid ${THEME_COLORS.secondary};
    // &:hover {
    //   background-color: ${THEME_COLORS.white};
    //   color: ${THEME_COLORS.secondary};
    // }
    font-size: ${(props) => (props.size === 'small' ? '14px' : '16px')};
    font-weight: ${(props) => (props.size === 'small' ? '500' : '600')};
    &.category_button {
        width: 100%;
        text-align: center;
        padding: 12px 18px;
        height: 48px;
    }
    &.white {
        background-color: ${THEME_COLORS.white};
    }
`
const Text = styled.p`
  &.white {
    color: ${THEME_COLORS.secondary};
  }
`
const Image = styled.img`
  margin-left: 10px;
  width: 24px;
  height: 24px;
  display: block;
`
const ButtonContainer = styled.p`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`
const Stock = styled.div`
  display: flex;
  align-items: center;
  color: red;
  font-size: 14px;
  font-family: 'poppins-medium';
  margin-bottom: 20px;
  position: absolute;
  top: 30px;
`
