import React from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../ThemeConfig'

function SecondaryButton({ text, onClick, size }) {
  return (
    <MainContainer onClick={onClick} size={size}>
      {text}
    </MainContainer>
  )
}

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: ${(props) => (props.size === 'small' ? '10px 20px' : '16px 32px')};
  cursor: pointer;
  color: ${THEME_COLORS.secondary};
  border-radius: 6px;
  width: max-content;
  background-color: ${THEME_COLORS.white};
  border: 2px solid ${THEME_COLORS.secondary};
  font-family: 'poppins-medium';
  /* &:hover {
    background-color: ${THEME_COLORS.secondary};
    color: ${THEME_COLORS.light_orange};
  } */
  font-size: ${(props) => (props.size === 'small' ? '14px' : '16px')};
  font-weight: ${(props) => (props.size === 'small' ? '500' : '600')};
  @media screen and (max-width: 980px) {
    padding: 12px 24px;
  }
  @media screen and (max-width: 768px) {
    padding: 8px 16px;
    font-size: 14px;
  }
`
export default SecondaryButton
