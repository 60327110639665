import React, { useState } from 'react'
import styled from 'styled-components'
import close from './../../../../assets/icons/close-square.svg'
import medicine from './../../../../assets/images/medicine1.png'
import medicine2 from './../../../../assets/images/medicine2.png'
import { Link, useNavigate } from 'react-router-dom'

export default function ConfirmOrder({
  setConfirmOrder,
  setConfirmAddress,
  setModal,
  confirmOrderData,
  setConfirmOrderData,
  confirmProducts,
}) {
  const [shippingFee, setSippingFee] = useState('50')
  function formatDate(timestamp) {
    const dateParts = timestamp.split('T')[0].split('-')
    const year = dateParts[0]
    const month = dateParts[1]
    const day = dateParts[2]

    return `${day}-${month}-${year}`
  }
  const time = confirmOrderData[0]?.time
  const formattedDate = formatDate(time)
  const total = parseFloat(confirmOrderData[0].total_amount)
  // const total_amount = total + 50
  // const new_total = total_amount.toString()

  return (
    <MainContainer>
      <TopContainer>
        <Titlecontainer>
          <Heading>Your Order Confirmed!</Heading>
          <Image
            onClick={() => {
              setConfirmOrder(false)
              setConfirmAddress(false)
              setModal(false)
              window.location.reload(); 
            }}
          >
            <Img src={close} alt="Image" />
          </Image>
        </Titlecontainer>
        {/* <Titlecontainer> */}
        <Name>
          Hello
          {confirmOrderData[0].customer_name}
        </Name>
        <Message>
          Your Order has been confirmed and will be shipping within next seven
          days.
        </Message>
        {/* </Titlecontainer> */}
      </TopContainer>
      <OrderDetails>
        <Details>
          <Title>Order Date</Title>
          <Info> {formattedDate} </Info>
        </Details>
        <Details>
          <Title>Order No</Title>
          <Info>{confirmOrderData[0].order_id}</Info>
        </Details>
        {/* <Details>
                    <Title>Pyment</Title>
                    <Info>03 Nav 2022</Info>
                </Details> */}
        <Details className="shipping-address">
          <Title>Shipping Address</Title>
          <Info>
            {confirmOrderData[0].address.address}, 
            {confirmOrderData[0].address.pincode},
            {confirmOrderData[0].address.location},
          </Info>
        </Details>
      </OrderDetails>
      {confirmProducts?.map((product) => (
        <ProductDetails>
          <SingleItem>
            <ItemLeft>
              <ImageContainer>
                <Img src={product.image} alt="Image" />
              </ImageContainer>
              <Product>
                <Name>{product.product_name}</Name>
                <PackDetail>Pack of {product.pack}</PackDetail>
                <PackDetail>{product.quantity_in_pack} Capsules</PackDetail>
              </Product>
            </ItemLeft>
            <ItemRight>
              <Price>₹ {product.subtotal}</Price>
            </ItemRight>
          </SingleItem>
        </ProductDetails>
      ))}

      <PriceTable>
        <PriceDetails>
          <PriceTop>
            <PriceName>Subtotal</PriceName>
            <Price>₹ {confirmOrderData[0].total_amount} </Price>
          </PriceTop>
          <PriceTop>
            {/* <PriceName>Shipping Fee</PriceName>
            <Price>₹{shippingFee}.00</Price> */}
          </PriceTop>
          {/* <PriceTop>
                        <PriceName>Discount</PriceName>
                        <Price>₹ 36.00</Price>
                    </PriceTop> */}

          <PriceBottom>
            <Total>Total</Total>
            <TotalPrice>₹{total}</TotalPrice>
          </PriceBottom>
        </PriceDetails>
      </PriceTable>
      <BottomContainer>
        <Paragraph>
          Well be sending a shipping confirmetion the items shipped
          successfully.
        </Paragraph>
      </BottomContainer>
      <TrackyourOrder to="/order-status">Track Your Order</TrackyourOrder>
    </MainContainer>
  )
}
const MainContainer = styled.div`
  /* max-width: 729px; */
  width: 800px;
  padding: 32px;
  height: 100vh;
  /* height: 700px; */
  overflow: scroll;
  /* max-height: 90vh; */

  ::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: 1280px) {
    width: 729px;
  }
  @media screen and (max-width: 980px) {
    width: 630px;
  }
  @media screen and (max-width: 640px) {
    width: 520px;
  }
  @media screen and (max-width: 540px) {
    width: 465px;
  }
  @media screen and (max-width: 480px) {
    width: 360px;
  }
  @media screen and (max-width: 360px) {
    width: 300px;
  }
`
const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* margin-bottom: 32px; */
  border-bottom: 1px solid #bbbbbb;
  padding-bottom: 26px;
`
const Titlecontainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Heading = styled.h3`
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  color: #0fc642;
  @media screen and (max-width: 640px) {
    font-size: 20px;
  }
`
const Image = styled.div`
  width: 24px;
  cursor: pointer;
`
const Img = styled.img`
  width: 100%;
  display: block;
`
const Name = styled.p`
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #161616;
  @media screen and (max-width: 640px) {
    font-size: 18px;
  }
`
const Message = styled.p`
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #464646;
  @media screen and (max-width: 640px) {
    font-size: 12px;
  }
`
const OrderDetails = styled.div`
  display: flex;
  border-bottom: 1px solid #bbbbbb;
  justify-content: space-between;
  padding: 26px 0;
  @media screen and (max-width: 640px) {
    flex-wrap: wrap;
    gap: 8px;
  }
`
const Details = styled.div`
  @media screen and (max-width: 640px) {
    &.shipping-address {
      width: 40%;
    }
  }
  @media screen and (max-width: 640px) {
    width: 48%;
    &.shipping-address {
      width: 48%;
    }
  }
  @media screen and (max-width: 480px) {
    &.shipping-address {
      width: 100%;
    }
  }
`
const Title = styled.p`
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #6b6b6b;
  @media screen and (max-width: 640px) {
    font-size: 13px;
  }
`
const Info = styled.p`
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #464646;
  @media screen and (max-width: 640px) {
    font-size: 12px;
  }
`
const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  border-bottom: 1px solid #bbbbbb;
  padding: 26px 0;
`
const SingleItem = styled.div`
  display: flex;
  justify-content: space-between;
`
const ItemLeft = styled.div`
  display: flex;
  gap: 20px;
`
const ImageContainer = styled.div`
  width: 93px;
  border-radius: 8px;
  @media screen and (max-width: 1280px) {
    display: none;
  }
`
const Product = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const PackDetail = styled.p`
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  color: #6b6b6b;
  @media screen and (max-width: 640px) {
    font-size: 14px;
  }
`
const ItemRight = styled.div`
  width: 30%;
  @media screen and (max-width: 1280px) {
    width: 40%;
  }
  @media screen and (max-width: 640px) {
    width: max-content;
  }
`
const Price = styled.td`
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #161616;
`
const PriceTable = styled.table`
  padding: 22px 0;
  display: flex;
  justify-content: end;
`
const PriceDetails = styled.table`
  /* display: flex; */
  /* justify-content: end; */
  width: 40%;
  align-items: flex-end;
  flex-direction: column;
  border-bottom: 1px solid #aaaaaa;
  @media screen and (max-width: 1280px) {
    width: 40%;
  }
  @media screen and (max-width: 980px) {
    width: 80%;
  }
  @media screen and (max-width: 640px) {
    width: 80%;
  }
`
const PriceTop = styled.tr`
  /* display: flex; */
`
const PriceName = styled.td`
  font-family: 'poppins-regular';
  font-style: normal;
  padding: 7px 0;
  font-weight: 500;
  font-size: 20px;
  color: #6b6b6b;
  @media screen and (max-width: 640px) {
    font-size: 16px;
  }
`
const PriceBottom = styled.tr`
  width: 100%;
`
const Total = styled.td`
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #161616;
  padding: 16px 0;
  border-right: none;
`
const TotalPrice = styled.td`
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #161616;
  padding: 16px 0;
`
const BottomContainer = styled.div``
const Paragraph = styled.p`
  font-family: 'poppins-regular';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #464646;
  text-align: center;
  @media screen and (max-width: 640px) {
    font-size: 14px;
  }
`
const TrackyourOrder = styled(Link)`
  /* width: 200px; */
  padding: 10px;
  background-color: #106f39;
  color: #fff;
  font-size: 16px;
  font-family: 'poppins-medium';
  border-radius: 6px;
  text-align: center;
  display: inline-block;
  width: 100%;
  margin-top: 20px;
`
