import styled from "styled-components";
import { TextField } from "@mui/material";

const CssTextField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
            borderColor: "#56b57f",
            color: "#56b57f",
        },
        "&.Mui-focused fieldset": {
            borderColor: "green",
        },
    },
    "& label.Mui-focused": {
        color: "green",
    },
});

export default CssTextField;
