import React from 'react'
import styled from 'styled-components'
import { THEME_COLORS } from '../../../../src/ThemeConfig'
import logout from '../../../../src/assets/icons/logout.svg'

export default function Logout({ setModal }) {
  // const handleEnter = (event) => {
  //     if (event.key.toLowerCase() === "enter") {
  //         const form = event.target.form;
  //         const index = [...form].indexOf(event.target);
  //         form.elements[index + 1].focus();
  //         event.preventDefault();
  //     }
  // };

  const logOut = () => {
    localStorage.clear()
    window.location = '/'
  }

  // const [anchorEl, setAnchorEl] = useState(null);
  // const [selected, setSelected] = useState(false);
  // const handleClose = () => {
  //     setAnchorEl(null);
  //     // setSelected(!selected);
  // };

  return (
    <MainCoontainer>
      {/* <LogoutIcon>
        <Img src={logout} />
      </LogoutIcon> */}
      <LogoutTitle>Are you sure, you want to Log out?</LogoutTitle>
      {/* <SubTitle>Are you sure, you want to Log out?</SubTitle> */}
      <Container>
        <Bottom>
          {/* <Button
            text={'Cancel'}
            size={'small'}
            onClick={() => {
              setModal(false)
            }}
          />
          <PrimaryButton size={'small'} text={'Logout'} onClick={logOut} /> */}
          <PrimaryButton
            onClick={() => {
              setModal(false)
            }}
          >
            Cancel
          </PrimaryButton>
          <SecondaryButton onClick={logOut}>Logout</SecondaryButton>
        </Bottom>
      </Container>
    </MainCoontainer>
  )
}
const MainCoontainer = styled.div`
  /* width: 350px;
  height: 290px; */
  background: ${THEME_COLORS.white};
  border-radius: 16px;
  overflow: hidden;
  padding: 30px;
  @media screen and (max-width: 480px) {
    padding: 20px;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`
const LogoutIcon = styled.div`
  width: 48px;
  height: 48px;
`
const Img = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`
const Bottom = styled.div`
  /* width: 60%; */
  display: flex;
  justify-content: space-between;
  margin-top: 34px;
`
const LogoutTitle = styled.h4`
  font-size: 22px;
  font-family: 'poppins-semibold';
  color: ${THEME_COLORS.background_1};
  margin-top: 18px;

  @media screen and (max-width: 360px) {
    font-size: 14px;
  }
`
const SubTitle = styled.p`
  font-size: 16px;
  font-family: 'poppins-regular';
  width: max-content;
  color: ${THEME_COLORS.text_2};
  margin-top: 8px;
`
const PrimaryButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  margin-left: 20px;
  cursor: pointer;
  color: ${THEME_COLORS.white};
  font-family: 'poppins-semibold';
  border-radius: 6px;
  width: max-content;
  background-color: ${THEME_COLORS.secondary};
  border: 2px solid ${THEME_COLORS.secondary};
  font-size: 14px;

  @media screen and (max-width: 480px) {
    /* padding: 10px 30px; */
  }
  @media screen and (max-width: 360px) {
    /* padding: 10px 30px; */
    font-size: 13px;
    margin-left: 0px;
  }
`
const SecondaryButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  margin-left: 20px;
  cursor: pointer;
  color: ${THEME_COLORS.secondary};
  border-radius: 6px;
  width: max-content;
  background-color: ${THEME_COLORS.white};
  border: 2px solid ${THEME_COLORS.secondary};
  font-family: 'poppins-medium';
  font-size: 14px;
  cursor: pointer;
  @media screen and (max-width: 480px) {
    /* padding: 10px 30px; */
  }
  @media screen and (max-width: 360px) {
    /* padding: 10px 30px; */
    font-size: 13px;
  }
`
